import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { t } from 'i18next'
import CustomRadioButton from '../../../components/CustomRadioButton'
import CustomInput from '../../../components/CustomInput'
import { Button } from '../../../components/Button'
import {
  CreateEventParams,
  Event3StoreContext,
} from '../../../store/event3Store'
import ModalRegular from '../../../components/ModalRegular'
import { Location, LocationInterface } from '../../../Models/Location'
import { prop } from 'cheerio/lib/api/attributes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faLocationPin,
  faMapPin,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'
import { faLocationPen, faLocationPlus } from '@fortawesome/pro-solid-svg-icons'
import { LocationBox } from './LocationBox'
import { Slider } from '../../../components/Slider'
import { UserStoreContext } from '../../../store/userStore'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import CustomInputNew from '../../../components/CustomInputNew'

export enum LOCATION_TYPE {
  FREE_TEXT = 'freetext',
  COORD = 'coord',
  ADDRESS = 'address',
  SAVED = 'saved',
}

export const useEventPlaceSelections = () => {
  const { t } = useTranslation()

  return [
    { value: LOCATION_TYPE.ADDRESS, label: t('events.addAdress') },
    { value: LOCATION_TYPE.FREE_TEXT, label: t('events.freeTextPlace') },
    { value: LOCATION_TYPE.COORD, label: t('events.coordinates') },
    { value: LOCATION_TYPE.SAVED, label: t('events.chooseSavedPlace') },
  ]
}

interface Props {
  value: string
  onChange: (e: CreateEventParams) => void
  newEvent: CreateEventParams
  onDone: () => void // hämta värdena och visa från riktig db
  onSave: () => void // spara värdena
  onClose: () => void
  locations: Location[]
  openModalForCreatingNewLocation?: boolean
  setOpenModalForCreatingNewLocation?: (value: boolean) => void
}

const initialNewLocationValues: LocationInterface = {
  id: undefined,
  type: undefined,
  shared: undefined,
  name: undefined,
  lat: undefined,
  long: undefined,
  city: undefined,
  address: undefined,
  zipcode: undefined,
}

export const SavedLocationSlider: React.FC<{
  locations: Location[]
}> = ({ locations }) => {
  const [saveLocationShared, setSavedLocationShared] = useState(true)
  const { currentUserRole } = useContext(UserStoreContext)
  const [selectedLocation, setSelectedLocation] =
    useState<LocationInterface | null>()
  const { archiveLocation, fetchLocations, shareLocation } =
    useContext(Event3StoreContext)
  const { t } = useTranslation()

  const { schoolId } = useParams()

  return (
    <div>
      {selectedLocation ? (
        <div className="bg-white rounded-2xl shadow-sm my-2">
          <LocationBox
            location={selectedLocation}
            onSaveLocationModal={() => {}}
            hideOnSave={true}
            onRemove={() => {
              if (selectedLocation.id && schoolId) {
                archiveLocation(selectedLocation.id).finally(() => {
                  fetchLocations([schoolId]).finally(() => {
                    setSelectedLocation(null)
                  })
                })
              }
            }}
          />
          <div className="flex mr-4 items-center justify-end gap-x-2">
            <div className="mb-2 font-semibold">
              <>{t('events.sharePlace')}</>
            </div>
            <Slider
              disabled={
                selectedLocation.ownerId === currentUserRole?.id ? false : true
              }
              id={0}
              onSliderClick={() => {
                if (selectedLocation.id && schoolId) {
                  shareLocation(
                    selectedLocation.id,
                    selectedLocation.shared === 'private'
                      ? 'shared'
                      : 'private',
                  ).finally(() => {
                    fetchLocations([schoolId]).finally(() => {
                      setSelectedLocation({
                        ...selectedLocation,
                        shared:
                          selectedLocation.shared === 'private'
                            ? 'shared'
                            : 'private',
                      })
                      //setSelectedLocation(null)
                    })
                  })
                }
              }}
              onBoxClick={() => {}}
              value={selectedLocation.shared === 'private' ? false : true}
            />
          </div>
        </div>
      ) : (
        <div className="p-2 text-center font-semibold text-gray-400">
          <>{t('events.noPlaceChoosen')}</>
        </div>
      )}
      <div className="border-t-2 border-dashed border-gray-400 my-4"></div>

      <div className={'flex justify-center gap-x-6 w-full py-2 items-center'}>
        {saveLocationShared ? (
          <Button
            variant="borderBlue"
            size="sm"
            fontSize="font-bold"
            label={t('events.sharedPlaces')}
            onClick={() => {
              setSelectedLocation(null)
              setSavedLocationShared(true)
            }}
          />
        ) : (
          <p
            className="cursor-pointer font-semibold"
            onClick={() => {
              setSelectedLocation(null)
              setSavedLocationShared(true)
            }}
          >
            {t('events.sharedPlaces')}
          </p>
        )}

        {!saveLocationShared ? (
          <Button
            variant="borderBlue"
            size="sm"
            fontSize="font-bold"
            label={t('events.myPlaces')}
            onClick={() => {
              setSelectedLocation(null)
              setSavedLocationShared(false)
            }}
          />
        ) : (
          <p
            className="cursor-pointer font-semibold"
            onClick={() => {
              setSelectedLocation(null)
              setSavedLocationShared(false)
            }}
          >
            {t('events.myPlaces')}
          </p>
        )}
      </div>
      <div className="flex flex-col gap-y-2">
        {locations
          .filter((loc) => {
            if (saveLocationShared) {
              return loc.shared && loc.shared === 'shared'
            } else {
              return (
                loc.shared &&
                loc.shared === 'private' &&
                loc.owner?.id === currentUserRole?.id
              )
            }
          })
          .map((location) => {
            return (
              <div
                className={classNames(
                  'rounded-xl bg-white p-3 flex justify-between',
                )}
                onClick={() => {
                  if (selectedLocation?.id === location.id) {
                    setSelectedLocation(null)
                  } else {
                    setSelectedLocation(location.asJson())
                  }

                  //setNewLocationDone(true)
                  /*props.onChange({
                        ...props.newEvent,
                        location: location.asJson(),
                      })*/
                }}
              >
                <div className="flex flex-row w-full gap-x-2">
                  <div>
                    <FontAwesomeIcon
                      icon={faMapPin}
                      className="text-eventsMain"
                    />
                  </div>
                  <div> {location.name}</div>
                </div>
                <div
                  className={classNames(
                    'w-6 h-6 rounded-full border border-orange-300 justify-center flex items-center',
                    selectedLocation?.id === location.id ? 'bg-orange-300' : '',
                  )}
                >
                  {selectedLocation?.id === location.id && (
                    <FontAwesomeIcon color="#ffffff" icon={faCheck} />
                  )}
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export const CreateLocation: React.FC<Props> = observer((props: Props) => {
  const eventPlaceSelections = useEventPlaceSelections()
  const nameInputRef = useRef<HTMLInputElement>(null)
  const streetInputRef = useRef<HTMLInputElement>(null)
  const zipInputRef = useRef<HTMLInputElement>(null)
  const cityInputRef = useRef<HTMLInputElement>(null)
  const [openModal, setOpenModal] = useState(false)
  const [locationType, setLocationType] = useState<LOCATION_TYPE>(
    props.newEvent.locationType,
  )
  const [newLocation, setNewLocation] = useState<LocationInterface>()
  const [saveLocationShared, setSavedLocationShared] = useState(true)
  const [locationsToShow, setLocationsToShow] = useState<Location[]>([])
  const [newLocationDone, setNewLocationDone] = useState(false)
  const [saveLocationModal, setSaveLocationModal] = useState(false)
  const [saveShareToSchool, setSaveShareToSchool] = useState(false)
  const [showEditLocationsModal, setShowEditLocationModal] = useState(false)
  const { schoolId } = useParams()
  const [location, setLocation] = useState({ lat: '', long: '' })

  const { saveLocation, fetchLocations } = useContext(Event3StoreContext)

  const { currentUserRole } = useContext(UserStoreContext)
  const { t } = useTranslation()

  useEffect(() => {
    // Lägg till filter här på vad som ska visas
    setLocationsToShow(props.locations)
  }, props.locations)

  useEffect(() => {
    setLocationType(props.newEvent.locationType)
    if (props.newEvent.locationType !== LOCATION_TYPE.SAVED) setOpenModal(true)
    setNewLocation({
      ...initialNewLocationValues,
      type: props.newEvent.locationType,
    })
  }, [props.newEvent.locationType])

  return (
    <>
      {/* Popup for editing the locations */}

      <ModalRegular
        title={t('events.editPlace')}
        isOpen={showEditLocationsModal}
        onClose={() => {
          setShowEditLocationModal(false)
        }}
        className="!max-w-lg p-4"
        classNameTitle="flex justify-center text-black w-full p-2"
      >
        <div>
          <SavedLocationSlider locations={props.locations} />
          <div className="flex justify-center p-4 w-full">
            <Button
              size="sm"
              className="w-[50%]"
              fontSize="font-bold"
              label={t('general.done')}
              variant={'fullBlue'}
              onClick={async () => {
                setShowEditLocationModal(false)
              }}
            />
          </div>
        </div>
      </ModalRegular>

      {props.value === LOCATION_TYPE.SAVED && (
        <div>
          <div
            className={'flex justify-center gap-x-6 w-full py-2 items-center'}
          >
            {saveLocationShared ? (
              <Button
                variant="borderBlue"
                size="sm"
                fontSize="font-bold"
                label={t('events.sharedPlaces')}
                onClick={() => {
                  setSavedLocationShared(true)
                }}
              />
            ) : (
              <p
                className="cursor-pointer font-semibold"
                onClick={() => {
                  setSavedLocationShared(true)
                }}
              >
                {t('events.sharedPlaces')}
              </p>
            )}

            {!saveLocationShared ? (
              <Button
                variant="borderBlue"
                size="sm"
                fontSize="font-bold"
                label={t('events.myPlaces')}
                onClick={() => {
                  setSavedLocationShared(false)
                }}
              />
            ) : (
              <p
                className="cursor-pointer font-semibold"
                onClick={() => {
                  setSavedLocationShared(false)
                }}
              >
                {t('events.myPlaces')}
              </p>
            )}
          </div>

          <div className="flex flex-col gap-y-2 mb-12 max-h-[250px] overflow-y-auto ">
            {props.locations
              .filter((loc) => {
                if (saveLocationShared) {
                  return loc.shared && loc.shared === 'shared'
                } else {
                  return (
                    loc.shared &&
                    loc.shared === 'private' &&
                    loc.owner?.id === currentUserRole?.id
                  )
                }
              })
              .map((location) => {
                return (
                  <div
                    className={classNames(
                      'rounded-2xl bg-white p-3 flex justify-between shadow-sm',
                    )}
                    onClick={() => {
                      setNewLocation(location.asJson())
                      setNewLocationDone(true)
                      /*props.onChange({
                        ...props.newEvent,
                        location: location.asJson(),
                      })*/
                    }}
                  >
                    <div className="flex flex-row w-full gap-x-2">
                      <div>
                        <FontAwesomeIcon
                          icon={faMapPin}
                          className="text-eventsMain"
                        />
                      </div>
                      <div> {location.name}</div>
                    </div>
                    <div
                      className={classNames(
                        'w-6 h-6 rounded-full border border-orange-300 justify-center flex items-center',
                        newLocation?.id === location.id ? 'bg-orange-300' : '',
                      )}
                    >
                      {newLocation?.id === location.id && (
                        <FontAwesomeIcon color="#ffffff" icon={faCheck} />
                      )}
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      )}
      {newLocation && saveLocationModal && (
        <ModalRegular
          title={t('general.saveLocation')}
          isOpen={saveLocationModal}
          onClose={() => {
            setSaveLocationModal(false)
          }}
          className="!max-w-sm relative"
          classNameTitle="text-black flex justify-center p-4 w-full"
        >
          <LocationBox
            hideOnSave={true}
            hideRemove={true}
            location={newLocation}
            onSaveLocationModal={() => setSaveLocationModal(true)}
            onRemove={() => {
              setNewLocationDone(false)
              setNewLocation({ ...newLocation, type: LOCATION_TYPE.SAVED })
              setLocationType(LOCATION_TYPE.SAVED)
              props.onChange({
                ...props.newEvent,
                locationType: LOCATION_TYPE.SAVED,
              })
            }}
          />
          <div className="flex justify-center items-center gap-x-8 absolute bottom-4 left-0 right-0">
            <div className="flex justify-start  gap-x-2">
              <div className=" font-semibold">
                <>{t('events.sharePlace')}</>
              </div>
              <Slider
                id={0}
                onSliderClick={() => {
                  setSaveShareToSchool(!saveShareToSchool)
                }}
                onBoxClick={() => {}}
                value={saveShareToSchool}
              />
            </div>
            <Button
              label={t('documents.save')}
              size="sm"
              variant="fullBlue"
              onClick={async () => {
                if (currentUserRole) {
                  await saveLocation(
                    newLocation,
                    [currentUserRole?.school_pointer.id],
                    saveShareToSchool ? 'shared' : 'private',
                  ).finally(() => {
                    setSaveLocationModal(false)
                    if (schoolId) {
                      fetchLocations([schoolId])
                    }
                  })
                }
              }}
            />
          </div>
        </ModalRegular>
      )}
      {newLocationDone &&
        newLocation &&
        props.value !== LOCATION_TYPE.SAVED && (
          <LocationBox
            location={newLocation}
            onSaveLocationModal={() => setSaveLocationModal(true)}
            onRemove={() => {
              setNewLocationDone(false)
              setNewLocation({ ...newLocation, type: LOCATION_TYPE.SAVED })
              setLocationType(LOCATION_TYPE.SAVED)
              props.onChange({
                ...props.newEvent,
                locationType: LOCATION_TYPE.SAVED,
              })
            }}
          />
        )}

      <div
        className="mt-2 flex justify-between gap-x-36  absolute bottom-8 "
        id="locationBtnsContainer"
      >
        {/* <Button
          size="sm"
          variant={'borderRed'}
          label={t('general.cancel')}
          onClick={() => {
            props.onClose()
          }}
        /> */}
        <div className="flex justify-end relative" id="editLocationBtn">
          <Button
            size="sm"
            variant={'borderGreen'}
            label={'edit Location'}
            icon={faLocationPen}
            onClick={() => {
              setShowEditLocationModal(true)
            }}
          />
        </div>
        {newLocationDone && (
          <Button
            label={t('events.addPlace')}
            size="sm"
            variant="fullBlue"
            onClick={() => {
              props.onChange({ ...props.newEvent, location: newLocation })
              props.onClose()
            }}
          />
        )}
      </div>

      {/* popup that show the choosed alternative */}
      <ModalRegular
        title={
          locationType === LOCATION_TYPE.ADDRESS
            ? '!!Add a New Address'
            : locationType === LOCATION_TYPE.FREE_TEXT
            ? '!!Enter Address as Free Text'
            : locationType === LOCATION_TYPE.COORD
            ? '!!Add Address by Coordinates'
            : ''
        }
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false)
          props.onClose()
        }}
        className="!max-w-lg p-6 relative !min-h-[400px]"
        classNameTitle="text-black w-full flex justify-center mb-2 -mt-3 py-2"
      >
        <div className="flex flex-col gap-2">
          {locationType !== LOCATION_TYPE.SAVED && (
            <>
              {/* <CustomInput
                required
                label={t('documents.name')}
                ref={nameInputRef}
                value={newLocation?.name}
                onChange={(value) => {
                  setNewLocation({ ...newLocation, name: value })
                }}
              /> */}
              <CustomInputNew
                required
                onChange={(value) => {
                  setNewLocation({ ...newLocation, name: value })
                }}
                placeholder="Location Name"
                longPlaceholder="Ange Location Name"
                label="!!Location Name"
                value={newLocation?.name}
              />
            </>
          )}
          {locationType === LOCATION_TYPE.COORD && (
            <div className="flex flex-col gap-y-4">
              {/* ------------------------------------------- */}
              <CustomInputNew
                required
                onChange={(value) => {
                  setLocation({ ...location, lat: value })
                }}
                placeholder={t('events.latitude')}
                longPlaceholder={t('events.exLatPlaceholder')}
                label={t('events.latitude')}
                onlyNumbersAndDot={true}
                value={location?.lat ? String(location?.lat) : undefined}
              />
              {/* <div>
                <div className="flex flex-row gap-x-2 ml-2">
                  {(!newLocation?.lat || newLocation.lat === 0) && (
                    <div className=" text-red-500 ">*</div>
                  )}
                  <label className="text-sm font-bold mb-2">
                    <>{t('events.latitude')}</>
                  </label>
                </div>
                <input
                  type="number"
                  inputMode="numeric"
                  placeholder={t('events.exLatPlaceholder')}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={
                    newLocation?.lat ? String(newLocation?.lat) : undefined
                  }
                  onChange={(value) => {
                    setNewLocation({
                      ...newLocation,
                      lat: Number(value.target.value),
                    })
                  }}
                />
              </div> */}
              {/* -------------------------------------------- */}
              {/* <div>
                <div className="flex flex-row gap-x-2 ml-2">
                  {(!newLocation?.long || newLocation.long === 0) && (
                    <div className=" text-red-500 ">*</div>
                  )}
                  <label className="text-sm font-bold mb-2">
                    <>{t('events.longitude')}</>
                  </label>
                </div>

                <input
                  placeholder={t('events.exLongPlaceholder')}
                  inputMode="numeric"
                  type="number"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={
                    newLocation?.long ? String(newLocation?.long) : undefined
                  }
                  onChange={(value) => {
                    setNewLocation({
                      ...newLocation,
                      long: Number(value.target.value),
                    })
                  }}
                />
              </div> */}
              <CustomInputNew
                required
                onChange={(value) => {
                  setLocation({ ...location, long: value })
                }}
                placeholder={t('events.exLongPlaceholder')}
                longPlaceholder={t('events.exLongPlaceholder')}
                label={t('events.longitude')}
                onlyNumbersAndDot={true}
                value={location?.long ? String(location?.long) : undefined}
              />
              {/* ------------------------------------------- */}
            </div>
          )}
          {locationType === LOCATION_TYPE.FREE_TEXT && <></>}
          {locationType === LOCATION_TYPE.ADDRESS && (
            <>
              {/* <CustomInput
                label={t('contactList.adress')}
                required
                ref={streetInputRef}
                value={newLocation?.address}
                onChange={(value) => {
                  setNewLocation({ ...newLocation, address: value })
                }}
              /> */}
              <CustomInputNew
                required
                onChange={(value) => {
                  setNewLocation({ ...newLocation, address: value })
                }}
                placeholder="Address"
                longPlaceholder="Ange address"
                label={t('contactList.adress')}
                value={newLocation?.address}
              />
              {/* <CustomInput
                label={t('admin.areaCode')}
                onlyNumbers
                required
                ref={zipInputRef}
                value={newLocation?.zipcode}
                onChange={(value) => {
                  setNewLocation({ ...newLocation, zipcode: value })
                }}
              /> */}
              <CustomInputNew
                required
                onlyNumbers
                onChange={(value) => {
                  setNewLocation({ ...newLocation, zipcode: value })
                }}
                placeholder="ZIP Code"
                longPlaceholder="Ange ZIP Code"
                label={t('admin.areaCode')}
                value={newLocation?.zipcode}
              />
              {/* <CustomInput
                label={t('admin.city')}
                required
                ref={cityInputRef}
                value={newLocation?.city}
                onChange={(value) => {
                  setNewLocation({ ...newLocation, city: value })
                }}
                
              /> */}
              <CustomInputNew
                required
                onChange={(value) => {
                  setNewLocation({ ...newLocation, city: value })
                }}
                placeholder="City"
                longPlaceholder="Ange City Name"
                label={t('admin.city')}
                value={newLocation?.city}
              />
            </>
          )}
          <div className="flex justify-center w-full absolute bottom-0 right-1 py-4">
            {/* <Button
              size="sm"
              variant={'borderRed'}
              label={t('general.cancel')}
              onClick={() => {
                setNewLocation(undefined)
                setOpenModal(false)
              }}
            /> */}
            <Button
              size="sm"
              fontSize="font-bold"
              className="w-[50%]"
              label={t('general.done')}
              variant={'fullBlue'}
              onClick={async () => {
                if (locationType === LOCATION_TYPE.ADDRESS) {
                  if (
                    !newLocation?.name ||
                    !newLocation.address ||
                    !newLocation.zipcode ||
                    !newLocation.city
                  ) {
                    Swal.fire({
                      text: t('events.addInfoLocationText'),
                      icon: 'error',
                      showCancelButton: false,
                      confirmButtonColor: '#7eb5f4',
                      cancelButtonColor: '#d33',
                      confirmButtonText: t('general.ok'),
                      cancelButtonText: t('general.cancel'),
                    })
                    return
                  }
                } else if (locationType === LOCATION_TYPE.FREE_TEXT) {
                  if (!newLocation?.name) {
                    Swal.fire({
                      text: t('events.addNameLocationText'),
                      icon: 'error',
                      showCancelButton: false,
                      confirmButtonColor: '#7eb5f4',
                      cancelButtonColor: '#d33',
                      confirmButtonText: t('general.ok'),
                      cancelButtonText: t('general.cancel'),
                    })
                    return
                  }
                } else if (locationType === LOCATION_TYPE.COORD) {
                  if (!newLocation?.name || !location.lat || !location.long) {
                    Swal.fire({
                      text: t('events.addLongLatLocationText'),
                      icon: 'error',
                      showCancelButton: false,
                      confirmButtonColor: '#7eb5f4',
                      cancelButtonColor: '#d33',
                      confirmButtonText: t('general.ok'),
                      cancelButtonText: t('general.cancel'),
                    })
                    return
                  } else {
                    setNewLocation({
                      ...newLocation,
                      lat: Number(location.lat),
                      long: Number(location.long),
                    })
                  }
                }
                props.onChange({
                  ...props.newEvent,
                  locationType: locationType,
                })
                setOpenModal(false)
                setNewLocationDone(true)
              }}
            />
          </div>
        </div>
      </ModalRegular>
    </>
  )
})
