import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useState } from 'react'
import { faUser } from '@fortawesome/pro-solid-svg-icons'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { LoadingBar } from '../LoadingBar'
import { Kid } from '../../Models/Kid'
import { getImageFromKid } from '../../utils/parseUtils'

interface Props {
  onClick?: () => void
  kid: Kid
  showDepartmentName?: boolean
  naked?: boolean
  makeCenter?: boolean
  onlyName?: boolean
}

const KidListCardHorizontal: React.FC<Props> = (props) => {
  return (
    <>
      <div
        className={classNames(
          'w-full min-h-[60px] overflow-hidden rounded-2xl',
          props.makeCenter ? 'flex justify-center' : '',
          props.naked ? '' : 'shadow-sm cursor-pointer bg-white',
        )}
        onClick={(e) => {
          props.onClick?.()
        }}
      >
        <div className="flex space-x-4 rounded-b-2xl items-center font-bold font-sans text-neutral-600 relative px-2">
          <div
            className={classNames('flex py-3 font-medium justify-start w-full')}
          >
            <>
              <div className="flex flex-row">
                <div className="border shadow items-center bg-gray-200 max-h-[50px] min-h-[50px] max-w-[50px] min-w-[50px] rounded-full overflow-hidden relative">
                  <img
                    src={getImageFromKid(props.kid)}
                    className={classNames(
                      'absolute inset-0 object-cover rounded-full',
                    )}
                    alt="kidPhoto"
                  />
                </div>
              </div>
            </>
            <div className="flex flex-col ml-3 text-sm items-start justify-center">
              {props.onlyName ? (
                <div className="font-bold text-xl">
                  {props.kid.firstName + ' ' + props.kid.lastName}
                </div>
              ) : (
                <>
                  <div className="font-semibold truncate w-[220px] whitespace-nowrap">
                    {props.kid.firstName + ' ' + props.kid.lastName}
                  </div>
                  <div className="font-normal text-xs text-gray-500">
                    {props.kid.kid_pers_id_num}
                  </div>
                  {props.showDepartmentName && (
                    <div className="font-normal text-xs text-gray-500">
                      {props.kid.departmentPointer.klassName}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default KidListCardHorizontal
