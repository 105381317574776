import {
  faMapPin,
  faTrashCan,
  faLocation,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../components/Button'
import { LocationInterface } from '../../../Models/Location'
import { faLocationPlus } from '@fortawesome/pro-solid-svg-icons'
import Swal from 'sweetalert2'
import classNames from 'classnames'

interface LocationBoxProps {
  location: LocationInterface
  onSaveLocationModal: () => void
  onRemove: () => void
  hideRemove?: boolean
  hideOnSave?: boolean
  showIcon?: boolean
  noPadding?: boolean
}

export const openInGoogleMaps = (location: LocationInterface) => {
  let googleMapsUrl = ''

  // Case 1: Address, city, name, and zipcode are available
  if (location.address && location.city && location.zipcode) {
    const address = `${location.address}, ${location.zipcode} ${location.city}`
    googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address,
    )}`
  }
  // Case 2: Coordinates (lat, long) and name are available
  else if (location.lat && location.long && location.name) {
    googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      location.lat,
    )},${encodeURIComponent(location.long)}`
  }
  // Case 3: Freetext name
  else if (location.name) {
    googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      location.name,
    )}`
  } else {
    console.error('Location data is insufficient to open in Google Maps.')
    return
  }

  // Open the Google Maps link in a new tab
  window.open(googleMapsUrl, '_blank')
}

export const LocationBox: React.FC<LocationBoxProps> = ({
  location,
  onSaveLocationModal,
  onRemove,
  hideRemove,
  hideOnSave,
  showIcon,
  noPadding,
}) => {
  const { t } = useTranslation()

  return (
    <div
      onClick={() => openInGoogleMaps(location)}
      className={classNames(
        'flex justify-between rounded-xl cursor-pointer flex-row items-center w-full',
        noPadding ? '' : 'p-4',
      )}
    >
      <div className="">
        {location.name && (
          <div className="flex flex-row gap-x-2 w-full items-center cursor-pointer font-bold justify-center">
            {location.name}
          </div>
        )}

        {location.address && (
          <div className="cursor-pointer">{location.address}</div>
        )}
        <div className="cursor-pointer">
          {location.zipcode && <>{location.zipcode + ' '}</>}
          {location.city && <>{location.city}</>}
        </div>
        {location.lat && <div className="cursor-pointer">{location.lat}</div>}
        {location.long && <div className="cursor-pointer">{location.long}</div>}
      </div>
      <div className="flex flex-row gap-x-2">
        {showIcon && (
          <FontAwesomeIcon
            icon={faMapMarkerAlt}
            size="2x"
            className="text-green-400"
          />
        )}
        {!hideOnSave && (
          <Button
            label="Save"
            fontSize="font-bold"
            variant="borderBlue"
            size="xs"
            icon={faLocationPlus}
            onClick={(e) => {
              e.stopPropagation()
              onSaveLocationModal()
            }}
            title={t('general.saveLocation')}
          />
        )}
        {!hideRemove && (
          <Button
            label={'Remove'}
            variant="borderRed"
            size="xs"
            fontSize="font-bold"
            icon={faTrashCan}
            onClick={(e) => {
              e.stopPropagation()
              Swal.fire({
                text: t('general.archiveLocation') + '🥺',
                icon: 'warning',
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonColor: '#7eb5f4',
                cancelButtonColor: '#d33',
                confirmButtonText: t('general.ok'),
                cancelButtonText: t('general.cancel'),
              }).then(async (result) => {
                if (result.isConfirmed) {
                  onRemove()
                }
              })
            }}
          />
        )}
      </div>
    </div>
  )
}
