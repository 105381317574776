import React from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const DashboardItem: React.FC<{
  title: string
  icon: any
  onClick?: any
  className?: string
  textClass?: string
}> = ({ title, icon, className, onClick, textClass }) => {
  return (
    <div
      onClick={() => {
        if (onClick) {
          onClick()
        }
      }}
      className={'flex flex-col items-center justify-center  cursor-pointer '}
    >
      <div
        className={classNames(
          'h-[80px] w-[80px] sm:h-[88px] hover:scale-[101%] hover:shadow-tyraShadow  sm:w-[88px] 2xl:h-[100px] 2xl:w-[100px] flex justify-center items-center rounded-3xl shadow',
          className,
        )}
      >
        <FontAwesomeIcon className="text-white" icon={icon} size="3x" />
      </div>
      <div className="relative overflow-hidden h-20 whitespace-pre-line">
        <p
          className={classNames('mt-2 text-xs font-bold text-black', textClass)}
        >
          {title}
        </p>
      </div>
    </div>
  )
}
