import {
  faArrowsRepeat,
  faCheck,
  faLeft,
  faLeftFromLine,
  faRight,
  faRightFromLine,
  faTrashCan,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment, { Moment } from 'moment'
import { useEffect, useState } from 'react'
import { getDateForTranslatation } from '../views/events/Events'
import { CalendarCell } from './CalendarCell'
import { CalendarDateIcon } from './CalendarDateIcon'
import {
  CreateEventParams,
  EVENT3_TYPE,
  IsoDate,
  IsoDateTime,
  IsoTime,
  RecurrenceParams,
  Times,
} from '../store/event3Store'
import classNames from 'classnames'
import { Button } from './Button'
import ModalRegular from './ModalRegular'
import i18n from '../i18nextConfig'
import DatePicker from 'react-datepicker'
import {
  faCalendarAlt,
  faCalendarDays,
  faCaretDown,
  faClock,
  faMinus,
  faPen,
  faPenToSquare,
  faPlus,
  faRepeat,
} from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import Singleselect from './SingleSelectOpen'
import { useTranslation } from 'react-i18next'
import {
  createManyEventTimesForBooking,
  useCountToLengthsToMeeting,
  useDaysSelections,
  useLengthsToMeeting,
  useRepeatSelections,
} from '../views/events3/CreateEvent3'
import { motion } from 'framer-motion'
import { EventDots } from '../views/events3/components/EventDots'
import { eventTypeColor } from '../views/events3/components/EventDetailCard'
import { DateOrRepeatPopup } from './DateOrRepeatPopup'
import { ButtonDropdown } from './Dropdown'

interface Props {
  recurringDaysWave?: boolean
  lastMonthDate?: Moment
  selected?: Moment
  onChangeEvent: (e: CreateEventParams) => void
  newEvent?: CreateEventParams
  onDateClick: (
    value: string[],
    lastDate: string,
    recurrenceEndDate?: boolean,
  ) => void
  changeLastMonthDate?: (value: Moment) => void
  changeMonth?: (value: Moment) => void
  onAddTime?: (times: { start: string; end: string }, dates: string[]) => void
  onRemoveTime?: (id: string) => void
  onEditTime?: (id: string, times: { start: string; end: string }) => void
  onEditTimes?: (
    times: {
      date: IsoDate
      start?: IsoDateTime
      end?: IsoDateTime
      fakeId: string
    }[],
  ) => void
  dates: string[] | undefined
  multiDates?: boolean
  isRecurrence?: boolean
  recurrence?: RecurrenceParams
  onEditRecurrence?: (value: boolean) => void
  allowTimes?: boolean
  times?: {
    date: IsoDate
    start?: IsoDateTime
    end?: IsoDateTime
    fakeId: string
  }[]
  currentMonth?: Moment
  allowSevenDays?: boolean
  colorBasedOnEventType?: string
}

export interface MultipleTimes {
  start?: IsoTime
  length?: string
  pause?: string
  amount?: string
}

const initialMultipleTimesValues: MultipleTimes = {
  start: '12:00',
  length: '15',
  pause: '5',
  amount: '5',
}

export const Calendar: React.FC<Props> = ({
  recurringDaysWave,
  lastMonthDate = moment().subtract(1, 'months'),
  onChangeEvent,
  newEvent,
  onDateClick,
  changeLastMonthDate,
  changeMonth,
  onAddTime,
  onEditTimes,
  onEditRecurrence,
  dates,
  multiDates,
  isRecurrence,
  recurrence,
  allowTimes,
  times,
  currentMonth = moment(),
  allowSevenDays,
  colorBasedOnEventType,
}) => {
  const [currentDate, setCurrentDate] = useState(currentMonth)
  /*  const [firstDate, setFirstDate] = useState(currentMonth.startOf('month'))
  const [lastDate, setLastDate] = useState(currentMonth.endOf('month'))
  const [daysOfMonth, setDaysOfMonth] = useState(currentMonth.daysInMonth()) */
  const [multipleTimes, setMultipleTimes] = useState<MultipleTimes>(
    initialMultipleTimesValues,
  )
  const [recurrenceDateSelect, setRecurrenceDateSelect] = useState<
    'startDate' | 'endDate'
  >('startDate')
  const [selectedDates, setSelectedDates] = useState<string[]>([])
  const [separateDate, setSeparateDate] = useState<string[]>([])
  const [proccessSingularOrMultipleDates, setProccessSingularOrMultipleDates] =
    useState<string>('')
  const [showNewTimeModal, setShowNewTimeModal] = useState(false)
  const [showEditDateModal, setShowEditDateModal] = useState(false)
  const [showMultipleTimes, setShowMultipleTimes] = useState(false)
  const [multipleTimesStep, setMultipleTimesStep] = useState(1)
  const [editInterval, setEditInterval] = useState(true)
  const [editEndDate, setEditEndDate] = useState(false)
  const [newTime, setNewTime] = useState({
    start: moment().format('HH:00'),
    end: moment().add(1, 'hours').format('HH:00'),
  })
  const [editTime, setEditTime] = useState<Times | null>(null)
  const [editTimes, setEditTimes] = useState<
    {
      date: IsoDate
      start?: IsoDateTime
      end?: IsoDateTime
      fakeId: string
    }[]
  >([])
  const { t } = useTranslation()
  const [showNewTimeModalRepeat, setShowNewTimeModalRepeat] = useState(false)
  const [groupedTimes, setGroupedTimes] = useState<string[]>()
  const [slideOutDate, setSlideOutDate] = useState<boolean>(false)
  const [responsiveHeight, setResponsiveHeight] = useState<string>('96')

  const repeatSelections = useRepeatSelections()

  const days = useDaysSelections()
  const lengthsToMeeting = useLengthsToMeeting()
  const countToMeeting = useCountToLengthsToMeeting()

  const eventsTypeColor =
    newEvent?.eventType!! === EVENT3_TYPE.ACTIVITIES
      ? 'greenForrest'
      : newEvent?.eventType!! === EVENT3_TYPE.BOOKING
      ? 'purpleLight'
      : newEvent?.eventType!! === EVENT3_TYPE.MEETING
      ? 'groupsMain'
      : newEvent?.eventType!! === EVENT3_TYPE.IMPORTANT
      ? 'absenceMain'
      : 'blueTyra'

  useEffect(() => {
    if (times) {
      /*  const pushedDates: string[] = [] */
      const datesInTimes: string[] = []

      for (const time of times) {
        if (!datesInTimes.find((date) => date === time.date)) {
          datesInTimes.push(time.date)
        }
      }
      if (datesInTimes) {
        setGroupedTimes(
          datesInTimes.sort((a, b) => {
            return new Date(a).getTime() - new Date(b).getTime()
          }),
        )
        setSelectedDates(
          selectedDates.filter((sD) => datesInTimes.includes(sD)),
        )
      }
    }
  }, [times])

  useEffect(() => {
    if (times) {
      setEditTimes(times)
    }
  }, [times])

  const prefixDays =
    moment(currentDate.startOf('month')).day() === 0
      ? 6
      : moment(currentDate.startOf('month')).day() - 1
  const suffixDays =
    moment(currentDate.endOf('month')).day() === 0
      ? 0
      : 7 - moment(currentDate.endOf('month')).day()
  //const [prefixDays, setPrefixDays] = useState(firstDate.day())
  //const [suffixDays, setSuffixDays] = useState(6 - lastDate.day())
  const daysOfWeek = [
    t('days.monday'),
    t('days.tuesday'),
    t('days.wednesday'),
    t('days.thursday'),
    t('days.friday'),
    t('days.saturday'),
    t('days.sunday'),
  ]

  const dateInCell = (cellDate: string) => {
    return dates?.find((ab: any) => cellDate === ab)
  }
  const showDays = () => {
    const div = []

    for (var i = 0; i < currentDate.daysInMonth(); i++) {
      //let red = false
      const cellDate = moment(
        moment(currentDate).format('YYYY') +
          '-' +
          moment(currentDate).format('MM') +
          '-' +
          (i + 1).toString(),
      ).format('YYYY-MM-DD')

      const active = dateInCell(cellDate)

      div.push(
        <CalendarCell
          onClick={() => {
            if (
              (moment(cellDate).isoWeekday() === 6 ||
                moment(cellDate).isoWeekday() === 7) &&
              !allowSevenDays
            ) {
            } else {
              if (isRecurrence) {
                if (recurrenceDateSelect === 'startDate') {
                  if (moment(cellDate) < moment(recurrence?.endsOn)) {
                    handleDateClick(cellDate)
                  }
                } else {
                  if (moment(cellDate) > moment(recurrence?.startsOn)) {
                    handleDateClick(cellDate)
                  }
                }
              } else {
                handleDateClick(cellDate)
              }
            }
          }}
          active={active ? true : false}
          key={i}
          opacity={
            isRecurrence
              ? recurrenceDateSelect === 'startDate'
                ? moment(cellDate) > moment(recurrence?.endsOn)
                : moment(cellDate) < moment(recurrence?.startsOn)
              : (moment(cellDate).isoWeekday() === 6 ||
                  moment(cellDate).isoWeekday() === 7) &&
                !allowSevenDays
              ? true
              : false
          }
          className={`${
            cellDate === moment().format('YYYY-MM-DD') &&
            newEvent &&
            `outline outline-2 outline-${eventsTypeColor} rounded-full`
          }`}
          eventTypeColor={`${eventsTypeColor}`}
        >
          <>{i + 1}</>
          <EventDots date={cellDate} type="events3" />
        </CalendarCell>,
      )
    }

    return div
  }
  /*const amountOfAbsenceDaysCurrentMonth = () => {
    let count = 0

    for (var i = 0; i < daysOfMonth; i++) {
      //let red = false
      const cellDate = moment(
        moment(value).format('YYYY') +
          '-' +
          moment(value).format('MM') +
          '-' +
          (i + 1).toString(),
      ).format('YYYY-MM-DD')

      const active = dateInCell(cellDate)
      if (active) {
        count = count + 1
      }
    }
    return count
  }*/

  const handleDateClick = (date: string) => {
    if (multiDates) {
      if (dates?.includes(date)) {
        const oldDates: string[] = dates ? dates : []
        setSlideOutDate(true)
        onDateClick(
          oldDates.filter((d) => d !== date),
          date,
          isRecurrence
            ? recurrenceDateSelect === 'endDate'
              ? true
              : false
            : undefined,
        )
        setSelectedDates(selectedDates.filter((d) => d !== date))
        setSlideOutDate(false)
      } else {
        const oldDates: string[] = dates ? dates : []
        /*const newDates: string[] =*/ oldDates.push(date) //dates ? dates.push(date) as string[] : [date]
        onDateClick(
          oldDates,
          date,
          isRecurrence
            ? recurrenceDateSelect === 'endDate'
              ? true
              : false
            : undefined,
        )
        setSelectedDates([...selectedDates, date])
      }
    } else {
      if (dates?.includes(date)) {
        onDateClick([], date, undefined)
      } else {
        onDateClick([date], date, undefined)
      }
    }
  }

  const showPrefixDays = () => {
    const div = []
    for (var i = 0; i < currentDate.daysInMonth(); i++) {
      if (i > currentDate.daysInMonth() - (prefixDays + 1)) {
        div.push(
          <CalendarCell
            className=""
            opacity
            key={i}
            eventTypeColor={`${eventsTypeColor}`}
          >
            {i + 1}
          </CalendarCell>,
        )
      }
    }
    return div
  }
  const showSuffixDays = () => {
    const div = []
    for (var i = 0; i < suffixDays; i++) {
      div.push(
        <CalendarCell
          className=""
          opacity
          key={i}
          eventTypeColor={`${eventsTypeColor}`}
        >
          {i + 1}
        </CalendarCell>,
      )
    }
    return div
  }

  const nextMonth = () => {
    //onChange(moment(currentDate).add(1, 'months'))
    setCurrentDate(moment(currentDate).add(1, 'months'))
    if (changeLastMonthDate) {
      changeLastMonthDate(moment(lastMonthDate).add(1, 'months'))
    }

    if (changeMonth) {
      changeMonth(moment(currentMonth).add(1, 'months'))
    }
  }

  const prevMonth = () => {
    //onChange(moment(currentDate).subtract(1, 'months'))
    setCurrentDate(moment(currentDate).subtract(1, 'months'))
    if (changeLastMonthDate) {
      changeLastMonthDate(moment(lastMonthDate).subtract(1, 'months'))
    }
    if (changeMonth) {
      changeMonth(moment(currentMonth).subtract(1, 'months'))
    }
  }

  const nextYear = () => {
    //onChange(moment(currentDate).add(1, 'years'))
    setCurrentDate(moment(currentDate).add(1, 'years'))
    if (changeLastMonthDate) {
      changeLastMonthDate(moment(lastMonthDate).add(1, 'years'))
    }
    if (changeMonth) {
      changeMonth(moment(currentMonth).add(1, 'years'))
    }
  }

  const prevYear = () => {
    //onChange(moment(currentDate).subtract(1, 'years'))
    setCurrentDate(moment(currentDate).subtract(1, 'years'))
    if (changeLastMonthDate) {
      changeLastMonthDate(moment(lastMonthDate).subtract(1, 'years'))
    }
    if (changeMonth) {
      changeMonth(moment(currentMonth).subtract(1, 'years'))
    }
  }
  const [switchToSecondAlternative, setSwitchToSecondAlternative] =
    useState<boolean>(false)
  const [confirmState, setConfirmState] = useState<string>('')

  return (
    <>
      <DateOrRepeatPopup
        switchToSecondAlternative={switchToSecondAlternative}
        setSwitchToSecondAlternative={setSwitchToSecondAlternative}
        confirmState={confirmState}
        onChangeEvent={onChangeEvent}
        newEvent={newEvent!!}
      ></DateOrRepeatPopup>
      <div className="flex flex-row justify-start text-xl text-gray-800 font-medium ml-5">
        <>{/*t('absence.absenceThisMonth')*/}</>
        <div className="text-absenceMain font-bold ml-2">
          {/*{amountOfAbsenceDaysCurrentMonth()} <>{t('absence.absenceDays')}</>*/}
        </div>
      </div>
      <div className="flex pl-4 w-full justify-between">
        <div>
          {newEvent !== undefined &&
            newEvent.eventType !== EVENT3_TYPE.BOOKING && (
              <>
                {isRecurrence && newEvent?.mod !== 'edit' ? (
                  <div className="flex">
                    <Button
                      label={t('events.changeToCustomDatesBTN')}
                      variant="custom"
                      textColor="black"
                      bgrondColor="bg-white"
                      size="sm"
                      fontSize="font-bold"
                      icon={faCalendarDays}
                      iconColor="text-redLight"
                      onClick={() => {
                        setSwitchToSecondAlternative(true)
                        setConfirmState('changeToCustomDate')
                      }}

                      // on click open calendar and show chooed times
                    />
                    <div className="flex w-full gap-y-2 pl-8" id="dateInterval">
                      <div
                        className="flex justify-between items-center"
                        id="startDate"
                      >
                        <div
                          className={classNames(
                            ' transition duration-300 flex gap-2 items-center font-semibold text-sm',
                          )}
                        >
                          {recurrence?.startsOn} {' till '}
                          {recurrence?.endsOn}
                          <div
                            className="text-xs cursor-pointer text-blueDark font-bold"
                            onClick={() => {
                              //setRecurrenceDateSelect('endDate')
                              setEditEndDate(true)
                            }}
                          >
                            <FontAwesomeIcon icon={faPenToSquare} />{' '}
                            {t('absence.change')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {newEvent.mod !== 'edit' && (
                      <Button
                        label={t('events.changeToRepeatBTN')}
                        variant="custom"
                        textColor="black"
                        bgrondColor="bg-white"
                        size="sm"
                        fontSize="font-bold"
                        icon={faRepeat}
                        iconColor="text-greenForrest"
                        onClick={() => {
                          setSwitchToSecondAlternative(true)
                          setConfirmState('changeToRepeat')
                        }}
                        // on click open repeate popup if no many dates are choosed.
                        // on click and many dates are choosed notify the user that dates will be cleaned.
                      />
                    )}
                  </>
                )}

                {/* the datepicker to edit the end date */}
                {editEndDate && (
                  <>
                    <ModalRegular
                      isOpen={editEndDate}
                      onClose={() => setEditEndDate(false)}
                      title={''}
                      className=" max-w-4xl !-pt-4"
                    >
                      <div className="flex flex-col justify-center gap-4 p-4 ">
                        <div className="flex  flex-row justify-around">
                          <div id="startsOn-Calendar">
                            <p className="flex justify-center font-black">
                              {t('events.startDate')}
                            </p>
                            <Calendar
                              onChangeEvent={() => {}}
                              multiDates={false}
                              colorBasedOnEventType={`${eventsTypeColor}`}
                              dates={[
                                newEvent?.recurrence?.startsOn ??
                                  moment().format('YYYY-MM-DD'),
                              ]}
                              onDateClick={(value, lastDate) => {
                                if (newEvent && recurrence) {
                                  onChangeEvent({
                                    ...newEvent,
                                    recurrence: {
                                      ...recurrence,
                                      startsOn: lastDate,
                                    },
                                  })
                                }
                              }}
                              currentMonth={
                                moment(newEvent?.recurrence?.startsOn) ??
                                moment()
                              }
                            />
                          </div>
                          <div className="flex justify-center items-center pb-4">
                            <div className="w-[2px] bg-black h-[320px] "></div>
                          </div>
                          <div id="endsOn-Calendar">
                            <p className="flex justify-center font-black">
                              {t('events.endDate')}
                            </p>
                            <Calendar
                              onChangeEvent={() => {}}
                              multiDates={false}
                              colorBasedOnEventType={`${eventsTypeColor}`}
                              dates={[
                                newEvent?.recurrence?.endsOn ??
                                  moment().format('YYYY-MM-DD'),
                              ]}
                              onDateClick={(value, lastDate) => {
                                if (newEvent && recurrence) {
                                  onChangeEvent({
                                    ...newEvent,
                                    recurrence: {
                                      ...recurrence,
                                      endsOn: lastDate,
                                    },
                                  })
                                }
                              }}
                              currentMonth={
                                moment(newEvent?.recurrence?.endsOn) ?? moment()
                              }
                            />
                          </div>
                        </div>
                        <Button
                          size="lg"
                          fontSize="font-bold"
                          className="min-w-[250px] max-w-[50%] my-1.5 m-auto"
                          variant={'fullBlue'}
                          label={t('general.done')}
                          onClick={() => {
                            setEditEndDate(false)
                          }}
                        />
                      </div>
                    </ModalRegular>
                  </>
                )}
              </>
            )}
        </div>
        {/***************************** At least one date choosed buttons for add, add many, edit *********************************/}
        {selectedDates.length > 0 &&
          newEvent?.eventType !== 'info' &&
          newEvent?.eventType !== 'important' && (
            <div>
              <div
                className={`flex flex-row justify-between items-center text-center gap-2 pr-2`}
              >
                <Button
                  label={t('events.addTime')}
                  variant="custom"
                  textColor="black"
                  size="sm"
                  fontSize="font-bold"
                  icon={faClock}
                  onClick={() => {
                    if (!selectedDates.length) {
                      Swal.fire({
                        text: `${t('events.addTimeToScheduledEvent')}`,
                        icon: 'warning',
                        showCancelButton: false,
                        focusConfirm: false,
                        confirmButtonColor: '#7eb5f4',
                        cancelButtonColor: '#d33',
                        confirmButtonText: t('general.ok'),
                      })
                    } else {
                      setProccessSingularOrMultipleDates('multiple')
                      setShowNewTimeModal(true)
                    }
                  }}
                />
                {newEvent?.eventType === EVENT3_TYPE.BOOKING && (
                  <Button
                    label={t('events.addMoreTimes')}
                    className="flex items-center justify-center"
                    variant="custom"
                    textColor="black"
                    fontSize="font-bold"
                    size="sm"
                    icon={faClock}
                    iconColor="text-red-300"
                    onClick={() => {
                      if (!selectedDates.length) {
                        Swal.fire({
                          text: `${t('events.addTimeToScheduledEvent')}`,
                          icon: 'warning',
                          showCancelButton: false,
                          focusConfirm: false,
                          confirmButtonColor: '#7eb5f4',
                          cancelButtonColor: '#d33',
                          confirmButtonText: t('general.ok'),
                        })
                      } else {
                        setShowMultipleTimes(true)
                      }
                    }}
                  />
                )}
                <Button
                  label={t('notes.edit')}
                  variant="custom"
                  textColor="black"
                  iconColor="text-orange-400"
                  fontSize="font-bold"
                  size="sm"
                  icon={faPenToSquare}
                  onClick={() => {
                    if (!selectedDates.length) {
                      Swal.fire({
                        text: `${t('events.editScheduledEvent')}`,
                        icon: 'warning',
                        showCancelButton: false,
                        focusConfirm: false,
                        confirmButtonColor: '#7eb5f4',
                        cancelButtonColor: '#d33',
                        confirmButtonText: t('general.ok'),
                      })
                    } else {
                      setProccessSingularOrMultipleDates('editMultiple')
                      setShowEditDateModal(true)
                    }
                  }}
                />
              </div>
            </div>
          )}{' '}
      </div>

      <div
        className={`flex flex-wrap mb-2 ${
          !newEvent && 'bg-white rounded-2xl justify-center items-center'
        } `}
      >
        <div className="h-auto flex-1 mt-12 sm:m-3 font-medium flex flex-col sm:flex-row">
          {/*<div className="w-[400px] grid grid-cols-7 overflow-visible ">*/}
          {(!isRecurrence || newEvent?.mod === 'edit') && (
            <div
              className={multiDates ? 'basis-full sm:basis-7/12' : 'basis-full'}
            >
              <div className="grid grid-cols-7 gap-2 overflow-visible  rounded-2xl p-2">
                <CalendarCell
                  onClick={prevYear}
                  className=" text-blueDark  cursor-pointer font-bold hover:scale-125 transition ease-in rounded-full "
                >
                  <FontAwesomeIcon icon={faLeftFromLine} />
                </CalendarCell>
                <CalendarCell
                  onClick={prevMonth}
                  className=" text-blueDark  cursor-pointer font-bold hover:scale-125 transition ease-in rounded-full "
                >
                  <FontAwesomeIcon icon={faLeft} />
                </CalendarCell>
                <CalendarCell className="col-span-3 font-bold text-xl cursor-default capitalize text-gray-700">
                  {getDateForTranslatation(currentDate.format('MM'))}{' '}
                  {currentDate.format('YYYY')}
                </CalendarCell>
                <CalendarCell
                  onClick={nextMonth}
                  className=" text-blueDark  cursor-pointer font-bold hover:scale-125 transition ease-in rounded-full  "
                >
                  <FontAwesomeIcon icon={faRight} />
                </CalendarCell>

                <CalendarCell
                  onClick={nextYear}
                  className=" text-blueDark  cursor-pointer font-bold hover:scale-125 transition ease-in rounded-full"
                >
                  <FontAwesomeIcon icon={faRightFromLine} />
                </CalendarCell>

                {daysOfWeek.map((day) => (
                  <CalendarCell
                    className=" text-gray-700 font-semibold cursor-default"
                    key={day}
                    eventTypeColor={`${eventsTypeColor}`}
                  >
                    {day}
                  </CalendarCell>
                ))}
                {showPrefixDays()}
                {showDays()}
                {showSuffixDays()}
              </div>
            </div>
          )}
          {(multiDates || newEvent?.mod === 'edit') && (
            <div
              className={`basis-full ${
                (!isRecurrence || newEvent?.mod === 'edit') && 'sm:basis-6/12'
              } ml-4`}
            >
              <div className={`flex flex-col gap-1 px-1 h-[380px] flex-nowrap`}>
                {/* {newEvent?.eventType !== EVENT3_TYPE.BOOKING && (
                  <TabHeaderSlider
                    tabs={[
                      { label: t('blog.chooseDate'), value: 'date' },
                      { label: t('general.repeat'), value: 'repeat' },
                    ]}
                    activeTab={
                      isRecurrence
                        ? { label: t('general.repeat'), value: 'repeat' }
                        : { label: t('blog.chooseDate'), value: 'date' }
                    }
                    onClick={(value: { label: string; value: string }) => {
                      let disableRepeat = false
                      let toManyTimes = false
                      let toManyDays = false
                      let timesForRepeat: string[] = []

                      if (value.value === 'repeat') {
                        if (dates && dates?.length > 1) {
                          disableRepeat = true
                          toManyDays = true
                          console.log('TEEEEST HEJ')
                        }

                        if (!disableRepeat) {
                          if (times) {
                            for (const time of times) {
                              if (
                                time.start !== undefined &&
                                time.end !== undefined
                              ) {
                                const timePair = `${time.start}-${time.end}`
                                if (timesForRepeat.includes(timePair)) {
                                } else {
                                  timesForRepeat.push(timePair)
                                }
                              }
                            }
                            if (timesForRepeat.length > 1) {
                              disableRepeat = true
                              toManyTimes = true
                            }
                          }
                        }
                      }

                      if (!disableRepeat) {
                        value.value === 'repeat'
                          ? setResponsiveHeight('full')
                          : setResponsiveHeight('96')
                        if (onEditRecurrence) {
                          onEditRecurrence(
                            value.value === 'repeat' ? true : false,
                          )
                        }
                        if (newEvent && recurrence) {
                          const dayIds: string[] = times
                            ? times?.map((time) =>
                                moment(time.date).format('ddd').toLowerCase(),
                              ) ?? []
                            : dates?.map((date) =>
                                moment(date).format('ddd').toLowerCase(),
                              ) ?? []

                          const finalDays = days
                            .filter((day) => dayIds.includes(day.id))
                            .map((day) => day.id)

                          onChangeEvent({
                            ...newEvent,
                            isRecurrence:
                              value.value === 'repeat' ? true : false,
                            recurrence: {
                              ...recurrence,
                              startsOn: dates?.length
                                ? dates[0]
                                : recurrence.startsOn,
                              endsOn: dates?.length
                                ? moment(dates[0])
                                    .add(6, 'months')
                                    .format('YYYY-MM-DD')
                                : recurrence.endsOn,
                              endTime:
                                times?.length && times[0].end
                                  ? times[0].end
                                  : undefined,
                              startTime:
                                times?.length && times[0].start
                                  ? times[0].start
                                  : undefined,
                              days: finalDays,
                            },
                          })
                        }
                      } else {
                        if (toManyTimes) {
                          Swal.fire({
                            title: t('events.toManyTimesTitle'),
                            html: t('events.toManyTimesText'),
                            icon: 'warning',
                            showCancelButton: false,
                            focusConfirm: false,
                            confirmButtonColor: '#7eb5f4',
                            cancelButtonColor: '#d33',
                            confirmButtonText: t('general.ok'),
                          })
                        } else if (toManyDays) {
                          Swal.fire({
                            title: t('events.toManyDaysTitle'),
                            html: t('events.toManyDaysText'),
                            icon: 'warning',
                            showCancelButton: false,
                            focusConfirm: false,
                            confirmButtonColor: '#7eb5f4',
                            cancelButtonColor: '#d33',
                            confirmButtonText: t('general.ok'),
                          })
                        }
                      }
                    }}
                  />
                )} */}

                {isRecurrence && newEvent?.mod !== 'edit' && (
                  <div className="flex  gap-y-8 text-sm align-middle w-full h-full p-2">
                    <div className="w-[33%]">
                      <div
                        className="w-full font-semibold text-lg"
                        id="chooseDay"
                      >
                        {t('general.chooseDay')}
                      </div>
                      <div
                        className={classNames(
                          'gap-3 grid grid-cols-2 w-[80%] justify-between mt-3',
                          recurringDaysWave ? 'animate-shake' : '',
                        )}
                      >
                        {days.map((day) => {
                          return (
                            <Button
                              variant={
                                recurrence?.days.includes(day.id)
                                  ? 'custom'
                                  : 'fullWhiteDarkText'
                              }
                              label={day.label}
                              size="sm"
                              bgrondColor={`bg-${eventsTypeColor}`}
                              className="!text-white "
                              onClick={() => {
                                if (newEvent && newEvent.recurrence) {
                                  if (recurrence?.days.includes(day.id)) {
                                    onChangeEvent({
                                      ...newEvent,
                                      recurrence: {
                                        ...newEvent.recurrence,
                                        days: newEvent.recurrence.days.filter(
                                          (recDay) => recDay !== day.id,
                                        ),
                                      },
                                    })
                                  } else {
                                    onChangeEvent({
                                      ...newEvent,
                                      recurrence: {
                                        ...newEvent.recurrence,
                                        days: [
                                          ...newEvent.recurrence.days,
                                          day.id,
                                        ],
                                      },
                                    })
                                  }
                                }
                              }}
                            />
                          )
                        })}
                      </div>
                    </div>
                    <div
                      className={classNames(
                        'overflow-hidden',
                        'w-[33%] items-center justify-center',
                      )}
                      id="weekRepeat"
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center text-lg font-semibold">
                          <FontAwesomeIcon
                            icon={faArrowsRepeat}
                            className="text-greenSelected"
                          />

                          {
                            repeatSelections.find(
                              (rep) => rep.id === recurrence?.interval,
                            )?.label
                          }
                        </div>
                      </div>

                      <motion.div
                        initial={{ height: 0 }}
                        animate={{ height: 'auto' }}
                        className="flex flex-col ml-4 "
                      >
                        {repeatSelections.map((rS) => {
                          return (
                            <div
                              className={classNames(
                                ' p-2  ',
                                rS.id === newEvent?.recurrence?.interval &&
                                  'font-semibold',
                              )}
                            >
                              <span
                                className="self-auto hover:bg-gray-200 p-2 cursor-pointer hover:rounded-full px-3"
                                onClick={() => {
                                  if (newEvent && newEvent.recurrence) {
                                    onChangeEvent({
                                      ...newEvent,
                                      recurrence: {
                                        ...newEvent.recurrence,
                                        interval: rS.id,
                                      },
                                    })
                                  }
                                  setEditInterval(!editInterval)
                                }}
                              >
                                {rS.label}
                              </span>
                            </div>
                          )
                        })}
                      </motion.div>
                    </div>
                    {allowTimes ? (
                      <div className="flex flex-col w-[33%]">
                        <div className="flex items-start justify-between w-full">
                          <div className="flex gap-2 items-center text-lg">
                            <FontAwesomeIcon icon={faClock} />{' '}
                            <p className="font-semibold">
                              {t('general.chooseTime')}:
                            </p>
                            {recurrence?.startTime && recurrence.endTime ? (
                              <p className="flex text-sm items-center">
                                {recurrence.startTime} - {recurrence.endTime}
                              </p>
                            ) : (
                              <p className="text-sm font-bold">
                                {t('events.fullDay')}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="flex justify-center gap-4 flex-col pt-4">
                          <div className="flex justify-center gap-x-4">
                            <div className="relative">
                              <DatePicker
                                inline
                                popperPlacement="bottom"
                                popperClassName="text-center"
                                selected={
                                  recurrence?.startTime
                                    ? new Date(
                                        moment().format(
                                          'YYYY-MM-DD ' + recurrence.startTime,
                                        ),
                                      )
                                    : new Date(
                                        moment()
                                          .startOf('day')
                                          .add(8, 'hours')
                                          .format('YYYY-MM-DD HH:mm'),
                                      )
                                }
                                onChange={(date: Date) => {
                                  if (date === null) {
                                    return
                                  }

                                  if (recurrence && newEvent) {
                                    onChangeEvent({
                                      ...newEvent,
                                      recurrence: {
                                        ...recurrence,
                                        startTime: moment(date).format('HH:mm'),
                                      },
                                    })
                                  }
                                }}
                                minTime={
                                  new Date(moment().format('YYYY-MM-DD 00:01'))
                                }
                                maxTime={
                                  recurrence?.endTime
                                    ? new Date(moment().format('YYYY-MM-DD '))
                                    : new Date(
                                        moment().format('YYYY-MM-DD 23:59'),
                                      )
                                }
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption={t('events.startTime')}
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                locale={i18n.language}
                              />
                            </div>
                            <div className="relative">
                              <DatePicker
                                inline
                                popperPlacement="bottom"
                                popperClassName="text-center"
                                selected={
                                  recurrence?.endTime
                                    ? new Date(
                                        moment().format(
                                          'YYYY-MM-DD ' + recurrence.endTime,
                                        ),
                                      )
                                    : new Date(
                                        moment()
                                          .startOf('day')
                                          .add(17, 'hours')
                                          .format('YYYY-MM-DD HH:mm'),
                                      )
                                }
                                onChange={(date: Date) => {
                                  if (date === null) {
                                    return
                                  }
                                  if (recurrence && newEvent) {
                                    onChangeEvent({
                                      ...newEvent,
                                      recurrence: {
                                        ...recurrence,
                                        endTime: moment(date).format('HH:mm'),
                                      },
                                    })
                                  }
                                }}
                                minTime={
                                  recurrence?.startTime
                                    ? new Date(
                                        moment().format(
                                          'YYYY-MM-DD ' + recurrence.startTime,
                                        ),
                                      )
                                    : new Date(
                                        moment().format('YYYY-MM-DD 00:01'),
                                      )
                                }
                                maxTime={
                                  new Date(moment().format('YYYY-MM-DD 23:59'))
                                }
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption={t('events.endTime')}
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                locale={i18n.language}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="w-[34%] text-gray120">
                        <div className="flex flex-row justify-start gap-x-2 items-center ">
                          <FontAwesomeIcon icon={faClock} size="lg" />
                          <p className="font-semibold text-lg">
                            {t('events.chooseTime')}
                          </p>
                        </div>
                        <p className="break-all">
                          {t('events.eventsNoTimeInfo')}
                        </p>
                      </div>
                    )}
                  </div>
                )}

                {(!isRecurrence || newEvent?.mod === 'edit') && (
                  <>
                    {/***************************** No dates choosed *********************************/}
                    {dates && dates?.length < 1 && (
                      <div className="flex flex-col text-center text-sm h-full text-gray-400 mt-[35%]">
                        <FontAwesomeIcon
                          icon={faCalendarAlt}
                          className=" text-8xl"
                        />
                        {t('events.sceduleAnEvent')}
                      </div>
                    )}

                    {allowTimes ? (
                      /** TODO: Dela upp i komponenter valda tider, selectall/deselectall, modalregualr för 3 alternative [addtime, add many, edit]*/
                      <>
                        {groupedTimes?.length && groupedTimes.length > 1 ? (
                          <div
                            className="flex justify-end gap-x-2 items-center px-3 py-1 cursor-pointer"
                            onClick={() => {
                              if (selectedDates.length !== groupedTimes?.length)
                                setSelectedDates(groupedTimes)
                              else setSelectedDates([])
                            }}
                          >
                            <div className="text-xs font-semibold text-[#1398F7] animate-fadeIn">
                              {selectedDates.length === groupedTimes?.length
                                ? t('multiselect.deSelectAll')
                                : t('multiselect.selectAll')}
                            </div>
                            <div
                              className={classNames(
                                'w-4 h-4 rounded-full border justify-center flex items-center cursor-pointer',
                                selectedDates.length === groupedTimes?.length
                                  ? `${
                                      newEvent?.eventType!! ===
                                      EVENT3_TYPE.ACTIVITIES
                                        ? 'bg-greenForrest border-greenForrest'
                                        : newEvent?.eventType!! ===
                                          EVENT3_TYPE.BOOKING
                                        ? 'bg-purpleLight border-purpleLight'
                                        : newEvent?.eventType!! ===
                                          EVENT3_TYPE.MEETING
                                        ? ' bg-groupsMain border-groupsMain'
                                        : 'text-[#57d945]'
                                    }`
                                  : `${
                                      newEvent?.eventType!! ===
                                      EVENT3_TYPE.ACTIVITIES
                                        ? 'bg-white border-greenForrest'
                                        : newEvent?.eventType!! ===
                                          EVENT3_TYPE.BOOKING
                                        ? 'bg-white border-purpleLight'
                                        : newEvent?.eventType!! ===
                                          EVENT3_TYPE.MEETING
                                        ? ' bg-white border-groupsMain'
                                        : 'text-[#57d945]'
                                    }`,
                              )}
                            >
                              {selectedDates.length ===
                                groupedTimes?.length && (
                                <FontAwesomeIcon
                                  color="#ffffff"
                                  icon={faCheck}
                                  size="2xs"
                                />
                              )}
                            </div>
                          </div>
                        ) : null}
                        <div
                          className={`w-full overflow-auto gap-y-2 h-full  relative `}
                        >
                          <div className="sticky top-0 left-0 right-0  z-10 pointer-events-noneabsolute h-[10%] bg-gradient-to-b from-[#f6f6f6] to-[rgba(255,255,255,0)] w-full rounded-b-xl"></div>
                          {groupedTimes?.map((groupDate, index) => {
                            const timesToShow = times?.filter(
                              (time) => time.date === groupDate,
                            )
                            console.log(
                              timesToShow?.some(
                                (dateObj) =>
                                  dateObj.date === groupDate &&
                                  dateObj.end !== undefined,
                              ),
                            )

                            /* valda tider right column  */
                            return (
                              <div
                                className={`flex flex-row justify-between w-full gap-x-3 items-center pt-1  
                                  animate-slideIn cursor-pointer ${
                                    index === 0 ? 'mt-[-20px]' : ''
                                  }
                                `}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setSelectedDates(
                                    selectedDates.find(
                                      (date) => date === groupDate,
                                    )
                                      ? selectedDates.filter(
                                          (date) => date !== groupDate,
                                        )
                                      : [...selectedDates, groupDate],
                                  )
                                }}
                              >
                                <div className="justify-between items-center inline-flex shadow-sm pt-1 px-3 rounded-lg select-none w-full bg-white">
                                  <div
                                    className={` 
                                  border-l-4 ml-2 ${eventTypeColor(
                                    newEvent?.eventType!!,
                                  )}`}
                                  >
                                    <CalendarDateIcon date={groupDate} />
                                  </div>
                                  <div className="relative max-h-24  group ">
                                    {timesToShow?.some(
                                      (dateObj) =>
                                        dateObj.date === groupDate &&
                                        dateObj.end !== undefined,
                                    ) && (
                                      <div
                                        className="h-full bg-red-400 cursor-pointer"
                                        onClick={(e) => {
                                          e.stopPropagation()
                                        }}
                                      >
                                        {' '}
                                      </div>
                                    )}
                                    <div className="absolute top-0 left-0 right-0 h-6 bg-gradient-to-b from-white to-transparent pointer-events-none"></div>
                                    <div className=" max-h-28 overflow-y-scroll py-3 w-full px-3">
                                      <ul
                                        className={`text-sm text-[#1398F7] w-full h-14 flex flex-col items-center justify-center ${
                                          timesToShow?.some(
                                            (dateObj) =>
                                              dateObj.date === groupDate &&
                                              dateObj.end !== undefined,
                                          ) && ''
                                        }`}
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          if (
                                            timesToShow?.some(
                                              (dateObj) =>
                                                dateObj.date === groupDate &&
                                                dateObj.end !== undefined,
                                            )
                                          ) {
                                            setSeparateDate([groupDate])
                                            setProccessSingularOrMultipleDates(
                                              'editSingular',
                                            )
                                            setShowEditDateModal(true)
                                          }
                                        }}
                                      >
                                        {timesToShow?.map((time, index) => (
                                          <>
                                            {time.start && time.end ? (
                                              <li>{`${time.start} - ${time.end}`}</li>
                                            ) : (
                                              <div
                                                className={`flex items-center justify-center h-14  relative w-[120px]`}
                                              >
                                                <p
                                                  className={`text-xs hover:text-blueTyra  ${
                                                    newEvent?.eventType!! ===
                                                    EVENT3_TYPE.ACTIVITIES
                                                      ? 'text-greenForrest'
                                                      : newEvent?.eventType!! ===
                                                        EVENT3_TYPE.BOOKING
                                                      ? 'text-purpleLight'
                                                      : newEvent?.eventType!! ===
                                                        EVENT3_TYPE.MEETING
                                                      ? 'text-groupsMain'
                                                      : 'text-[#57d945]'
                                                  }`}
                                                  onClick={(e) => {
                                                    e.stopPropagation()
                                                    setSeparateDate([groupDate])
                                                    setProccessSingularOrMultipleDates(
                                                      'singular',
                                                    )
                                                    setShowNewTimeModal(true)
                                                  }}
                                                >
                                                  {t('events.addTime')}
                                                </p>

                                                {newEvent?.eventType ===
                                                  EVENT3_TYPE.BOOKING && (
                                                  <div className="absolute inset-x-0 top-0 flex flex-col  opacity-0 hover:opacity-100 transition-opacity duration-100">
                                                    <div className="">
                                                      <ButtonDropdown.Menu>
                                                        <ButtonDropdown.Section>
                                                          <ButtonDropdown.Button
                                                            icon={faClock}
                                                            label={t(
                                                              'events.addTime',
                                                            )}
                                                            className="text-blue-300 !p-0 !py-1 !px-1 !space-x-0 !gap-x-1 flex items-center"
                                                            onClick={(e) => {
                                                              e.stopPropagation()
                                                              setSeparateDate([
                                                                groupDate,
                                                              ])
                                                              setProccessSingularOrMultipleDates(
                                                                'singular',
                                                              )
                                                              setShowNewTimeModal(
                                                                true,
                                                              )
                                                            }}
                                                          />
                                                          <ButtonDropdown.Button
                                                            icon={faClock}
                                                            label={t(
                                                              'events.addMoreTimes',
                                                            )}
                                                            className="text-red-300  !p-0 !py-1 !px-1 !space-x-0 !gap-x-1 flex items-center  "
                                                            onClick={(e) => {
                                                              e.stopPropagation()
                                                              setSeparateDate([
                                                                groupDate,
                                                              ])
                                                              setProccessSingularOrMultipleDates(
                                                                'addManyTimesSingular',
                                                              )
                                                              setShowMultipleTimes(
                                                                true,
                                                              )
                                                            }}
                                                          />
                                                        </ButtonDropdown.Section>
                                                      </ButtonDropdown.Menu>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            )}
                                          </>
                                        ))}
                                      </ul>
                                    </div>
                                    <div className="absolute bottom-0 left-0 right-0 h-4 bg-gradient-to-t from-white to-transparent pointer-events-none"></div>
                                  </div>
                                  <div
                                    className={classNames(
                                      'w-4 h-4 rounded-full border justify-center flex items-center cursor-pointer',
                                      selectedDates.find(
                                        (date) => date === groupDate,
                                      )
                                        ? `${
                                            newEvent?.eventType!! ===
                                            EVENT3_TYPE.ACTIVITIES
                                              ? 'bg-greenForrest border-greenForrest'
                                              : newEvent?.eventType!! ===
                                                EVENT3_TYPE.BOOKING
                                              ? 'bg-purpleLight border-purpleLight'
                                              : newEvent?.eventType!! ===
                                                EVENT3_TYPE.MEETING
                                              ? ' bg-groupsMain border-groupsMain'
                                              : 'text-[#57d945]'
                                          }`
                                        : `${
                                            newEvent?.eventType!! ===
                                            EVENT3_TYPE.ACTIVITIES
                                              ? 'bg-white border-greenForrest'
                                              : newEvent?.eventType!! ===
                                                EVENT3_TYPE.BOOKING
                                              ? 'bg-white border-purpleLight'
                                              : newEvent?.eventType!! ===
                                                EVENT3_TYPE.MEETING
                                              ? ' bg-white border-groupsMain'
                                              : 'text-[#57d945]'
                                          }`,
                                    )}
                                  >
                                    {selectedDates.find(
                                      (date) => date === groupDate,
                                    ) && (
                                      <FontAwesomeIcon
                                        color="#ffffff"
                                        icon={faCheck}
                                        size="2xs"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                          {groupedTimes && groupedTimes.length > 3 && (
                            <div className="sticky h-[15%] bg-gradient-to-t from-[#f6f6f6] to-[rgba(255,255,255,0)] bottom-0 left-0 w-full flex justify-center items-center rounded-b-xl"></div>
                          )}
                        </div>

                        <div className="flex justify-between">
                          <ModalRegular
                            title={`${
                              multipleTimesStep === 1
                                ? t('events.selectStartTime')
                                : multipleTimesStep === 2
                                ? t('events.lengthOfMeeting')
                                : multipleTimesStep === 3
                                ? t('events.pauseInbetweenMeetings')
                                : multipleTimesStep === 4
                                ? t('events.numberOfMeetings')
                                : ''
                            }`}
                            isOpen={showMultipleTimes}
                            closeColor="#f6f6f6"
                            onClose={() => {
                              setShowMultipleTimes(false)
                              setMultipleTimesStep(1)
                              setMultipleTimes(initialMultipleTimesValues)
                            }}
                            className="max-w-sm !overflow-visible"
                            classNameTitle="p-4 w-full text-black flex justify-center"
                          >
                            <div className="flex justify-center gap-4 flex-col p-4">
                              <div className="flex justify-center gap-x-4">
                                <div className="relative justify-center items-center flex flex-col">
                                  {multipleTimesStep === 1 && (
                                    <>
                                      <DatePicker
                                        inline
                                        selected={
                                          new Date(
                                            moment().format(
                                              'YYYY-MM-DD ' +
                                                multipleTimes.start,
                                            ),
                                          )
                                        }
                                        onChange={(date: Date) => {
                                          if (date === null) {
                                            return
                                          }
                                          setMultipleTimes((prevState) => ({
                                            ...prevState,
                                            start: moment(date).format('HH:mm'),
                                          }))
                                          //setNewKid((prevState) => ({ ...prevState, kidName: displayName }))
                                        }}
                                        minTime={
                                          new Date(
                                            moment().format('YYYY-MM-DD 00:01'),
                                          )
                                        }
                                        maxTime={
                                          new Date(
                                            moment().format('YYYY-MM-DD 23:59'),
                                          )
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption={t('events.startTime')}
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"
                                        locale={i18n.language}
                                      />
                                    </>
                                  )}
                                  {multipleTimesStep === 2 && (
                                    <>
                                      <Singleselect
                                        items={lengthsToMeeting}
                                        onChange={(value) => {
                                          setMultipleTimes((prevState) => ({
                                            ...prevState,
                                            length: value?.value,
                                          }))
                                        }}
                                        initialSelectedItem={{
                                          value: multipleTimes.length,
                                          label: multipleTimes.length,
                                        }}
                                        itemVariableKey="label"
                                        placeholder="Hejsan"
                                      />
                                    </>
                                  )}
                                  {multipleTimesStep === 3 && (
                                    <>
                                      <Singleselect
                                        items={lengthsToMeeting}
                                        onChange={(value) => {
                                          setMultipleTimes((prevState) => ({
                                            ...prevState,
                                            pause: value?.value,
                                          }))
                                        }}
                                        initialSelectedItem={{
                                          value: multipleTimes.pause,
                                          label: multipleTimes.pause,
                                        }}
                                        itemVariableKey="label"
                                        placeholder="Hejsan"
                                      />
                                    </>
                                  )}
                                  {multipleTimesStep === 4 && (
                                    <>
                                      <Singleselect
                                        items={countToMeeting}
                                        onChange={(value) => {
                                          setMultipleTimes((prevState) => ({
                                            ...prevState,
                                            amount: value?.value,
                                          }))
                                        }}
                                        initialSelectedItem={{
                                          value: multipleTimes.amount,
                                          label: multipleTimes.amount,
                                        }}
                                        itemVariableKey="label"
                                        placeholder="Hejsan"
                                      />
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="flex justify-center gap-x-4 mt-2">
                                <Button
                                  variant="fullRed"
                                  size="sm"
                                  label={
                                    multipleTimesStep === 1
                                      ? t('general.cancel')
                                      : t('login.back')
                                  }
                                  onClick={() => {
                                    if (multipleTimesStep > 1) {
                                      setMultipleTimesStep(
                                        multipleTimesStep - 1,
                                      )
                                    } else {
                                      setShowMultipleTimes(false)
                                      setMultipleTimesStep(1)
                                      setMultipleTimes(
                                        initialMultipleTimesValues,
                                      )
                                    }
                                  }}
                                />
                                <Button
                                  variant="fullBlue"
                                  size="sm"
                                  label={
                                    multipleTimesStep === 4
                                      ? t('general.done')
                                      : t('login.nextTitle')
                                  }
                                  onClick={async () => {
                                    if (multipleTimesStep === 4) {
                                      if (newEvent?.times) {
                                        let allTimes = newEvent.times ?? []

                                        let dontRemove:
                                          | {
                                              date: IsoDate
                                              start?: IsoDateTime
                                              end?: IsoDateTime
                                              fakeId: string
                                            }[]
                                          | undefined = []
                                        const { newTimes, dates } =
                                          createManyEventTimesForBooking(
                                            multipleTimes,
                                            newEvent.times.filter((time) => {
                                              if (
                                                separateDate.length > 0 &&
                                                proccessSingularOrMultipleDates ===
                                                  'addManyTimesSingular'
                                              ) {
                                                return separateDate.includes(
                                                  time.date,
                                                )
                                              } else {
                                                return selectedDates.includes(
                                                  time.date,
                                                )
                                              }
                                            }),
                                          )

                                        if (
                                          separateDate.length > 0 &&
                                          proccessSingularOrMultipleDates ===
                                            'addManyTimesSingular'
                                        ) {
                                          for (const newT of newTimes) {
                                            allTimes.push(newT)
                                          }

                                          allTimes = allTimes.filter(
                                            (concreteTimes) =>
                                              concreteTimes.date !==
                                                separateDate[0] ||
                                              concreteTimes.start != null,
                                          )
                                          setSeparateDate([])
                                        } else {
                                          for (const newT of newTimes) {
                                            allTimes.push(newT)
                                          }

                                          dontRemove = newEvent?.times?.filter(
                                            (time) =>
                                              !selectedDates.includes(
                                                time.date,
                                              ),
                                          )

                                          allTimes = allTimes
                                            .filter((aT) =>
                                              selectedDates.includes(aT.date),
                                            )
                                            .filter((aT) => aT.start)
                                          if (dontRemove) {
                                            allTimes.push(...dontRemove)
                                          }
                                        }

                                        onChangeEvent({
                                          ...newEvent,
                                          times: allTimes,
                                        })
                                        setShowMultipleTimes(false)
                                        setMultipleTimesStep(1)
                                        setMultipleTimes(
                                          initialMultipleTimesValues,
                                        )
                                      }
                                    } else {
                                      setMultipleTimesStep(
                                        multipleTimesStep + 1,
                                      )
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </ModalRegular>
                          <ModalRegular
                            title={t('events.addNewTime')}
                            isOpen={showNewTimeModal}
                            onClose={() => {
                              /* setSeparateDate([]) */
                              setShowNewTimeModal(false)
                            }}
                            closeColor="#f6f6f6"
                            className="max-w-sm !overflow-visible"
                            classNameTitle="p-4 w-full text-black flex justify-center"
                          >
                            <div className="flex justify-center gap-4 flex-col  p-4">
                              <div className="flex justify-center gap-x-4">
                                <div className="relative">
                                  <DatePicker
                                    inline
                                    selected={
                                      new Date(
                                        moment().format(
                                          'YYYY-MM-DD ' + newTime.start,
                                        ),
                                      )
                                    }
                                    onChange={(date: Date) => {
                                      if (date === null) {
                                        return
                                      }

                                      setNewTime({
                                        start: moment(date).format('HH:mm'),
                                        end: newTime.end,
                                      })
                                    }}
                                    minTime={
                                      new Date(
                                        moment().format('YYYY-MM-DD 00:01'),
                                      )
                                    }
                                    maxTime={
                                      new Date(moment().format('YYYY-MM-DD '))
                                    }
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption={t('events.startTime')}
                                    dateFormat="HH:mm"
                                    timeFormat="HH:mm"
                                    locale={i18n.language}
                                  />
                                </div>
                                <div className="relative inline-block">
                                  <DatePicker
                                    inline
                                    selected={
                                      new Date(
                                        moment().format(
                                          'YYYY-MM-DD ' + newTime.end,
                                        ),
                                      )
                                    }
                                    onChange={(date: Date) => {
                                      if (date === null) {
                                        return
                                      }
                                      setNewTime({
                                        start: newTime.start,
                                        end: moment(date).format('HH:mm'),
                                      })
                                    }}
                                    minTime={
                                      new Date(
                                        moment().format(
                                          'YYYY-MM-DD ' + newTime.start,
                                        ),
                                      )
                                    }
                                    maxTime={
                                      new Date(
                                        moment().format('YYYY-MM-DD 23:59'),
                                      )
                                    }
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption={t('events.endTime')}
                                    dateFormat="HH:mm"
                                    timeFormat="HH:mm"
                                    locale={i18n.language}
                                  />
                                </div>
                              </div>
                              <div className="flex justify-center gap-x-4">
                                <Button
                                  className="w-[70%]"
                                  variant="fullBlue"
                                  size="lg"
                                  fontSize="font-bold"
                                  label={t('general.save')}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    if (
                                      onAddTime &&
                                      selectedDates.length > 0 &&
                                      proccessSingularOrMultipleDates ===
                                        'multiple'
                                    ) {
                                      onAddTime(newTime, selectedDates)
                                      setShowNewTimeModal(false)
                                    }

                                    if (
                                      onAddTime &&
                                      separateDate.length > 0 &&
                                      proccessSingularOrMultipleDates ===
                                        'singular'
                                    ) {
                                      onAddTime(newTime, separateDate)
                                      setSeparateDate([])
                                      setShowNewTimeModal(false)
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </ModalRegular>
                          <ModalRegular
                            title={t('blog.edit')}
                            isOpen={showEditDateModal}
                            onClose={() => {
                              setShowEditDateModal(false)
                              /* setSeparateDate([]) */
                              if (times) {
                                setEditTimes(times)
                              }
                            }}
                            closeColor="#f6f6f6"
                            className=" max-w-sm max-h-[400px]"
                            classNameTitle="p-4 w-full text-black flex justify-center"
                          >
                            <div className="px-10 h-[500px] relative">
                              <div className="pb-32 h-full overflow-y-auto">
                                {groupedTimes
                                  ?.filter((gD) => {
                                    if (
                                      proccessSingularOrMultipleDates ===
                                      'editSingular'
                                    )
                                      return separateDate.includes(gD)
                                    else if (
                                      proccessSingularOrMultipleDates ===
                                      'editMultiple'
                                    ) {
                                      return selectedDates.includes(gD)
                                    }
                                  })
                                  .map((groupDate) => {
                                    const timesToShow = editTimes?.filter(
                                      (time) => time.date === groupDate,
                                    )
                                    if (
                                      !timesToShow.find(
                                        (time) => time.date === groupDate,
                                      )
                                    ) {
                                      return null
                                    }
                                    return (
                                      <div
                                        className={`flex gap-y-4 flex-col justify-between mb-3`}
                                      >
                                        <div className="flex mt-4">
                                          <b>
                                            {moment(groupDate).format(
                                              'YYYY-MM-DD',
                                            )}
                                          </b>
                                        </div>
                                        <div className="flex flex-col justify-between items-center gap-y-2">
                                          {timesToShow?.map((time, index) => (
                                            <div
                                              className="w-full flex justify-between items-center p-2 hover:bg-gray-100 rounded-xl"
                                              key={index}
                                            >
                                              <div className="text-sm">
                                                {time.start && time.end ? (
                                                  `${time.start} - ${time.end}`
                                                ) : (
                                                  <>{t('events.noSetTime')}</>
                                                )}
                                              </div>
                                              <div className="flex flex-row gap-x-2">
                                                <Button
                                                  variant="fullRed"
                                                  size="xs"
                                                  icon={faTrashCan}
                                                  onClick={() => {
                                                    //  onRemove()
                                                    setEditTimes(
                                                      editTimes.filter(
                                                        (eT) =>
                                                          eT.fakeId !==
                                                          time.fakeId,
                                                      ),
                                                    )
                                                  }}
                                                />
                                                <Button
                                                  variant="fullBlue"
                                                  size="xs"
                                                  icon={faPen}
                                                  onClick={() => {
                                                    setEditTime(time)
                                                    //onSaveLocationModal()
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                        {proccessSingularOrMultipleDates ===
                                          'editSingular' && (
                                          <div className="flex justify-between items-center p-2">
                                            <p className={`text-sm`}>
                                              {t('events.addTime')}
                                            </p>
                                            <Button
                                              variant="fullBlue"
                                              size="xs"
                                              icon={faPlus}
                                              onClick={(e) => {
                                                if (
                                                  !selectedDates.includes(
                                                    groupDate,
                                                  )
                                                ) {
                                                  e.stopPropagation()
                                                  setSeparateDate([groupDate])
                                                  setProccessSingularOrMultipleDates(
                                                    'singular',
                                                  )
                                                  setShowNewTimeModal(true)
                                                }
                                              }}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    )
                                  })}
                              </div>
                              <div className="flex justify-center gap-x-3 p-2 bg-gradient-to-t from-white fixed bottom-0 left-0 w-full">
                                <Button
                                  size="sm"
                                  variant="fullRed"
                                  label={t('general.cancel')}
                                  onClick={() => {
                                    setShowEditDateModal(false)
                                    if (times) {
                                      setEditTimes(times)
                                    }
                                  }}
                                />
                                <Button
                                  size="sm"
                                  variant="fullBlue"
                                  label={t('general.done')}
                                  onClick={() => {
                                    if (onEditTimes) {
                                      onEditTimes(editTimes)
                                    }
                                    setShowEditDateModal(false)
                                    /*if (onAddTime && selectedDates) {
                                    onAddTime(newTime, selectedDates)
                                    }*/
                                  }}
                                />
                              </div>
                            </div>
                            {/* Redigera tid modul */}
                            {editTime && (
                              <ModalRegular
                                title={t('blog.edit')}
                                isOpen={editTime ? true : false}
                                onClose={() => setEditTime(null)}
                                closeColor="#f6f6f6"
                                className="overflow-visible max-w-sm"
                                classNameTitle="p-4 w-full text-black flex justify-center"
                              >
                                <div className="flex justify-center gap-4 flex-col p-4">
                                  <div className="flex justify-center gap-x-4">
                                    <div className="relative">
                                      <DatePicker
                                        inline
                                        selected={
                                          editTime.start
                                            ? new Date(
                                                moment().format(
                                                  'YYYY-MM-DD ' +
                                                    editTime.start,
                                                ),
                                              )
                                            : null
                                        }
                                        onChange={(date: Date) => {
                                          if (date === null) {
                                            return
                                          }
                                          setEditTime({
                                            start: moment(date).format('HH:mm'),
                                            end: editTime.end,
                                            date: editTime.date,
                                            fakeId: editTime.fakeId,
                                          })
                                        }}
                                        minTime={
                                          new Date(
                                            moment().format('YYYY-MM-DD 00:01'),
                                          )
                                        }
                                        maxTime={
                                          editTime.end
                                            ? new Date(
                                                moment().format('YYYY-MM-DD '),
                                              )
                                            : new Date(
                                                moment().format(
                                                  'YYYY-MM-DD 23:59',
                                                ),
                                              )
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption={t('events.startTime')}
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"
                                        locale={i18n.language}
                                      />
                                    </div>
                                    <div className="relative inline-block">
                                      <DatePicker
                                        inline
                                        selected={
                                          editTime.end
                                            ? new Date(
                                                moment().format(
                                                  'YYYY-MM-DD ' + editTime.end,
                                                ),
                                              )
                                            : null
                                        }
                                        onChange={(date: Date) => {
                                          if (date === null) {
                                            return
                                          }
                                          setEditTime({
                                            start: editTime.start,
                                            end: moment(date).format('HH:mm'),
                                            date: editTime.date,
                                            fakeId: editTime.fakeId,
                                          })
                                        }}
                                        minTime={
                                          editTime.start
                                            ? new Date(
                                                moment().format(
                                                  'YYYY-MM-DD ' +
                                                    editTime.start,
                                                ),
                                              )
                                            : new Date(
                                                moment().format(
                                                  'YYYY-MM-DD 00:01',
                                                ),
                                              )
                                        }
                                        maxTime={
                                          new Date(
                                            moment().format('YYYY-MM-DD 23:59'),
                                          )
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption={t('events.endTime')}
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"
                                        locale={i18n.language}
                                      />
                                    </div>
                                  </div>
                                  <div className="flex justify-center gap-x-4">
                                    <Button
                                      className="w-[70%]"
                                      size="lg"
                                      fontSize="font-bold"
                                      variant="fullBlue"
                                      label={t('general.save')}
                                      onClick={async () => {
                                        const newEditTimes = editTimes.map(
                                          (time) => {
                                            if (
                                              editTime.fakeId === time.fakeId
                                            ) {
                                              return editTime
                                            } else {
                                              return time
                                            }
                                          },
                                        )
                                        await setEditTimes(newEditTimes)
                                        setEditTime(null)
                                        /*if (onAddTime && selectedDates) {
                                  onAddTime(newTime, selectedDates)
                                }*/
                                      }}
                                    />
                                  </div>
                                </div>
                              </ModalRegular>
                            )}
                            {/* Slut redigera tid modul */}
                          </ModalRegular>
                        </div>
                      </>
                    ) : (
                      <div className="h-96 overflow-auto px-1">
                        <div
                          className={`flex  flex-wrap gap-3  justify-start `}
                        >
                          {dates
                            ?.sort((a, b) => {
                              return (
                                new Date(a).getTime() - new Date(b).getTime()
                              )
                            })
                            .map((date) => {
                              return (
                                <div className="shadow-sm p-2 pl-4 rounded-2xl animate-slideIn bg-white w-[155px]">
                                  <div
                                    className={` 
                                  border-l-4 ml-2 ${eventTypeColor(
                                    newEvent?.eventType!!,
                                  )}`}
                                  >
                                    <CalendarDateIcon date={date} />
                                  </div>
                                </div>
                              )
                            })}
                        </div>
                        {dates && dates?.length > 15 && (
                          <div className="sticky h-[6vh] bg-gradient-to-t from-white to-[rgba(255,255,255,0)] bottom-0 left-0 w-full rounded-2xl"></div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
