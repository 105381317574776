import React, { useContext, useEffect, useState } from 'react'
import {
  IEventActivitiesOverviewUser,
  IEventDetails,
  IEventInfoOverviewUser,
} from '../EventDetailPage'
import OverviewUserCard from './OverviewUserCard'
import { Button } from '../../../components/Button'
import { Event3StoreContext } from '../../../store/event3Store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleCheck,
  faCircleExclamation,
  faCircleXmark,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { getFullMonthForTranslatation } from '../CreateEvent3'
import { EventListItemData } from '../Events3'
import { CircleMenuReqResEvents } from './CircleMenuReqResEvents'
import { DepartmentStoreContext } from '../../../store/departmentStore'
import { Department } from '../../../Models/Department'
import { t } from 'i18next'

export interface IKidRelation {
  name: string
  thumbnail?: string
  relation?: string
  departmentId?: string
  departmentName?: string
  kidId?: string
  userId?: string
  roleId?: string
  isUser: boolean
}

export interface User
  extends IEventInfoOverviewUser,
    IEventActivitiesOverviewUser {
  isPresent?: boolean | undefined
  userRoleId?: string
  missingDep?: boolean
  multipleDep?: boolean
}

interface DepartmentGroup {
  header: string
  users: User[]
}

const createDepTuplet = (
  departments?: Department[],
  users?: User[],
  departmentsFilter?: (string | undefined)[],
): DepartmentGroup[] => {
  const target: DepartmentGroup[] = []
  const depDict: Record<string, User[]> = {}
  const multiple: User[] = []
  const missing: User[] = []
  console.log(departmentsFilter)
  console.log(users)

  users?.forEach((user) => {
    if (user.departmentIds?.every((element) => element === null)) {
      missing.push(user)
    } else if (user.departmentIds.length > 1) {
      multiple.push(user)
    } else if (user.departmentIds.length === 1) {
      const klassName = departments?.find(
        (dep) => user.departmentIds[0] === dep.id,
      )?.id
      console.log(klassName)

      if (klassName) {
        if (!depDict[klassName]) {
          depDict[klassName] = []
        }
        depDict[klassName].push(user)
      }
    }
  })
  console.log(depDict)

  for (const [key, value] of Object.entries(depDict)) {
    console.log('key: ', key, 'value: ', value)
    console.log(departments?.find((dep) => key === dep.id)?.klassName)

    if (departmentsFilter?.includes(key) || departmentsFilter?.length === 0)
      target.push({
        header: departments?.find((dep) => key === dep.id)?.klassName!!,
        users: value,
      })
  }

  target.sort((a, b) => a.header.localeCompare(b.header))
  console.log(target)

  if (multiple.length > 0) {
    const multipleDeps = t('events.connectedSeveralDepartment')
    if (departmentsFilter?.length === 0)
      target.push({ header: multipleDeps, users: multiple })

    const filteredUsers = multiple.filter((user) =>
      user.departmentIds?.some((department) =>
        departmentsFilter?.includes(department),
      ),
    )

    if (filteredUsers.length > 0) {
      target.push({ header: multipleDeps, users: filteredUsers })
    }
  }

  if (missing.length > 0) {
    const missingDep = 'Department is missing'
    target.push({ header: missingDep, users: missing })
  }

  return target
}

export const OverviewReqResUsersList: React.FC<{
  filterDepartments?: (string | undefined)[]
  eventOverview?: IEventDetails
  eventDetail?: EventListItemData
  refreshDetail: () => void
  hasResponded: boolean
  openHelpSection: boolean
  setOpenHelpSection: (ishelpSectionOpen: boolean) => void
}> = ({
  filterDepartments,
  eventOverview,
  eventDetail,
  refreshDetail,
  hasResponded,
  openHelpSection,
  setOpenHelpSection,
}) => {
  const [selected, setSelected] = useState<string>('accepted')
  const [dataAction, setDataAction] = useState<string>('')
  const [isDisabled, setIsDisabled] = useState(false)
  const [usersList, setUsersList] = useState<DepartmentGroup[]>()

  const { sendReminder } = useContext(Event3StoreContext)
  const { departments } = useContext(DepartmentStoreContext)

  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = (e: any) => {
    e.stopPropagation()
    setIsOpen(!isOpen)
  }

  const handleClick = (type: string) => {
    setSelected(type)
  }

  const getClassNames = (type: string) => {
    const isSelected = selected === type
    return `
    flex items-center
    text-sm 
    font-bold 
    transition-colors duration-300 ease-in-out
    ${
      isSelected && selected === 'accepted'
        ? 'border-b-4 border-[#6BBD73]'
        : isSelected && selected === 'declined'
        ? 'border-b-4 border-red-400'
        : isSelected && selected === 'notReplied'
        ? 'border-b-4 border-[#E1A6F0]'
        : isSelected && selected === 'message'
        ? 'border-b-4 border-[#7eb5f4]'
        : 'text-gray-400'
    }  
    cursor-pointer
  `
  }
  const usersToShow = (category: any) => {
    return createDepTuplet(departments, category, filterDepartments)
  }

  const date = moment(eventOverview?.lastReminderSent)
  const month = getFullMonthForTranslatation(date.format('MM'))
  const formattedDateTime = date.format(
    `[Reminder sent] DD [${month}] YYYY [at] HH:mm`,
  )

  useEffect(() => {
    if (eventOverview?.lastReminderSent) {
      const lastReminderDate = moment(eventOverview.lastReminderSent)
      const now = moment()
      const hoursDifference = now.diff(lastReminderDate, 'hours')

      if (hoursDifference < 24) {
        setIsDisabled(true)
      }
    }
    if (eventOverview?.acceptedCount) {
      handleClick('accepted')
      setUsersList(usersToShow(eventOverview?.acceptedParticipants))
    } else {
      handleClick('notReplied')
      setUsersList(usersToShow(eventOverview?.notRepliedParticipants))
    }
  }, [eventOverview, hasResponded])

  const attending = eventOverview?.acceptedCount
  const notAttending = eventOverview?.declinedCount
  const notReplied = eventOverview?.notRepliedCount
  const msgs = eventOverview?.hasMessageCount
  useEffect(() => {
    console.log('hahahahahhahahahaha')

    if (eventOverview?.acceptedCount) {
      handleClick('accepted')
      setUsersList(usersToShow(eventOverview?.acceptedParticipants))
    } else {
      handleClick('notReplied')
      setUsersList(usersToShow(eventOverview?.notRepliedParticipants))
    }
  }, [filterDepartments, refreshDetail])

  const presentParticipants = createDepTuplet(
    departments,
    eventOverview?.acceptedParticipants,
    filterDepartments,
  )
  const isTodayAfterEvent = moment().isAfter(
    moment(eventDetail?.eventDate),
    'day',
  )

  return (
    <div className="flex flex-col justify-between h-full relative">
      <div className={` overflow-y-auto h-full group`}>
        <div
          className={` flex justify-around items-center py-2 `}
          onClick={(e) => e.stopPropagation()}
        >
          <p
            className={`${getClassNames('accepted')}`}
            onClick={(e) => {
              e.stopPropagation()
              setDataAction('')
              handleClick('accepted')
              setUsersList(usersToShow(eventOverview?.acceptedParticipants))
            }}
          >
            <FontAwesomeIcon
              icon={faCircleCheck}
              className={` pr-2 ${
                selected === 'accepted' ? 'text-[#6BBD73]' : 'text-gray-300'
              }`}
            />
            <p>
              {t('events.accepted')} {'('}
              {attending}
              {')'}
            </p>
          </p>

          <p
            className={`${getClassNames('declined')}`}
            onClick={(e) => {
              e.stopPropagation()
              setDataAction('')
              handleClick('declined')
              setUsersList(usersToShow(eventOverview?.declinedParticipants))
            }}
          >
            <FontAwesomeIcon
              icon={faCircleXmark}
              className={`pr-2 ${
                selected === 'declined' ? 'text-red-400' : 'text-gray-300'
              }`}
            />
            <p>
              {t('events.declined')} {'('}
              {notAttending}
              {')'}
            </p>
          </p>
          <p
            className={`${getClassNames('notReplied')}`}
            onClick={(e) => {
              e.stopPropagation()
              setDataAction('')
              handleClick('notReplied')
              setUsersList(usersToShow(eventOverview?.notRepliedParticipants))
            }}
          >
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className={`pr-2
            ${selected === 'notReplied' ? 'text-[#E1A6F0]' : 'text-gray-300'}
            `}
            />
            <p>
              {t('events.notReplied')} {'('}
              {notReplied}
              {')'}
            </p>
          </p>

          <p
            className={`flex items-center ${getClassNames('message')}`}
            onClick={(e) => {
              e.stopPropagation()
              handleClick('message')
              setDataAction('messages')
              setUsersList(usersToShow(eventOverview?.hasMessageParticipants))
            }}
            data-action="messages"
          >
            <span className="flex rounded-full w-3 h-3 bg-gray-300  items-center justify-center mr-2">
              <FontAwesomeIcon
                size="2xs"
                icon={faEnvelope}
                className={`text-gray-100 pr-0.25 ${
                  selected === 'message' ? 'bg-[#7eb5f4]' : 'text-white'
                }`}
              />
            </span>
            <p>
              {t('events.messages')} {'('}
              {msgs}
              {')'}
            </p>
          </p>
        </div>
        <div className={`flex flex-col  `}>
          {usersList?.map((group) => (
            <React.Fragment key={group.header}>
              <h2 className="pl-4 text-sm font-bold">
                {group.header} ({group.users.length})
              </h2>
              <div className="mb-4 last:mb-24 px-4 w-full">
                <ul className="flex flex-wrap justify-between ">
                  {group.users.map((user) => {
                    return (
                      <li key={user.id} className="px-1 w-[49%]">
                        <OverviewUserCard
                          user={user}
                          eventDetail={eventDetail}
                          refreshDetail={refreshDetail}
                          dataAction={dataAction}
                        />
                      </li>
                    )
                  })}
                </ul>
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className="flex w-full justify-center absolute bottom-6 bg-gradient-to-t from-[#F6F6F6]">
          {selected === 'notReplied' && isTodayAfterEvent !== true && (
            <Button
              className="w-[40%] my-3"
              disabled={isDisabled}
              size="sm"
              variant="fullBlue"
              fontSize="font-bold"
              label={isDisabled ? formattedDateTime : t('absence.sendNotice')}
              onClick={async (e) => {
                e.stopPropagation()
                try {
                  await sendReminder(
                    eventDetail?.eventTimeId,
                    eventOverview?.notRepliedParticipants.map(
                      (user) => user.userId,
                    ),
                  )
                  refreshDetail()
                } catch (error) {
                  console.error(error)
                }
              }}
            />
          )}
        </div>
      </div>
      {selected === 'accepted' && (
        <div
          className={`flex absolute bottom-6 ${
            isOpen &&
            'backdrop-blur-sm animate-fadeIn ease-in-out absolute bottom-6 w-full h-[95%]'
          }`}
        >
          <CircleMenuReqResEvents
            presentParticipants={presentParticipants}
            eventTimeId={eventDetail?.eventTimeId}
            refreshDetail={refreshDetail}
            toggleMenu={toggleMenu}
            departments={eventDetail?.departmentIds}
            isOpen={isOpen}
            openHelpSection={openHelpSection}
            setOpenHelpSection={setOpenHelpSection}
          />
        </div>
      )}
    </div>
  )
}
