import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { Button } from '../../../components/Button'
import ModalRegular from '../../../components/ModalRegular'
import { ActiveUsers, DocumentStoreContext } from '../../../store/documentStore'
import { UserStoreContext } from '../../../store/userStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser,
  faCheckCircle,
  faUsers,
  faArrowUp,
  faCaretUp,
  faCaretRight,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import { UserRole } from '../../../Models/UserRole'
import { User } from '../../../Models/User'
import {
  NotificationContext,
  NotificationVariant,
} from '../../../App/notification/notificationManger'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'
import { Loading } from '../../../components/Loading'
import { USER_ROLE_STATUS, USER_ROLE_TYPE } from '../../../Enums/TyraEnums'
import { CreateEventParams } from '../../../store/event3Store'
import { TabHeaderSlider } from '../../documents/DocumentMain'
import { Kid } from '../../../Models/Kid'
import { getImageFromRole } from '../../../utils/parseUtils'
import { DepartmentStoreContext } from '../../../store/departmentStore'
import { KidsStoreContext } from '../../../store/kidsStore'
import { AnimatePresence, motion } from 'framer-motion'

export const findRoles = (items: any, search: string) => {
  const text = search.split(' ')
  return items.filter((item: any) => {
    return text.every((el) => {
      return (
        item?.user?.attributes?.firstName.toLowerCase().includes(el) ||
        item?.user?.attributes?.lastName.toLowerCase().includes(el)
      )
    })
  })
}

export const findKids = (items: any, search: string) => {
  const text = search.split(' ')
  return items.filter((item: any) => {
    return text.every((el) => {
      return (
        item?.kid.attributes?.firstName.toLowerCase().includes(el) ||
        item?.kid.attributes?.lastName.toLowerCase().includes(el)
      )
    })
  })
}

const findKidsMissing = (items: any, search: string) => {
  const text = search.split(' ')
  return items.filter((item: any) => {
    return text.every((el) => {
      return (
        item.attributes?.firstName.toLowerCase().includes(el) ||
        item.attributes?.lastName.toLowerCase().includes(el)
      )
    })
  })
}

export const SpecificPersonModal: React.FC<{
  onSaved: any
  newEvent: CreateEventParams
  onClose: () => void
  onSave: (e: string[]) => void
}> = observer(({ onSaved, newEvent, onClose, onSave }) => {
  const { t } = useTranslation()
  const targetDivRef = useRef<HTMLDivElement | null>(null)
  const {
    getSchoolActiveUsers,
    schoolsActiveUsers,
    shareDocument,
    activeDocument,
    setActiveDocument,
    getDoc,
  } = useContext(DocumentStoreContext)
  const { notify } = useContext(NotificationContext)
  const [loading, setLoading] = useState(false)
  const { savedUserRoleId, currentUser, currentUserRole } =
    useContext(UserStoreContext)
  const { departmentsForMultiSchool } = useContext(DepartmentStoreContext)
  const { fetchKids, kids } = useContext(KidsStoreContext)
  const [showModal, setShowModal] = useState(false)
  const [personal, setPersonal] = useState(true)
  const [parent, setParent] = useState(true)
  const [selectedUserRoleIds, setSelectedUserRoleIds] = useState<any>(
    newEvent.specificUserRoleIds ?? [],
  )
  const [editSelectedUserRoleIds, setEditSelectedUserRoleIds] = useState<any>(
    [],
  )
  const [removeFromSelectedUserRoleIds, setRemoveFromSelectedUserRoleIds] =
    useState<string[]>([])
  const [selectedUsers, setSelectedUsers] = useState<any>([])
  const [initialSelectedUsers, setInitialSelectedUsers] = useState<any>(
    newEvent.specificUserRoleIds ?? [],
  )
  const [showSelectedSpecifics, setShowSelectedSpecifics] = useState(false)
  const [searchActiveUsers, setSearchActiveUsers] =
    useState<ActiveUsers | null>()
  const [searchKidsMissing, setSearchKidsMissing] = useState<Kid[] | null>()
  const [clicked, setClicked] = useState(false)
  const [activeTab, setActiveTab] = useState<{
    label: string
    value: string
    color?: string
  }>(
    newEvent.onlyStaff
      ? {
          label: t('documents.staff'),
          value: 'staff',
        }
      : {
          label: t('general.kids'),
          value: 'kids',
        },
  )

  const search = (searchText: string) => {
    const roles = findRoles(schoolsActiveUsers?.userRoles, searchText)
    const kidsSearch = findKids(schoolsActiveUsers?.kids, searchText)
    const kidsMissing = findKidsMissing(kids, searchText)
    console.log('kidsMissing', kidsMissing)
    if (searchText?.length > 0) {
      setSearchActiveUsers({ userRoles: roles, kids: kidsSearch })
      setSearchKidsMissing(kidsMissing)
    } else {
      setSearchActiveUsers(null)
      setSearchKidsMissing(null)
    }
  }
  useEffect(() => {
    if (savedUserRoleId && currentUserRole) {
      setLoading(true)
      fetchKids(currentUserRole)
      getSchoolActiveUsers(savedUserRoleId).finally(() => {
        setLoading(false)
      })
    }
    return () => {
      setActiveDocument(null)
      setInitialSelectedUsers([])
      setSelectedUsers([])
      setClicked(false)
    }
  }, [savedUserRoleId])

  useEffect(() => {}, [schoolsActiveUsers])

  useEffect(() => {
    if (activeDocument) {
      setInitialSelectedUsers(
        activeDocument?.attributes?.user_shared_with ?? [],
      )
    }
  }, [activeDocument])

  const areAllIncluded = (
    smallArray: string[],
    bigArray: string[],
  ): boolean => {
    const bigSet = new Set(bigArray)
    return smallArray.every((id) => bigSet.has(id))
  }

  useEffect(() => {}, [activeTab])

  const items = searchActiveUsers ? searchActiveUsers : schoolsActiveUsers
  const itemKidsMissing = searchKidsMissing ? searchKidsMissing : kids

  const sortGuardians = (
    userRoles?: UserRole[],
  ): { department: { id: string; name: string }; userRoles: UserRole[] }[] => {
    if (!userRoles || userRoles.length < 1) {
      return []
    }

    // Collect user roles and their department mappings
    const userIds: {
      userId: string
      userRole: UserRole
      kidDepartmentId: string | null
    }[] = []

    for (const userRole of userRoles) {
      if (userRole.user && userRole.kid) {
        const kid = kids?.find((k) => k.id === userRole.attributes.kid.id)
        userIds.push({
          userId: userRole.user.id,
          userRole,
          kidDepartmentId: kid?.attributes.departmentPointer?.id || null,
        })
      }
    }

    // Group by userId to check for multiple departments
    const userRoleGroups: Record<
      string,
      { userId: string; userRole: UserRole; kidDepartmentId: string | null }[]
    > = userIds.reduce((acc, curr) => {
      if (!acc[curr.userId]) {
        acc[curr.userId] = []
      }
      acc[curr.userId].push(curr)
      return acc
    }, {} as Record<string, { userId: string; userRole: UserRole; kidDepartmentId: string | null }[]>)

    const result: {
      department: { id: string; name: string }
      userRoles: UserRole[]
    }[] = []

    for (const department of departmentsForMultiSchool ?? []) {
      const row: {
        department: {
          id: string
          name: string
        }
        userRoles: UserRole[]
      } = {
        department: {
          id: department.id,
          name: department.klassName ?? '',
        },
        userRoles: [],
      }

      // Add userRoles where the user is only associated with this department
      for (const [userId, roles] of Object.entries(userRoleGroups)) {
        const uniqueDepartments = new Set(roles.map((r) => r.kidDepartmentId))

        if (
          uniqueDepartments.size === 1 &&
          uniqueDepartments.has(department.id)
        ) {
          row.userRoles.push(...roles.map((r) => r.userRole))
        }
      }

      if (row.userRoles.length > 0) {
        result.push(row)
      }
    }

    // Handle users with roles across multiple departments
    const multiDepRow: {
      department: {
        id: string
        name: string
      }
      userRoles: UserRole[]
    } = {
      department: {
        id: 'multi-department',
        name: t('events.severalDepartments'),
      },
      userRoles: [],
    }

    // Use a Set to track processed users
    const processedUsers = new Set<string>()

    for (const [userId, roles] of Object.entries(userRoleGroups)) {
      const uniqueDepartments = new Set(roles.map((r) => r.kidDepartmentId))

      if (uniqueDepartments.size > 1 && !processedUsers.has(userId)) {
        // Add the first role of the user (or customize as needed)
        multiDepRow.userRoles.push(roles[0].userRole)
        processedUsers.add(userId) // Mark this user as processed
      }
    }

    if (multiDepRow.userRoles.length > 0) {
      result.push(multiDepRow)
    }

    return result
  }

  return (
    <ModalRegular
      title={
        showSelectedSpecifics
          ? `${
              editSelectedUserRoleIds.length > 2
                ? t('events.youAndPlural').replace(
                    '%1$s',
                    (editSelectedUserRoleIds.length - 1).toString(),
                  )
                : editSelectedUserRoleIds.length === 2
                ? t('events.youAndSingular').replace(
                    '%1$s',
                    (editSelectedUserRoleIds.length - 1).toString(),
                  )
                : editSelectedUserRoleIds.length === 1
                ? t('events.onlyYouShared')
                : t('events.notShared')
            }`
          : t('events.chooseRecipient')
      }
      isOpen={true}
      className={
        '!max-w-4xl pl-4 pt-6 !overflow-hidden h-[100vh] !bg-[#f6f6f6]'
      }
      classNameTitle="mb-4 w-full text-black !important flex justify-center "
      onClose={() => {
        onClose()
      }}
    >
      <div ref={targetDivRef} className="absolute top-0"></div>
      <div
        className={'mt-2 mb-4 flex flex-col gap-y-7 absolute top-2.5 right-5'}
      >
        <div className="flex flex-wrap w-full justify-center items-center font-normal">
          <SearchInput
            onChange={(s: string) => {
              search(s)
            }}
          />
        </div>
      </div>
      {showSelectedSpecifics === false && (
        <div className="font-bold w-full">
          <TabHeaderSlider
            tabs={
              newEvent.onlyStaff
                ? [
                    {
                      label: t('documents.staff'),
                      value: 'staff',
                    },
                  ]
                : [
                    {
                      label: t('general.kids'),
                      value: 'kids',
                    },
                    {
                      label: t('general.guardian'),
                      value: 'guardians',
                    },
                    {
                      label: t('documents.staff'),
                      value: 'staff',
                    },
                  ]
            }
            onClick={(value) => {
              setActiveTab(value)
            }}
            activeTab={activeTab}
          />
        </div>
      )}
      <div className=" grid grid-cols-2 w-[100%] h-[80%] !overflow-y-auto pb-8 overflow-x-hidden scrollbar-hidden">
        <motion.div
          initial="initialState"
          animate={showSelectedSpecifics ? 'animateState' : 'exitState'}
          exit="exitState"
          transition={{
            type: 'tween',
            duration: 0.5,
          }}
          variants={{
            initialState: {
              x: '100vw',
            },
            animateState: {
              x: 0,
            },
            exitState: {
              x: '-100vw',
            },
          }}
          className={`w-[200%] `}
        >
          <div className="flex flex-col flex-wrap gap-2 pr-4">
            {items?.userRoles
              ?.filter((userRole) => userRole.role_type > USER_ROLE_TYPE.PARENT)
              ?.filter((userRole) =>
                selectedUserRoleIds.find(
                  (roleId: string) => roleId === userRole.id,
                ),
              )?.length ? (
              <div className="col-span-4 font-bold my-2">
                {t('documents.staff')}
              </div>
            ) : null}
            <div className="grid grid-cols-7 flex-wrap gap-2">
              {items?.userRoles
                ?.filter(
                  (userRole) => userRole.role_type > USER_ROLE_TYPE.PARENT,
                )
                ?.filter((userRole) =>
                  selectedUserRoleIds.find(
                    (roleId: string) => roleId === userRole.id,
                  ),
                )
                ?.map((userRole, index) => {
                  return (
                    <UserRoleItem
                      userRole={userRole}
                      selected={editSelectedUserRoleIds.find(
                        (sURI: string) => sURI === userRole.id,
                      )}
                      onClick={() => {
                        if (
                          editSelectedUserRoleIds.find(
                            (sURI: string) => sURI === userRole.id,
                          )
                        ) {
                          if (userRole.id === currentUserRole?.id) {
                            if (editSelectedUserRoleIds.length === 1) {
                              setEditSelectedUserRoleIds(
                                editSelectedUserRoleIds.filter(
                                  (sURI: string) => sURI !== userRole.id,
                                ),
                              )
                            }
                          } else {
                            setEditSelectedUserRoleIds(
                              editSelectedUserRoleIds.filter(
                                (sURI: string) => sURI !== userRole.id,
                              ),
                            )
                          }
                        } else {
                          if (
                            editSelectedUserRoleIds.length === 0 &&
                            currentUserRole &&
                            userRole.id !== currentUserRole.id
                              ? currentUserRole.id
                              : null
                          ) {
                            setEditSelectedUserRoleIds([
                              ...editSelectedUserRoleIds,
                              userRole.id,
                              currentUserRole?.id,
                            ])
                          } else {
                            setEditSelectedUserRoleIds([
                              ...editSelectedUserRoleIds,
                              userRole.id,
                            ])
                          }
                        }
                      }}
                    />
                  )
                })}
            </div>
          </div>

          <div className="grid grid-cols-1 flex-wrap gap-1">
            {sortGuardians(
              items?.userRoles
                /*?.filter(
                  (userRole) =>
                    selectedUserRoleIds.find(
                      (roleId: string) => roleId === userRole.id,
                    ) &&
                    !removeFromSelectedUserRoleIds.find(
                      (roleId: string) => roleId === userRole.id,
                    ),
                )*/
                .filter(
                  (userRole) => userRole.role_type === USER_ROLE_TYPE.PARENT,
                ),
            ).map((userItems, index) => {
              const anyShow = userItems.userRoles.some((userRole) => {
                const guardianUserRoles = items?.userRoles.filter(
                  (uR) => uR.user?.id === userRole.user?.id && uR.kid,
                )
                return guardianUserRoles?.some((guardianRole) =>
                  selectedUserRoleIds.includes(guardianRole.id),
                )
              })
              if (
                !userItems.userRoles.find(
                  (userRole) =>
                    selectedUserRoleIds.find(
                      (roleId: string) => roleId === userRole.id,
                    ) /*&&
                    !removeFromSelectedUserRoleIds.find(
                      (roleId: string) => roleId === userRole.id,
                    )*/,
                )
              ) {
                //return null
              }
              return (
                <div
                  key={index}
                  className={'flex flex-col gap-y-1 w-full pr-4'}
                >
                  <div
                    id={`department-${userItems.department.id}`}
                    className={classNames(
                      'col-span-2  my-2',
                      anyShow ? '' : 'hidden',
                    )}
                  >
                    {userItems.department.name}
                  </div>
                  <div className={'grid grid-cols-3 gap-2'}>
                    {userItems.userRoles.map((userRole) => {
                      let guardianUserRoles = items?.userRoles
                        .filter(
                          (uR) => uR.user?.id === userRole.user?.id && uR.kid,
                        )
                        ?.map((uR) => uR)
                      let show = false
                      for (const guardianRole of guardianUserRoles ?? []) {
                        if (
                          selectedUserRoleIds.find(
                            (sURI: string) => sURI === guardianRole.id,
                          )
                        ) {
                          show = true
                        }
                      }
                      if (!show) {
                        return null
                      }
                      return (
                        <GuardianItem
                          key={userRole.id} // Use a unique identifier if available, fallback to index
                          kids={items?.kids?.filter(
                            (kid) =>
                              kid.kid.departmentPointer &&
                              kid.userRoles.find(
                                (uR) =>
                                  uR?.user?.id === userRole.user?.id &&
                                  uR.role_type === USER_ROLE_TYPE.PARENT,
                              ),
                          )}
                          userRole={userRole}
                          selectedUserRoleIds={editSelectedUserRoleIds}
                          guardianUserRoles={guardianUserRoles}
                          guardianClick={(userRoleId: string) => {
                            if (
                              editSelectedUserRoleIds.find(
                                (id: string) => id === userRoleId,
                              )
                            ) {
                              setEditSelectedUserRoleIds(
                                editSelectedUserRoleIds.filter(
                                  (id: string) => id !== userRoleId,
                                ),
                              )
                            } else {
                              if (
                                editSelectedUserRoleIds.length === 0 &&
                                currentUserRole &&
                                userRole.id !== currentUserRole.id
                                  ? currentUserRole.id
                                  : null
                              ) {
                                setEditSelectedUserRoleIds([
                                  ...editSelectedUserRoleIds,
                                  userRoleId,
                                  currentUserRole?.id,
                                ])
                              } else {
                                setEditSelectedUserRoleIds([
                                  ...editSelectedUserRoleIds,
                                  userRoleId,
                                ])
                              }
                              /*setEditSelectedUserRoleIds([
                                ...editSelectedUserRoleIds,
                                userRoleId,
                              ])*/
                            }
                          }}
                          onClick={() => {
                            let ids: string[] = []

                            for (const guardianRole of guardianUserRoles ??
                              []) {
                              ids.push(guardianRole.id)
                            }

                            if (
                              editSelectedUserRoleIds.filter((id: string) =>
                                ids.includes(id),
                              )?.length > 0
                            ) {
                              setEditSelectedUserRoleIds(
                                editSelectedUserRoleIds.filter(
                                  (id: string) => !ids.includes(id),
                                ),
                              )
                            } else {
                              if (
                                editSelectedUserRoleIds.length === 0 &&
                                currentUserRole
                              ) {
                                setEditSelectedUserRoleIds([
                                  ...editSelectedUserRoleIds,
                                  ...(guardianUserRoles?.map(
                                    (role) => role.id,
                                  ) ?? []),
                                  currentUserRole?.id,
                                ])
                              } else {
                                setEditSelectedUserRoleIds([
                                  ...editSelectedUserRoleIds,
                                  ...(guardianUserRoles?.map(
                                    (role) => role.id,
                                  ) ?? []),
                                ])
                              }
                            }
                          }}
                        />
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        </motion.div>

        <motion.div
          initial="initialState"
          animate={showSelectedSpecifics ? 'exitState' : 'animateState'}
          exit="exitState"
          transition={{
            type: 'tween',
            duration: 0.5,
          }}
          variants={{
            initialState: {
              x: 0,
            },
            animateState: {
              x: 0,
            },
            exitState: {
              x: '100vw',
            },
          }}
          //className="col-span-1 row-span-1"
          className={`w-[200%] relative left-[-100%] font-bold ${
            showSelectedSpecifics === true && '!overflow-hidden'
          }`}
        >
          {loading && <Loading />}

          <div className="px-0 pt-2 overflow-auto pr-4">
            <div className={'grid grid-cols-1 gap-y-1 pb-8'}>
              {activeTab.value === 'kids' && (
                <>
                  {departmentsForMultiSchool?.map((department) => {
                    let kidIds: string[] = []
                    return (
                      <>
                        <div>
                          {items?.kids?.filter(
                            (kid) =>
                              kid.kid.departmentPointer?.id === department.id,
                          )?.length ? (
                            <div className="flex justify-between col-span-2 font-bold my-2">
                              {department.klassName}
                              <p
                                className="pr-4 text-blueDark font-semibold cursor-pointer text-sm -mr-4"
                                onClick={() => {
                                  const userRoleIds = items.kids
                                    ?.filter(
                                      (kid) =>
                                        kid.kid.departmentPointer?.id ===
                                        department.id,
                                    )
                                    .flatMap((kid) =>
                                      kid.userRoles.map((user) => user.id),
                                    )

                                  if (
                                    areAllIncluded(
                                      userRoleIds || [],
                                      selectedUserRoleIds,
                                    )
                                  ) {
                                    setSelectedUserRoleIds([
                                      ...selectedUserRoleIds.filter(
                                        (id: string) =>
                                          !userRoleIds?.includes(id),
                                      ),
                                    ])
                                  } else {
                                    if (
                                      selectedUserRoleIds.length === 0 &&
                                      currentUserRole
                                    ) {
                                      setSelectedUserRoleIds([
                                        ...selectedUserRoleIds,
                                        ...userRoleIds!!,
                                        currentUserRole.id,
                                      ])
                                    } else {
                                      setSelectedUserRoleIds([
                                        ...selectedUserRoleIds,
                                        ...userRoleIds!!,
                                      ])
                                    }
                                  }
                                }}
                              >
                                {areAllIncluded(
                                  items.kids
                                    ?.filter(
                                      (kid) =>
                                        kid.kid.departmentPointer?.id ===
                                        department.id,
                                    )
                                    .flatMap((kid) =>
                                      kid.userRoles.map((user) => user.id),
                                    ),
                                  selectedUserRoleIds,
                                )
                                  ? t('multiselect.deSelectAll')
                                  : t('settings.markAll')}
                              </p>
                            </div>
                          ) : null}
                        </div>
                        <div className="grid grid-cols-3 flex-wrap gap-2">
                          {items?.kids
                            ?.filter(
                              (kid) =>
                                kid.kid.departmentPointer?.id === department.id,
                            )
                            ?.map((kid, index) => {
                              if (!parent) {
                                return null
                              }
                              kidIds.push(kid.kid.id)
                              return (
                                <KidItem
                                  key={index}
                                  kid={kid}
                                  selectedUserRoleIds={selectedUserRoleIds}
                                  guardianClick={(userRoleId: string) => {
                                    if (
                                      selectedUserRoleIds.find(
                                        (id: string) => id === userRoleId,
                                      )
                                    ) {
                                      setSelectedUserRoleIds(
                                        selectedUserRoleIds.filter(
                                          (id: string) => id !== userRoleId,
                                        ),
                                      )
                                    } else {
                                      if (
                                        selectedUserRoleIds.length === 0 &&
                                        currentUserRole
                                      ) {
                                        setSelectedUserRoleIds([
                                          ...selectedUserRoleIds,
                                          userRoleId,
                                          currentUserRole?.id,
                                        ])
                                      } else {
                                        setSelectedUserRoleIds([
                                          ...selectedUserRoleIds,
                                          userRoleId,
                                        ])
                                      }
                                    }
                                  }}
                                  onClick={() => {
                                    const ids = kid?.userRoles?.map(
                                      (uR: any) => uR?.id,
                                    )
                                    if (
                                      selectedUserRoleIds.filter((id: string) =>
                                        ids.includes(id),
                                      )?.length > 0
                                    ) {
                                      setSelectedUserRoleIds(
                                        selectedUserRoleIds.filter(
                                          (id: string) => !ids.includes(id),
                                        ),
                                      )
                                    } else {
                                      const userRoleIds = kid.userRoles?.map(
                                        (uR) => uR.id,
                                      )
                                      if (
                                        selectedUserRoleIds.length === 0 &&
                                        currentUserRole
                                      ) {
                                        setSelectedUserRoleIds([
                                          ...selectedUserRoleIds,
                                          ...userRoleIds,
                                          currentUserRole.id,
                                        ])
                                      } else {
                                        setSelectedUserRoleIds([
                                          ...selectedUserRoleIds,
                                          ...userRoleIds,
                                        ])
                                      }
                                    }
                                  }}
                                />
                              )
                            })}
                          {itemKidsMissing
                            ?.filter(
                              (kid) =>
                                kid.departmentPointer?.id === department.id &&
                                !kidIds.find((kidId) => kidId === kid.id),
                            )
                            ?.map((kid, index) => {
                              if (!parent) {
                                return null
                              }

                              return (
                                <KidItem
                                  key={index}
                                  kid={{ kid: kid }}
                                  selectedUserRoleIds={selectedUserRoleIds}
                                  guardianClick={(userRoleId: string) => {
                                    if (
                                      selectedUserRoleIds.find(
                                        (id: string) => id === userRoleId,
                                      )
                                    ) {
                                      setSelectedUserRoleIds(
                                        selectedUserRoleIds.filter(
                                          (id: string) => id !== userRoleId,
                                        ),
                                      )
                                    } else {
                                      if (
                                        selectedUserRoleIds.length === 0 &&
                                        currentUserRole
                                      ) {
                                        setSelectedUserRoleIds([
                                          ...selectedUserRoleIds,
                                          userRoleId,
                                          currentUserRole?.id,
                                        ])
                                      } else {
                                        setSelectedUserRoleIds([
                                          ...selectedUserRoleIds,
                                          userRoleId,
                                        ])
                                      }
                                    }
                                  }}
                                  missingPeople={true}
                                  onClick={() => {}}
                                />
                              )
                            })}
                        </div>
                      </>
                    )
                  })}
                </>
              )}
              {activeTab.value === 'guardians' && (
                <>
                  {sortGuardians(
                    items?.userRoles?.filter(
                      (userRole) =>
                        userRole.role_type === USER_ROLE_TYPE.PARENT,
                    ),
                  ).map((userItems, index) => (
                    <React.Fragment key={index}>
                      <div className="col-span-2 font-bold my-2 flex justify-between">
                        {userItems.department.name}
                        <p
                          className="pr-4 text-blueDark font-semibold cursor-pointer text-sm -mr-4"
                          onClick={() => {
                            if (
                              areAllIncluded(
                                userItems.userRoles.map(
                                  (userRole) => userRole.id,
                                ),
                                selectedUserRoleIds,
                              )
                            ) {
                              setSelectedUserRoleIds(
                                selectedUserRoleIds.filter(
                                  (id: string) =>
                                    ![
                                      ...userItems.userRoles.map(
                                        (userRole) => userRole.id,
                                      ),
                                    ]?.includes(id),
                                ),
                              )
                            } else {
                              if (
                                selectedUserRoleIds.length === 0 &&
                                currentUserRole
                              ) {
                                setSelectedUserRoleIds([
                                  ...selectedUserRoleIds,
                                  ...userItems.userRoles.map(
                                    (userRole) => userRole.id,
                                  ),
                                  currentUserRole.id,
                                ])
                              } else {
                                setSelectedUserRoleIds([
                                  ...selectedUserRoleIds,
                                  ...userItems.userRoles.map(
                                    (userRole) => userRole.id,
                                  ),
                                ])
                              }
                            }
                          }}
                        >
                          {areAllIncluded(
                            userItems.userRoles.map((userRole) => userRole.id),
                            selectedUserRoleIds,
                          )
                            ? t('multiselect.deSelectAll')
                            : t('settings.markAll')}
                        </p>
                      </div>
                      <div className="grid grid-cols-3 flex-wrap gap-2">
                        {userItems.userRoles.map((userRole) => {
                          let guardianUserRoles = items?.userRoles
                            .filter(
                              (uR) =>
                                uR.user?.id === userRole.user?.id && uR.kid,
                            )
                            ?.map((uR) => uR)

                          return (
                            <GuardianItem
                              key={userRole.id} // Use a unique identifier if available, fallback to index
                              kids={items?.kids?.filter(
                                (kid) =>
                                  kid.kid.departmentPointer &&
                                  kid.userRoles.find(
                                    (uR) =>
                                      uR?.user?.id === userRole.user?.id &&
                                      uR.role_type === USER_ROLE_TYPE.PARENT,
                                  ),
                              )}
                              userRole={userRole}
                              selectedUserRoleIds={selectedUserRoleIds}
                              guardianUserRoles={guardianUserRoles}
                              guardianClick={(userRoleId: string) => {
                                if (
                                  selectedUserRoleIds.find(
                                    (id: string) => id === userRoleId,
                                  )
                                ) {
                                  setSelectedUserRoleIds(
                                    selectedUserRoleIds.filter(
                                      (id: string) => id !== userRoleId,
                                    ),
                                  )
                                } else {
                                  if (
                                    selectedUserRoleIds.length === 0 &&
                                    currentUserRole
                                  ) {
                                    setSelectedUserRoleIds([
                                      ...selectedUserRoleIds,
                                      userRoleId,
                                      currentUserRole.id,
                                    ])
                                  } else {
                                    setSelectedUserRoleIds([
                                      ...selectedUserRoleIds,
                                      userRoleId,
                                    ])
                                  }
                                }
                              }}
                              onClick={() => {
                                let ids: string[] = []

                                for (const guardianRole of guardianUserRoles ??
                                  []) {
                                  ids.push(guardianRole.id)
                                }

                                if (
                                  selectedUserRoleIds.filter((id: string) =>
                                    ids.includes(id),
                                  )?.length > 0
                                ) {
                                  setSelectedUserRoleIds(
                                    selectedUserRoleIds.filter(
                                      (id: string) => !ids.includes(id),
                                    ),
                                  )
                                } else {
                                  if (
                                    selectedUserRoleIds.length === 0 &&
                                    currentUserRole
                                  ) {
                                    setSelectedUserRoleIds([
                                      ...selectedUserRoleIds,
                                      ...(guardianUserRoles?.map(
                                        (role) => role.id,
                                      ) ?? []),
                                      currentUserRole.id,
                                    ])
                                  } else {
                                    setSelectedUserRoleIds([
                                      ...selectedUserRoleIds,
                                      ...(guardianUserRoles?.map(
                                        (role) => role.id,
                                      ) ?? []),
                                    ])
                                  }
                                }
                              }}
                            />
                          )
                        })}
                      </div>
                    </React.Fragment>
                  ))}
                </>
              )}
              {activeTab.value === 'staff' && (
                <div className="w-full ">
                  {departmentsForMultiSchool?.map((department) => {
                    return (
                      <>
                        {items?.userRoles
                          ?.filter(
                            (userRole) =>
                              userRole.role_type > USER_ROLE_TYPE.PARENT,
                          )
                          ?.filter(
                            (userRole) =>
                              userRole.staff_primary_dep?.id === department.id,
                          ).length ? (
                          <div className="col-span-2 font-bold my-2 -mr-4 flex justify-between">
                            {department.klassName}
                            <p
                              className="pr-4 text-blueDark font-semibold cursor-pointer text-sm"
                              onClick={() => {
                                if (
                                  areAllIncluded(
                                    items?.userRoles
                                      ?.filter(
                                        (userRole) =>
                                          userRole.role_type >
                                          USER_ROLE_TYPE.PARENT,
                                      )
                                      ?.filter(
                                        (userRole) =>
                                          userRole.staff_primary_dep?.id ===
                                          department.id,
                                      )
                                      .map((user) => user.id),
                                    selectedUserRoleIds,
                                  )
                                ) {
                                  setSelectedUserRoleIds(
                                    selectedUserRoleIds.filter(
                                      (id: string) =>
                                        !items?.userRoles
                                          ?.filter(
                                            (userRole) =>
                                              userRole.role_type >
                                              USER_ROLE_TYPE.PARENT,
                                          )
                                          ?.filter(
                                            (userRole) =>
                                              userRole.staff_primary_dep?.id ===
                                              department.id,
                                          )
                                          .map((user) => user.id)
                                          .includes(id),
                                    ),
                                  )
                                } else {
                                  if (
                                    selectedUserRoleIds.length === 0 &&
                                    currentUserRole
                                  ) {
                                    setSelectedUserRoleIds([
                                      ...selectedUserRoleIds,
                                      ...items?.userRoles
                                        ?.filter(
                                          (userRole) =>
                                            userRole.role_type >
                                            USER_ROLE_TYPE.PARENT,
                                        )
                                        ?.filter(
                                          (userRole) =>
                                            userRole.staff_primary_dep?.id ===
                                            department.id,
                                        )
                                        .map((user) => user.id),
                                      currentUserRole.id,
                                    ])
                                  } else {
                                    setSelectedUserRoleIds([
                                      ...selectedUserRoleIds,
                                      ...items?.userRoles
                                        ?.filter(
                                          (userRole) =>
                                            userRole.role_type >
                                            USER_ROLE_TYPE.PARENT,
                                        )
                                        ?.filter(
                                          (userRole) =>
                                            userRole.staff_primary_dep?.id ===
                                            department.id,
                                        )
                                        .map((user) => user.id),
                                    ])
                                  }
                                }
                              }}
                            >
                              {areAllIncluded(
                                items?.userRoles
                                  ?.filter(
                                    (userRole) =>
                                      userRole.role_type >
                                      USER_ROLE_TYPE.PARENT,
                                  )
                                  ?.filter(
                                    (userRole) =>
                                      userRole.staff_primary_dep?.id ===
                                      department.id,
                                  )
                                  .map((user) => user.id),
                                selectedUserRoleIds,
                              )
                                ? t('multiselect.deSelectAll')
                                : t('settings.markAll')}
                            </p>
                          </div>
                        ) : null}

                        <div className="grid grid-cols-7 flex-wrap gap-2">
                          {items?.userRoles
                            ?.filter(
                              (userRole) =>
                                userRole.role_type > USER_ROLE_TYPE.PARENT,
                            )
                            ?.filter(
                              (userRole) =>
                                userRole.staff_primary_dep?.id ===
                                department.id,
                            )
                            ?.map((userRole, index) => {
                              return (
                                <UserRoleItem
                                  userRole={userRole}
                                  selected={selectedUserRoleIds.find(
                                    (sURI: string) => sURI === userRole.id,
                                  )}
                                  onClick={() => {
                                    if (
                                      selectedUserRoleIds.find(
                                        (sURI: string) => sURI === userRole.id,
                                      )
                                    ) {
                                      if (userRole.id === currentUserRole?.id) {
                                        if (selectedUserRoleIds.length === 1) {
                                          setSelectedUserRoleIds(
                                            selectedUserRoleIds.filter(
                                              (sURI: string) =>
                                                sURI !== userRole.id,
                                            ),
                                          )
                                        }
                                      } else {
                                        setSelectedUserRoleIds(
                                          selectedUserRoleIds.filter(
                                            (sURI: string) =>
                                              sURI !== userRole.id,
                                          ),
                                        )
                                      }
                                    } else {
                                      setSelectedUserRoleIds([
                                        ...selectedUserRoleIds,
                                        userRole.id,
                                      ])
                                    }
                                  }}
                                />
                              )
                            })}
                        </div>
                      </>
                    )
                  })}
                  <div className="col-span-2 font-bold my-2 flex justify-between">
                    {t('events.connectedSeveralDepartment')}
                    <p
                      className="pr-4 text-blueDark font-semibold cursor-pointer text-sm -mr-4"
                      onClick={() => {
                        if (
                          areAllIncluded(
                            items?.userRoles
                              ?.filter(
                                (userRole) =>
                                  userRole.role_type > USER_ROLE_TYPE.PARENT,
                              )
                              ?.filter(
                                (userRole) => !userRole.staff_primary_dep?.id,
                              )
                              .map((user) => user.id)!!,
                            selectedUserRoleIds,
                          )
                        ) {
                          setSelectedUserRoleIds(
                            selectedUserRoleIds.filter(
                              (id: string) =>
                                !items?.userRoles
                                  ?.filter(
                                    (userRole) =>
                                      userRole.role_type >
                                      USER_ROLE_TYPE.PARENT,
                                  )
                                  ?.filter(
                                    (userRole) =>
                                      !userRole.staff_primary_dep?.id,
                                  )
                                  .map((user) => user.id),
                            ),
                          )
                        } else {
                          if (
                            selectedUserRoleIds.length === 0 &&
                            currentUserRole
                          ) {
                            setSelectedUserRoleIds([
                              ...selectedUserRoleIds,
                              ...(items?.userRoles
                                ?.filter(
                                  (userRole) =>
                                    userRole.role_type > USER_ROLE_TYPE.PARENT,
                                )
                                ?.filter(
                                  (userRole) => !userRole.staff_primary_dep?.id,
                                )
                                .map((user) => user.id) || []),
                              currentUserRole.id,
                            ])
                          } else {
                            setSelectedUserRoleIds([
                              ...selectedUserRoleIds,
                              ...(items?.userRoles
                                ?.filter(
                                  (userRole) =>
                                    userRole.role_type > USER_ROLE_TYPE.PARENT,
                                )
                                ?.filter(
                                  (userRole) => !userRole.staff_primary_dep?.id,
                                )
                                .map((user) => user.id) || []),
                            ])
                          }
                        }
                      }}
                    >
                      {areAllIncluded(
                        items?.userRoles
                          ?.filter(
                            (userRole) =>
                              userRole.role_type > USER_ROLE_TYPE.PARENT,
                          )
                          ?.filter(
                            (userRole) => !userRole.staff_primary_dep?.id,
                          )
                          .map((user) => user.id)!!,
                        selectedUserRoleIds,
                      )
                        ? t('multiselect.deSelectAll')
                        : t('settings.markAll')}
                    </p>
                  </div>
                  <div className="grid grid-cols-7 flex-wrap gap-2">
                    {items?.userRoles
                      ?.filter(
                        (userRole) =>
                          userRole.role_type > USER_ROLE_TYPE.PARENT,
                      )
                      ?.filter((userRole) => !userRole.staff_primary_dep?.id)
                      ?.map((userRole, index) => {
                        return (
                          <UserRoleItem
                            userRole={userRole}
                            selected={
                              userRole.id === currentUserRole?.id
                                ? true
                                : selectedUserRoleIds.find(
                                    (sURI: string) => sURI === userRole.id,
                                  )
                            }
                            onClick={() => {
                              if (
                                selectedUserRoleIds.find(
                                  (sURI: string) => sURI === userRole.id,
                                )
                              ) {
                                setSelectedUserRoleIds(
                                  selectedUserRoleIds.filter(
                                    (sURI: string) => sURI !== userRole.id,
                                  ),
                                )
                              } else {
                                setSelectedUserRoleIds([
                                  ...selectedUserRoleIds,
                                  userRole.id,
                                ])
                              }
                            }}
                          />
                        )
                      })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </motion.div>
      </div>

      <div
        className={
          'flex justify-center w-full bg-gradient-to-t from-[#f6f6f6] sticky bottom-0 left-0 pb-4'
        }
      >
        <div
          className="absolute left-0 bottom-4 text-md px-8 py-[16px] bg-white border border-blueDark rounded-full text-blueDark flex gap-x-2"
          onClick={() => {
            setShowSelectedSpecifics(!showSelectedSpecifics)
            setEditSelectedUserRoleIds(selectedUserRoleIds)
            setRemoveFromSelectedUserRoleIds([])
            if (targetDivRef.current) {
              targetDivRef.current.scrollIntoView({
                behavior: 'auto',
                block: 'start',
              })
            }
          }}
        >
          <FontAwesomeIcon icon={faUsers} />
          <FontAwesomeIcon
            icon={faCaretRight}
            className={classNames(
              'transition duration-300',
              showSelectedSpecifics ? '-rotate-180' : 'rotate-0',
            )}
          />
        </div>
        <Button
          size="lg"
          variant="primary"
          fontSize="font-bold"
          className="w-[70%]"
          loading={loading}
          label={t('documents.save')}
          onClick={() => {
            if (showSelectedSpecifics) {
              /*setSelectedUserRoleIds(
                selectedUserRoleIds.filter(
                  (sURI: string) =>
                    !removeFromSelectedUserRoleIds.find(
                      (rFSURI) => rFSURI === sURI,
                    ),
                ),
              )*/
              setSelectedUserRoleIds(editSelectedUserRoleIds)
              setShowSelectedSpecifics(false)
            } else {
              onSave(selectedUserRoleIds)
              onClose()
            }
          }}
        />
      </div>
    </ModalRegular>
  )
})

const SearchInput = ({ onChange }: { onChange: any }) => {
  const [text, setText] = useState('')
  const { t } = useTranslation()

  return (
    <div className="flex w-full relative">
      <span className="absolute left-6 top-2 mr-6 text-gray-400">
        <FontAwesomeIcon icon={faSearch} size="sm" />
      </span>
      <input
        className={classNames(
          'block w-full px-12 py-2 placeholder-gray-400 border border-gray-300 rounded-full shadow appearance-none focus:outline-none focus:ring-eventsMain focus:border-eventsMain sm:text-sm',
        )}
        type={'text'}
        value={text}
        placeholder={t('documents.search') + '...'}
        onChange={(e) => {
          setText(e.target.value)
          onChange(e.target.value.toLowerCase())
        }}
      />
    </div>
  )
}

const KidItem = ({
  kid,
  onClick,
  guardianClick,
  selectedUserRoleIds,
  missingPeople,
}: {
  kid: any
  onClick: any
  guardianClick: any
  selectedUserRoleIds?: string[]
  missingPeople?: boolean
}) => {
  const { t } = useTranslation()
  const allRolesSelected = () => {
    if (kid.userRoles) {
      for (const role of kid?.userRoles) {
        if (!selectedUserRoleIds?.find((id) => id === role?.id)) {
          return false
        }
      }
    } else {
      return false
    }
    return true
  }
  return (
    <div
      className={classNames(
        'flex border cursor-pointer rounded-xl active:scale-95 transition-all ease-in-out duration-500 p-1',
        allRolesSelected() && 'border-blueDark',
      )}
      onClick={() => {
        onClick()
      }}
    >
      <div className="flex flex-col w-[80px] h-[80px] gap-1 text-center justify-center items-center bg-gradient-to-r from-inherit flex-shrink-0 py-2">
        <img
          className={
            'w-8 h-8 rounded-full flex justify-center items-center object-cover'
          }
          src={
            kid?.kid?.attributes?.kidImage?._url ||
            '/images/placeholder-image.png'
          }
          alt=""
        />

        <div
          className={
            'text-2xs text-center font-bold  w-[70px] line-clamp-2 leading-tight'
          }
        >
          <p>
            {kid?.kid?.attributes?.firstName + ' '}
            <br />
            {kid?.kid?.attributes?.lastName}
          </p>
        </div>
      </div>

      <div className={'flex overflow-auto space-x-2 items-center'}>
        {missingPeople && (
          <div className="text-2xs text-gray-400 font-semibold ml-4">
            {t('events.childNoGuardians')}
          </div>
        )}
        {kid?.userRoles?.map((role: UserRole, index: number) => {
          const isSelected = () => {
            return selectedUserRoleIds?.find((id) => id === role?.id)
          }
          return (
            <div
              key={index}
              className={classNames(
                'flex flex-col justify-center items-center relative border p-2 rounded-xl w-[80px] h-[80px] gap-y-2 flex-shrink-0',
                isSelected() && 'border-blueDark bg-blue-50',
              )}
              onClick={(e) => {
                e.stopPropagation()
                guardianClick(role?.id)
              }}
            >
              <img
                className={
                  'w-8 h-8 rounded-full flex justify-center items-center object-cover'
                }
                src={
                  role?.user?.attributes?.myPic_thumb?._url ||
                  '/images/placeholder-image.png'
                }
                alt=""
              />
              <p
                className={
                  'text-2xs text-center font-bold w-[70px] line-clamp-2 leading-tight'
                }
              >
                {role?.user?.attributes?.firstName}
                <br />
                {role?.user?.attributes?.lastName}
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const GuardianItem = ({
  kids,
  userRole,
  onClick,
  guardianClick,
  selectedUserRoleIds,
  guardianUserRoles,
  parentComponent,
}: {
  kids: any
  userRole: UserRole
  onClick: any
  guardianClick: any
  selectedUserRoleIds?: string[]
  guardianUserRoles?: UserRole[]
  parentComponent?: string
}) => {
  const allRolesSelected = () => {
    for (const role of guardianUserRoles ?? []) {
      if (!selectedUserRoleIds?.find((id) => id === role.id)) {
        return false
      }
    }
    return true
  }

  const userRolesForUser = guardianUserRoles?.filter(
    (role) => role.user?.id === userRole?.user?.id,
  )

  const alreadyActiveStaff = guardianUserRoles?.some(
    (role) =>
      role.user?.id === userRole?.user?.id &&
      role.role_type >= USER_ROLE_TYPE.TEACHER_LOWEST &&
      role.status === 40,
  )
  const waiteResponseStaff = guardianUserRoles?.some(
    (role) =>
      role.user?.id === userRole?.user?.id &&
      role.role_type >= USER_ROLE_TYPE.TEACHER_LOWEST &&
      role.status === 10,
  )

  const [alreadyInvitedUser, setAlreadyInvitedUser] = useState<boolean>(false)
  const { t } = useTranslation()

  console.log(alreadyActiveStaff)

  console.log(
    userRolesForUser?.map(
      (role) =>
        role.user?.firstName +
        ', ' +
        role.user?.lastName +
        ',role status ' +
        role.status +
        ',role type ' +
        role.role_type,
    ),
  )

  return (
    <div
      className={classNames(
        `flex border cursor-pointer rounded-xl ${
          alreadyActiveStaff
            ? 'bg-green-100/30'
            : waiteResponseStaff
            ? 'bg-yellow-100/30'
            : 'bg-wihte'
        } active:scale-95 transition-all ease-in-out duration-500 p-1`,
        allRolesSelected() && 'border-blueDark',
        alreadyActiveStaff && 'border-green-400 ',
        waiteResponseStaff && 'border-yellow-400',
      )}
    >
      <div
        className="flex flex-col w-[80px] h-[80px] gap-1 text-center justify-center items-center  flex-shrink-0 py-2 "
        onClick={() => {
          if (alreadyActiveStaff) {
            setAlreadyInvitedUser(true)
          } else if (waiteResponseStaff) {
            setAlreadyInvitedUser(true)
          } else onClick()
        }}
      >
        <img
          className={
            'w-8 h-8 rounded-full flex justify-center items-center object-cover'
          }
          src={
            userRole?.user?.attributes?.myPic?._url ||
            '/images/placeholder-image.png'
          }
          alt=""
        />
        <div
          className={
            'text-2xs text-center font-bold flex-1 w-[80px] line-clamp-2 leading-tight'
          }
        >
          <p>
            {userRole?.user?.attributes?.firstName + ' '}
            <br />
            {userRole?.user?.attributes?.lastName}
          </p>
        </div>
      </div>
      <div className={'flex overflow-auto space-x-2'}>
        {kids?.map((kid: any, index: number) => {
          const kidGuardian = guardianUserRoles?.find(
            (uR) => uR.kid?.id === kid.id,
          )
          const isSelected = () => {
            return selectedUserRoleIds?.find((id) => id === kidGuardian?.id)
          }
          return (
            <div
              key={index}
              className={classNames(
                'flex flex-col justify-center items-center relative border p-2 rounded-xl w-[80px] h-[80px] gap-y-2 flex-shrink-0',
                isSelected() && 'border-blueDark bg-blue-50',
              )}
              onClick={(e) => {
                e.stopPropagation()
                //guardianClick(kidUserRole?.id)
                guardianClick(kidGuardian?.id)
              }}
            >
              <img
                className={
                  'w-8 h-8 rounded-full flex justify-center items-center object-cover'
                }
                src={
                  kid.kid?.attributes?.kidImage?._url ||
                  '/images/placeholder-image.png'
                }
                alt=""
              />
              <p
                className={
                  'text-2xs text-center font-bold w-[70px] line-clamp-2 leading-tight'
                }
              >
                {kid.kid?.attributes?.firstName + ' '}
                <br />
                {kid.kid?.attributes?.lastName}
              </p>
            </div>
          )
        })}
      </div>
      <ModalRegular
        title={t('login.sessionExpiredTitle') + ' 😬'}
        isOpen={alreadyInvitedUser}
        className={'!max-w-xl px-8 '}
        classNameTitle=" py-4 w-full text-black !important flex justify-center relative"
        onClose={() => {
          setAlreadyInvitedUser(false)
        }}
      >
        <div className="font-semibold text-center py-[70px]">
          {alreadyActiveStaff
            ? '!Vald användare är redan Aktive'
            : '!Vald användare har redan pågående inbjuden'}
        </div>
        <div className="fixed bottom-6 right-0 left-0  flex  items-end justify-center">
          <Button
            variant="fullBlue"
            label={t('general.ok')}
            fontSize="font-bold"
            size="sm"
            className="w-[40%]"
            onClick={() => {
              setAlreadyInvitedUser(false)
            }}
          />
        </div>
      </ModalRegular>
    </div>
  )
}

// Define the type for the hook's options
interface UseFitTextOptions {
  minFontSize?: number
  maxFontSize?: number
}

// Define the type for the return value of the hook
interface UseFitTextResult {
  ref: React.RefObject<HTMLDivElement>
  fontSize: number
}

const useFitText = ({
  minFontSize = 12,
  maxFontSize = 36,
}: UseFitTextOptions = {}): UseFitTextResult => {
  const ref = useRef<HTMLDivElement>(null)
  const [fontSize, setFontSize] = useState<number>(16)

  useEffect(() => {
    let animationFrameId: number

    const resizeObserver = new ResizeObserver((entries) => {
      animationFrameId = window.requestAnimationFrame(() => {
        const entry = entries[0]
        if (entry && ref.current) {
          const { width, height } = entry.contentRect
          // Calculate the new font size based on container size
          let newFontSize = Math.min(width / 10, height / 2)
          // Constrain the font size within min and max limits
          newFontSize = Math.max(
            minFontSize,
            Math.min(newFontSize, maxFontSize),
          )

          // Only update if the new font size is different to avoid unnecessary renders
          if (newFontSize !== fontSize) {
            setFontSize(newFontSize)
          }
        }
      })
    })

    if (ref.current) {
      resizeObserver.observe(ref.current)
    }

    // Clean up on unmount
    return () => {
      resizeObserver.disconnect()
      window.cancelAnimationFrame(animationFrameId)
    }
  }, [minFontSize, maxFontSize]) // Remove `fontSize` from dependencies

  return { ref, fontSize }
}

export const UserRoleItem = ({
  userRole,
  onClick,
  selected,
}: {
  userRole: UserRole
  onClick: any
  selected?: boolean
}) => {
  /*const { ref, fontSize } = useFitText({ minFontSize: 8, maxFontSize: 32 })*/

  return (
    <div
      className={classNames(
        'flex border hover:bg-blue-50 cursor-pointer rounded-xl  items-center justify-center ',
        selected ? 'border-blueDark bg-blue-50' : 'bg-white',
      )}
      onClick={() => onClick()}
    >
      <div className="flex flex-col justify-center items-center relative  p-2 rounded-xl  gap-y-2 flex-shrink-0">
        <img
          className="w-8 h-8 rounded-full"
          src={getImageFromRole(userRole)}
          alt=""
        />

        <p className={'text-2xs text-center font-bold w-[70px] line-clamp-2'}>
          {userRole?.user?.attributes?.firstName + ' '}
          <br />
          {userRole?.user?.attributes?.lastName}
        </p>
      </div>
    </div>
  )
}

const SelectedUserItem = ({
  user,
  onClick,
  selected,
}: {
  user: User
  onClick: any
  selected?: boolean
}) => {
  return (
    <div
      className={classNames(
        'h-[60px] flex border hover:bg-blue-50 cursor-pointer',
        selected && 'bg-green-50',
      )}
      onClick={() => {
        onClick(selected)
      }}
    >
      <div className={'flex  items-center p-3 relative w-full'}>
        {selected && (
          <FontAwesomeIcon
            className={'text-eventsMain absolute top-2 left-2 '}
            icon={faCheckCircle}
          />
        )}

        {user?.attributes?.myPic_thumb?._url ? (
          <img
            className={'w-[30px] h-[30px] rounded-full'}
            src={user?.attributes?.myPic_thumb?._url}
            alt=""
          />
        ) : (
          <div
            className={
              'w-[30px] h-[30px] bg-gray-200 rounded-full flex justify-center items-center'
            }
          >
            <FontAwesomeIcon className={'text-gray-500 '} icon={faUser} />
          </div>
        )}
        <div className={'text-sm ml-3 flex-1'}>
          <p className={'font-medium whitespace-nowrap'}>
            {user?.attributes?.firstName + ' '}
            {user?.attributes?.lastName}
          </p>
        </div>
      </div>
    </div>
  )
}
