import { useContext, useEffect, useState } from 'react'
import Page from '../../../../components/Page'
import { NavigationStoreContext } from '../../../../store/navigationStore'
import { KidsStoreContext } from '../../../../store/kidsStore'
import { UserStoreContext } from '../../../../store/userStore'
import { DepartmentStoreContext } from '../../../../store/departmentStore'
import { Kid } from '../../../../Models/Kid'
import { useNavigate, useParams } from 'react-router-dom'
import { Loading } from '../../../../components/Loading'
import React from 'react'
import KidListCardHorizontal from '../../../../components/kid/KidListCardHorizontal'
import { observer } from 'mobx-react'
import { ChooseOrCreateParent } from './components/ChooseOrCreateParent'
import { ChooseExistingUser } from './components/ChooseExistingUser'
import { UserRole } from '../../../../Models/UserRole'
import { InformationAboutParent } from './components/InformationAboutParent'
import ModalRegular from '../../../../components/ModalRegular'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAt,
  faCheck,
  faLocation,
  faLocationDot,
  faPhone,
  faSearch,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import { Button } from '../../../../components/Button'
import { AdminStoreContext } from '../../../../store/adminStore'
import { useTranslation } from 'react-i18next'
import { LOGIN_METHOD, USER_ROLE_TYPE } from '../../../../Enums/TyraEnums'
import Swal from 'sweetalert2'
import classNames from 'classnames'
import Fuse from 'fuse.js'

export enum TYRA_RELATION_NEW {
  MOM = 'Mamma',
  DAD = 'Pappa',
  PARENT = 'Vårdnadshavare',
  TRUSTEE = 'Förvaltare',
  OTHER = 'Annat',
}
export interface RELATION_AND_AUTORITHY {
  relation: TYRA_RELATION_NEW
  addDocuments: boolean
  addSchemaTimesToOnlyStaff: boolean
  addNotesToOnlyStaff: boolean
}

export interface NewUser {
  pn?: string
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  streetAddress?: string
  postalCode?: string
  city?: string
}
export const newUserInitialValues = {
  pn: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  phone: undefined,
  streetAddress: undefined,
  postalCode: undefined,
  city: undefined,
}

export const createPerson = (newUserInfo: any, schoolId: string) => {
  return {
    givenName: newUserInfo.firstName,
    familyName: newUserInfo.lastName,
    civicNo: newUserInfo.pn.replace('-', ''),
    email: newUserInfo.email,
    phone: newUserInfo.phone,
    streetAddress: newUserInfo.streetAddress,
    postalCode: newUserInfo.postalCode,
    country: newUserInfo.country,
    schoolId: schoolId,
  }
}

export const InviteParents: React.FC = observer(() => {
  const { t } = useTranslation()
  const [showInviteModal, setShowInviteModal] = useState(false)
  const { fetchKids, kids } = useContext(KidsStoreContext)
  const {
    currentUserRole,
    fetchUserRolesToInviteToKid,
    fetchNewUserRoles,
    createPersonInvite,
    createUserRole,
  } = useContext(UserStoreContext)
  const { departments, fetchDepartments } = useContext(DepartmentStoreContext)
  const { setTitle } = useContext(NavigationStoreContext)

  const {
    inviteExistingGuardian,
    inviteGuardian,
    inviteTeacher,
    checkIfUserExist,
  } = useContext(AdminStoreContext)

  const [selectedUserRole, setSelectedUserRole] = useState<
    UserRole | undefined
  >(undefined)

  const { schoolId } = useParams()
  const [loading, setLoading] = useState(false)
  const [searchGoal, setSearchGoal] = useState<string>('')
  const [showAlternatives, setShowAlternatives] = useState<boolean>(false)
  const [refreshFetchingKids, setRefreshFetchingKids] = useState<boolean>(false)
  const [loginMethodWhenInvite, setLoginMethodWhenInvite] = useState<number>(0)

  const toggleRefreshFechingKids = () => {
    console.log('inside ')
    setRefreshFetchingKids(!refreshFetchingKids)
  }

  useEffect(() => {
    if (currentUserRole && schoolId) {
      setLoading(true)
      fetchKids(currentUserRole).finally(() => {
        setLoading(false)
      })
      fetchDepartments(schoolId, true)
      //fetchUserRoles(currentUserRole)
      fetchUserRolesToInviteToKid(schoolId)
      fetchNewUserRoles(schoolId).finally(() => {})
    }
  }, [schoolId, refreshFetchingKids])

  const fuse = new Fuse(kids!, {
    keys: ['firstName', 'lastName'],
    threshold: 0.3,
  })

  const results = searchGoal ? fuse.search(searchGoal).map((r) => r.item) : kids

  useEffect(() => {
    setTitle(t('admin.inviteParent'))
  }, [t])

  const [selectedKid, setSelectedKid] = useState<Kid | undefined>()
  const [chosenInviteType, setChosenInviteType] = useState<
    'existing' | 'new' | undefined
  >(undefined)
  const [relationAndAuthority, setRelationAndAuthority] =
    useState<RELATION_AND_AUTORITHY>({
      relation: TYRA_RELATION_NEW.PARENT,
      addDocuments: false,
      addSchemaTimesToOnlyStaff: false,
      addNotesToOnlyStaff: false,
    })
  const [otherText, setOtherText] = useState('')
  const [showSummary, setShowSummary] = useState(false)
  const [newUserInfo, setNewUserInfo] = useState<NewUser>(newUserInitialValues)
  const [userFound, setUserFound] = useState<
    | {
        email?: string
        name?: string
        nin?: string
        userId?: string
        phone?: string
        login_method?: LOGIN_METHOD
      }[]
    | null
  >(null)
  const [inviteAsNewUser, setInviteAsNewUser] = useState(false)
  const [showInloggningsAlternatives, setShowInloggningsAlternatives] =
    useState<boolean>(false)
  const [inviteAsExistingUser, setInviteAsExistingUser] = useState<
    null | string
  >(null)
  const [alertDataMissing, setAlertDataMissing] = useState<boolean>(false)
  const navigate = useNavigate()
  const hasGrandIDUser = userFound?.some(
    (user) => user.login_method === LOGIN_METHOD.GRANDID,
  )
  const hasPhoneUser = userFound?.some(
    (user) => user.login_method === LOGIN_METHOD.PHONE,
  )
  const hasEmailUser = userFound?.some(
    (user) => user.login_method === LOGIN_METHOD.EMAIL,
  )

  if (selectedUserRole) {
    console.log('selectedUserRole', selectedUserRole)
    return (
      <Page>
        <Button
          size="sm"
          className="w-[10%] mx-4 mt-4 text-nowrap"
          variant="callToActionBlue"
          fontSize="font-bold"
          label={t('general.backWithEmoji')}
          onClick={() => {
            setSelectedUserRole(undefined)
          }}
        />
        <div className="p-8">
          <InformationAboutParent
            onlyRelation={true}
            onChangeRelation={(value) => setRelationAndAuthority(value)}
            relationAndAuthority={relationAndAuthority}
            otherText={otherText}
            editFreeTextValue={(value: string) => setOtherText(value)}
            onDone={() => {
              setShowSummary(true)
            }}
          />
        </div>
        {showSummary && (
          <ModalRegular
            onClose={() => {
              setShowSummary(false)
            }}
            isOpen={true}
            title="!!Sammanfattning"
            className="max-w-lg"
            classNameTitle="font-black flex w-full p-4 pt-8 justify-center text-black"
          >
            <div className="py-4 px-8">
              <div
                className={`absolute -top-5 right-8 size-32 ${
                  showSummary && 'animate-wave'
                }`}
              >
                <img src="/logo.png" alt="logoTyra" />
              </div>

              <div className="flex flex-col justify-center p-8 gap-y-4">
                <div className="flex flex-col p-4 gap-y-4 text-sm font-semibold">
                  {selectedUserRole && (
                    <>
                      <div className="bg-white p-4 rounded-2xl">
                        <FontAwesomeIcon
                          className="mr-2 w-[20px]"
                          icon={faUser}
                          color={'#9B9B9B'}
                        />
                        {selectedUserRole.user?.firstName +
                          ' ' +
                          selectedUserRole.user?.lastName}
                        {selectedUserRole.user?.user_nin.attributes.nin
                          ? ' (' +
                            selectedUserRole.user?.user_nin.attributes.nin +
                            ')'
                          : null}
                      </div>
                      <div className="bg-white p-4 rounded-2xl">
                        <FontAwesomeIcon
                          className="mr-2 w-[20px]"
                          icon={faPhone}
                          color={'#1398F7'}
                        />
                        {selectedUserRole.user?.user_extra.attributes
                          .primary_phone ?? null}
                      </div>
                      <div className="bg-white p-4 rounded-2xl">
                        <FontAwesomeIcon
                          className="mr-2 w-[20px]"
                          icon={faAt}
                          color={'#C99CF2'}
                        />
                        {selectedUserRole.user?.user_extra.attributes.email ??
                          null}
                      </div>
                      {selectedUserRole.user?.user_extra.attributes
                        .street_address ? (
                        <div className="bg-white p-4 rounded-2xl">
                          <div>
                            <FontAwesomeIcon
                              className="mr-2 w-[20px]"
                              icon={faLocationDot}
                              color={'#6BBD73'}
                            />
                            {
                              selectedUserRole.user?.user_extra.attributes
                                .street_address
                            }
                          </div>
                          <div>
                            <FontAwesomeIcon
                              className="mr-2 w-[20px] opacity-0"
                              icon={faLocationDot}
                              color={'#6BBD73'}
                            />
                            {selectedUserRole.user?.user_extra.attributes
                              .zip_code +
                              ' ' +
                              selectedUserRole.user?.user_extra.attributes.city}
                          </div>
                        </div>
                      ) : null}
                    </>
                  )}
                </div>

                <div className="flex justify-center mt-4 z-40">
                  <Button
                    variant="callToActionBlue"
                    label={t('admin.invite')}
                    size="md"
                    className="w-[70%]"
                    fontSize="font-bold"
                    onClick={async () => {
                      if (selectedUserRole) {
                        if (schoolId && selectedKid && selectedUserRole.user) {
                          await inviteExistingGuardian(
                            schoolId,
                            selectedKid.id,
                            selectedUserRole.user.id,
                            relationAndAuthority.addDocuments,
                          )
                            .then((response) => {
                              console.log('response', response)
                              if (response === 'OK') {
                                Swal.fire({
                                  text: t('admin.inviteSent'),
                                  icon: 'success',
                                  showCancelButton: false,
                                  showConfirmButton: true,
                                  focusConfirm: false,
                                  confirmButtonColor: '#7eb5f4',
                                  cancelButtonColor: '#d33',
                                  cancelButtonText: t('general.cancel'),
                                  confirmButtonText: t('general.ok'),
                                }).finally(() => {
                                  setSelectedKid(undefined)
                                  setSelectedUserRole(undefined)
                                  setChosenInviteType(undefined)
                                  setRelationAndAuthority({
                                    relation: TYRA_RELATION_NEW.PARENT,
                                    addDocuments: false,
                                    addSchemaTimesToOnlyStaff: false,
                                    addNotesToOnlyStaff: false,
                                  })
                                  setOtherText('')
                                  setShowSummary(false)
                                  setNewUserInfo(newUserInitialValues)
                                  setUserFound(null)
                                  setInviteAsNewUser(false)
                                  setInviteAsExistingUser(null)
                                })
                              }
                              if (response === 'ALREADY_INVITED') {
                                // HANTERA FELMEDDELANDE
                                Swal.fire({
                                  text: t('admin.userAlreadyInvited'),
                                  icon: 'error',
                                  showCancelButton: false,
                                  showConfirmButton: true,
                                  focusConfirm: false,
                                  confirmButtonColor: '#7eb5f4',
                                  cancelButtonColor: '#d33',
                                  cancelButtonText: t('general.cancel'),
                                  confirmButtonText: t('general.ok'),
                                })
                              }
                              if (response === 'ERROR') {
                                // HANTERA FELMEDDELANDE
                                Swal.fire({
                                  title: t('events.eventAlarmFail'),
                                  text: t('statistics.contactSupport'),
                                  icon: 'error',
                                  showCancelButton: false,
                                  showConfirmButton: true,
                                  focusConfirm: false,
                                  confirmButtonColor: '#7eb5f4',
                                  cancelButtonColor: '#d33',
                                  cancelButtonText: t('general.cancel'),
                                  confirmButtonText: t('general.ok'),
                                })
                              }
                              return response
                            })
                            .catch((e) => {
                              console.error(e)
                            })
                            .finally(() => {})
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </ModalRegular>
        )}
      </Page>
    )
  }

  if (chosenInviteType) {
    return (
      <div className="relative">
        <Page>
          <Button
            size="sm"
            className="w-[10%] mx-4 mt-4 text-nowrap"
            variant="callToActionBlue"
            fontSize="font-bold"
            label={t('general.backWithEmoji')}
            onClick={() => {
              setChosenInviteType(undefined)
              setNewUserInfo(newUserInitialValues)
            }}
          />
          <div className="p-8">
            {chosenInviteType === 'new' ? (
              <InformationAboutParent
                onlyRelation={false}
                onChangeRelation={(value) => setRelationAndAuthority(value)}
                relationAndAuthority={relationAndAuthority}
                otherText={otherText}
                editFreeTextValue={(value: string) => setOtherText(value)}
                onDone={() => {
                  if (
                    newUserInfo.firstName &&
                    (newUserInfo.pn || newUserInfo.email || newUserInfo.phone)
                  ) {
                    setAlertDataMissing(false)
                    checkIfUserExist({
                      nin: newUserInfo.pn?.replace('-', ''),
                      firstName: newUserInfo.firstName,
                      lastName: newUserInfo.lastName,
                      phone: newUserInfo.phone,
                      email: newUserInfo.email,
                    })
                      .then((response) => {
                        if (response && response.length) {
                          setUserFound(response)
                        } else {
                          setShowInloggningsAlternatives(true)
                          /* setShowSummary(true) */
                        }
                      })
                      .catch((e) => {})
                      .finally(() => {})
                    //
                  } else {
                    setAlertDataMissing(true)
                  }
                }}
                newUserInfo={newUserInfo}
                setNewUserInfo={(userInfo: NewUser) => setNewUserInfo(userInfo)}
              />
            ) : (
              <ChooseExistingUser
                onSelectedUserRole={(userRole) => {
                  setSelectedUserRole(userRole)
                }}
              />
            )}
          </div>
          {
            <ModalRegular
              onClose={() => {
                setAlertDataMissing(false)
              }}
              isOpen={alertDataMissing}
              className="max-w-lg py-10 "
            >
              <div className="min-h-[200px] flex items-center">
                <p className="w-full justify-center flex font-semibold  text-center">
                  Please fill in the First Name field and at least one of the
                  following: Personal Number, Phone, or Email to proceed.
                </p>
              </div>
            </ModalRegular>
          }
          {(userFound || showInloggningsAlternatives) && (
            <ModalRegular
              onClose={() => {
                setUserFound(null)
                setShowInloggningsAlternatives(false)
              }}
              isOpen={true}
              className="max-w-lg py-6"
            >
              <div className="py-4 px-8">
                <div className="text-xl font-bold mb-4">
                  {t('admin.existingUserNotification')}
                </div>
                <div className="flex flex-col gap-y-4">
                  {userFound?.map((userInfo) => {
                    return (
                      <div
                        className="flex flex-col bg-white p-4 rounded-2xl text-sm relative cursor-pointer"
                        onClick={() => {
                          setInviteAsNewUser(false)
                          setInviteAsExistingUser(userInfo.userId!)
                        }}
                      >
                        <div>
                          {userInfo.name}{' '}
                          {userInfo.nin && <>({userInfo.nin})</>}
                          {userInfo.login_method === LOGIN_METHOD.GRANDID ? (
                            <>({t('login.login_username').toLowerCase()})</>
                          ) : null}
                        </div>
                        <div>
                          {userInfo.phone}{' '}
                          {userInfo.login_method === LOGIN_METHOD.PHONE ? (
                            <>({t('login.login_username').toLowerCase()})</>
                          ) : null}
                        </div>
                        <div>
                          {userInfo.email}{' '}
                          {userInfo.login_method === LOGIN_METHOD.EMAIL ? (
                            <>({t('login.login_username').toLowerCase()})</>
                          ) : null}
                        </div>
                        <div className="absolute right-4 top-0 h-full flex items-center">
                          <div
                            className={`w-6 h-6 rounded-full border flex justify-center items-center  ${
                              inviteAsExistingUser === userInfo.userId
                                ? 'border-transparent bg-blueSelected'
                                : 'border-gray-400'
                            }`}
                          >
                            {inviteAsExistingUser === userInfo.userId && (
                              <FontAwesomeIcon icon={faCheck} color="#ffffff" />
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                {(!hasGrandIDUser || !hasPhoneUser || !hasEmailUser) && (
                  <div className="text-xl font-bold mb-4 mt-4">
                    {t('general.or')}
                  </div>
                )}
                <div className="flex flex-col gap-y-3">
                  {/* Bjud in with personnumber */}
                  {!hasGrandIDUser && (
                    <div
                      className={`flex flex-col bg-white p-4 rounded-2xl text-sm relative  font-semibold ${
                        !newUserInfo?.pn
                          ? 'cursor-default text-gray120'
                          : 'cursor-pointer'
                      }`}
                      onClick={() => {
                        if (
                          (!inviteAsNewUser ||
                            loginMethodWhenInvite !== LOGIN_METHOD.GRANDID) &&
                          newUserInfo?.pn
                        ) {
                          setInviteAsNewUser(true)
                          setLoginMethodWhenInvite(LOGIN_METHOD.GRANDID)
                          setInviteAsExistingUser(null)
                        }
                      }}
                    >
                      <div>!Bjud in som ny användare på personnummer</div>
                      <div className="absolute right-4 top-0 h-full flex items-center">
                        <div
                          className={`w-6 h-6 rounded-full border flex justify-center items-center  ${
                            inviteAsNewUser &&
                            loginMethodWhenInvite === LOGIN_METHOD.GRANDID
                              ? 'border-transparent bg-blueSelected'
                              : 'border-gray-400'
                          }`}
                        >
                          {inviteAsNewUser && (
                            <FontAwesomeIcon icon={faCheck} color="#ffffff" />
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Bjud in with Telefonnummber */}
                  {!hasPhoneUser && (
                    <div
                      className={`flex flex-col bg-white p-4 rounded-2xl text-sm relative font-semibold   ${
                        !newUserInfo?.phone
                          ? 'cursor-default text-gray-500'
                          : 'cursor-pointer'
                      }`}
                      onClick={() => {
                        if (
                          !inviteAsNewUser ||
                          loginMethodWhenInvite !== LOGIN_METHOD.PHONE
                        ) {
                          setInviteAsNewUser(true)
                          setLoginMethodWhenInvite(LOGIN_METHOD.PHONE)
                          setInviteAsExistingUser(null)
                        }
                      }}
                    >
                      <div>!Bjud in som ny användare med mobilnummer</div>
                      <div className="absolute right-4 top-0 h-full flex items-center">
                        <div
                          className={`w-6 h-6 rounded-full border flex justify-center items-center  ${
                            inviteAsNewUser &&
                            loginMethodWhenInvite === LOGIN_METHOD.PHONE
                              ? 'border-transparent bg-blueSelected'
                              : 'border-gray-400'
                          }`}
                        >
                          {inviteAsNewUser && (
                            <FontAwesomeIcon icon={faCheck} color="#ffffff" />
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Invite with email */}
                  {!hasEmailUser && (
                    <div
                      className={`flex flex-col bg-white p-4 rounded-2xl text-sm relative  font-semibold  ${
                        !newUserInfo?.email
                          ? 'cursor-default text-gray-500'
                          : 'cursor-pointer'
                      }`}
                      onClick={() => {
                        if (
                          (!inviteAsNewUser ||
                            loginMethodWhenInvite !== LOGIN_METHOD.EMAIL) &&
                          newUserInfo?.email
                        ) {
                          setInviteAsNewUser(true)
                          setLoginMethodWhenInvite(LOGIN_METHOD.EMAIL)
                          setInviteAsExistingUser(null)
                        }
                      }}
                    >
                      <div>!Bjud in som ny användare med email</div>
                      <div className="absolute right-4 top-0 h-full flex items-center">
                        <div
                          className={`w-6 h-6 rounded-full border flex justify-center items-center  ${
                            inviteAsNewUser &&
                            loginMethodWhenInvite === LOGIN_METHOD.EMAIL
                              ? 'border-transparent bg-blueSelected'
                              : 'border-gray-400'
                          }`}
                        >
                          {inviteAsNewUser && (
                            <FontAwesomeIcon icon={faCheck} color="#ffffff" />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/*  */}
                {/* <div
                  className="flex flex-col bg-white p-4 rounded-2xl text-sm relative cursor-pointer"
                  onClick={() => {
                    if (!inviteAsNewUser) {
                      setInviteAsNewUser(true)
                      setInviteAsExistingUser(null)
                    }
                  }}
                >
                  <div>{t('admin.inviteAsNewUser')}</div>
                  <div className="absolute right-4 top-0 h-full flex items-center">
                    <div
                      className={`w-6 h-6 rounded-full border flex justify-center items-center  ${
                        inviteAsNewUser
                          ? 'border-transparent bg-blueSelected'
                          : 'border-gray-400'
                      }`}
                    >
                      {inviteAsNewUser && (
                        <FontAwesomeIcon icon={faCheck} color="#ffffff" />
                      )}
                    </div>
                  </div>
                </div> */}
                <div className="flex mt-14 justify-center">
                  <Button
                    variant="callToActionBlue"
                    className="w-[60%]"
                    size="md"
                    fontSize="font-bold"
                    label={
                      inviteAsExistingUser
                        ? t('admin.invite')
                        : t('login.continue')
                    }
                    onClick={() => {
                      if (inviteAsExistingUser) {
                        if (schoolId && selectedKid) {
                          inviteExistingGuardian(
                            schoolId,
                            selectedKid.id,
                            inviteAsExistingUser,
                            relationAndAuthority.addDocuments,
                          )
                            .then((response) => {
                              if (response === 'OK') {
                                Swal.fire({
                                  text: t('admin.inviteSent'),
                                  icon: 'success',
                                  showCancelButton: false,
                                  showConfirmButton: true,
                                  focusConfirm: false,
                                  confirmButtonColor: '#7eb5f4',
                                  cancelButtonColor: '#d33',
                                  cancelButtonText: t('general.cancel'),
                                  confirmButtonText: t('general.ok'),
                                }).finally(() => {
                                  setSelectedKid(undefined)
                                  setSelectedUserRole(undefined)
                                  setChosenInviteType(undefined)
                                  setRelationAndAuthority({
                                    relation: TYRA_RELATION_NEW.PARENT,
                                    addDocuments: false,
                                    addSchemaTimesToOnlyStaff: false,
                                    addNotesToOnlyStaff: false,
                                  })
                                  setOtherText('')
                                  setShowSummary(false)
                                  setNewUserInfo(newUserInitialValues)
                                  setUserFound(null)
                                  setInviteAsNewUser(false)
                                  setInviteAsExistingUser(null)
                                })
                              }
                              if (response === 'ALREADY_INVITED') {
                                // HANTERA FELMEDDELANDE
                                Swal.fire({
                                  text: t('admin.userAlreadyInvited'),
                                  icon: 'error',
                                  showCancelButton: false,
                                  showConfirmButton: true,
                                  focusConfirm: false,
                                  confirmButtonColor: '#7eb5f4',
                                  cancelButtonColor: '#d33',
                                  cancelButtonText: t('general.cancel'),
                                  confirmButtonText: t('general.ok'),
                                })
                              }
                              if (response === 'ERROR') {
                                // HANTERA FELMEDDELANDE
                                Swal.fire({
                                  title: t('events.eventAlarmFail'),
                                  text: t('statistics.contactSupport'),
                                  icon: 'error',
                                  showCancelButton: false,
                                  showConfirmButton: true,
                                  focusConfirm: false,
                                  confirmButtonColor: '#7eb5f4',
                                  cancelButtonColor: '#d33',
                                  cancelButtonText: t('general.cancel'),
                                  confirmButtonText: t('general.ok'),
                                })
                              }
                              return response
                            })
                            .catch((e) => {
                              console.error(e)
                            })
                            .finally(() => {})
                        }
                      } else {
                        setShowSummary(true)
                      }
                    }}
                  />
                </div>
              </div>
            </ModalRegular>
          )}

          {showSummary && (
            <ModalRegular
              onClose={() => {
                setShowSummary(false)
                setShowAlternatives(false)
              }}
              isOpen={true}
              title="!!Sammanfattning"
              className="max-w-lg relative"
              classNameTitle="font-black flex w-full p-4 pt-8 justify-center text-black"
            >
              <div
                className={`absolute -top-5 right-8 size-32 ${
                  showSummary && 'animate-wave'
                }`}
              >
                <img src="/logo.png" alt="logoTyra" />
              </div>
              <div
                className="py-4 px-8"
                onClick={() => setShowAlternatives(false)}
              >
                <div className="flex flex-col justify-center p-8 gap-y-4">
                  <div className="flex flex-col rounded-2xl gap-y-4 text-sm font-semibold">
                    <div className="bg-white p-4 rounded-2xl">
                      <FontAwesomeIcon
                        className="mr-2 w-[20px]"
                        icon={faUser}
                        color={'#9B9B9B'}
                      />
                      {newUserInfo.firstName +
                        ' ' +
                        (newUserInfo.lastName ? newUserInfo.lastName : '')}
                      {newUserInfo.pn ? ' (' + newUserInfo.pn + ')' : null}
                    </div>
                    {newUserInfo.phone && (
                      <div className="bg-white p-4 rounded-2xl">
                        <FontAwesomeIcon
                          className="mr-2 w-[20px]"
                          icon={faPhone}
                          color={'#1398F7'}
                        />
                        {newUserInfo.phone}
                      </div>
                    )}

                    {newUserInfo.email && (
                      <div className="bg-white p-4 rounded-2xl">
                        <FontAwesomeIcon
                          className="mr-2 w-[20px]"
                          icon={faAt}
                          color={'#C99CF2'}
                        />
                        {newUserInfo.email}
                      </div>
                    )}
                    {newUserInfo.streetAddress ? (
                      <>
                        <div className="bg-white p-4 rounded-2xl">
                          <FontAwesomeIcon
                            className="mr-2 w-[20px]"
                            icon={faLocationDot}
                            color={'#6BBD73'}
                          />
                          {newUserInfo.streetAddress},{' '}
                          {newUserInfo.postalCode + ', ' + newUserInfo.city}
                        </div>
                      </>
                    ) : null}
                    {/* <div className="flex justify-between items-center my-2 ">
                    <div className="">
                      !!Inloggningsmetod:
                      <br /> !!Mobilnummer
                    </div>
                    <Button
                      variant="borderBlueSelected"
                      label={t('absence.change')}
                      size="sm"
                      onClick={(e) => {
                        e.stopPropagation()
                        setShowAlternatives(true)
                      }}
                    />
                    {showAlternatives && (
                      <div
                        id="choicesContainer"
                        className="absolute inset-0 bg-black/50 z-50 flex flex-col gap-4 items-center justify-center pt-24"
                      >
                        {newUserInfo.pn && (
                          <Button
                            className="w-[40%]"
                            variant="borderRed"
                            label={t('login.bankid')}
                            size="md"
                          />
                        )}
                        {newUserInfo.phone && (
                          <Button
                            className="w-[40%]"
                            variant="borderGreen"
                            label={t('contactList.phone')}
                            size="md"
                          />
                        )}
                        {newUserInfo.email && (
                          <Button
                            className="w-[40%]"
                            variant="borderPurple"
                            label={t('login.email')}
                            size="md"
                          />
                        )}
                      </div>
                    )}
                  </div> */}
                    <div className="flex justify-between">
                      !!Vårdnadshavare till
                    </div>
                    <div className="bg-white p-4 rounded-2xl">
                      {selectedKid?.firstName + ' ' + selectedKid?.lastName}{' '}
                      {selectedKid?.kid_pers_id_num
                        ? ' (' + selectedKid?.kid_pers_id_num + ')'
                        : null}
                    </div>
                  </div>

                  <div className="flex justify-center mt-4 z-40">
                    <Button
                      variant="callToActionBlue"
                      label={t('admin.invite')}
                      size="md"
                      className="w-[70%]"
                      fontSize="font-bold"
                      onClick={async () => {
                        const newPerson = createPerson(newUserInfo, schoolId!)
                        try {
                          const personId = await createPersonInvite({
                            ...newPerson,
                            loginMethod: loginMethodWhenInvite,
                          })
                          const userRoleId = await createUserRole({
                            personUUID: personId!,
                            roleNum: USER_ROLE_TYPE.PARENT,
                            schoolId: schoolId!,
                            kidId: selectedKid?.id,
                            relation: relationAndAuthority.relation,
                          })
                          /*  alert(`Role Created: ${userRoleId}`) */
                          setShowSummary(false)
                          console.log('Person created with ID:', personId)
                        } catch (error) {
                          console.error(error)
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </ModalRegular>
          )}
        </Page>
      </div>
    )
  }
  /*  child selected view */

  if (selectedKid) {
    console.log(selectedKid)

    return (
      <ChooseOrCreateParent
        onChoose={(value) => {
          setChosenInviteType(value)
        }}
        onBack={() => {
          setSelectedKid(undefined)
        }}
        kid={selectedKid}
        refetchKids={toggleRefreshFechingKids}
      />
    )
  }
  /* main child view */
  return (
    <Page>
      {loading && <Loading />}
      <div className="flex flex-row mx-4 mt-4 w-full ">
        <Button
          size="sm"
          className="w-[10%] text-nowrap"
          variant="callToActionBlue"
          fontSize="font-bold"
          label={t('general.backWithEmoji')}
          onClick={() => {
            navigate(`/admin/${schoolId}/invite`)
          }}
        />
        <div className="flex w-full  justify-center">
          <div className="relative w-[35%]">
            <span className="absolute left-6 top-2 mr-6 text-gray-400">
              <FontAwesomeIcon icon={faSearch} size="sm" />
            </span>
            <input
              className={classNames(
                'px-12 py-2  placeholder-gray-400 border border-gray-300 rounded-full shadow appearance-none focus:outline-none focus:ring-eventsMain focus:border-eventsMain sm:text-sm',
              )}
              type={'text'}
              value={searchGoal}
              placeholder={t('documents.search') + '...'}
              onChange={(e) => {
                setSearchGoal(e.target.value)
              }}
            />
          </div>
        </div>
      </div>
      {departments?.map((department, index) => (
        <div className="px-6 pb-6">
          {results?.some(
            (kid) => kid?.attributes?.departmentPointer?.id === department?.id,
          ) && (
            <h2 className="font-bold my-6 text-xl">{department.klassName}</h2>
          )}
          <div className="grid grid-cols-3 gap-4">
            {results
              ?.filter(
                (kid) =>
                  kid?.attributes?.departmentPointer?.id === department?.id,
              )
              .map((kid) => (
                <KidListCardHorizontal
                  kid={kid}
                  onClick={() => {
                    setSelectedKid(kid)
                  }}
                />
              ))}
          </div>
        </div>
      ))}
    </Page>
  )
})
