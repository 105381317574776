import { useContext, useEffect, useState } from 'react'
import Page from '../../../../../components/Page'
import { t } from 'i18next'
import { NavigationStoreContext } from '../../../../../store/navigationStore'
import { KidsStoreContext } from '../../../../../store/kidsStore'
import { UserStoreContext } from '../../../../../store/userStore'
import { DepartmentStoreContext } from '../../../../../store/departmentStore'
import { getImageOrPlaceHolder } from '../../../../../utils/parseUtils'
import { Kid } from '../../../../../Models/Kid'
import { useParams } from 'react-router-dom'
import { Loading } from '../../../../../components/Loading'
import React from 'react'
import KidListCardHorizontal from '../../../../../components/kid/KidListCardHorizontal'
import { observer } from 'mobx-react'
import { Button } from '../../../../../components/Button'
import { USER_ROLE_STATUS } from '../../../../../Enums/TyraEnums'
import CustomRadioButton from '../../../../../components/CustomRadioButton'
import {
  NewUser,
  RELATION_AND_AUTORITHY,
  TYRA_RELATION_NEW,
} from '../InviteParents'
import classNames from 'classnames'
import CustomInput from '../../../../../components/CustomInput'
import CustomInputNew from '../../../../../components/CustomInputNew'
import PhoneInput from 'react-phone-input-2'
import { useTranslation } from 'react-i18next'
interface Props {
  onlyRelation: boolean
  onChangeRelation: (value: RELATION_AND_AUTORITHY) => void
  relationAndAuthority: RELATION_AND_AUTORITHY
  otherText: string
  editFreeTextValue: (value: string) => void
  onDone: () => void
  newUserInfo?: NewUser
  setNewUserInfo?: (userInfo: NewUser) => void
}
export const InformationAboutParent: React.FC<Props> = observer(
  ({
    onlyRelation,
    onChangeRelation,
    relationAndAuthority,
    otherText,
    editFreeTextValue,
    onDone,
    newUserInfo,
    setNewUserInfo,
  }) => {
    const [showInviteModal, setShowInviteModal] = useState(false)
    const { fetchKids, kids } = useContext(KidsStoreContext)
    const { currentUserRole, fetchUserRolesToInviteToKid, fetchNewUserRoles } =
      useContext(UserStoreContext)
    const { departments, fetchDepartments } = useContext(DepartmentStoreContext)
    const { setTitle } = useContext(NavigationStoreContext)

    const { schoolId } = useParams()
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()

    const requiredAdress =
      !!newUserInfo?.city ||
      newUserInfo?.postalCode ||
      newUserInfo?.streetAddress
    const options = [
      {
        value: TYRA_RELATION_NEW.PARENT,
        label: t('general.guardian'),
        text: '',
      },
      {
        value: TYRA_RELATION_NEW.MOM,
        label: t('general.mother'),
        text: '',
      },
      {
        value: TYRA_RELATION_NEW.DAD,
        label: t('general.father'),
        text: '',
      },
      {
        value: TYRA_RELATION_NEW.TRUSTEE,
        label: t('general.custodian'),
        text: '',
      },
      {
        value: TYRA_RELATION_NEW.OTHER,
        label: t('ourKids.other'),
        text: '',
        freeText: true,
      },
    ]

    useEffect(() => {
      setTitle(t('admin.inviteParent'))
    }, [t])
    console.log('firstname: ', !!newUserInfo?.firstName)
    console.log(
      'newUserInfo?.phone || newUserInfo?.email: ',
      newUserInfo?.phone || newUserInfo?.email,
    )
    console.log(
      'newUserInfo?.phone || newUserInfo?.email: ',
      !!newUserInfo?.firstName && (newUserInfo?.phone || newUserInfo?.email),
    )
    const disableContinueButton =
      (relationAndAuthority.relation === TYRA_RELATION_NEW.OTHER &&
        otherText === '') || // Always check this condition
      (!onlyRelation &&
        (!newUserInfo?.firstName ||
          !(newUserInfo?.phone || newUserInfo?.email || newUserInfo?.pn)))

    console.log('**************> ', currentUserRole?.school_pointer.territory)

    return (
      <>
        {loading && <Loading />}
        <div
          className={classNames(
            onlyRelation ? 'm-auto grid max-w-xl' : 'grid grid-cols-2 gap-x-4',
          )}
        >
          {!onlyRelation && (
            <div className="flex flex-col w-full gap-y-2">
              <div className="flex justify-center text-xl font-bold">
                {t('general.enterPersonalData')}
              </div>
              <div className="flex justify-center text-center text-xs">
                {t('general.dataSavedWithInvitaion')}
              </div>
              <div className="flex flex-col gap-y-4 mt-4">
                <div className="w-full">
                  <CustomInputNew
                    onChange={(value) => {
                      if (setNewUserInfo) {
                        setNewUserInfo({ ...newUserInfo, pn: value })
                      }
                    }}
                    placeholder={t('general.socialNr')}
                    longPlaceholder={t('general.enterPersonalNumber')}
                    label="!!Personnummer"
                    value={newUserInfo?.pn}
                    format="YYYYMMDD-LLXX"
                  />
                </div>
                {currentUserRole?.school_pointer.territory !== 724 && (
                  <div className="w-full text-sm">
                    {t('general.enteringPersonalNumberRecommindation')}
                  </div>
                )}
                <div className="w-full">
                  <CustomInputNew
                    onChange={(value) => {
                      if (setNewUserInfo) {
                        setNewUserInfo({ ...newUserInfo, firstName: value })
                      }
                    }}
                    placeholder={t('admin.firstName')}
                    longPlaceholder={t('general.enterFirstName')}
                    label="!!Förnamn"
                    value={newUserInfo?.firstName}
                    format="default"
                    required
                  />
                </div>
                <div className="w-full">
                  <CustomInputNew
                    onChange={(value) => {
                      if (setNewUserInfo) {
                        setNewUserInfo({ ...newUserInfo, lastName: value })
                      }
                    }}
                    placeholder={t('admin.surName')}
                    longPlaceholder={t('general.enterLastName')}
                    label="!!Förnamn"
                    value={newUserInfo?.lastName}
                    format="default"
                    required
                  />
                </div>
                <div className="w-full">
                  <CustomInputNew
                    onChange={(value) => {
                      if (setNewUserInfo) {
                        setNewUserInfo({ ...newUserInfo, email: value })
                      }
                    }}
                    placeholder={t('login.email')}
                    longPlaceholder={t('general.enterEmail')}
                    label="!!Förnamn"
                    value={newUserInfo?.email}
                    format="default"
                  />
                </div>
                <div className="w-full">
                  <CustomInputNew
                    onChange={(value) => {
                      if (setNewUserInfo) {
                        setNewUserInfo({ ...newUserInfo, phone: value })
                      }
                    }}
                    placeholder={t('contactList.phone')}
                    longPlaceholder={t('general.enterPhoneNumber')}
                    label="!!Förnamn"
                    value={newUserInfo?.phone}
                    format="phone"
                  />
                </div>
                <div className="w-full">
                  <CustomInputNew
                    onChange={(value) => {
                      if (setNewUserInfo) {
                        setNewUserInfo({ ...newUserInfo, streetAddress: value })
                      }
                    }}
                    placeholder={t('contactList.adress')}
                    longPlaceholder={t('general.enterAdress')}
                    label="!!Förnamn"
                    value={newUserInfo?.streetAddress}
                    format="default"
                    required={requiredAdress ? true : false}
                  />
                </div>
                <div className="w-full">
                  <CustomInputNew
                    onChange={(value) => {
                      if (setNewUserInfo) {
                        setNewUserInfo({ ...newUserInfo, postalCode: value })
                      }
                    }}
                    placeholder="!!Postnummer"
                    longPlaceholder={t('general.enterPostalCode')}
                    label="!!Förnamn"
                    value={newUserInfo?.postalCode}
                    format="default"
                    required={requiredAdress ? true : false}
                  />
                </div>
                <div className="w-full">
                  <CustomInputNew
                    onChange={(value) => {
                      if (setNewUserInfo) {
                        setNewUserInfo({ ...newUserInfo, city: value })
                      }
                    }}
                    placeholder="!!Stad"
                    longPlaceholder={t('general.enterCity')}
                    label="!!Förnamn"
                    value={newUserInfo?.city}
                    format="default"
                    required={requiredAdress ? true : false}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-col w-full gap-y-2 ">
            <div className="text-xl flex justify-center font-bold">
              {t('admin.relationshipAndpermissions')}
            </div>
            <div className="flex flex-col gap-y-4 rounded-2xl shadow-sm bg-white mt-3 p-3">
              <CustomRadioButton
                options={options}
                onChange={(value) => {
                  onChangeRelation({ ...relationAndAuthority, relation: value })
                }}
                value={relationAndAuthority.relation}
                freeTextValue={otherText}
                editFreeTextValue={(value: string) => editFreeTextValue(value)}
              />
            </div>
            <div className="flex flex-col gap-y-4 rounded-2xl shadow-sm bg-white mt-3 p-3">
              <CustomRadioButton
                options={[
                  {
                    value: 'addDocuments',
                    label: '',
                    text: t('admin.ableUploadDocAndCreateEvent'),
                    slider: true,
                  },
                ]}
                onChange={(value) => {
                  onChangeRelation({
                    ...relationAndAuthority,
                    addDocuments: !relationAndAuthority.addDocuments,
                  })
                }}
                value={
                  relationAndAuthority.addDocuments === true ? 'true' : 'false'
                }
              />
              <CustomRadioButton
                options={[
                  {
                    value: 'addSchemaTimesToOnlyStaff',
                    label: '',
                    text: t('admin.ableEnterSceduleTimesVisibleOnlyToStaff'),
                    slider: true,
                  },
                ]}
                onChange={(value) => {
                  onChangeRelation({
                    ...relationAndAuthority,
                    addSchemaTimesToOnlyStaff:
                      !relationAndAuthority.addSchemaTimesToOnlyStaff,
                  })
                }}
                value={
                  relationAndAuthority.addSchemaTimesToOnlyStaff === true
                    ? 'true'
                    : 'false'
                }
              />
              <CustomRadioButton
                options={[
                  {
                    value: 'addNotesToOnlyStaff',
                    label: '',
                    text: t('admin.ableCreateNotesVisibleOnlyToStaff'),
                    slider: true,
                  },
                ]}
                onChange={(value) => {
                  console.log('value', value)
                  onChangeRelation({
                    ...relationAndAuthority,
                    addNotesToOnlyStaff:
                      !relationAndAuthority.addNotesToOnlyStaff,
                  })
                }}
                value={
                  relationAndAuthority.addNotesToOnlyStaff === true
                    ? 'true'
                    : 'false'
                }
              />
            </div>
            {
              <div className="flex w-full justify-center">
                <Button
                  className={`mt-12 justify-center w-[70%]`}
                  label={t('login.continue')}
                  variant={
                    disableContinueButton ? 'disableGray' : 'callToActionBlue'
                  }
                  fontSize="font-bold"
                  onClick={() => {
                    if (!disableContinueButton) onDone()
                  }}
                />
              </div>
            }
          </div>
        </div>
      </>
    )
  },
)
