import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { t } from 'i18next'
import { Button } from '../../../components/Button'
import {
  CreateEventParams,
  EVENT3_TYPE,
  Times,
} from '../../../store/event3Store'
import { Calendar } from '../../../components/Calendar'
import { v4 as uuidv4 } from 'uuid'
import { CalendarDateIcon } from '../../../components/CalendarDateIcon'
import { DaysCircles } from './DaysCircles'
import { useDaysSelections } from '../CreateEvent3'
import {
  faCalendarDays,
  faCalendarPlus,
  faCaretDown,
  faRepeat,
  faX,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { UserStoreContext } from '../../../store/userStore'

export enum LOCATION_TYPE {
  FREE_TEXT = 'freetext',
  COORD = 'coord',
  ADDRESS = 'address',
  SAVED = 'saved',
}

interface Props {
  newEvent: CreateEventParams
  onChange: (e: CreateEventParams) => void
  onDone: () => void // hämta värdena och visa från riktig db
  onSave: () => void // spara värdena
  onClick: () => void
}
interface Props2 {
  newEvent: CreateEventParams
  onChange: (e: CreateEventParams) => void
  recurringDaysWave?: boolean
}

export const DateToEventSelections: React.FC<Props2> = (props: Props2) => {
  const { currentUserRole } = useContext(UserStoreContext)
  return (
    <div
      className="h-full"
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <Calendar
        recurringDaysWave={props.recurringDaysWave}
        isRecurrence={props.newEvent.isRecurrence}
        recurrence={props.newEvent.recurrence}
        onEditRecurrence={(value) => {
          props.onChange({
            ...props.newEvent,
            isRecurrence: value,
          })
        }}
        allowSevenDays={
          currentUserRole?.school_pointer.attributes.school_setting.attributes
            .eventIs7DayWeek
        }
        onDateClick={(
          dates: string[],
          lastDate: string,
          recurrenceEndDate?: boolean,
        ) => {
          /** TODO: välja ut sista datumet eller skicka med dates och currentDate? */
          if (props.newEvent.isRecurrence && props.newEvent.mod !== 'edit') {
            if (recurrenceEndDate) {
              props.onChange({
                ...props.newEvent,
                recurrence: {
                  ...props.newEvent.recurrence!,
                  endsOn: lastDate,
                },
              })
            } else {
              props.onChange({
                ...props.newEvent,
                recurrence: {
                  ...props.newEvent.recurrence!,
                  startsOn: lastDate,
                },
              })
            }
          } else {
            const newTimes = []
            const oldTimes = props.newEvent.times ?? []
            for (const date of dates) {
              for (const time of oldTimes) {
                if (time.date === date) {
                  newTimes.push(time)
                }
              }
              if (!oldTimes.find((oldTime) => oldTime.date === date)) {
                newTimes.push({
                  date: date,
                  start: undefined,
                  end: undefined,
                  fakeId: uuidv4(),
                })
              }
            }
            props.onChange({
              ...props.newEvent,
              times: newTimes,
            })
          }
        }}
        onAddTime={(times: { start: string; end: string }, dates: string[]) => {
          let times123 = props.newEvent.times
          for (const date of dates) {
            const timesOnDate = props.newEvent.times?.filter(
              (time) => time.date === date,
            )
            if (!timesOnDate) {
              throw new Error()
            }
            if (timesOnDate.length > 1) {
              // lägg till ny rad
              if (!props.newEvent.times) {
                throw new Error()
              }
              times123?.push({
                date: date,
                start: times.start,
                end: times.end,
                fakeId: uuidv4(),
              })
            } else {
              if (timesOnDate[0].start) {
                // lägg till ny rad
                if (!props.newEvent.times) {
                  throw new Error()
                }
                times123?.push({
                  date: date,
                  start: times.start,
                  end: times.end,
                  fakeId: uuidv4(),
                })
              } else {
                // lägg till start och end på befintlig
                if (!props.newEvent.times) {
                  throw new Error()
                }
                times123 = times123?.map((time) => {
                  if (time.date === date) {
                    return {
                      date: time.date,
                      start: times.start,
                      end: times.end,
                      fakeId: uuidv4(),
                    }
                  }
                  return time
                })
              }
            }
          }
          props.onChange({ ...props.newEvent, times: times123 })
        }}
        onChangeEvent={props.onChange}
        newEvent={props.newEvent}
        onRemoveTime={(id: string) => {
          props.onChange({
            ...props.newEvent,
            times: props.newEvent.times?.filter((time) => time.fakeId !== id),
          })
        }}
        onEditTime={(id: string, times: { start: string; end: string }) => {}}
        onEditTimes={(times: Times[]) => {
          props.onChange({ ...props.newEvent, times: times })
        }}
        dates={
          props.newEvent.isRecurrence && props.newEvent.mod !== 'edit'
            ? [
                props.newEvent.recurrence!.startsOn,
                props.newEvent.recurrence!.endsOn ?? undefined,
              ]
            : Array.from(
                new Set(props.newEvent.times?.map((time) => time.date)),
              )
        }
        times={props.newEvent.times}
        multiDates={props.newEvent.mod === 'edit' ? false : true}
        allowTimes={
          props.newEvent.eventType === EVENT3_TYPE.ACTIVITIES ||
          props.newEvent.eventType === EVENT3_TYPE.BOOKING ||
          props.newEvent.eventType === EVENT3_TYPE.MEETING
            ? true
            : false
        }
      />
    </div>
  )
}

export const DateToEvent: React.FC<Props> = observer((props: Props) => {
  const [openSelections, setOpenSelections] = useState(false)
  const [groupedTimes, setGroupedTimes] = useState<string[]>()
  const days = useDaysSelections()
  console.log(props.newEvent)

  useEffect(() => {
    if (props.newEvent.times) {
      const datesInTimes: string[] = []

      for (const time of props.newEvent.times) {
        if (!datesInTimes.find((date) => date === time.date)) {
          datesInTimes.push(time.date)
        }
      }
      if (datesInTimes) {
        setGroupedTimes(datesInTimes)
      }
    }
  }, [props.newEvent.times])
  console.log(props.newEvent)

  const dateTitle = () => {
    if (props.newEvent.times && props.newEvent.times.length > 1) {
      return props.newEvent.times.length + t('events.choosen')
    }

    if (props.newEvent.times && props.newEvent.times.length === 1) {
      return props.newEvent.times[0]?.date
    }

    if (props.newEvent.eventType === EVENT3_TYPE.BOOKING) {
      return t('events.timeNotSet')
    }

    return t('events.chooseDay')
  }

  return (
    <>
      <div
        className="w-full"
        onClick={() => {
          setOpenSelections(!openSelections)
        }}
      >
        <div className=" flex flex-col gap-y-2">
          <div className="font-semibold flex items-center justify-between">
            <div className="font-semibold flex items-center gap-x-1  text-nowrap w-full">
              {''}
              <FontAwesomeIcon
                icon={faCalendarDays}
                className="text-[#1398F7] text-xl p-1"
              />
              <>
                {props.newEvent.eventType === EVENT3_TYPE.INFO ||
                props.newEvent.eventType === EVENT3_TYPE.IMPORTANT
                  ? t('general.chooseDay')
                  : t('general.chooseDayAndTime')}
                :{' '}
                {groupedTimes && groupedTimes?.length < 1 && (
                  <>
                    {' '}
                    <span
                      className={`${
                        props.newEvent.eventType === EVENT3_TYPE.BOOKING &&
                        'text-red-500 font-semibold animate-bounce flex w-full justify-start ml-3'
                      }`}
                    >
                      {dateTitle()}
                    </span>
                  </>
                )}
              </>
            </div>
            {(props.newEvent.times?.length!! > 0 ||
              props.newEvent.recurrence?.days.length!! > 0) && (
              <Button
                label={t('blog.edit')}
                variant="remindStyle"
                size="xs"
                onClick={() => {
                  props.onClick()
                }}
              />
            )}
          </div>
          {props.newEvent.times?.length!! > 0 ||
          props.newEvent.recurrence?.days.length!! > 0 ? (
            <div className="flex pl-2">
              {props.newEvent.isRecurrence && props.newEvent.mod !== 'edit' ? (
                <div className="">
                  <DaysCircles
                    selectedDays={props.newEvent.recurrence?.days}
                    days={days.map((day) => day.id)}
                    recurrence={props.newEvent.recurrence}
                  />
                </div>
              ) : (
                <>
                  {groupedTimes?.map((groupDate, index) => {
                    const timesToShow = props.newEvent.times?.filter(
                      (time) => time.date === groupDate,
                    )
                    return (
                      <div
                        className={
                          index <= 1
                            ? `flex items-center py-1 rounded-lg shadow-sm w-[135px] mb-2 mr-2 relative bg-[#F6F6F6] ${
                                index === 2
                                  ? 'hover:border-blue-700 hover:text-blueDark cursor-pointer'
                                  : ''
                              }`
                            : ''
                        }
                        onClick={
                          index === 2
                            ? () => {
                                props.onClick()
                              }
                            : undefined
                        }
                      >
                        {index < 2 ? (
                          <div>
                            <div className="absolute -top-2 -right-2  w-4 h-4 bg-[#FC3E3E] rounded-full flex justify-center items-center cursor-pointer">
                              <FontAwesomeIcon
                                className="text-xs  text-white"
                                icon={faX}
                                size="2xs"
                                onClick={() => {
                                  const testDeletDates =
                                    props.newEvent.times?.filter((time) => {
                                      return (
                                        time.date !==
                                        (timesToShow ? timesToShow[0].date : '')
                                      )
                                    })
                                  props.newEvent.times = testDeletDates
                                }}
                              />
                            </div>
                            <CalendarDateIcon date={groupDate} />{' '}
                          </div>
                        ) : index === 2 ? (
                          <div className="justify-center items-center flex select-none w-full h-full">
                            <span className="font-bold text-md cursor-pointer text-eventsMain pl-2">{`+${
                              groupedTimes.length - 2
                            } `}</span>
                          </div>
                        ) : null}
                        {index < 2 ? (
                          <>
                            {props.newEvent.eventType ===
                              EVENT3_TYPE.ACTIVITIES ||
                            props.newEvent.eventType === EVENT3_TYPE.BOOKING ||
                            props.newEvent.eventType === EVENT3_TYPE.MEETING ? (
                              <div>
                                <div
                                  className={`${
                                    timesToShow?.length!! > 2 && 'h-[50px]'
                                  } overflow-auto scrollbar-hide`}
                                >
                                  {timesToShow
                                    ?.slice(0, 2)
                                    .map((time, index) => (
                                      <div
                                        key={index}
                                        className={`text-xs text-eventsMain font-semibold`}
                                      >
                                        <>
                                          {time.start && time.end
                                            ? `${time.start} - ${time.end}`
                                            : t('events.noSetTime')}
                                        </>
                                      </div>
                                    ))}
                                  {timesToShow?.length!! > 2 && (
                                    <div
                                      key="remaining"
                                      className="text-xs text-eventsMain font-semibold"
                                    >
                                      +{timesToShow?.length!! - 2}
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : null}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    )
                  })}
                </>
              )}
            </div>
          ) : (
            <div
              className={`flex gap-x-3 w-full ${
                props.newEvent.eventType === EVENT3_TYPE.BOOKING
                  ? 'justify-center'
                  : 'justify-between'
              }`}
            >
              <Button
                label={t('events.chooseDateBTN')}
                variant="custom"
                textColor="text-black"
                bgrondColor="bg-[#F6F6F6]"
                size="sm"
                fontSize="font-semibold"
                icon={faCalendarDays}
                iconColor="text-redLight"
                onClick={() => {
                  props.onChange({ ...props.newEvent, isRecurrence: false })
                  props.onClick()
                }}
                // on click open calendar and show chooed times
              />

              {props.newEvent.eventType !== EVENT3_TYPE.BOOKING && (
                <Button
                  label={t('events.repeatBTN')}
                  variant="custom"
                  bgrondColor="bg-[#F6F6F6]"
                  size="sm"
                  fontSize="font-semibold"
                  icon={faRepeat}
                  iconColor="text-greenForrest"
                  onClick={() => {
                    props.onChange({ ...props.newEvent, isRecurrence: true })
                    props.onClick()
                  }}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
})
