import React from 'react'
import ModalRegular from './ModalRegular'
import { Button } from './Button'
import { useTranslation } from 'react-i18next'
import { CreateEventParams } from '../store/event3Store'
import moment from 'moment'

interface props {
  switchToSecondAlternative: boolean
  setSwitchToSecondAlternative: (value: boolean) => void
  confirmState: string
  onChangeEvent: (e: CreateEventParams) => void
  newEvent: CreateEventParams
}

export const DateOrRepeatPopup: React.FC<props> = ({
  switchToSecondAlternative,
  setSwitchToSecondAlternative,
  confirmState,
  onChangeEvent,
  newEvent,
}) => {
  const { t } = useTranslation()

  return (
    <ModalRegular
      isOpen={switchToSecondAlternative}
      onClose={() => setSwitchToSecondAlternative(false)}
      title={t('documents.areYouSureRemoveFile')}
      className=" max-w-lg !-pt-4"
      classNameTitle="flex items-center justify-center text-black w-full py-4"
      hideCloseButton
    >
      <p className="flex  w-[80%] h-[80%] justify-center items-center py-12 text-center mx-auto">
        {t('events.switchDateAndReccurrence')}
      </p>
      <div className="flex flex-col">
        <div className="absolute bottom-10 w-full flex justify-center  gap-4">
          <Button
            size="sm"
            variant="fullWhiteGrayText"
            fontSize="font-bold"
            label={t('general.cancel')}
            onClick={() => {
              setSwitchToSecondAlternative(false)
            }}
          />
          <Button
            size="sm"
            variant="fullRed"
            fontSize="font-bold"
            label={t('events.confirm') + '  👍'}
            onClick={() => {
              if (confirmState === 'changeToCustomDate') {
                onChangeEvent({
                  ...newEvent,
                  isRecurrence: false,
                  recurrence: {
                    startsOn: moment().format('YYYY-MM-DD'),
                    endsOn: moment().add(1, 'month').format('YYYY-MM-DD'),
                    days: [],
                    endTime: undefined,
                    interval: 1,
                    startTime: undefined,
                  },
                })
              } else if (confirmState === 'changeToRepeat') {
                onChangeEvent({
                  ...newEvent,
                  isRecurrence: true,
                  times: [],
                })
              }
              setSwitchToSecondAlternative(false)
            }}
          />
        </div>
      </div>
    </ModalRegular>
  )
}
