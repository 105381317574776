import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import {
  faUserCircle,
  faChartColumn,
  faFolder,
  faNewspaper,
  faCommentMedical,
  faChildReaching,
  faRotate,
  faAddressCard,
  faChartBar,
  faChartSimple,
  faFileExport,
} from '@fortawesome/free-solid-svg-icons'

import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { UserStoreContext } from '../../../store/userStore'
import { Spinner } from '../../../components/Spinner'
import { getDashboardTitle, getImageFromRole } from '../../../utils/parseUtils'
import { DashboardItem } from './DashboardItem'
import { USER_ROLE_TYPE } from '../../../Enums/TyraEnums'
import {
  faMegaphone,
  faChartPie,
  faBookOpen,
  faBoxArchive,
  faPlus,
  faEllipsisVertical,
  faTrashCan,
  faOrnament,
  faPen,
  faUserShield,
} from '@fortawesome/pro-solid-svg-icons'
import ModalRegular from '../../../components/ModalRegular'
import { Button } from '../../../components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ButtonDropdown } from '../../../components/Dropdown'
import Swal from 'sweetalert2'
import CategoryBox, { Category } from '../../../components/CategoryBox'
import Box from '../../Box/Box'
import CustomInput from '../../../components/CustomInput'
export const Dashboard: React.FC<{
  userRoleId: string
  hideHeader?: boolean
  onClick?: any
}> = observer(({ userRoleId, hideHeader, onClick }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const { fetchUserRole, currentUserRole, readOnly } =
    useContext(UserStoreContext)
  const [showBox, setShowBox] = useState(false)
  const [selectedButton, setSelectedButton] = useState(null)
  const handleClick = (Button: any) => {
    setSelectedButton((prevState) => {
      if (prevState === Button) {
        // If the clicked button was already selected, unselect it
        return null
      } else {
        // Otherwise, select the clicked button and unselect all others
        return Button
      }
    })
  }
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible)
  }
  const [sliderValue, setSliderValue] = useState(1)
  const handleSliderClick = (newValue: any) => {
    setSliderValue(newValue)
    if (newValue === 0) {
      handleClick('slut')
    } else if (newValue === 1) {
      handleClick('fa')
    } else {
      handleClick('manga')
    }
  }
  const [changePSN, setChangePSN] = useState(true)
  const [finalModal, setFinalModal] = useState(false)
  const [clearifyModal, setClearifyModal] = useState(false)
  const [categories, setCategories] = useState<Category[]>([])
  const [inputValue, setInputValue] = useState('')
  const addCategory = () => {
    if (inputValue.trim() === '') {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'You have to type in text in the category section to create a category',
      })
    } else {
      setCategories([
        ...categories,
        {
          key: Date.now(),
          title: inputValue,
        },
      ])
      setInputValue('')
    }
  }
  const removeCategory = (key: number) => {
    setCategories(categories.filter((category) => category.key !== key))
  }

  useEffect(() => {
    if (userRoleId) {
      setLoading(true)
      fetchUserRole(userRoleId).finally(() => {
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
  }, [userRoleId])

  const [noUserState, setNoUserState] = useState('noActive')

  const showDocuments =
    currentUserRole?.school_pointer?.attributes.docsMod &&
    !currentUserRole?.school_pointer?.attributes.docsMod
      ? false
      : true

  if (loading) return <Spinner />
  return (
    <div className={'flex w-full justify-center items-center flex-col pb-4'}>
      {loading && <Spinner />}
      {!hideHeader && currentUserRole && (
        <div
          className={
            ' flex justify-center flex-col items-center py-8 2xl:py-12'
          }
        >
          {changePSN &&
            process.env.REACT_APP_PUBLIC_URL?.includes('localhost') && (
              <ModalRegular
                isOpen={changePSN}
                onClose={() => {
                  setChangePSN(false)
                  setClearifyModal(true)
                }}
                className="max-w-[400px]"
              >
                <div className="p-10 flex flex-col">
                  <h1 className="font-bold mb-2 justify-center items-center text-center">
                    <>{t('login.submitPSN')}</>
                  </h1>
                  <p className="font-normal">
                    <>{t('login.submitPSNText')}</>
                  </p>
                  <CustomInput
                    onChange={function (value: string): void {
                      throw new Error('Function not implemented.')
                    }}
                    onlyNumbers
                    format="YYYYMMDD-XXXX"
                    className="my-3"
                  />
                  <div className="flex flex-col justify-center items-center gap-y-4">
                    <Button
                      variant="fullBlue"
                      label={t('login.verifyWithBankId')}
                      size="setWidth"
                    />
                    <Button
                      variant="borderBlue"
                      label={t('login.saveWithoutVerify')}
                      size="setWidth"
                    />
                    <div
                      onClick={() => {
                        setChangePSN(false)
                        setClearifyModal(true)
                      }}
                      className="font-semibold text-eventsMain cursor-pointer hover:text-blue-500 text-sm"
                    >
                      <>{t('login.skip')}</>
                    </div>
                  </div>
                </div>
              </ModalRegular>
            )}

          {clearifyModal &&
            process.env.REACT_APP_PUBLIC_URL?.includes('localhost') && (
              <ModalRegular
                isOpen={clearifyModal}
                onClose={() => {
                  setClearifyModal(false)
                  setFinalModal(true)
                }}
                className="max-w-[500px]"
              >
                <div className="p-10 flex flex-col gap-y-4">
                  <h1 className="font-bold text-center mb-2">
                    <>{t('login.waitExplain')}</>
                  </h1>
                  <p>
                    <>{t('login.waitExplainText1')}</>
                  </p>

                  <p>
                    <>{t('login.waitExplainText2')}</>
                  </p>
                  <p>
                    <>{t('login.waitExplainText3')}</>
                  </p>
                  <div className="flex flex-col  items-center gap-y-4">
                    <Button
                      variant="fullBlue"
                      label={t('login.back')}
                      size="setWidth"
                    />
                    <div
                      onClick={() => {
                        setClearifyModal(false)
                        setFinalModal(true)
                      }}
                      className="font-semibold text-eventsMain cursor-pointer hover:text-blue-500 text-sm"
                    >
                      <>{t('login.skipAnyway')}</>
                    </div>
                  </div>
                </div>
              </ModalRegular>
            )}
          {finalModal &&
            process.env.REACT_APP_PUBLIC_URL?.includes('localhost') && (
              <ModalRegular
                isOpen={finalModal}
                onClose={() => {
                  setFinalModal(false)
                }}
                className="max-w-[400px]"
              >
                <div className="flex flex-col p-8 gap-y-4">
                  <h1 className="font-bold text-center mb-2">
                    <>{t('login.weUnderstand')}</>
                  </h1>
                  <p className="text-center">
                    <>{t('login.weUnderstandText1')}</>
                  </p>
                  <p className="text-center mb-2">
                    <>{t('login.weUnderstandText2')}</>
                  </p>
                  <div className="flex justify-center">
                    <div
                      onClick={() => {
                        setFinalModal(false)
                      }}
                      className="font-semibold text-eventsMain cursor-pointer hover:text-blue-500 text-sm"
                    >
                      <>{t('general.close')}</>
                    </div>
                  </div>
                </div>
              </ModalRegular>
            )}
          <img
            className={
              'w-[100px] h-[100px] 2xl:w-[120px]  2xl:h-[120px] object-cover rounded-full'
            }
            src={getImageFromRole(currentUserRole)}
            alt=""
          />

          {currentUserRole?.attributes?.role_type === USER_ROLE_TYPE.PARENT ? (
            <p className={'mt-4 font-medium text-2xl text-gray-500 '}>
              {getDashboardTitle(currentUserRole)}
            </p>
          ) : (
            <p className={'mt-4 font-medium text-2xl text-gray-500 '}>
              {t('menu.greetingBefore')} {getDashboardTitle(currentUserRole)}{' '}
              {t('menu.greetingAfter')}
            </p>
          )}
        </div>
      )}

      {currentUserRole ? (
        <div
          className={
            'grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 lg:gap-4 gap-x-4 gap-y-2  place-items-center px-5 font-medium'
          }
        >
          {currentUserRole &&
            currentUserRole?.role_type === USER_ROLE_TYPE.PARENT && (
              <>
                <DashboardItem
                  title={t('menu.portfolio')}
                  className={'bg-portfolioMain '}
                  icon={faUserCircle}
                  onClick={() => {
                    navigate(`/portfolio-kid`)
                    if (onClick) {
                      onClick()
                    }
                  }}
                />
                <DashboardItem
                  title={t('menu.notes')}
                  className={'bg-notesMain'}
                  icon={faBookOpen}
                  onClick={() => {
                    navigate(`/notes/` + currentUserRole.kid?.id)
                    if (onClick) {
                      onClick()
                    }
                  }}
                />
                <DashboardItem
                  title={t('menu.box')}
                  className={'bg-boxMain cursor-pointer'}
                  icon={faBoxArchive}
                  onClick={() => setShowBox(true)}
                />
                {currentUserRole.kid && (
                  <Box
                    isOpen={showBox}
                    onClose={() => setShowBox(false)}
                    kidId={currentUserRole.kid.id}
                  />
                )}
                {(process.env.REACT_APP_PUBLIC_URL?.includes('localhost') ||
                  process.env.REACT_APP_PUBLIC_URL?.includes('alfa')) && (
                  <DashboardItem
                    onClick={() => {
                      navigate(
                        `/events/${currentUserRole?.attributes?.school_pointer?.id}`,
                      )
                      if (onClick) {
                        onClick()
                      }
                    }}
                    title={t('menu.events')}
                    className={'bg-eventsMain'}
                    icon={faNewspaper}
                  />
                )}
              </>
            )}
          {currentUserRole.school_pointer.event3Mod ? (
            <>
              {currentUserRole &&
                currentUserRole?.role_type > USER_ROLE_TYPE.PARENT && (
                  <DashboardItem
                    onClick={() => {
                      navigate(
                        `/events/${currentUserRole?.attributes?.school_pointer?.id}`,
                      )
                      if (onClick) {
                        onClick()
                      }
                    }}
                    title={t('menu.events')}
                    className={'bg-eventsMain'}
                    icon={faNewspaper}
                  />
                )}
            </>
          ) : (
            <DashboardItem
              onClick={() => {
                navigate(
                  `/events/${currentUserRole?.attributes?.school_pointer?.id}`,
                )
                if (onClick) {
                  onClick()
                }
              }}
              title={t('menu.events')}
              className={'bg-eventsMain'}
              icon={faNewspaper}
            />
          )}

          {currentUserRole && (
            <DashboardItem
              title={
                currentUserRole?.role_type > USER_ROLE_TYPE.PARENT
                  ? t('menu.ourKids')
                  : t('menu.classList')
              }
              className={'bg-classlistMain'}
              icon={faChildReaching}
              onClick={() => {
                navigate(`/kids/`)
                if (onClick) {
                  onClick()
                }
              }}
            />
          )}
          {currentUserRole &&
            currentUserRole?.role_type > USER_ROLE_TYPE.PARENT && (
              <>
                <DashboardItem
                  title={t('menu.contactlists')}
                  className={'bg-contactsMain'}
                  icon={faAddressCard}
                  onClick={() => {
                    navigate(
                      `/contactlists/${currentUserRole?.attributes?.school_pointer?.id}`,
                    )
                    if (onClick) {
                      onClick()
                    }
                  }}
                />
              </>
            )}

          <DashboardItem
            title={t('menu.blog')}
            className={'bg-blogMain'}
            icon={faMegaphone}
            onClick={() => {
              navigate(
                `/blog/${currentUserRole?.attributes?.school_pointer?.id}`,
              )
              if (onClick) {
                onClick()
              }
            }}
          />
          <DashboardItem
            onClick={() => {
              navigate(`/absence`)
              if (onClick) {
                onClick()
              }
            }}
            title={t('menu.absence')}
            className={'bg-absenceMain'}
            icon={faCommentMedical}
          />
          {showDocuments && (
            <DashboardItem
              title={t('menu.documents')}
              className={'bg-documentsMain'}
              icon={faFolder}
              onClick={() => {
                navigate(
                  `/documents/${currentUserRole?.attributes?.school_pointer?.id}`,
                )
                if (onClick) {
                  onClick()
                }
              }}
            />
          )}
          {currentUserRole &&
            currentUserRole.role_type >= USER_ROLE_TYPE.ADMIN && (
              <DashboardItem
                title={t('admin.admin')}
                className={'bg-adminMain'}
                icon={faUserShield}
                onClick={() => {
                  navigate(
                    `/admin/${currentUserRole?.attributes?.school_pointer?.id}`,
                  )
                  if (onClick) {
                    onClick()
                  }
                }}
              />
            )}

          {/*
            Fix because in many cases, admins have staff_schedule_responsable as undefined - but admin should see everything.
          */}
          {currentUserRole &&
          currentUserRole.role_type > USER_ROLE_TYPE.TEACHER ? (
            <>
              <DashboardItem
                title={t('menu.attendance')}
                className={'bg-statistics'}
                icon={faChartSimple}
                onClick={() => {
                  navigate(
                    `/statistics/${currentUserRole?.attributes?.school_pointer?.id}`,
                  )
                  if (onClick) {
                    onClick()
                  }
                }}
              />
              {(process.env.REACT_APP_PUBLIC_URL?.includes('localhost') ||
                process.env.REACT_APP_PUBLIC_URL?.includes('alfa')) && (
                <DashboardItem
                  title={'!!Närvaro Personal'}
                  className={'bg-statistics'}
                  icon={faChartSimple}
                  onClick={() => {
                    navigate(
                      `/statisticsteacher/${currentUserRole?.attributes?.school_pointer?.id}`,
                    )
                    if (onClick) {
                      onClick()
                    }
                  }}
                />
              )}
            </>
          ) : (
            <>
              {currentUserRole &&
                currentUserRole?.role_type > USER_ROLE_TYPE.PARENT &&
                !readOnly?.events &&
                currentUserRole.staff_schedule_responsable && (
                  <DashboardItem
                    title={t('menu.attendance')}
                    className={'bg-statistics'}
                    icon={faChartSimple}
                    onClick={() => {
                      navigate(
                        `/statistics/${currentUserRole?.attributes?.school_pointer?.id}`,
                      )
                      if (onClick) {
                        onClick()
                      }
                    }}
                  />
                )}
            </>
          )}

          {currentUserRole &&
            currentUserRole?.role_type >= USER_ROLE_TYPE.TEACHER &&
            currentUserRole?.school_pointer?.attributes.skaModTeacher && (
              <DashboardItem
                title={t('export.exportDocumentation')}
                className={'bg-blue-400'}
                textClass={'!text-xs md:mt-[10px] md:text-center'}
                icon={faFileExport}
                onClick={() => {
                  navigate(`/pdf-export`)
                  if (onClick) {
                    onClick()
                  }
                }}
              />
            )}
        </div>
      ) : (
        <div className="">
          <p className="text-xl text-center font-bold">
            {t('general.noActiveAcc')}
          </p>
          <p className="text-center mt-2">{t('general.contactSchool')}</p>
        </div>
      )}
    </div>
  )
})
