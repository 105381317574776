import { observer } from 'mobx-react'
import { SORT_VALUES } from '../Statistics'
import { useContext, useEffect, useState } from 'react'
import { StatisticStoreContext } from '../../../store/statisticStore'
import { UserStoreContext } from '../../../store/userStore'
import { NotificationContext } from '../../../App/notification/notificationManger'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import Swal from 'sweetalert2'
import classNames from 'classnames'
import ModalRegular from '../../../components/ModalRegular'
import DatePicker, { registerLocale } from 'react-datepicker'
import { Button } from '../../../components/Button'
import { faCheck, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuidv4 } from 'uuid'
import { useNavigate } from 'react-router-dom'
import Parse from 'parse'

export const ShowWeek: React.FC<{
  statistics: any
  id: string
  firstDate: any
  firstDateOfWeek: any
  lastDate: any
  showWeekNumber?: boolean
  showKidInfo?: boolean
  schoolId?: string
  fetch: () => void
  fromDate: Date
  toDate: Date
  exporting?: boolean
  showSsn?: boolean
  sortBy?: SORT_VALUES
  type: 'kid' | 'teacher'
  href?: string
  firstName?: string
  lastName?: string
  image?: string
  ssn?: string
  logs?: any
  schedule?: any
  closedDates?: any
}> = observer(
  ({
    statistics,
    id,
    firstDate,
    firstDateOfWeek,
    lastDate,
    showWeekNumber = true,
    showKidInfo = false,
    schoolId,
    fetch,
    fromDate,
    toDate,
    exporting,
    showSsn,
    sortBy,
    type,
    href,
    firstName,
    lastName,
    image,
    ssn,
    logs,
    schedule,
    closedDates,
  }) => {
    const [editLogMulti, setEditLogMulti] = useState<any>(null)
    const [editLog, setEditLog] = useState<any>(null)
    const [newLog, setNewLog] = useState<any>(null)
    const [accept, setAccept] = useState(false)
    const { createNewLog, setFreeOnSchedule, setAbsenceOnSchedule } =
      useContext(StatisticStoreContext)
    const { currentUser } = useContext(UserStoreContext)

    const [weeks, setWeeks] = useState<any>([])
    const { notify } = useContext(NotificationContext)
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()

    const [freeForDay, setFreeForDay] = useState(false)
    const [absenceForDay, setAbsenceForDay] = useState(false)

    const generateWeeks = () => {
      let weeks: any[] = []
      for (var d = firstDate; d < lastDate; firstDate.add(1, 'days')) {
        if (!weeks.find((wn) => wn.week == moment(firstDate).get('week'))) {
          const days: Array<any> = []
          for (var x = 0; x < 7; x++) {
            days.push(firstDateOfWeek.format('YYYY-MM-DD'))
            firstDateOfWeek.add(1, 'days')
          }
          weeks.push({
            week: moment(firstDate).get('week'),
            days,
            fromDate: moment(firstDate).startOf('week').add(1, 'days'),
            toDate: moment(firstDate).endOf('week').subtract(1, 'days'),
          })
        }
        //moment(firstDate).add(1, 'days');
      }
      return weeks
    }

    useEffect(() => {
      const weeks = generateWeeks()
      setWeeks(weeks)
    }, [firstDate])

    /*useEffect(() => {
        const kid = fetchKid(createLog?.kidId ?? '')
      }, [createLog])*/

    const createLog = async () => {
      const log = await createNewLog(id, newLog.checkedIn, newLog.checkedOut)

      if (log) {
        setNewLog(null)
        fetch()
        /*notify({
          variant: NotificationVariant.OK,
          title: t('statistics.success'),
          message: t('statistics.InOutCheckSave'),
        })*/
        Swal.fire({
          text: t('statistics.InOutCheckSave'),
          icon: 'success',
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonColor: '#7eb5f4',
          cancelButtonColor: '#d33',
          confirmButtonText: t('general.ok'),
        })
      } else {
        /*notify({
          variant: NotificationVariant.ERROR,
          title: t('events.eventAlarmFail'),
          message: t('statistics.contactSupport'),
        })*/
        Swal.fire({
          text: t('statistics.contactSupport'),
          icon: 'error',
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonColor: '#7eb5f4',
          cancelButtonColor: '#d33',
          confirmButtonText: t('general.ok'),
        })
      }
    }

    const saveLog = async () => {
      try {
        console.log('HALLÅÅÅ 123 ***')
        for (const log of editLog) {
          console.log('HALLÅÅÅ 1234 ***')
          if (log.remove) {
            await removeLog(log)
          } else if (log.new) {
            await createNewLog(id, log.checkedIn, log.checkedOut)
          } else {
            console.log('HALLÅÅÅ 12345 ***', log)
            const logCheckedIn = await new Parse.Query('log').get(
              log.checkedInId,
            )
            console.log('HALLÅÅÅ 123451 ***', logCheckedIn)
            const logCheckedOut = await new Parse.Query('log').get(
              log.checkedOutId,
            )

            console.log('currentUser', currentUser)
            logCheckedIn.set('check_in_out_date', log.checkedIn)
            logCheckedIn.set('edited', true)
            logCheckedIn.set('user_performer_id', currentUser?.id)
            const saveCheckedIn = await logCheckedIn.save()
            console.log('HALLÅÅÅ 123456 ***')
            logCheckedOut.set('check_in_out_date', log.checkedOut)
            logCheckedOut.set('edited', true)
            logCheckedOut.set('user_performer_id', currentUser?.id)
            const saveCheckedOut = await logCheckedOut.save()
          }
        }
        Swal.fire({
          text: t('statistics.changesSaved'),
          icon: 'success',
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonColor: '#7eb5f4',
          cancelButtonColor: '#d33',
          confirmButtonText: t('general.ok'),
        })
        setEditLog(null)
        fetch()
      } catch (error) {
        Swal.fire({
          text: t('statistics.contactSupport'),
          icon: 'error',
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonColor: '#7eb5f4',
          cancelButtonColor: '#d33',
          confirmButtonText: t('general.ok'),
        })
      }
      /*if (saveCheckedIn && saveCheckedOut) {
         
          Swal.fire({
            text: t('statistics.changesSaved'),
            icon: 'success',
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonColor: '#7eb5f4',
            cancelButtonColor: '#d33',
            confirmButtonText: t('general.ok'),
          })
          setEditLog(null)
          fetch()
        } 
          Swal.fire({
            text: t('statistics.contactSupport'),
            icon: 'error',
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonColor: '#7eb5f4',
            cancelButtonColor: '#d33',
            confirmButtonText: t('general.ok'),
          })
        }*/
    }

    const removeLog = async (log: any) => {
      const logCheckedIn = await new Parse.Query('log').get(log.checkedInId)
      const logCheckedOut = await new Parse.Query('log').get(log.checkedOutId)

      logCheckedIn.set('check_in_out_date', log.checkedIn)
      const saveCheckedIn = await logCheckedIn.destroy().then(
        () => {
          return true
        },
        (error) => {
          /* notify({
            variant: NotificationVariant.ERROR,
            title: t('events.eventAlarmFail'),
            message: t('statistics.contactSupport'),
          })*/
          Swal.fire({
            text: t('statistics.contactSupport'),
            icon: 'error',
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonColor: '#7eb5f4',
            cancelButtonColor: '#d33',
            confirmButtonText: t('general.ok'),
          })
          throw error
        },
      )
      logCheckedOut.set('check_in_out_date', log.checkedOut)
      const saveCheckedOut = await logCheckedOut.destroy().then(
        () => {
          return true
        },
        (error) => {
          /* notify({
            variant: NotificationVariant.ERROR,
            title: t('events.eventAlarmFail'),
            message: t('statistics.contactSupport'),
          })*/
          Swal.fire({
            text: t('statistics.contactSupport'),
            icon: 'error',
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonColor: '#7eb5f4',
            cancelButtonColor: '#d33',
            confirmButtonText: t('general.ok'),
          })
          throw error
        },
      )

      /*notify({
        variant: NotificationVariant.OK,
        title: t('statistics.success'),
        message: t('statistics.changesSaved'),
      })*/
      /*Swal.fire({
          text: t('statistics.changesSaved'),
          icon: 'success',
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonColor: '#7eb5f4',
          cancelButtonColor: '#d33',
          confirmButtonText: t('general.ok'),
        })
        setEditLog(null)
        fetch()*/
    }

    return (
      <>
        {weeks.map((week: any, index: any) => {
          let totalTimeAttendingHours = 0
          let totalTimeAttendingMinutes = 0
          let totalTimeAttendingHoursMulti = 0
          let totalTimeAttendingMinutesMulti = 0
          return (
            <tr
              //className={index % 2 == 0 ? 'bg-white' : 'bg-gray-200'}
              className={exporting ? '!text-black text-xs' : ''}
              key={index}
            >
              {showWeekNumber && (
                <td className="p-2 whitespace-nowrap">
                  <>
                    {t('general.week')} {week.week}
                    <br />
                    <span className="text-2xs">
                      {moment(week.fromDate).format('DD/MM') +
                        ' - ' +
                        moment(week.toDate).format('DD/MM')}
                    </span>
                  </>
                </td>
              )}
              {showKidInfo && (
                <>
                  <td
                    className="p-2 whitespace-nowrap"
                    onClick={() => {
                      if (href) {
                        navigate(href)
                      }
                    }}
                  >
                    {/*<a href={href}>*/}
                    <div className="flex items-center cursor-pointer hover:scale-[102%]">
                      <div className="flex-shrink-0 mr-2 sm:mr-3">
                        <img
                          className="rounded-full"
                          src={image}
                          width="60"
                          height="60"
                          alt={''}
                        />
                      </div>
                      <div className="font-bold text-gray-800 mr-12">
                        {/*exporting ? (
                          <>{firstName + ' ' + lastName}</>
                        ) : (*/}
                        <>
                          {sortBy === SORT_VALUES.FIRSTNAMEASC ||
                          sortBy === SORT_VALUES.FIRSTNAMEDESC
                            ? firstName
                            : null}
                          {sortBy === SORT_VALUES.LASTNAMEASC ||
                          sortBy === SORT_VALUES.LASTNAMEDESC
                            ? lastName
                            : null}
                          <br />{' '}
                          <span className="font-normal">
                            {sortBy === SORT_VALUES.FIRSTNAMEASC ||
                            sortBy === SORT_VALUES.FIRSTNAMEDESC
                              ? lastName
                              : null}
                            {sortBy === SORT_VALUES.LASTNAMEASC ||
                            sortBy === SORT_VALUES.LASTNAMEDESC
                              ? firstName
                              : null}
                          </span>
                        </>
                        {/*)}*/}
                        <br />{' '}
                        {!exporting ? (
                          <span className="text-xs text-gray-700 font-normal">
                            {ssn}
                          </span>
                        ) : showSsn ? (
                          <span className="text-xs text-gray-700 font-normal">
                            {ssn}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    {/*</a>*/}
                  </td>
                  {/*showSsn && (
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{kidStatistics.kidSsn}</div>
                      </td>
                    )*/}
                </>
              )}

              {week.days.map((day: any) => {
                let checkedInArray = []
                let checkedIn = null
                let checkedOut = null
                let logMulti = logs?.filter(
                  (log: any) =>
                    moment(log.checkedIn).format('YYYY-MM-DD') === day &&
                    moment(log.checkedOut).format('YYYY-MM-DD') === day,
                )

                for (const row of logMulti ?? []) {
                  if (row.checkedIn && row.checkedOut) {
                    var diffMs =
                      (moment(
                        moment(row.checkedOut).format('YYYY-MM-DD HH:mm'),
                      ) as any) -
                      (moment(
                        moment(row.checkedIn).format('YYYY-MM-DD HH:mm'),
                      ) as any)
                    // TODO Should have better data type above.
                    var diffHrs = Math.floor((diffMs % 86400000) / 3600000)
                    var diffMins = Math.round(
                      ((diffMs % 86400000) % 3600000) / 60000,
                    )
                    totalTimeAttendingHoursMulti =
                      totalTimeAttendingHoursMulti + diffHrs
                    totalTimeAttendingMinutesMulti =
                      totalTimeAttendingMinutesMulti + diffMins
                    if (totalTimeAttendingMinutesMulti >= 60) {
                      totalTimeAttendingHoursMulti =
                        totalTimeAttendingHoursMulti + 1
                      totalTimeAttendingMinutesMulti =
                        totalTimeAttendingMinutesMulti - 60
                    }
                  }
                }
                let log = logs?.find(
                  (log: any) =>
                    moment(log.checkedIn).format('YYYY-MM-DD') === day &&
                    moment(log.checkedOut).format('YYYY-MM-DD') === day,
                )

                checkedIn = logs?.find(
                  (log: any) =>
                    moment(log.checkedIn).format('YYYY-MM-DD') === day,
                )?.checkedIn
                checkedOut = logs?.find(
                  (log: any) =>
                    moment(log.checkedOut).format('YYYY-MM-DD') === day,
                )?.checkedOut

                if (checkedIn && checkedOut) {
                  var diffMs = (checkedOut as any) - (checkedIn as any)
                  // TODO Should have better data type above.
                  var diffHrs = Math.floor((diffMs % 86400000) / 3600000)
                  var diffMins = Math.round(
                    ((diffMs % 86400000) % 3600000) / 60000,
                  )
                  totalTimeAttendingHours = totalTimeAttendingHours + diffHrs
                  totalTimeAttendingMinutes =
                    totalTimeAttendingMinutes + diffMins

                  if (totalTimeAttendingMinutes >= 60) {
                    totalTimeAttendingHours = totalTimeAttendingHours + 1
                    totalTimeAttendingMinutes = totalTimeAttendingMinutes - 60
                  }
                }

                let scheduleStart = schedule?.find(
                  (sched: any) => sched.date == day,
                )?.from
                let scheduleEnd = schedule?.find(
                  (sched: any) => sched.date == day,
                )?.to

                if (moment(day).day() == 0 || moment(day).day() == 6) {
                  return null
                }

                if (
                  moment(day).format('YYYY-MM-DD') <
                  moment(fromDate).format('YYYY-MM-DD')
                ) {
                  return <td></td>
                }

                if (
                  moment(day).format('YYYY-MM-DD') >
                  moment(toDate).format('YYYY-MM-DD')
                ) {
                  return <td></td>
                }
                if (
                  closedDates?.find(
                    (cD: string) => cD === moment(day).format('YYYY-MM-DD'),
                  )
                ) {
                  return (
                    <td className="text-center cursor-pointer hover:bg-blue-200 text-blue-500">
                      <>
                        <span className="w-full text-center">
                          <>{t('statistics.closed')}</>
                        </span>
                      </>
                    </td>
                  )
                }
                if (
                  schedule?.find((sched: any) => sched.date == day)?.from ==
                  'null'
                ) {
                  return (
                    <td
                      className={classNames(
                        'text-center cursor-pointer hover:bg-blue-200',
                        checkedIn && checkedOut
                          ? 'text-green-600 font-bold'
                          : '',
                        exporting ? '!text-black' : '',
                      )}
                      onClick={() => {
                        if (type === 'kid') {
                          if (log) {
                            setEditLog(logMulti)
                          } else {
                            setNewLog({
                              kidId: id,
                              date: new Date(day),
                              checkedIn: new Date(
                                moment(day).format('YYYY-MM-DD 08:00'),
                              ),
                              checkedOut: new Date(
                                moment(day).format('YYYY-MM-DD 16:00'),
                              ),
                              freeAvailable: true,
                            })
                          }
                        }
                      }}
                    >
                      {checkedIn && checkedOut && (
                        <>
                          {moment(checkedIn).format('HH:mm') +
                            ' - ' +
                            moment(checkedOut).format('HH:mm')}{' '}
                        </>
                      )}{' '}
                      {!exporting && (
                        <>
                          {checkedIn && checkedOut && <br />}
                          <span className="w-full text-center">-</span>
                        </>
                      )}
                    </td>
                  )
                } else if (
                  scheduleStart &&
                  scheduleEnd &&
                  /*checkedIn &&
                      checkedOut*/
                  logMulti?.length
                ) {
                  return (
                    <td
                      className={classNames(
                        'text-center relative text-green-600 hover:bg-blue-200',
                        exporting ? '!text-black' : '',
                      )}
                      onClick={() => {
                        if (type === 'kid') {
                          setEditLog(logMulti)
                        }
                        /* else {
                            setNewLog({
                              kidId: kidStatistics?.kidId,
                              date: new Date(day),
                              checkedIn: new Date(
                                moment(day).format('YYYY-MM-DD 08:00'),
                              ),
                              checkedOut: new Date(
                                moment(day).format('YYYY-MM-DD 16:00'),
                              ),
                            })
                          }*/
                      }}
                    >
                      {/*{checkedIn && checkedOut && (*/}
                      <>
                        {/*moment(checkedIn).format('HH:mm') +
                                ' - ' +
                          moment(checkedOut).format('HH:mm')*/}
                        {logMulti
                          ?.sort(
                            (a: any, b: any) =>
                              moment(a.checkedIn).valueOf() -
                              moment(b.checkedIn).valueOf(),
                          )
                          ?.map((multi: any) => {
                            return (
                              <div
                                className={classNames(
                                  multi.break
                                    ? 'text-brownBox'
                                    : 'text-green-600',
                                )}
                              >
                                {multi.break ? '*' : null}
                                {moment(multi.checkedIn).format('HH:mm') +
                                  ' - ' +
                                  moment(multi.checkedOut).format('HH:mm')}{' '}
                                <br />
                              </div>
                            )
                          })}
                        {/*!exporting && (
                              <div
                                className="absolute top-2 right-0 rounded-full w-5 h-5 bg-eventsMain text-white cursor-pointer"
                                onClick={() => {
                                  setNewLog({
                                    kidId: kidStatistics?.kidId,
                                    date: new Date(day),
                                    checkedIn: new Date(
                                      moment(day).format('YYYY-MM-DD 08:00'),
                                    ),
                                    checkedOut: new Date(
                                      moment(day).format('YYYY-MM-DD 16:00'),
                                    ),
                                  })
                                }}
                              >
                                +
                              </div>
                              )*/}
                      </>
                      {/*)}*/}

                      {/*
                        
                            TODO: DONT DO THIS ?? SHOW FREE IF CHECKED IN AND OUT*/}

                      {/*{!exporting && ( */}
                      <table className="!text-orange-400 w-full font-normal">
                        <tbody>
                          <tr>
                            <td className="text-green-600 w-full text-center block"></td>
                          </tr>
                          {scheduleStart && (
                            <tr className="">
                              {scheduleStart == 'ledig' ? (
                                <td
                                  className={classNames(
                                    'text-blue-500 w-full text-center',
                                    exporting ? '!text-black' : '',
                                  )}
                                >
                                  <>{t('statistics.offHour')}</>
                                </td>
                              ) : (
                                <td
                                  className={classNames(
                                    '!text-orange-400 relative w-full text-center',
                                    exporting ? '!text-black' : '',
                                  )}
                                >
                                  {exporting && '('}
                                  {scheduleStart} - {scheduleEnd}
                                  {exporting && ')'}
                                </td>
                              )}
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {/* )} */}
                    </td>
                  )
                } else if (
                  schedule?.find((sched: any) => sched.date == day)?.from ==
                  'Stängt'
                ) {
                  return (
                    <td
                      className={classNames(
                        'text-center cursor-pointer hover:bg-blue-200',
                        checkedIn && checkedOut
                          ? 'text-green-600 font-bold'
                          : 'text-blue-500',
                        exporting ? '!text-black' : '',
                      )}
                      onClick={() => {
                        if (type === 'kid') {
                          if (log) {
                            setEditLog(logMulti)
                          } else {
                            setNewLog({
                              kidId: id,
                              date: new Date(day),
                              checkedIn: new Date(
                                moment(day).format('YYYY-MM-DD 08:00'),
                              ),
                              checkedOut: new Date(
                                moment(day).format('YYYY-MM-DD 16:00'),
                              ),
                              absenceAvailable: true,
                              week: week.week,
                              freeAvailable: false,
                            })
                            setAbsenceForDay(false)
                          }
                        }
                      }}
                    >
                      {checkedIn && checkedOut && (
                        <>
                          {moment(checkedIn).format('HH:mm') +
                            ' - ' +
                            moment(checkedOut).format('HH:mm')}
                        </>
                      )}{' '}
                      <>
                        <span className="w-full text-center">
                          <>{t('statistics.closed')}</>
                        </span>
                      </>
                    </td>
                  )
                } else if (
                  schedule?.find((sched: any) => sched.date == day)?.from ==
                  'ledig'
                ) {
                  return (
                    <td
                      className={classNames(
                        'text-center cursor-pointer hover:bg-blue-200',
                        checkedIn && checkedOut
                          ? 'text-green-600 font-bold'
                          : 'text-blue-500',
                        exporting ? '!text-black' : '',
                      )}
                      onClick={() => {
                        if (type === 'kid') {
                          if (log) {
                            setEditLog(logMulti)
                          } else {
                            setNewLog({
                              kidId: id,
                              date: new Date(day),
                              checkedIn: new Date(
                                moment(day).format('YYYY-MM-DD 08:00'),
                              ),
                              checkedOut: new Date(
                                moment(day).format('YYYY-MM-DD 16:00'),
                              ),
                              absenceAvailable: true,
                              week: week.week,
                              freeAvailable: false,
                            })
                            setAbsenceForDay(false)
                          }
                        }
                      }}
                    >
                      {checkedIn && checkedOut && (
                        <>
                          {moment(checkedIn).format('HH:mm') +
                            ' - ' +
                            moment(checkedOut).format('HH:mm')}
                        </>
                      )}{' '}
                      <>
                        <span className="w-full text-center">
                          <>{t('statistics.offHour')}</>
                        </span>
                      </>
                    </td>
                  )
                } else if (day < moment().format('YYYY-MM-DD')) {
                  if (/*checkedIn && checkedOut*/ logMulti?.length) {
                    return (
                      <td
                        className="p-2 text-center whitespace-nowrap font-bold cursor-pointer hover:bg-blue-200"
                        onClick={() => {
                          if (type === 'kid') {
                            setEditLog(logMulti)
                          }
                        }}
                      >
                        {logMulti
                          ?.sort(
                            (a: any, b: any) =>
                              moment(a.checkedIn).valueOf() -
                              moment(b.checkedIn).valueOf(),
                          )
                          ?.map((multi: any) => {
                            return (
                              <div
                                className={classNames(
                                  multi.break
                                    ? 'text-brownBox'
                                    : 'text-green-600',
                                  exporting ? '!text-black' : '',
                                )}
                              >
                                {multi.break ? '*' : null}
                                {moment(multi.checkedIn).format('HH:mm') +
                                  ' - ' +
                                  moment(multi.checkedOut).format('HH:mm')}{' '}
                                <br />
                              </div>
                            )
                          })}
                      </td>
                    )
                  } else {
                    return (
                      <td
                        className={classNames(
                          'text-red-500 text-center font-medium cursor-pointer hover:bg-blue-200',
                          exporting ? '!text-black' : '',
                        )}
                        onClick={() => {
                          if (type === 'kid') {
                            let newCheckedIn =
                              scheduleStart && scheduleStart !== 'ledig'
                                ? moment(day).format(
                                    'YYYY-MM-DD ' + scheduleStart,
                                  )
                                : moment(day).format('YYYY-MM-DD 08:00')
                            let newCheckedOut =
                              scheduleEnd && scheduleEnd !== 'ledig'
                                ? moment(day).format(
                                    'YYYY-MM-DD ' + scheduleEnd,
                                  )
                                : moment(day).format('YYYY-MM-DD 16:00')

                            setNewLog({
                              kidId: id,
                              date: new Date(day),
                              checkedIn: new Date(newCheckedIn),
                              checkedOut: new Date(newCheckedOut),
                              freeAvailable: true,
                              week: week.week,
                              scheduleStart: scheduleStart,
                              scheduleEnd: scheduleEnd,
                            })
                          }
                        }}
                      >
                        <>{t('absence.absence')}</>
                        {/*{!exporting && (*/}
                        <table className="hideWhenExport w-full font-normal">
                          <tbody>
                            <tr>
                              <td className="text-green-600 w-full text-center block"></td>
                            </tr>
                            {scheduleStart && (
                              <tr className="">
                                {scheduleStart == 'ledig' ? (
                                  <td className="text-blue-500 w-full text-center">
                                    <>{t('statistics.offHour')}</>
                                  </td>
                                ) : (
                                  <td className="text-orange-400 w-full text-center">
                                    {exporting && (
                                      <>
                                        <br />
                                        {'('}
                                      </>
                                    )}
                                    {scheduleStart} - {scheduleEnd}
                                    {exporting && ')'}
                                  </td>
                                )}
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {/* )} */}
                      </td>
                    )
                  }
                } else {
                  let scheduleStart = schedule?.find(
                    (sched: any) => sched.date == day,
                  )?.from
                  let scheduleEnd = schedule?.find(
                    (sched: any) => sched.date == day,
                  )?.to
                  if (scheduleStart && scheduleEnd) {
                    var diffMs =
                      (moment(
                        moment(day).format('YYYY-MM-DD ' + scheduleEnd),
                      ) as any) -
                      (moment(
                        moment(day).format('YYYY-MM-DD ' + scheduleStart),
                      ) as any)

                    // TODO Should have better data type above.
                    var diffHrs = Math.floor((diffMs % 86400000) / 3600000)
                    var diffMins = Math.round(
                      ((diffMs % 86400000) % 3600000) / 60000,
                    )
                    totalTimeAttendingHoursMulti =
                      totalTimeAttendingHoursMulti + diffHrs
                    totalTimeAttendingMinutesMulti =
                      totalTimeAttendingMinutesMulti + diffMins
                    if (totalTimeAttendingMinutesMulti >= 60) {
                      totalTimeAttendingHoursMulti =
                        totalTimeAttendingHoursMulti + 1
                      totalTimeAttendingMinutesMulti =
                        totalTimeAttendingMinutesMulti - 60
                    }

                    return (
                      <td className="text-orange-400 text-center">
                        {scheduleStart} - {scheduleEnd}
                      </td>
                    )
                  } else {
                    return <td></td>
                  }
                }
              })}

              <td className="text-center font-semibold text-black">
                {/*totalTimeAttendingHours}h {totalTimeAttendingMinutes}m - EN
                <br />*/}
                {totalTimeAttendingHoursMulti}h {totalTimeAttendingMinutesMulti}
                m{/* BÅDA */}
              </td>
            </tr>
          )
        })}
        {editLog && (
          <ModalRegular
            className="overflow-visible max-w-lg"
            isOpen={editLog ? true : false}
            onClose={() => {
              setEditLog(null)
            }}
          >
            <div className="w-full flex justify-center mb-4 p-4 text-center">
              <b>
                {firstName + ' ' + lastName} -{' '}
                {editLog && moment(editLog[0].checkedIn).format('YYYY-MM-DD')}
              </b>
            </div>
            <div className="p-10 flex flex-col gap-y-10">
              <div className="flex flex-col flex-wrap w-full justify-center ">
                <div className="flex flex-col w-full justify-center relative gap-y-2">
                  {editLog
                    .filter((log: any) => !log.remove)
                    .map((log: any) => {
                      return (
                        <div className="flex justify-center flex-row items-start gap-x-2 ">
                          <div className="flex justify-center flex-col items-start ">
                            <div className="text-green-400 font-semibold ">
                              {t('statistics.checkedInTime')}
                            </div>
                            <div className="flex items-center rounded-xl">
                              {' '}
                              <div>
                                <DatePicker
                                  className="border border-gray-500 shadow p-2 py-[10px] rounded-xl text-center max-w-[140px] focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                                  selected={
                                    log?.checkedIn
                                      ? log?.checkedIn == 'ledig'
                                        ? null
                                        : log?.checkedIn
                                      : null
                                  }
                                  onChange={(date: Date) => {
                                    const updatedEditLog = editLog.map(
                                      (row: any) => {
                                        if (
                                          row.checkedInId === log.checkedInId
                                        ) {
                                          // This is the row we want to update
                                          return { ...row, checkedIn: date }
                                        } else {
                                          // This is not the row we want to update, so we return it unchanged
                                          return row
                                        }
                                      },
                                    )
                                    setEditLog(updatedEditLog)
                                  }}
                                  minTime={
                                    new Date(
                                      moment(log?.checkedIn).format(
                                        'YYYY-MM-DD 00:01',
                                      ),
                                    )
                                  }
                                  maxTime={log?.checkedOut ?? null}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={1}
                                  timeCaption="HH:mm"
                                  dateFormat="HH:mm"
                                  timeFormat="HH:mm"
                                  locale={i18n.language}
                                  onKeyDown={(e) => {
                                    e.preventDefault()
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="flex justify-center items-center">
                            <div className="flex justify-center flex-col items-start">
                              <div className="text-red-300 font-semibold ">
                                {t('statistics.checkedOutTime')}
                              </div>
                              <div className=" flex items-center rounded-xl">
                                <DatePicker
                                  className="border border-gray-500 shadow p-2 py-[10px] rounded-xl text-center max-w-[140px] focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                                  selected={log?.checkedOut ?? null}
                                  onChange={(date: Date) => {
                                    const updatedEditLog = editLog.map(
                                      (row: any) => {
                                        if (
                                          row.checkedOutId === log.checkedOutId
                                        ) {
                                          // This is the row we want to update
                                          return { ...row, checkedOut: date }
                                        } else {
                                          // This is not the row we want to update, so we return it unchanged
                                          return row
                                        }
                                      },
                                    )
                                    setEditLog(updatedEditLog)
                                  }}
                                  minTime={log?.checkedIn ?? null}
                                  maxTime={
                                    new Date(
                                      moment(log?.checkedOut).format(
                                        'YYYY-MM-DD 23:59',
                                      ),
                                    )
                                  }
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={1}
                                  timeCaption="HH:mm"
                                  dateFormat="HH:mm"
                                  timeFormat="HH:mm"
                                  locale={i18n.language}
                                  onKeyDown={(e) => {
                                    e.preventDefault()
                                  }}
                                />
                              </div>
                            </div>
                            <div className="ml-3 mt-[20px] absolute right-5 t-[20px]">
                              <Button
                                variant="fullRed"
                                size="md"
                                //label="Ta bort"
                                icon={faTrashCan}
                                onClick={() => {
                                  //removeLog(log)
                                  const updatedEditLog = editLog.map(
                                    (row: any) => {
                                      if (
                                        row.checkedOutId === log.checkedOutId
                                      ) {
                                        // This is the row we want to update
                                        return { ...row, remove: true }
                                      } else {
                                        // This is not the row we want to update, so we return it unchanged
                                        return row
                                      }
                                    },
                                  )
                                  setEditLog(updatedEditLog)
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  {/*<div className="flex justify-center items-center gap-4 mt-2">
                            <div className="flex items-center gap-4 py-2 px-4 rounded-xl">
                              <Button
                            variant="default"
                           label="Sätt som ledig"
                          onClick={() => {
                             saveLog("free")
                           }}
                        />
                              </div>
                              </div>*/}
                  <div className="w-full flex justify-center mt-5">
                    <Button
                      variant="fullBlue"
                      size="md"
                      //label="Ta bort"
                      icon={faPlus}
                      onClick={() => {
                        //removeLog(log)
                        setEditLog([
                          ...editLog,
                          {
                            new: true,
                            checkedIn: new Date(
                              moment(editLog[0].checkedIn).format(
                                'YYYY-MM-DD 08:00',
                              ),
                            ),
                            checkedInId: uuidv4(),
                            checkedOut: new Date(
                              moment(editLog[0].checkedIn).format(
                                'YYYY-MM-DD 16:00',
                              ),
                            ),
                            checkedOutId: uuidv4(),
                          },
                        ])
                        /*const updatedEditLog = editLog.map((row: any) => {
                            if (row.checkedOutId === log.checkedOutId) {
                              // This is the row we want to update
                              return { ...row, remove: true }
                            } else {
                              // This is not the row we want to update, so we return it unchanged
                              return row
                            }
                          })
                          setEditLog(updatedEditLog)*/
                      }}
                    />
                  </div>

                  <div className="w-full flex justify-center mt-8">
                    <Button
                      variant="fullGreen"
                      size="custom"
                      label="Spara"
                      onClick={() => {
                        saveLog()
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ModalRegular>
        )}

        {newLog && (
          <ModalRegular
            className="overflow-visible p-0 max-w-lg"
            isOpen={newLog ? true : false}
            onClose={() => {
              setNewLog(null)
              setFreeForDay(false)
              setAbsenceForDay(false)
            }}
          >
            <div className="w-full flex justify-center mb-4 p-4 text-center">
              <b>
                {firstName + ' ' + lastName} -{' '}
                {moment(newLog?.date).format('YYYY-MM-DD')}
              </b>
            </div>
            <div className="p-10 flex flex-col gap-y-10">
              <div className="flex flex-col flex-wrap w-full justify-center">
                <div className="flex flex-row w-full justify-center relative gap-x-2">
                  <div className={'flex justify-center flex-col items-start '}>
                    <div className="flex justify-center flex-col items-start ">
                      <div className="text-green-400 font-semibold ">
                        {t('statistics.checkedInTime')}
                      </div>
                      <div className="flex items-center rounded-xl">
                        <DatePicker
                          className="border border-gray-500 shadow p-2 py-[10px] rounded-xl text-center max-w-[140px] focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                          selected={
                            newLog && newLog?.checkedIn
                              ? newLog?.checkedIn
                              : new Date(
                                  moment(newLog?.date).format(
                                    'YYYY-MM-DD 08:00',
                                  ),
                                )
                          }
                          onChange={(date: Date) =>
                            setNewLog({ ...newLog, checkedIn: date })
                          }
                          minTime={
                            new Date(
                              moment(newLog?.checkedIn).format(
                                'YYYY-MM-DD 00:01',
                              ),
                            )
                          }
                          disabled={freeForDay}
                          maxTime={newLog?.checkedOut ?? null}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={1}
                          timeCaption="HH:mm"
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                          locale={i18n.language}
                          onKeyDown={(e) => {
                            e.preventDefault()
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center items-center">
                    <div className="flex justify-center flex-col items-start">
                      <div className="text-red-300 font-semibold ">
                        {t('statistics.checkedOutTime')}
                      </div>
                      <div className=" flex items-center rounded-xl">
                        <DatePicker
                          className="border border-gray-500 shadow p-2 py-[10px] rounded-xl text-center max-w-[140px] focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                          selected={
                            newLog && newLog?.checkedOut
                              ? newLog?.checkedOut
                              : new Date(
                                  moment(newLog?.date).format(
                                    'YYYY-MM-DD 16:00',
                                  ),
                                )
                          }
                          disabled={freeForDay}
                          onChange={(date: Date) =>
                            setNewLog({ ...newLog, checkedOut: date })
                          }
                          minTime={newLog?.checkedIn ?? null}
                          maxTime={
                            new Date(
                              moment(newLog?.checkedOut).format(
                                'YYYY-MM-DD 23:59',
                              ),
                            )
                          }
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={1}
                          timeCaption="HH:mm"
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                          locale={i18n.language}
                          onKeyDown={(e) => {
                            e.preventDefault()
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex items-end">
                    {newLog.absenceAvailable ? (
                      !absenceForDay ? (
                        <Button
                          variant={'squaredShadow'}
                          label={t('absence.absence')}
                          size="md"
                          onClick={() => {
                            setAbsenceForDay(true)
                          }}
                        />
                      ) : (
                        <Button
                          className="text-white"
                          variant={'squaredShadowAccept'}
                          label={t('absence.absence')}
                          icon={faCheck}
                          reverseIcon
                          size="md"
                          onClick={() => {
                            setAbsenceForDay(false)
                          }}
                        />
                      )
                    ) : null}
                    {newLog.freeAvailable ? (
                      !freeForDay ? (
                        <Button
                          variant={'squaredShadow'}
                          label={t('statistics.offHour')}
                          size="md"
                          onClick={() => {
                            setFreeForDay(true)
                          }}
                        />
                      ) : (
                        <Button
                          className="text-white"
                          variant={'squaredShadowAccept'}
                          label={t('statistics.offHour')}
                          icon={faCheck}
                          reverseIcon
                          size="md"
                          onClick={() => {
                            setFreeForDay(false)
                          }}
                        />
                      )
                    ) : null}
                  </div>
                </div>
              </div>
              {newLog.scheduleStart && newLog.scheduleEnd && (
                <div className=" text-gray-500 font-semibold pl-5">
                  <>{t('statistics.accordingSchedule')}: </>
                  {newLog && newLog?.scheduleStart
                    ? newLog.scheduleStart
                    : '08:30'}{' '}
                  -{' '}
                  {newLog && newLog?.scheduleEnd ? newLog.scheduleEnd : '16:30'}
                </div>
              )}
              <div className="w-full flex justify-end">
                <Button
                  size="custom"
                  variant="fullBlue"
                  label={t('general.save')}
                  onClick={() => {
                    if (freeForDay) {
                      setFreeOnSchedule(
                        id,
                        newLog?.checkedIn,
                        newLog?.week,
                      ).finally(() => {
                        fetch()
                        setNewLog(null)
                        setFreeForDay(false)
                        setAbsenceForDay(false)
                      })
                    } else if (absenceForDay) {
                      setAbsenceOnSchedule(
                        id,
                        newLog?.checkedIn,
                        newLog?.week,
                      ).finally(() => {
                        fetch()
                        setNewLog(null)
                        setFreeForDay(false)
                        setAbsenceForDay(false)
                      })
                    } else {
                      createLog().finally(() => {
                        setNewLog(null)
                        setFreeForDay(false)
                        setAbsenceForDay(false)
                      })
                    }
                  }}
                />
              </div>
            </div>
          </ModalRegular>
        )}
      </>
    )
  },
)
