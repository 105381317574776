import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import CustomInput from '../../../components/CustomInput'
import { Button } from '../../../components/Button'
import {
  CreateEventParams,
  EVENT3_TYPE,
  EVENT3_USER_FOR,
} from '../../../store/event3Store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowDown,
  faArrowUp,
  faCalendarDays,
  faLocationDot,
  faLocationPin,
  faRepeat,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { faFilePlus, faLocationPlus } from '@fortawesome/pro-solid-svg-icons'

export enum LOCATION_TYPE {
  FREE_TEXT = 'freetext',
  COORD = 'coord',
  ADDRESS = 'address',
  SAVED = 'saved',
}

interface Props {
  newEvent: CreateEventParams
  onChange: (e: CreateEventParams) => void
  onDone: () => void // hämta värdena och visa från riktig db
  onSave: () => void // spara värdena
  onClick: () => void
  openModalForCreatingNewLocation?: boolean
  setOpenModalForCreatingNewLocation?: (value: boolean) => void
}

export const LocationToEvent: React.FC<Props> = observer((props: Props) => {
  const [openSelections, setOpenSelections] = useState(false)
  const { t } = useTranslation()
  const [showCreateAlternative, setShowCreateAlternative] = useState<boolean>()

  return (
    <div
      onClick={() => {
        if (!props.newEvent.location) {
          setOpenSelections(!openSelections)
        }
      }}
      className="w-full relative"
    >
      <div className="flex justify-between gap-y-1 items-center">
        <div className="font-semibold flex items-center">
          {/*<>{t('events.addPlace')}</>*/}
          <FontAwesomeIcon
            icon={faLocationDot}
            className="text-[#6BBD73] text-xl p-1"
          />
          {t('events.place') + ': '}{' '}
          {!props.newEvent.location && (
            <p className=" ml-2 font-normal">
              {' '}
              !!create new or choose from saved
            </p>
          )}
        </div>
        {props.newEvent.location && (
          <Button
            variant="fullRed"
            size="sm"
            icon={faTrashCan}
            onClick={() => {
              props.onChange({
                ...props.newEvent,
                location: undefined,
                locationType: LOCATION_TYPE.SAVED,
              })
            }}
          />
        )}
      </div>
      <div className="flex pl-2 mt-1">
        {props.newEvent.location ? (
          <div className="flex flex-row gap-x-2 items-center">
            {props.newEvent.location.name}{' '}
          </div>
        ) : (
          <div className={`flex gap-x-1 w-full mt-2 justify-between`}>
            <Button
              label={t('general.createNew')}
              variant="custom"
              textColor="text-black"
              bgrondColor="bg-[#F6F6F6]"
              size="sm"
              fontSize="font-semibold"
              onClick={() => {
                setShowCreateAlternative(!showCreateAlternative)
              }}
            />

            <Button
              label={t('events.chooseSavedPlace')}
              variant="custom"
              bgrondColor="bg-[#F6F6F6]"
              size="sm"
              fontSize="font-semibold"
              onClick={() => {
                props.onClick()
                props.onChange({
                  ...props.newEvent,
                  locationType: LOCATION_TYPE.SAVED,
                })
              }}
            />
          </div>
        )}
      </div>
      {showCreateAlternative && (
        <div className="flex flex-col animate-fadeIn duration-300 gap-4 ml-2 mt-3 absolute left-0 top-full z-50">
          <Button
            className="z-40"
            label={t('events.addAdress')}
            variant="custom"
            textColor="text-black"
            bgrondColor="bg-[#F6F6F6]"
            size="sm"
            fontSize="font-semibold"
            onClick={() => {
              props.onClick()
              props.onChange({
                ...props.newEvent,
                locationType: LOCATION_TYPE.ADDRESS,
              })
              setShowCreateAlternative(!showCreateAlternative)
            }}
          />
          <Button
            className="z-40"
            label={t('events.freeTextPlace')}
            variant="custom"
            textColor="text-black"
            bgrondColor="bg-[#F6F6F6]"
            size="sm"
            fontSize="font-semibold"
            onClick={() => {
              props.onClick()
              props.onChange({
                ...props.newEvent,
                locationType: LOCATION_TYPE.FREE_TEXT,
              })
              setShowCreateAlternative(!showCreateAlternative)
            }}
          />
          <Button
            className="z-40"
            label={t('events.coordinates')}
            variant="custom"
            textColor="text-black"
            bgrondColor="bg-[#F6F6F6]"
            size="sm"
            fontSize="font-semibold"
            onClick={() => {
              props.onClick()
              props.onChange({
                ...props.newEvent,
                locationType: LOCATION_TYPE.COORD,
              })
              setShowCreateAlternative(!showCreateAlternative)
            }}
          />
        </div>
      )}
      {showCreateAlternative && (
        <div
          className="fixed inset-0 bg-black bg-opacity-40 z-40"
          onClick={() => setShowCreateAlternative(false)}
        ></div>
      )}
    </div>
  )
})
