import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { Spinner } from '../../components/Spinner'
import Page from '../../components/Page'
import { UserStoreContext } from '../../store/userStore'
import { NavigationStoreContext } from '../../store/navigationStore'
import { useTranslation } from 'react-i18next'
import { StatisticStoreContext } from '../../store/statisticStore'
import DatePicker, { registerLocale } from 'react-datepicker'
import sv from 'date-fns/locale/sv'
import en from 'date-fns/locale/en-GB'
import es from 'date-fns/locale/es'
import moment from 'moment'
import { Button } from '../../components/Button'
import { KidStoreContext } from '../../store/kidStore'
import Singleselect from '../../components/Singleselect'
import { KidsStoreContext } from '../../store/kidsStore'
import { getImageFromRole, getImageOrPlaceHolder } from '../../utils/parseUtils'
import { Kid } from '../../Models/Kid'
import { t } from 'i18next'
import ModalRegular from '../../components/ModalRegular'
import Parse from 'parse'
import {
  NotificationContext,
  NotificationVariant,
} from '../../App/notification/notificationManger'
import classNames from 'classnames'
import { Loading } from '../../components/Loading'
import Swal from 'sweetalert2'
import { faCheck, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import ColorCard from '../../components/ColorCard'
import { SORT_VALUES } from './Statistics'
import { v4 as uuidv4 } from 'uuid'
import { PresenceScheduleDiffContainer } from './components/PresenceScheduleDiffContainer'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { ShowWeek } from './components/ShowWeek'
registerLocale('sv', sv)
registerLocale('en', en)
registerLocale('es', es)

export const StatisticsDetailTeacher: React.FC = observer(() => {
  let { schoolId, userRoleId } = useParams()
  const { setTitle } = useContext(NavigationStoreContext)
  const { t, i18n } = useTranslation()
  const { fetchKid, kid } = useContext(KidStoreContext)
  const { saveKidTariff } = useContext(KidsStoreContext)
  const [historicSchemaCsvnExport, setHistoricSchemaCsvnExport] =
    useState<boolean>(false)
  const {
    getStatisticsTeacher,
    statisticsTeacherDetailed,
    statisticsFromDate,
    setStatisticsFromDate,
    statisticsToDate,
    setStatisticsToDate,
    statisticsWeeksOnly,
    setStatisticsWeeksOnly,
  } = useContext(StatisticStoreContext)
  const isMonday = (date: Date) => {
    const day = date.getDay()
    if (!statisticsWeeksOnly) return day !== 1337
    return (
      day !== 2 && day !== 3 && day !== 4 && day !== 5 && day !== 6 && day !== 0
    )
  }
  const isSunday = (date: Date) => {
    const day = date.getDay()
    if (!statisticsWeeksOnly) return day !== 1337
    return (
      day !== 2 && day !== 3 && day !== 4 && day !== 5 && day !== 6 && day !== 1
    )
  }
  /*const [fromDate, setFromDate] = useState<Date | null>(
    new Date(
      moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'),
    ),
  )
  const [toDate, setToDate] = useState<Date | null>(
    new Date(moment().endOf('week').format('YYYY-MM-DD')),
  )*/
  const {
    savedUserRoleId,
    fetchUserRole,
    fetchTeacherUserRole,
    currentUserRole,
    teacherUserRole,
  } = useContext(UserStoreContext)
  const [loading, setLoading] = useState(true)
  let [firstDate, setFirstDate] = useState(moment().startOf('week'))
  let [lastDate, setLastDate] = useState(moment().endOf('week'))
  const [amountOfDays, setAmountOfDays] = useState(7)
  const [typeOfView, setTypeOfView] = useState<'day' | 'oneWeek' | 'month'>(
    'oneWeek',
  )

  useEffect(() => {
    if (savedUserRoleId && schoolId) {
      /*getStatistics(
        savedUserRoleId,
        fromDate,
        toDate,
        selectedDepartments,
        selectedKids,
        schoolId,
      )*/

      setLoading(true)
      fetchUserRole(savedUserRoleId).finally(() => {})
      setTitle(t('menu.attendance'))
    }
  }, [])

  const [exporting, setExporting] = useState(false)
  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay))
  }

  const generatePdf = async (type: 'p' | 'l') => {
    setExporting(true)
    await timeout(1000)
    const doc = new jsPDF(type)

    /*autoTable(doc, {
      //startY: 30,

      //html: showSum ? '#my-table-header' : '#my-table-header' + i,
      html: '#my-table-header',
      //theme: 'striped',
      useCss: true,
      includeHiddenHtml: false,
      //styles: { cellPadding: 10 },
    })*/

    autoTable(doc, {
      //startY: 30,
      //html: showSum ? '#my-table' : '#my-table' + i,
      html: '#my-table',
      //theme: 'striped',
      useCss: true,
      includeHiddenHtml: false,
      didDrawPage: function (data) {
        // @ts-ignore
        const pageCount = doc.internal.getNumberOfPages()

        doc.setFontSize(8)

        var pageSize = doc.internal.pageSize
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight()
      },
    })

    let docName = ''

    docName =
      'tyra-export-weekly_' +
      moment(statisticsFromDate).format('YYYY-MM-DD') +
      '-' +
      moment(statisticsToDate).format('YYYY-MM-DD') +
      '.pdf'

    doc.save(docName)

    setExporting(false)
  }

  const generateCsvSum = async () => {
    let rows: any = []
    let headers: any[] = []
    headers.push(t('statistics.kidName'))
    headers.push(t('statistics.totalScheduleTime'))
    if (
      moment(statisticsFromDate).format('YYYY-MM-DD') <
      moment().format('YYYY-MM-DD')
    ) {
      headers.push(t('statistics.totalPresence'))
      headers.push(t('statistics.totalDiff'))
    }
    headers.push(t('statistics.schedulePerWeek'))

    if (
      moment(statisticsFromDate).format('YYYY-MM-DD') <
      moment().format('YYYY-MM-DD')
    ) {
      headers.push(t('statistics.presencePerWeek')) // Per vecka
      headers.push(t('statistics.diffPerWeek'))
    }

    headers.push(t('statistics.schedulePerDay'))

    if (
      moment(statisticsFromDate).format('YYYY-MM-DD') <
      moment().format('YYYY-MM-DD')
    ) {
      headers.push(t('statistics.presencePerDay')) // Per dag
      headers.push(t('statistics.diffPerDay'))
      //}
    }

    headers.push(t('statistics.offHour'))
    //headers.push(t('statistics.tariff'))

    rows.push(headers)

    let rowElements = [statisticsTeacherDetailed.name]
    rowElements.push(
      statisticsTeacherDetailed.scheduleTotal?.hours +
        'h ' +
        statisticsTeacherDetailed.scheduleTotal?.minutes +
        'm',
    )
    if (
      moment(statisticsFromDate).format('YYYY-MM-DD') <
      moment().format('YYYY-MM-DD')
    ) {
      rowElements.push(
        statisticsTeacherDetailed.timeAttendingTotal?.hours +
          'h ' +
          statisticsTeacherDetailed.timeAttendingTotal?.minutes +
          'm',
      )
      rowElements.push(
        statisticsTeacherDetailed.timeDifferenceTotal?.hours +
          'h ' +
          statisticsTeacherDetailed.timeDifferenceTotal?.minutes +
          'm',
      )
    }

    rowElements.push(
      statisticsTeacherDetailed.scheduleHoursPerWeek?.hours +
        'h ' +
        statisticsTeacherDetailed.scheduleHoursPerWeek?.minutes +
        'm',
    )

    if (
      moment(statisticsFromDate).format('YYYY-MM-DD') <
      moment().format('YYYY-MM-DD')
    ) {
      rowElements.push(
        statisticsTeacherDetailed.timeAttending?.hours +
          'h ' +
          statisticsTeacherDetailed.timeAttending?.minutes +
          'm',
      )
      rowElements.push(
        statisticsTeacherDetailed.timeDifference?.hours +
          'h ' +
          statisticsTeacherDetailed.timeDifference?.minutes +
          'm',
      )
    }

    rowElements.push(
      statisticsTeacherDetailed.schedulePerDay?.hours +
        'h ' +
        statisticsTeacherDetailed.schedulePerDay?.minutes +
        'm',
    )
    if (
      moment(statisticsFromDate).format('YYYY-MM-DD') <
      moment().format('YYYY-MM-DD')
    ) {
      rowElements.push(
        statisticsTeacherDetailed.timeAttendingPerDay?.hours +
          'h ' +
          statisticsTeacherDetailed.timeAttendingPerDay?.minutes +
          'm',
      )
      rowElements.push(
        statisticsTeacherDetailed.timeDifferencePerDay?.hours +
          'h ' +
          statisticsTeacherDetailed.timeDifferencePerDay?.minutes +
          'm',
      )
    }

    rowElements.push(statisticsTeacherDetailed.scheduleTotal?.totalFree)
    /*let tariff = statistics.tariff || ''
    rowElements.push(tariff)*/
    rows.push(rowElements)
    //})

    let csvContent = 'data:text/csv;charset=utf-8,'

    rows.forEach(function (rowArray: any) {
      let row = Array.isArray(rowArray) ? rowArray.join(',') : rowArray
      csvContent += row + '\r\n'
    })

    var encodedUri = encodeURI(csvContent)
    var hiddenElement = document.createElement('a')
    hiddenElement.href = encodedUri
    hiddenElement.target = '_blank'
    hiddenElement.download =
      'summery-export_' +
      statisticsTeacherDetailed.name +
      '_' +
      moment(statisticsFromDate).format('YYYY-MM-DD') +
      '-' +
      moment(statisticsToDate).format('YYYY-MM-DD') +
      '.csv'
    hiddenElement.click()
  }

  const generateDays = (week: any) => {
    let weeks: any[] = []
    let firstDateOfWeek = moment(week.fromDate).startOf('week')
    let firstDateToAdd = moment(week.fromDate).startOf('week')
    /*for (var d = firstDateToAdd; d < lastDate; firstDateToAdd.add(1, 'days')) {
      if (!weeks.find((wn) => wn.week == moment(firstDateToAdd).get('week'))) {*/
    const days: Array<any> = []
    for (var x = 0; x < 7; x++) {
      days.push(firstDateOfWeek.format('YYYY-MM-DD'))
      firstDateOfWeek.add(1, 'days')
    }
    //weeks.push({ week: moment(firstDate).get('week'), days })
    //}
    //moment(firstDate).add(1, 'days');
    //}
    return { week, days }
  }
  const generateCsv = async () => {
    let rows: any = []
    let firstDateOfWeek = moment(statisticsToDate).startOf('week')
    let weeks: any[] = []
    const firstDate1 = moment(statisticsFromDate).startOf('week')
    const lastDate1 = moment(statisticsToDate).endOf('week')
    for (var d = firstDate1; d < lastDate1; firstDate1.add(1, 'days')) {
      if (!weeks.find((wn) => wn.week == moment(firstDate1).get('week'))) {
        const days: Array<any> = []
        for (var x = 0; x < 7; x++) {
          days.push(firstDateOfWeek.format('YYYY-MM-DD'))
          firstDateOfWeek.add(1, 'days')
        }
        weeks.push({
          week: moment(firstDate1).get('week'),
          days,
          fromDate: moment(firstDate1).startOf('week'),
          toDate: moment(firstDate1).endOf('week'),
        })
      }
    }

    /*if (index > 0) {
        rows.push([''])
      }
      rows.push([dep.klassName])
      rows.push([''])*/
    weeks.forEach((week, index) => {
      if (index > 0) {
        rows.push([''])
      }

      rows.push([
        t('general.week') +
          ';' +
          t('days.monday') +
          ';' +
          t('days.tuesday') +
          ';' +
          t('days.wednesday') +
          ';' +
          t('days.thursday') +
          ';' +
          t('days.friday') +
          ';' +
          t('general.total') +
          ';',
      ])
      //}

      let totalTimeAttendingHours = 0
      let totalTimeAttendingMinutes = 0
      let totalTimeAttendingHoursMulti = 0
      let totalTimeAttendingMinutesMulti = 0
      const kidWeek = generateDays(week)
      let row = ''
      row = row + week.week + ' - ' + moment(week.fromDate).format('YYYY')

      teacherUserRole &&
        kidWeek.days?.forEach((day: any) => {
          let log = statisticsTeacherDetailed.logs?.find(
            (log: any) =>
              moment(log.checkedIn).format('YYYY-MM-DD') === day &&
              moment(log.checkedOut).format('YYYY-MM-DD') === day,
          )
          let logMulti = statisticsTeacherDetailed.logs
            ?.filter((log: any) => !log.break)
            .filter(
              (log: any) =>
                moment(log.checkedIn).format('YYYY-MM-DD') === day &&
                moment(log.checkedOut).format('YYYY-MM-DD') === day,
            )

          for (const row of logMulti ?? []) {
            if (row.checkedIn && row.checkedOut) {
              var diffMs =
                (moment(
                  moment(row.checkedOut).format('YYYY-MM-DD HH:mm'),
                ) as any) -
                (moment(
                  moment(row.checkedIn).format('YYYY-MM-DD HH:mm'),
                ) as any)
              // TODO Should have better data type above.
              var diffHrs = Math.floor((diffMs % 86400000) / 3600000)
              var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000)
              totalTimeAttendingHoursMulti =
                totalTimeAttendingHoursMulti + diffHrs
              totalTimeAttendingMinutesMulti =
                totalTimeAttendingMinutesMulti + diffMins
              if (totalTimeAttendingMinutesMulti >= 60) {
                totalTimeAttendingHoursMulti = totalTimeAttendingHoursMulti + 1
                totalTimeAttendingMinutesMulti =
                  totalTimeAttendingMinutesMulti - 60
              }
            }
          }
          let checkedIn = statisticsTeacherDetailed.logs?.find(
            (log: any) => moment(log.checkedIn).format('YYYY-MM-DD') === day,
          )?.checkedIn
          let checkedOut = statisticsTeacherDetailed.logs?.find(
            (log: any) => moment(log.checkedOut).format('YYYY-MM-DD') === day,
          )?.checkedOut

          if (checkedIn && checkedOut) {
            var diffMs = (checkedOut as any) - (checkedIn as any)
            // TODO Should have better data type above.
            var diffHrs = Math.floor((diffMs % 86400000) / 3600000)
            var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000)
            totalTimeAttendingHours = totalTimeAttendingHours + diffHrs
            totalTimeAttendingMinutes = totalTimeAttendingMinutes + diffMins
          }

          let scheduleStart = statisticsTeacherDetailed.schedule?.find(
            (sched: any) => sched.date == day,
          )?.from
          let scheduleEnd = statisticsTeacherDetailed.schedule?.find(
            (sched: any) => sched.date == day,
          )?.to
          if (moment(day).day() === 6) {
            row =
              row +
              ';' +
              totalTimeAttendingHoursMulti +
              'h ' +
              totalTimeAttendingMinutesMulti +
              'm'
          }
          if (moment(day).day() == 0 || moment(day).day() == 6) {
            //row = row + ';' + ''
            return
          }

          if (
            moment(day).format('YYYY-MM-DD') <
            moment(statisticsFromDate).format('YYYY-MM-DD')
          ) {
            row = row + ';' + ''
            return
          }

          if (
            moment(day).format('YYYY-MM-DD') >
            moment(statisticsToDate).format('YYYY-MM-DD')
          ) {
            row = row + ';' + ''
            return
          }

          if (checkedIn && checkedOut) {
            row =
              row +
              ';' +
              logMulti
                ?.sort(
                  (a: any, b: any) =>
                    moment(a.checkedIn).valueOf() -
                    moment(b.checkedIn).valueOf(),
                )
                ?.map((multi: any, index: number) => {
                  if (index > 0) {
                    return (
                      '\u2028' +
                      moment(multi.checkedIn).format('HH:mm') +
                      ' - ' +
                      moment(multi.checkedOut).format('HH:mm')
                    )
                  } else {
                    return (
                      moment(multi.checkedIn).format('HH:mm') +
                      ' - ' +
                      moment(multi.checkedOut).format('HH:mm')
                    )
                  }
                })
            if (
              scheduleStart &&
              scheduleEnd &&
              scheduleStart !== 'ledig' &&
              historicSchemaCsvnExport
            ) {
              row =
                row + '\u2028' + '(' + scheduleStart + ' - ' + scheduleEnd + ')'
            }
            return
          }

          if (
            statisticsTeacherDetailed.closedDates?.find(
              (cD: string) => cD === moment(day).format('YYYY-MM-DD'),
            )
          ) {
            row = row + ';' + t('statistics.closed')
            return
          }

          if (scheduleStart == 'ledig') {
            row = row + ';' + t('statistics.offHour')
            return
          }

          if (day >= moment().format('YYYY-MM-DD')) {
            if (scheduleStart && scheduleEnd) {
              row = row + ';' + scheduleStart + ' - ' + scheduleEnd
              return
            }
          }

          if (day < moment().format('YYYY-MM-DD')) {
            if (checkedIn && checkedOut) {
              row =
                row +
                ';' +
                moment(checkedIn).format('HH:mm') +
                ' - ' +
                moment(checkedOut).format('HH:mm')
              if (
                scheduleStart &&
                scheduleEnd &&
                scheduleStart !== 'ledig' &&
                historicSchemaCsvnExport
              ) {
                row = row + ' ( ' + scheduleStart + ' - ' + scheduleEnd + ' )'
              }
              return
            } else {
              row = row + ';' + t('absence.absence')
              if (
                scheduleStart &&
                scheduleEnd &&
                scheduleStart !== 'ledig' &&
                historicSchemaCsvnExport
              ) {
                row = row + ' ( ' + scheduleStart + ' - ' + scheduleEnd + ' )'
              }
              return
            }
          }

          row = row + ';' + ''
        })

      rows.push([row])
      //})
    })

    let csvContent = 'data:text/csv;charset=utf-8,'

    rows.forEach(function (rowArray: any) {
      let row = rowArray.join(';')
      csvContent += row + '\r\n'
    })
    var encodedUri = encodeURI(csvContent)
    var hiddenElement = document.createElement('a')
    hiddenElement.href = encodedUri
    hiddenElement.target = '_blank'
    hiddenElement.download =
      'weekly-export_' +
      statisticsTeacherDetailed.name +
      '_' +
      moment(statisticsFromDate).format('YYYY-MM-DD') +
      '-' +
      moment(statisticsToDate).format('YYYY-MM-DD') +
      '.csv'
    hiddenElement.click()
    //window.open(encodedUri)
  }

  useEffect(() => {
    if (userRoleId) {
      setLoading(true)
      fetchTeacherUserRole(userRoleId).then(() => {})
    }
  }, [userRoleId])

  useEffect(() => {
    if (savedUserRoleId && teacherUserRole && schoolId) {
      getStatisticsTeacher(
        savedUserRoleId,
        [],
        [teacherUserRole.id],
        schoolId,
        false,
        true,
      ).finally(() => {
        setLoading(false)
      })
    }
  }, [savedUserRoleId, teacherUserRole, schoolId])

  const onChangeFromDate = (date: Date) => {
    setStatisticsFromDate(date)
    getData(date, null)
  }

  const onChangeToDate = (date: Date) => {
    setStatisticsToDate(date)
    getData(null, date)
  }

  const getData = (
    fromDateSelected: Date | null = null,
    toDateSelected: Date | null = null,
  ) => {
    if (savedUserRoleId && teacherUserRole && schoolId) {
      setLoading(true)
      if (fromDateSelected) {
        getStatisticsTeacher(
          savedUserRoleId,
          [],
          [teacherUserRole.id],
          schoolId,
          false,
          true,
        ).finally(() => {
          setLoading(false)
        })
      } else if (toDateSelected) {
        getStatisticsTeacher(
          savedUserRoleId,
          [],
          [teacherUserRole.id],
          schoolId,
          false,
          true,
        ).finally(() => {
          setLoading(false)
        })
      } else {
        getStatisticsTeacher(
          savedUserRoleId,
          [],
          [teacherUserRole.id],
          schoolId,
          false,
          true,
        ).finally(() => {
          setLoading(false)
        })
      }
    }
  }
  const [focusRing1, setFocusRing1] = useState(false)
  const [focusRing2, setFocusRing2] = useState(false)
  {
    /* if (loading) {
    return <Loading />
  }*/
  }
  /*
  Total tid valda datum istället för genomsnitt per dag
  */
  if (loading) {
    return <Loading />
  }
  return (
    <Page naked>
      {loading && <Loading />}
      <div className=" mb-24">
        {/*<Button
          loading={loading}
          type="submit"
          variant="primary"
          label={'Hämta data'}
          onClick={() => getData()}
          //disabled={isSubmitting}
  />*/}

        <ColorCard
          titlePicture={
            <div className="flex flex-row items-center gap-x-2">
              <img
                className={'w-[60px] h-[60px] rounded-full'}
                src={getImageFromRole(teacherUserRole)}
                alt=""
              />
              <p className={'font-semibold text-white text-lg'}>
                {statisticsTeacherDetailed.name}
                <br></br>
                {statisticsTeacherDetailed.ssn}
              </p>
            </div>
          }
          bgColor="bg-eventsMain"
          actionButton={
            <div className="flex justify-center bg-white rounded-lg  w-fitt p-1 mt-2 sm:mr-10">
              <div className="flex justify-center items-center flex-col sm:flex-row gap-x-4">
                <div className="relative">
                  <DatePicker
                    className="border border-gray-200 hover:border-eventsMain text-xs sm:text-sm cursor-pointer  max-w-[100px] p-[1px] sm:p-2 rounded-lg text-center focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                    dateFormat="yyyy-MM-dd"
                    locale={i18n.language}
                    selected={statisticsFromDate as unknown as Date}
                    onChange={(date: Date) => {
                      onChangeFromDate(date)
                      setFocusRing1(false)
                    }}
                    onFocus={() => setFocusRing1(true)}
                    onBlur={() => {
                      setFocusRing1(false)
                    }}
                    filterDate={isMonday}
                    onKeyDown={(e) => {
                      e.preventDefault()
                    }}
                  />
                </div>
                <div className="text-gray-600 font-normal text-xs sm:text-sm">
                  {t('general.to')}
                </div>
                <div className="relative">
                  <DatePicker
                    className="border border-gray-200 hover:border-eventsMain text-xs sm:text-sm cursor-pointer  max-w-[100px] p-[1px] sm:p-2 rounded-lg text-center focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                    dateFormat="yyyy-MM-dd"
                    locale={i18n.language}
                    selected={statisticsToDate as unknown as Date}
                    onChange={(date: Date) => {
                      onChangeToDate(date)
                      setFocusRing2(false)
                    }}
                    onFocus={() => setFocusRing2(true)}
                    onBlur={() => {
                      setFocusRing2(false)
                    }}
                    filterDate={isSunday}
                    onKeyDown={(e) => {
                      e.preventDefault()
                    }}
                  />
                </div>
              </div>
            </div>
          }
        >
          <div className="flex flex-col w-full justify-center h-full p-6">
            <div>
              <div className="flex justify-end gap-x-4 mt-[-15px] mb-[20px] relative">
                <Button
                  size="xs"
                  variant="fullGray"
                  label={t('statistics.exportLandscape')}
                  onClick={() => generatePdf('l')}
                />
                <Button
                  size="xs"
                  variant="fullGray"
                  label={t('statistics.exportPortrait')}
                  onClick={() => generatePdf('p')}
                />

                {/* Day by day CSV*/}
                <Button
                  size="xs"
                  variant="fullGray"
                  label={t('contactList.exportCSV')}
                  onClick={() => {
                    generateCsv()
                  }}
                />
                <div className="absolute bottom-[-25px] right-0 text-xs font-normal justify-end">
                  <label className="flex flex-row gap-x-2 justify-end">
                    <>{t('statistics.includeHistoricSchemaCsvExport')}</>
                    <input
                      id="showSsn"
                      type="checkbox"
                      checked={historicSchemaCsvnExport}
                      //className="hidden-checkbox"
                      onChange={() =>
                        setHistoricSchemaCsvnExport(!historicSchemaCsvnExport)
                      }
                    />
                  </label>{' '}
                </div>
              </div>
              <div className="flex flex-row flex-wrap w-full cursor-default justify-center gap-x-6 gap-y-4 mb-5">
                <div className="min-w-[300px] max-w-[500px]">
                  <div className="px-1">
                    <h4 className="font-bold text-sm">{t('general.total')}</h4>
                    <div className="mt-[14px]"></div>
                  </div>
                  <PresenceScheduleDiffContainer
                    presence={statisticsTeacherDetailed?.timeAttendingTotal}
                    schema={statisticsTeacherDetailed?.scheduleTotal}
                    diff={statisticsTeacherDetailed?.timeDifferenceTotal}
                    compact={false}
                    showHeader={false}
                    type="teacher"
                    teacherBreak={statisticsTeacherDetailed?.timeBreakTotal}
                  />
                </div>
                {!statisticsWeeksOnly ? (
                  <div className="min-w-[300px] max-w-[500px]">
                    <div className="flex flex-col gap-x-4 w-full px-1 justify-between items-left text-left">
                      <h4 className="font-bold text-sm">
                        {t('statistics.averagePerWeek')}
                      </h4>
                      <span className="text-2xs">
                        <b>
                          {' '}
                          <>{t('statistics.obs')}</>
                        </b>{' '}
                        <>{t('statistics.basedOnAveragePerWeek')}</>
                      </span>
                    </div>
                    <PresenceScheduleDiffContainer
                      presence={statisticsTeacherDetailed?.timeAttending}
                      schema={statisticsTeacherDetailed?.scheduleHoursPerWeek}
                      diff={statisticsTeacherDetailed?.timeDifference}
                      compact={false}
                      showHeader={false}
                      type="teacher"
                      teacherBreak={statisticsTeacherDetailed?.timeBreakPerWeek}
                    />
                  </div>
                ) : (
                  <div className="min-w-[300px] max-w-[500px]">
                    <div className="flex flex-row gap-x-4 w-full px-1 justify-between items-end text-end">
                      <h4 className="font-bold text-sm">
                        {t('statistics.averagePerDay')}
                      </h4>
                    </div>
                    <div className="text-xl shadow border overflow-hidden  rounded-xl grid-cols-1 ">
                      <div className="w-full text-xs p-4 font-medium flex-row flex justify-between ">
                        <div className="flex flex-col">
                          <b>{t('general.presence')}:</b>
                          <div className="text-green-500">
                            {statisticsTeacherDetailed?.timeAttendingPerDay &&
                              statisticsTeacherDetailed?.timeAttendingPerDay
                                .hours +
                                'h' +
                                ' ' +
                                statisticsTeacherDetailed?.timeAttendingPerDay
                                  .minutes +
                                'm'}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <b>{t('menu.scheme')}:</b>
                          <div className="text-orange-400">
                            {statisticsTeacherDetailed?.schedulePerDay &&
                              statisticsTeacherDetailed?.schedulePerDay.hours +
                                'h' +
                                ' ' +
                                statisticsTeacherDetailed?.schedulePerDay
                                  .minutes +
                                'm'}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <b>Diff:</b>{' '}
                          <div
                            className={
                              statisticsTeacherDetailed?.timeDifferencePerDay &&
                              statisticsTeacherDetailed?.timeDifferencePerDay
                                .negative
                                ? 'text-red-500'
                                : 'text-green-600'
                            }
                          >
                            {statisticsTeacherDetailed?.timeDifferencePerDay &&
                              statisticsTeacherDetailed?.timeDifferencePerDay
                                .hours +
                                'h' +
                                ' ' +
                                statisticsTeacherDetailed?.timeDifferencePerDay
                                  .minutes +
                                'm'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/*<div>
                  <div className="px-1">
                    <h4 className="font-bold text-sm">
                      {t('statistics.tariff')}
                    </h4>
                  </div>
                  <div className="text-xl  shadow border rounded-xl grid-cols-1 mt-[14px]">
                    <div className="w-full text-center justify-center py-[7px] px-6 text-xl ">
                      <Singleselect
                        dropdownClassName="w-[295px] -right-[25px] rounded-full flex flex-col w-full border cursor-pointer"
                        removeSelected
                        hasBorder
                        onChange={(e: any) => {
                          
                          //setFieldValue('type', e ? e.label : null)
                          if (kid) {
                            saveKidTariff(kid, Number(e.value))
                          }

                          if (kidId) {
                            fetchKid(kidId, true)
                          }
                        }}
                        items={tariffValues}
                        initialSelectedItem={
                          kid && kid.tariff
                            ? tariffValues.find(
                                (v) => Number(v.value) == Number(kid.tariff),
                              )
                            : {
                                value: null,
                                label: t('statistics.tariffNothingSelected'),
                              }
                        }
                        itemVariableKey={'label'}
                        placeholder={t('statistics.tariff')}
                      />
                    </div>
                  </div>
                </div>*/}
              </div>
            </div>

            <div className="flex flex-row gap-x-1 justify-end mb-3 text-xs font-medium">
              <h4 className="">{t('statistics.daysOff')}:</h4>
              <div className="flex text-center justify-center">
                {statisticsTeacherDetailed?.scheduleHoursPerWeek && (
                  <div className="font-bold">
                    {statisticsTeacherDetailed?.scheduleHoursPerWeek.totalFree}
                    {statisticsTeacherDetailed?.scheduleHoursPerWeek
                      .totalFreeNotBeen > 0 && (
                      <>
                        {' '}
                        <span className="text-orange-400  group relative w-max cursor-help">
                          (
                          {
                            statisticsTeacherDetailed?.scheduleHoursPerWeek
                              .totalFreeNotBeen
                          }
                          )
                          <span className="pointer-events-none absolute -top-10 left-0 w-max max-w-[200px] opacity-0 transition-opacity group-hover:opacity-100 border p-1 rounded-xl text-xs bg-gray-100 border-gray-200 text-gray-900">
                            {t('statistics.freeNotBeen')}
                          </span>
                        </span>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
            <table className="table-auto w-full" id="my-table">
              <thead className="text-xs">
                <tr>
                  <th className="p-2 whitespace-nowrap w-[13%] text-left">
                    <span className="text-base font-bold">
                      {/*t('general.week')*/}

                      {exporting ? (
                        <>
                          {moment(statisticsFromDate).format('YYYY')}{' '}
                          {moment(statisticsFromDate).format('YYYY') !==
                          moment(statisticsToDate).format('YYYY') ? (
                            <> - {moment(statisticsToDate).format('YYYY')}</>
                          ) : null}
                        </>
                      ) : (
                        t('general.date')
                      )}
                    </span>
                    <br />
                    <span className="text-2xs">
                      <br />
                    </span>
                  </th>
                  <th className="p-2 whitespace-nowrap w-[13%] text-center">
                    <span className="text-base font-bold">
                      {t('days.monday')}
                    </span>
                    <br />
                    <span className="text-2xs">
                      <br />
                    </span>
                  </th>
                  <th className="p-2 whitespace-nowrap w-[13%] text-center">
                    <span className="text-base font-bold">
                      {t('days.tuesday')}
                    </span>
                    <br />
                    <span className="text-2xs">
                      <br />
                    </span>
                  </th>
                  <th className="p-2 whitespace-nowrap w-[13%] text-center">
                    <span className="text-base font-bold">
                      {t('days.wednesday')}
                    </span>
                    <br />
                    <span className="text-2xs">
                      <br />
                    </span>
                  </th>
                  <th className="p-2 whitespace-nowrap w-[13%] text-center">
                    <span className="text-base font-bold">
                      {t('days.thursday')}
                    </span>
                    <br />
                    <span className="text-2xs">
                      <br />
                    </span>
                  </th>
                  <th className="p-2 whitespace-nowrap w-[13%] text-center">
                    <span className="text-base font-bold">
                      {t('days.friday')}
                    </span>
                    <br />
                    <span className="text-2xs">
                      <br />
                    </span>
                  </th>
                  <th className="p-2 whitespace-nowrap w-[13%] text-center">
                    <span className="text-base font-bold">
                      {t('general.total')}
                    </span>
                    <br />
                    <span className="text-2xs">
                      <br />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm divide-y divide-gray-100">
                <ShowWeek
                  id={teacherUserRole?.id!}
                  statistics={statisticsTeacherDetailed}
                  logs={statisticsTeacherDetailed.logs}
                  firstDate={moment(statisticsFromDate).startOf('week')}
                  firstDateOfWeek={moment(statisticsFromDate).startOf('week')}
                  lastDate={moment(statisticsToDate).endOf('week')}
                  showWeekNumber={true}
                  fromDate={statisticsFromDate!}
                  toDate={statisticsToDate!}
                  exporting={exporting}
                  fetch={() => {
                    if (savedUserRoleId && teacherUserRole && schoolId) {
                      getStatisticsTeacher(
                        savedUserRoleId,
                        [],
                        [teacherUserRole.id],
                        schoolId,
                        false,
                        true,
                      ).finally(() => {
                        setLoading(false)
                      })
                    }
                  }}
                  type={'teacher'}
                  schedule={statisticsTeacherDetailed.schedule}
                />
                {/*<ShowWeeks
                  kidStatistics={statistics}
                  firstDate={moment(statisticsFromDate).startOf('week')}
                  firstDateOfWeek={moment(statisticsFromDate).startOf('week')}
                  lastDate={moment(statisticsToDate).endOf('week')}
                  showWeekNumber={true}
                  fetch={() => {
                    if (savedUserRoleId && kid && schoolId) {
                      getKidStatistics(
                        savedUserRoleId,
                        //fromDate,
                        //toDate,
                        [],
                        [kid],
                        schoolId,
                      )
                    }
                  }}
                  fromDate={statisticsFromDate!}
                  toDate={statisticsToDate!}
                  exporting={exporting}
                />*/}
                {/*showWeeks({
                          statistics,
                          moment(fromDate).startOf('week'),
                          moment(fromDate).startOf('week'),
                          moment(toDate).endOf('week'),
                        })*/}
                {/*statistics?.kidsList?.map((kid: any) => {
                          return (
                            <tr>
                              <td className="p-2 whitespace-nowrap">
                                <div className="flex items-center">
                                  <div className="w-10 h-10 flex-shrink-0 mr-2 sm:mr-3">
                                    <img
                                      className="rounded-full"
                                      src={kid.kidImage}
                                      width="40"
                                      height="40"
                                      alt={''}
                                    />
                                  </div>
                                  <div className="font-medium text-gray-800">
                                    {kid.kidName}
                                  </div>
                                </div>
                              </td>
                              <td className="p-2 whitespace-nowrap">
                                <div className="text-left">{kid.kidSsn}</div>
                              </td>
                              <td className="p-2 whitespace-nowrap">
                                <div className="text-left font-medium text-gray-800">
                                  {kid.kidScheduleHoursPerWeek.hours +
                                    'h ' +
                                    kid.kidScheduleHoursPerWeek.minutes +
                                    'm'}
                                </div>
                              </td>
                              <td className="p-2 whitespace-nowrap">
                                <div className="text-left font-medium text-gray-800">
                                  {kid.kidTimeAttending.hours +
                                    'h ' +
                                    kid.kidTimeAttending.minutes +
                                    'm'}
                                </div>
                              </td>
                              <td className="p-2 whitespace-nowrap">
                                <div
                                  className={
                                    kid.kidTimeDifference.negative
                                      ? 'text-left font-medium text-gray-800'
                                      : 'text-left font-medium text-red-500'
                                  }
                                >
                                  {kid.kidTimeDifference.hours +
                                    'h ' +
                                    kid.kidTimeDifference.minutes +
                                    'm'}
                                </div>
                              </td>
                              <td className="p-2 whitespace-nowrap">
                                <div className="text-left font-medium text-gray-800">
                                  {kid.kidScheduleHoursPerWeek.totalFree +
                                    ' dagar'}
                                </div>
                              </td>
                              <td className="p-2 whitespace-nowrap">
                                <div className="text-lg text-center">
                                  {kid.tariff}
                                </div>
                              </td>
                            </tr>
                          )
                        })*/}
              </tbody>
            </table>
          </div>
        </ColorCard>
      </div>
    </Page>
  )
})
