import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { t } from 'i18next'
import { Button } from '../../../components/Button'
import { CreateEventParams } from '../../../store/event3Store'
import { faUserGroup } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Department } from '../../../Models/Department'
import { KidStoreContext } from '../../../store/kidStore'
import { Kid } from '../../../Models/Kid'
import ColorCard from '../../../components/ColorCard'
import { getImageOrPlaceHolder } from '../../../utils/parseUtils'
import { RandomColors } from '../../../components/RandomColors'
import classNames from 'classnames'
import { faSplit } from '@fortawesome/pro-solid-svg-icons'

interface Props {
  newEvent: CreateEventParams
  onChange: (e: CreateEventParams) => void
  onDone: () => void // hämta värdena och visa från riktig db
  onSave: () => void // spara värdena
  onClick: () => void
}
interface Props2 {
  newEvent: CreateEventParams
  onChange: (e: CreateEventParams) => void
  departments: Department[]
}

export const SeparateParentsToBookingSelections: React.FC<Props2> = observer(
  (props: Props2) => {
    const { fetchKidsForEvent3Booking, kidsForEvent3Booking } =
      useContext(KidStoreContext)
    console.log('departments', props.departments)
    console.log('kids', kidsForEvent3Booking)
    useEffect(() => {
      if (props.newEvent.depIds) {
        fetchKidsForEvent3Booking(props.newEvent.depIds)
      }
    }, [props.newEvent.depIds])

    const updateAllowSeparateBookings = (kidId: string) => {
      let allowSeparateBookings = props.newEvent.allowSeparateBookings

      if (!allowSeparateBookings) {
        return [kidId]
      }

      if (allowSeparateBookings.includes(kidId)) {
        allowSeparateBookings = allowSeparateBookings.filter(
          (row) => row !== kidId,
        )
        return allowSeparateBookings
      }

      allowSeparateBookings.push(kidId)
      return allowSeparateBookings
    }

    return (
      <div className="h-[60vh] overflow-auto">
        {props.departments?.map((department: Department, index: number) => {
          return (
            <>
              <ColorCard
                title={department?.attributes?.klassName + ''}
                bgColor={
                  department.get('departmentColor')
                    ? 'bg-[' + department.get('departmentColor') + ']'
                    : RandomColors[index]
                }
              >
                <div
                  className={
                    'flex flex-wrap max-w-7xl py-3 font-medium justify-center'
                  }
                >
                  {kidsForEvent3Booking
                    ?.filter((k: Kid) => {
                      return (
                        k?.attributes?.departmentPointer?.id === department?.id
                      )
                    })
                    .sort((a, b) => {
                      return a?.attributes?.kidName.localeCompare(
                        b?.attributes?.kidName,
                      )
                    })
                    .map((kid) => {
                      return (
                        <div
                          key={kid?.id}
                          onClick={() => {
                            //setSelectedKid(kid)
                            props.onChange({
                              ...props.newEvent,
                              allowSeparateBookings:
                                updateAllowSeparateBookings(kid.id),
                            })
                          }}
                          className={
                            'flex flex-col space-y-2 items-center justify-center p-3 cursor-pointer hover:shadow-md hover:bg-gray-50 rounded-2xl '
                          }
                        >
                          <div className="relative">
                            <img
                              className={classNames(
                                'w-[80px] h-[80px] rounded-full object-cover shadow',
                                props.newEvent.allowSeparateBookings?.includes(
                                  kid.id,
                                ) && ' border-[7px] border-eventsMain',
                              )}
                              src={getImageOrPlaceHolder(
                                kid?.attributes?.kidImage?._url,
                              )}
                              alt=""
                            />
                          </div>
                          <p>{kid?.attributes.kidName}</p>
                        </div>
                      )
                    })}
                </div>
              </ColorCard>
            </>
          )
        })}
      </div>
    )
  },
)

export const SeparateParentsToBooking: React.FC<Props> = observer(
  (props: Props) => {
    const [openSelections, setOpenSelections] = useState(false)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [groupedTimes, setGroupedTimes] = useState<string[]>()
    const { fetchKidsForEvent3Booking, kidsForEvent3Booking } =
      useContext(KidStoreContext)
    console.log(props.newEvent.allowSeparateBookings)

    useEffect(() => {
      fetchKidsForEvent3Booking(props.newEvent.depIds!)
      if (props.newEvent.times) {
        const datesInTimes: string[] = []

        for (const time of props.newEvent.times) {
          if (!datesInTimes.find((date) => date === time.date)) {
            datesInTimes.push(time.date)
          }
        }
        if (datesInTimes) {
          setGroupedTimes(datesInTimes)
        }
      }
    }, [props.newEvent.times])

    return (
      <>
        <div
          className="w-full"
          onClick={() => {
            setOpenSelections(!openSelections)
          }}
        >
          <div className=" flex flex-col gap-y-2">
            <div className="font-semibold flex items-center justify-between">
              <div className="font-semibold flex items-center gap-x-1">
                {''}
                <FontAwesomeIcon
                  icon={faSplit}
                  rotation={270}
                  className="text-[#C99CF2] text-xl"
                />
                <>{t('events.bookSeparateMeetings')}:</>
              </div>
              <Button
                label={
                  props.newEvent.allowSeparateBookings &&
                  props.newEvent.allowSeparateBookings.length
                    ? t('blog.edit')
                    : t('events.add')
                }
                variant="remindStyle"
                size="xs"
                onClick={() => {
                  props.onClick()
                }}
              />
            </div>
            {props.newEvent.allowSeparateBookings &&
            props.newEvent.allowSeparateBookings.length ? (
              <div
                className={
                  'flex flex-wrap max-w-7xl py-3 font-medium justify-center'
                }
              >
                {props.newEvent.allowSeparateBookings.map((kidId) => {
                  const kid = kidsForEvent3Booking?.find(
                    (kid) => kid.id === kidId,
                  )
                  console.log(kid)

                  if (kid) {
                    return (
                      <div
                        key={kid.id}
                        onClick={() => {
                          //setSelectedKid(kid)
                          /*props.onChange({
                            ...props.newEvent,
                            allowSeparateBookings: updateAllowSeparateBookings(
                              kid.id,
                            ),
                          })*/
                        }}
                        className={
                          'flex flex-col space-y-2 items-center justify-center p-3 cursor-pointer hover:shadow-md hover:bg-gray-50 rounded-2xl '
                        }
                      >
                        <div className="relative">
                          <img
                            className={classNames(
                              'w-[25px] h-[25px] rounded-full object-cover shadow',
                              '',
                            )}
                            src={getImageOrPlaceHolder(
                              kid?.attributes?.kidImage?._url,
                            )}
                            alt=""
                          />
                        </div>
                        <p className="text-2xs">{kid?.attributes.kidName}</p>
                      </div>
                    )
                  } else {
                    return <></>
                  }
                })}
              </div>
            ) : (
              <div className="flex pl-2">
                <>{t('events.allowSeparateGuardiansText')}</>
              </div>
            )}
          </div>
        </div>
      </>
    )
  },
)
