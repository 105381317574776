import { observer } from 'mobx-react'

import { EventListItemData } from '../Events3'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faXmark } from '@fortawesome/free-solid-svg-icons'
import { IEventDetails } from '../EventDetailPage'
import { useContext, useState } from 'react'
import { OverviewUsersList, User } from './OverviewUsersList'
import { useTranslation } from 'react-i18next'
import ModalRegular from '../../../components/ModalRegular'
import { DepartmentStoreContext } from '../../../store/departmentStore'
import { Department } from '../../../Models/Department'
import ListviewSelections from '../../../components/ListviewSelections'

interface Iprops {
  eventDetail?: EventListItemData
  isOverviewVisible: boolean
  setOverviewVisible: (isOverviewVisible: boolean) => void
  eventOverview?: IEventDetails
  refreshDetail: () => void
  openHelpSection: boolean
  setOpenHelpSection: (ishelpSectionOpen: boolean) => void
}

const getUniqueDepartments = (
  departments?: Department[],
): (string | undefined)[] => {
  const allDepartments = departments?.flatMap((dep) => dep.klassName)
  return Array.from(new Set(allDepartments))
}

export const EventOverview: React.FC<Iprops> = observer(
  ({
    eventDetail,
    setOverviewVisible,
    isOverviewVisible,
    eventOverview,
    refreshDetail,
    openHelpSection,
    setOpenHelpSection,
  }) => {
    const infoType = eventDetail?.type === 'info'
    const importantInfoType = eventDetail?.type === 'important'
    const activityType = eventDetail?.type === 'activities'
    const meetingType = eventDetail?.type === 'meeting'
    const bookingType = eventDetail?.type === 'booking'

    const { t } = useTranslation()

    const [filter, setFilter] = useState<boolean>(false)
    const [filterDepartments, setFilterDepartments] = useState<
      string[] | undefined
    >([])
    const { departments } = useContext(DepartmentStoreContext)

    return (
      <>
        <div onClick={(e) => e.stopPropagation()}>
          <ModalRegular
            title={t('documents.overview')}
            isOpen={isOverviewVisible}
            /* bgColor="bg-eventsMain p-4" */
            closeColor="white"
            className={'h-[600px] py-4 relative !overflow-hidden !bg-[#F9FAFB]'}
            classNameTitle="mb-4 w-full text-black !important flex justify-center font-semibold"
            onClose={() => {
              setOverviewVisible(false)
            }}
          >
            <div className="flex items-center pr-3 absolute top-5 left-5">
              <FontAwesomeIcon
                icon={faFilter}
                size="lg"
                className={`${
                  infoType
                    ? 'text-[#7eb5f4]'
                    : importantInfoType
                    ? 'text-[#f26d6d]'
                    : activityType
                    ? 'text-[#6BBD73]'
                    : meetingType
                    ? 'text-[#ffa948]'
                    : bookingType
                    ? 'text-[#E1A6F0]'
                    : 'text-white'
                } cursor-pointer`}
                onClick={(e) => {
                  e.stopPropagation()
                  setFilter(!filter)
                }}
              />
            </div>

            {filter && (
              <>
                <ListviewSelections
                  isOpen={true}
                  onOpen={() => console.log()}
                  selectedIds={filterDepartments}
                  onClick={(value) => setFilterDepartments(value)}
                  items={
                    departments?.map((dep) => {
                      return { label: dep.klassName ?? '', id: dep.id }
                    }) ?? []
                  }
                  header={t('admin.departments')}
                  type="list"
                />
                {/* <div
                  className="fixed top-14 left-0 p-4 h-full w-full z-20 backdrop-blur-sm animate-fadeIn overflow-y-auto"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="flex justify-between">
                    <h1 className="text-sm font-semibold mb-4">
                      {t('admin.departments')}{' '}
                    </h1>
                    <span className="flex justify-end font-semibold text-gray-500 text-sm">
                      {filterDepartments.length}
                      {' / '}
                      {departments_local.length}
                    </span>
                  </div>
                  <div className="space-y-2 overflow-y-auto h-full">
                    <label className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        checked={isAllSelected}
                        onChange={handleSelectAllChange}
                        onClick={(e) => e.stopPropagation()}
                        className="form-checkbox h-4 w-4 text-indigo-600"
                      />
                      <span className="font-semibold text-sm">
                        {t('multiselect.selectAll')}
                      </span>
                    </label>
                    {departments_local.map((department) => (
                      <label
                        key={department}
                        className="flex items-center space-x-2"
                      >
                        <input
                          type="checkbox"
                          checked={filterDepartments.includes(department)}
                          onChange={() => handleDepartmentChange(department)}
                          onClick={(e) => e.stopPropagation()}
                          className="form-checkbox h-4 w-4 text-indigo-600 text-sm"
                        />
                        <span className="font-semibold text-sm">
                          {department === null ? 'Inga barn' : department}
                        </span>
                      </label>
                    ))}
                  </div>
                </div> */}
              </>
            )}
            <div className="w-full h-full relative z-0">
              <OverviewUsersList
                filterDepartments={filterDepartments}
                eventOverview={eventOverview}
                eventDetail={eventDetail}
                refreshDetail={refreshDetail}
                openHelpSection={openHelpSection}
                setOpenHelpSection={setOpenHelpSection}
              />
            </div>
          </ModalRegular>
        </div>
      </>
    )
  },
)
