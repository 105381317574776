import { observer } from 'mobx-react'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { EventListItemData, ILocation } from '../Events3'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUserGroup,
  faUsers,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../components/Button'
import { DepartmentStoreContext } from '../../../store/departmentStore'
import { UserStoreContext } from '../../../store/userStore'
import { StepButton } from './StepButton'
import { DateToEvent, DateToEventSelections } from './DateToEvent'
import {
  CreateEventParams,
  EVENT3_POST_TO,
  EVENT3_TYPE,
  Event3StoreContext,
  EventInfoResponse,
  EventTimeSmallInterface,
  TimeSpan,
  Times,
} from '../../../store/event3Store'
import {
  CREATE_EVENT_STEP,
  initialValues,
  usePostToSelections,
} from '../CreateEvent3'
import { TagsToEvent, TagsToEventSelections } from './TagsToEvent'
import { TagStoreContext } from '../../../store/tagStore'
import { LocationToEvent } from './LocationToEvent'
import {
  SeparateParentsToBooking,
  SeparateParentsToBookingSelections,
} from './SeparateParentsToBooking'
import { AddFileModalNew } from './AddFileModalNew'
import { Kid } from '../../../Models/Kid'
import { AddParticipatingChildren } from './AddParticipatingChildren'
import CustomRadioButton from '../../../components/CustomRadioButton'
import Swal from 'sweetalert2'
import { PublishEventModal } from './PublishEventModal'
import ModalRegular from '../../../components/ModalRegular'
import classNames from 'classnames'
import { PostEventToSelections } from './PostEventTo'
import { TypeOfEventSelections } from './TypeOfEvent'
import { ChooseSchoolsSelections } from './ChooseSchools'
import { CreateLocation } from './CreateLocation'
import { useParams } from 'react-router-dom'
import { SpecificPersonModal } from './SpecificPersonModal'

export interface EditEventParams {
  mode: 'edit'
  eventTimeId: string
  eventGroupInfoId: string
  updateTimeOnly?: boolean
  topic?: string
  content?: string
  fileIds?: (string | undefined)[] | undefined
  tagIds?: (string | undefined)[] | undefined
  locationId?: ILocation
  maxParticipants?: number
  depIds?: string[]
  newTimeSpan?: Times
  onlyStaff?: boolean
  publishAs?: string
  eventResponsible?: string
  isPinned?: boolean
  sendPush?: boolean
}

export interface UpdatePayload {
  deletedFileIds: string[]
  deletedTimeIds: EventTimeSmallInterface[]
  timeSpans: TimeSpan[]
}
const EditBookingEventPopUp: React.FC<{
  fetchedEventDetail: EventInfoResponse
  editMode: boolean
  setEditEventMode: (value: boolean) => void
  handleExpaneded: () => void
  handleToggleMenu?: () => void
}> = observer(
  ({
    fetchedEventDetail,
    editMode,
    setEditEventMode,
    handleExpaneded,
    handleToggleMenu,
  }) => {
    const files: any[] | undefined =
      fetchedEventDetail.files && fetchedEventDetail.files.map((file) => file)

    const fileIds: any[] | undefined = fetchedEventDetail.files
      ? fetchedEventDetail.files
          .map((file) => file.id)
          .filter((id): id is string => id !== undefined)
      : undefined

    const tags: any[] | undefined = fetchedEventDetail.tags
      ? fetchedEventDetail.tags
          .map((tag) => tag.id)
          .filter((id): id is string => id !== undefined)
      : undefined

    const popupRef = useRef<HTMLDivElement>(null)
    const { t } = useTranslation()
    const { fetchDepartmentsForMultiSchools, departmentsForMultiSchool } =
      useContext(DepartmentStoreContext)
    const {
      currentUserRole,
      getSchoolsForUserRoleAdmins,
      schoolsForUserRoleAdmins,
    } = useContext(UserStoreContext)

    const { tagsForEvent, fetchTagsForEvent } = useContext(TagStoreContext)
    const [toBeEditEvent, setToBeEditEvent] =
      useState<CreateEventParams>(initialValues)
    const [choosenKids, setChoosenKids] = useState<Kid[]>([])
    const [openAddParticipatingChildren, setOpenAddParticipatingChildren] =
      useState(false)
    const [showModal, setShowModal] = useState(false)
    const [currentStep, setCurrentStep] = useState<CREATE_EVENT_STEP>()
    const [openModal, setOpenModal] = useState(false)
    const { fetchLocations, locations } = useContext(Event3StoreContext)
    const { schoolId } = useParams()
    const postToSelections = usePostToSelections()
    const [openSpecificUsers, setOpenSpecificUsers] = useState<boolean>(false)
    const [extraParticipants, setExtraParticipants] = useState<string>(
      `${
        fetchedEventDetail.maxParticipants
          ? fetchedEventDetail.maxParticipants
          : ''
      }`,
    )
    const [updatePayload, setUpdatePayload] = useState<UpdatePayload>({
      deletedFileIds: [],
      deletedTimeIds: fetchedEventDetail.times!,
      timeSpans: [],
    })

    //  Function to update specific properties easily
    const updateField = <K extends keyof typeof updatePayload>(
      field: K,
      value: (typeof updatePayload)[K],
    ) => {
      setUpdatePayload((prev) => ({
        ...prev,
        [field]: value, // Update only the specific field
      }))
    }

    // Function to find mismatched IDs
    const findMismatchedIds = (arr1: any[], arr2: any[]): string[] => {
      return arr1
        .filter(
          (slot1) =>
            !arr2.some(
              (slot2) =>
                slot1.start_isodate === slot2.date &&
                slot1.start_time === slot2.start &&
                slot1.end_time === slot2.end,
            ),
        )
        .map((slot) => slot.id)
    }

    const removeDeletedFileIds = (deletedId: string) => {
      setUpdatePayload((prev) => ({
        ...prev,
        deletedFileIds: [...prev.deletedFileIds, deletedId], // Append new ID
      }))
    }

    console.log('*******************> ', fetchedEventDetail.times)

    const handleChangeParticipants = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      event.stopPropagation()
      const value = event.target.value
      const parsedValue = parseInt(value, 10)

      if (!isNaN(parsedValue)) {
        setExtraParticipants(value)
        toBeEditEvent.maxParticipants = parsedValue
      } else {
        setExtraParticipants('')
      }
    }
    const toggleAddParticipatingChildrenPopUp = () => {
      setOpenAddParticipatingChildren(!openAddParticipatingChildren)
    }

    const handleDateChange = () => {
      setCurrentStep(CREATE_EVENT_STEP.DATE)
      setOpenModal(true)
    }
    const handleTagsChange = () => {
      setCurrentStep(CREATE_EVENT_STEP.TAGS)
      setOpenModal(true)
    }
    const handlePlatsChange = () => {
      setCurrentStep(CREATE_EVENT_STEP.LOCATION)
      if (!toBeEditEvent.location) {
        setOpenModal(true)
      }
    }

    const handleSeparateParentsChange = () => {
      setCurrentStep(CREATE_EVENT_STEP.SEPARATE_PARENTS)
      setOpenModal(true)
    }

    useEffect(() => {
      fetchDepartmentsForMultiSchools([
        currentUserRole?.school_pointer.id ?? '',
      ])
      fetchTagsForEvent(
        [currentUserRole?.school_pointer.id ?? ''],
        fetchedEventDetail.type,
        fetchedEventDetail.onlyStaff ?? false,
      )
    }, [currentUserRole])

    useEffect(() => {
      setToBeEditEvent({
        ...toBeEditEvent,
        mod: 'edit',
        eventType:
          EVENT3_TYPE[
            fetchedEventDetail.type.toUpperCase() as keyof typeof EVENT3_TYPE
          ],
        topic: fetchedEventDetail.topic,
        content: fetchedEventDetail.content,
        files: files,
        fileIds: fileIds,
        tagIds: tags,
        isRecurrence: fetchedEventDetail.isRecurring,
        /*  eventResponsible: fetchedEventDetail.eventResponsible, */
        location: fetchedEventDetail.location,
        depIds: fetchedEventDetail.departmentIds,
        maxParticipants: fetchedEventDetail.maxParticipants,
        times: fetchedEventDetail.times?.map((item) => ({
          date: fetchedEventDetail.eventDate,
          start: item.start_time || undefined,
          end: item.end_time || undefined,
          fakeId: item.id ?? '',
        })),
        onlyStaff: fetchedEventDetail.onlyStaff,
        specificUserRoleIds: fetchedEventDetail.specificRoleIds,
        allowComments: fetchedEventDetail.allowComments!,
        allowSeparateBookings: fetchedEventDetail.separateBookingKidIds,
        /* publishAs: // how to find the info about the publish as?, */
        /* eventResponsible: // where in the ui we pass responsible */
        /* isPinned: fetchedEventDetail.isPinned, */
      })
    }, [fetchedEventDetail])

    useEffect(() => {
      if (schoolId) {
        fetchLocations([schoolId])
      }
    }, [schoolId])

    console.log('fetchedEventDetail', fetchedEventDetail)
    console.log('toBeEditEvent', toBeEditEvent)
    console.log('UPPPPPPPPPPPP', updatePayload)

    return (
      <div onClick={(e) => e.stopPropagation()}>
        <ModalRegular
          title={t('events.editEvent') + '?'}
          isOpen={editMode}
          className={
            '!max-w-5xl !min-h-[700px] py-6 px-3 !relative !overflow-hidden'
          }
          classNameTitle="mb-4 w-full text-black !important flex justify-center"
          onClose={() => {
            setEditEventMode(false)
            handleToggleMenu?.()
          }}
        >
          <div className="flex flex-col w-full h-full">
            {((fetchedEventDetail.specificRoleIds &&
              fetchedEventDetail.specificRoleIds?.length <= 0) ||
              fetchedEventDetail.specificRoleIds === undefined) && (
              <div id="departments " className="flex flex-col p-4 gap-y-3">
                <p className="font-semibold flex justify-between">
                  {t('events.selectedDepartments')}
                  {' ( '} {toBeEditEvent.depIds?.length} {')'}
                  <p
                    className="text-blueDark cursor-pointer"
                    onClick={() => {
                      if (
                        toBeEditEvent.depIds?.length ===
                        departmentsForMultiSchool?.length
                      ) {
                        setToBeEditEvent({
                          ...toBeEditEvent,
                          depIds: [],
                        })
                      } else {
                        setToBeEditEvent({
                          ...toBeEditEvent,
                          depIds: [
                            ...departmentsForMultiSchool?.map(
                              (dep) => dep.id,
                            )!!,
                          ],
                        })
                      }
                    }}
                  >
                    {toBeEditEvent.depIds?.length ===
                    departmentsForMultiSchool?.length
                      ? t('multiselect.deSelectAll')
                      : t('multiselect.selectAll')}
                  </p>
                </p>
                <div className="flex items-center h-[60px] overflow-x-auto text-nowrap gap-x-2 scrollbar-hide -mr-7 pr-2">
                  {departmentsForMultiSchool
                    ?.slice()
                    .sort((a, b) => {
                      const isASelected = toBeEditEvent.depIds?.includes(a.id)
                        ? 0
                        : 1
                      const isBSelected = toBeEditEvent.depIds?.includes(b.id)
                        ? 0
                        : 1
                      return isASelected - isBSelected
                    })
                    .map((dep) => {
                      return (
                        <Button
                          key={dep.id}
                          size="sm"
                          fontSize="font-semibold"
                          variant={
                            toBeEditEvent.depIds?.find(
                              (depId) => depId === dep.id,
                            )
                              ? 'lightPurple'
                              : 'fullWhiteGrayText'
                          }
                          label={dep.klassName}
                          onClick={() => {
                            if (
                              toBeEditEvent.depIds?.find(
                                (depId) => depId === dep.id,
                              )
                            ) {
                              // Remove from array
                              setToBeEditEvent({
                                ...toBeEditEvent,
                                depIds: [
                                  ...toBeEditEvent.depIds?.filter(
                                    (depId) => depId !== dep.id,
                                  ),
                                ],
                              })
                            } else {
                              // Add to array
                              if (toBeEditEvent.depIds)
                                setToBeEditEvent({
                                  ...toBeEditEvent,
                                  depIds: [...toBeEditEvent.depIds, dep.id],
                                })
                            }
                          }}
                        />
                      )
                    })}
                </div>
              </div>
            )}
            <div className="flex p-2 h-[340px]">
              <div className="basis-7/12 pr-2 mr-2 ">
                <div className="py-6 px-4 w-full bg-white rounded-3xl flex flex-col">
                  <div className="flex flex-col">
                    <div className="flex items-center space-x-2">
                      <div className="flex-1">
                        <input
                          type="text"
                          id="header"
                          className={`appearance-none border-b ${
                            !toBeEditEvent.topic ||
                            toBeEditEvent.topic.trim() === ''
                              ? 'border-black-700 placeholder-black'
                              : 'border-gray-300'
                          } w-full py-2 px-3 text-black-100 leading-tight focus:outline-none focus:shadow-outline font-semibold`}
                          placeholder={t('notes.header')}
                          value={toBeEditEvent?.topic}
                          onChange={(e) => {
                            const value = e.target.value
                            // Kapitalisera det första tecknet i strängen och lägg till resten av strängen som den är.
                            const capitalizedValue =
                              value.charAt(0).toUpperCase() + value.slice(1)
                            setToBeEditEvent({
                              ...toBeEditEvent,
                              topic: capitalizedValue,
                            })
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col ">
                    <textarea
                      id="description"
                      className="py-2 px-3 placeholder-gray-500 leading-tight focus:outline-none focus:shadow-outline text-md h-[200px]"
                      placeholder={t('events.contentPlaceholder')}
                      onChange={(e) => {
                        e.stopPropagation()
                        setToBeEditEvent({
                          ...toBeEditEvent,
                          content: e.target.value,
                        })
                      }}
                      value={toBeEditEvent.content}
                    ></textarea>
                  </div>
                </div>
                <div className="rounded-2xl shadow-sm p-2 bg-white mt-4">
                  <CustomRadioButton
                    options={[
                      {
                        value: 'allow_comments',
                        label: t('events.allowComments'),
                        text: '',
                      },
                    ]}
                    onChange={() => {
                      setToBeEditEvent({
                        ...toBeEditEvent,
                        allowComments: !toBeEditEvent.allowComments,
                      })
                    }}
                    value={toBeEditEvent.allowComments ? 'true' : 'false'}
                  />
                </div>
                <ModalRegular
                  isOpen={openModal}
                  onClose={() => {
                    setOpenModal(false)
                  }}
                  className={classNames(
                    'px-4 py-6 transition',
                    currentStep === CREATE_EVENT_STEP.DATE ||
                      currentStep === CREATE_EVENT_STEP.SEPARATE_PARENTS
                      ? 'max-w-5xl'
                      : 'max-w-lg',
                  )}
                >
                  {currentStep === CREATE_EVENT_STEP.POST_TO && (
                    <PostEventToSelections
                      onChange={setToBeEditEvent}
                      newEvent={toBeEditEvent}
                      departments={departmentsForMultiSchool ?? []}
                      schools={schoolsForUserRoleAdmins}
                    />
                  )}
                  {currentStep === CREATE_EVENT_STEP.TYPE && (
                    <TypeOfEventSelections
                      onChange={setToBeEditEvent}
                      newEvent={toBeEditEvent}
                      closeModal={() => {
                        setOpenModal(false)
                      }}
                    />
                  )}
                  {currentStep === CREATE_EVENT_STEP.DATE && (
                    <DateToEventSelections
                      onChange={setToBeEditEvent}
                      newEvent={toBeEditEvent}
                    />
                  )}
                  {currentStep === CREATE_EVENT_STEP.TAGS && (
                    <TagsToEventSelections
                      newEvent={toBeEditEvent}
                      tags={tagsForEvent ?? []}
                      schools={schoolsForUserRoleAdmins}
                      onChange={setToBeEditEvent}
                      onDone={() => {}}
                      onSave={() => {}}
                    />
                  )}
                  {currentStep === CREATE_EVENT_STEP.CHOOSE_SCHOOLS && (
                    <ChooseSchoolsSelections
                      newEvent={toBeEditEvent}
                      schools={schoolsForUserRoleAdmins ?? []}
                      onChange={setToBeEditEvent}
                    />
                  )}
                  {currentStep === CREATE_EVENT_STEP.LOCATION && (
                    <CreateLocation
                      value={toBeEditEvent.locationType}
                      newEvent={toBeEditEvent}
                      onChange={setToBeEditEvent}
                      onDone={() => {}}
                      onSave={() => {}}
                      locations={locations ?? []}
                      onClose={() => {
                        setOpenModal(false)
                        //setNewEvent
                      }}
                    />
                  )}
                  {currentStep === CREATE_EVENT_STEP.SEPARATE_PARENTS && (
                    <SeparateParentsToBookingSelections
                      newEvent={toBeEditEvent}
                      departments={
                        departmentsForMultiSchool?.filter((dep) =>
                          toBeEditEvent.depIds?.includes(dep.id),
                        ) ?? []
                      }
                      onChange={setToBeEditEvent}
                    />
                  )}

                  {currentStep !== CREATE_EVENT_STEP.LOCATION && (
                    <div className="flex flex-row justify-center items-center w-full  pt-4">
                      <Button
                        size="lg"
                        className="w-[50%]"
                        variant={'fullBlue'}
                        label={t('events.add')}
                        onClick={(e) => {
                          e.stopPropagation()
                          setOpenModal(false)
                        }}
                      />
                    </div>
                  )}
                </ModalRegular>
              </div>
              <div className="basis-5/12 flex flex-col gap-y-2 overflow-y-auto h-[430px] animate-fadeIn mb-4">
                {fetchedEventDetail.specificRoleIds !== undefined &&
                  fetchedEventDetail.specificRoleIds.length > 0 &&
                  fetchedEventDetail.type !== EVENT3_TYPE.BOOKING && (
                    <div className="rounded-2xl shadow-sm bg-white font-semibold px-4 py-2 flex flex-col items-center">
                      <p className="w-full flex justify-start">
                        {t('events.shareToSpecificUser')}
                      </p>
                      <p
                        onClick={() => {
                          setOpenSpecificUsers(true)
                        }}
                        className="flex w-full justify-end text-blueDark cursor-pointer text-sm"
                      >
                        {fetchedEventDetail?.specificRoleIds &&
                        fetchedEventDetail?.specificRoleIds.length ? (
                          <span className="text-blueDark">
                            <FontAwesomeIcon icon={faUsers} className="mr-2" />{' '}
                            {t('events.showChosenUsers')}
                          </span>
                        ) : (
                          t('events.chooseOrEdit')
                        )}
                      </p>
                    </div>
                  )}
                {(fetchedEventDetail?.type === EVENT3_TYPE.MEETING ||
                  fetchedEventDetail?.type === EVENT3_TYPE.ACTIVITIES) &&
                  fetchedEventDetail.eventFor && (
                    <div className="rounded-2xl shadow-sm bg-white font-semibold px-4 py-4 flex flex-col items-center">
                      <div className="flex items-center">
                        <p className="flex-grow pr-2 font-semibold">
                          {t('events.maximumNumParticipants')}
                        </p>
                        <input
                          type="string"
                          value={extraParticipants ?? ''}
                          placeholder={t('events.unlimited')}
                          onChange={handleChangeParticipants}
                          className="w-28 h-12 text-md border border-gray-200 rounded text-center font-semibold placeholder:text-gray-300 focus:outline-none"
                        />
                      </div>
                    </div>
                  )}
                <CustomRadioButton
                  options={postToSelections}
                  disable={
                    fetchedEventDetail.type === EVENT3_TYPE.BOOKING ||
                    fetchedEventDetail.isRecurring === true
                  }
                  onChange={(value) => {
                    if (value === EVENT3_POST_TO.ONLY_STAFF) {
                      setToBeEditEvent({
                        ...toBeEditEvent,
                        postTo:
                          toBeEditEvent.postTo === value
                            ? EVENT3_POST_TO.ALL
                            : value,
                        onlyStaff: toBeEditEvent.onlyStaff ? false : true,
                        destKids: undefined,
                        destRoles: undefined,
                      })
                    } else if (value === EVENT3_POST_TO.SPECIFIC_USERS) {
                      setToBeEditEvent({
                        ...toBeEditEvent,
                        postTo: value,
                        onlyStaff: undefined,
                        destKids: [],
                        destRoles: [],
                      })
                    }
                  }}
                  value={
                    toBeEditEvent.onlyStaff === true ? 'only_staff' : 'all'
                  }
                />
                <StepButton>
                  <div className="flex flex-row w-full items-center">
                    <DateToEvent
                      newEvent={toBeEditEvent}
                      onChange={setToBeEditEvent}
                      onDone={() => {}}
                      onSave={() => {}}
                      onClick={handleDateChange}
                    />
                  </div>
                </StepButton>
                <StepButton>
                  <div className="flex flex-row justify-between w-full items-center">
                    <TagsToEvent
                      newEvent={toBeEditEvent}
                      tags={tagsForEvent ?? []}
                      schools={schoolsForUserRoleAdmins}
                      onChange={setToBeEditEvent}
                      onDone={() => {}}
                      onSave={() => {}}
                      onClick={handleTagsChange}
                    />
                  </div>
                </StepButton>
                {fetchedEventDetail.type === EVENT3_TYPE.ACTIVITIES ||
                fetchedEventDetail.type === EVENT3_TYPE.BOOKING ||
                fetchedEventDetail.type === EVENT3_TYPE.MEETING ? (
                  <StepButton>
                    <div className="flex flex-row justify-between w-full items-center ">
                      <LocationToEvent
                        newEvent={toBeEditEvent}
                        onChange={setToBeEditEvent}
                        onDone={() => {}}
                        onSave={() => {}}
                        onClick={handlePlatsChange}
                      />
                    </div>
                  </StepButton>
                ) : null}
                {fetchedEventDetail.type === EVENT3_TYPE.BOOKING && (
                  <StepButton>
                    <div className="flex flex-row justify-between w-full items-center ">
                      <SeparateParentsToBooking
                        newEvent={toBeEditEvent}
                        onChange={setToBeEditEvent}
                        onDone={() => {}}
                        onSave={() => {}}
                        onClick={handleSeparateParentsChange}
                      />
                    </div>
                  </StepButton>
                )}
                <StepButton>
                  <AddFileModalNew
                    newEvent={toBeEditEvent}
                    onChange={setToBeEditEvent}
                    removeDeletedFileIds={removeDeletedFileIds}
                  />
                </StepButton>
                {fetchedEventDetail.type === EVENT3_TYPE.ACTIVITIES &&
                  fetchedEventDetail.onlyStaff !== true && (
                    <StepButton>
                      <div className="flex flex-col  justify-between items-center w-full">
                        <div className="font-bold flex flex-col items-center justify-between w-full">
                          <div className="font-bold flex flex-wrap items-center justify-between gap-x-1 w-full overflow-x-auto">
                            {''}
                            {!toBeEditEvent.addParticipatingKids?.length ? (
                              <div className="flex">
                                <FontAwesomeIcon
                                  icon={faUserGroup}
                                  className="text-orange-300 text-xl p-1"
                                />
                                <p>{t('events.addParticipatingChildren')} </p>
                              </div>
                            ) : (
                              <div className="flex items-center">
                                <FontAwesomeIcon
                                  icon={faUserGroup}
                                  className="text-orange-300 text-xl p-1"
                                />
                                <p>
                                  Selected children{'('}
                                  {toBeEditEvent.addParticipatingKids?.length}
                                  {')'}
                                </p>
                              </div>
                            )}
                            <Button
                              label={t('events.add')}
                              variant="remindStyle"
                              size="xs"
                              onClick={(e) => {
                                toggleAddParticipatingChildrenPopUp()
                              }}
                            />
                          </div>
                          <div className="flex flex-row items-center w-[300px] whitespace-nowrap gap-1 mt-2 overflow-x-auto p-2">
                            {choosenKids.map((kid) => (
                              <div className="flex flex-col items-center justify-around space-x-1">
                                <img
                                  className={`w-12 h-12 rounded-full object-cover bg-slate-400`}
                                  alt=""
                                  key={kid?.id}
                                >
                                  {}
                                </img>
                                <p className="font-semibold">{kid.kidName}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      {openAddParticipatingChildren && (
                        <AddParticipatingChildren
                          newEvent={toBeEditEvent}
                          onClose={() => setOpenAddParticipatingChildren(false)}
                          openAddParticipatingChildren={
                            openAddParticipatingChildren
                          }
                          depIds={toBeEditEvent.depIds}
                          onChange={setToBeEditEvent}
                          choosenKids={choosenKids}
                          onChangeChoosenKids={setChoosenKids}
                          toggleAddParticipatingChildrenPopUp={
                            toggleAddParticipatingChildrenPopUp
                          }
                        />
                      )}
                    </StepButton>
                  )}
                <div className="sticky bottom-0 left-0 py-9 bg-gradient-to-t from-[#f6f6f6] w-full z-30"></div>
              </div>
            </div>

            <div className="flex w-full justify-center h-[15%] items-center absolute bottom-0 left-0 animate-fadeIn z-30">
              <Button
                className="w-[40%]"
                size="lg"
                variant="fullBlue"
                fontSize="font-bold"
                label={t('login.nextTitle')}
                onClick={() => {
                  if (
                    !toBeEditEvent.topic ||
                    !toBeEditEvent.depIds ||
                    (toBeEditEvent.depIds?.length < 1 &&
                      toBeEditEvent.specificUserRoleIds === undefined)
                  ) {
                    Swal.fire({
                      icon: 'warning',
                      text: t('events.addHeaderText'),
                      showCancelButton: false,
                      focusConfirm: false,
                      confirmButtonColor: '#7eb5f4',
                      cancelButtonColor: '#d33',
                      confirmButtonText: t('general.ok'),
                    })
                  } else {
                    if (toBeEditEvent.isRecurrence) {
                      if (
                        toBeEditEvent.recurrence?.startsOn &&
                        toBeEditEvent.recurrence.endsOn &&
                        toBeEditEvent.recurrence.days &&
                        toBeEditEvent.recurrence.interval
                      ) {
                        setShowModal(true)
                      } else {
                        Swal.fire({
                          icon: 'warning',
                          text: t('events.addHeaderText'),
                          showCancelButton: false,
                          focusConfirm: false,
                          confirmButtonColor: '#7eb5f4',
                          cancelButtonColor: '#d33',
                          confirmButtonText: t('general.ok'),
                        })
                      }
                    } else {
                      if (toBeEditEvent.eventType === EVENT3_TYPE.BOOKING) {
                        if (
                          toBeEditEvent.times?.length &&
                          toBeEditEvent.times.find(
                            (time) => time.start && time.end,
                          )
                        ) {
                          setShowModal(true)
                        } else {
                          Swal.fire({
                            icon: 'warning',
                            text: t('events.noSetTime'),
                            showCancelButton: false,
                            focusConfirm: false,
                            confirmButtonColor: '#7eb5f4',
                            cancelButtonColor: '#d33',
                            confirmButtonText: t('general.ok'),
                          })
                        }
                      } else {
                        setShowModal(true)
                      }
                    }
                  }
                }}
              />
            </div>
            {openSpecificUsers && (
              <SpecificPersonModal
                onSaved={() => {}}
                newEvent={toBeEditEvent}
                onClose={() => {
                  setOpenSpecificUsers(false)
                }}
                onSave={(specificUserRoleIds) => {
                  setToBeEditEvent({
                    ...toBeEditEvent,
                    specificUserRoleIds: specificUserRoleIds,
                  })
                }}
              />
            )}
            <PublishEventModal
              newEvent={toBeEditEvent}
              editBookingData={updatePayload}
              departments={departmentsForMultiSchool ?? []}
              showModal={showModal}
              setShowModal={setShowModal}
              onChange={setToBeEditEvent}
              onDone={() => {}}
              onSave={() => {
                setEditEventMode(false)
                handleExpaneded()
              }}
              dataAction={{
                eventGroupInfoId: fetchedEventDetail.eventGroupInfoId,
              }}
            />
          </div>
        </ModalRegular>
      </div>
    )
  },
)

export default EditBookingEventPopUp
