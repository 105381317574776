import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalRegular from '../../../components/ModalRegular'
import { Button } from '../../../components/Button'
import { useContext } from 'react'
import { UserStoreContext } from '../../../store/userStore'
import classNames from 'classnames'
import { getImageFromRole } from '../../../utils/parseUtils'

interface Teacher {
  userId: string
  userRoleId: string
  firstName: string
  lastName: string
  ssn: string
  image: string
  logs: any
  statistics: any
  schedule: any[]
  closedDates: any[]
  schoolName: string
  totalDiff?: { hours: number; minutes: number }
  totalSchedule?: { hours: number; minutes: number }
  totalBreak?: { hours: number; minutes: number }
}

interface TeacherSelectionModalProps {
  isOpen: boolean
  onClose: () => void
  teachers: Teacher[]
  onGeneratePDF: (selectedTeachers: Teacher[]) => void
}

const TeacherSelectionModal: React.FC<TeacherSelectionModalProps> = ({
  isOpen,
  onClose,
  teachers,
  onGeneratePDF,
}) => {
  const { t } = useTranslation()
  const { teacherUserRoles } = useContext(UserStoreContext)

  // Initialize all teachers as selected
  const [selectedTeachers, setSelectedTeachers] = useState<{
    [key: string]: boolean
  }>(() => {
    // Create an object with teacher userRoleIds as keys and true as values
    const initialSelection: { [key: string]: boolean } = {}
    teachers.forEach((teacher) => {
      if (teacher && teacher.userRoleId) {
        initialSelection[teacher.userRoleId] = true
      }
    })
    return initialSelection
  })

  // Toggle selection for a specific teacher
  const toggleTeacherSelection = (teacherRoleId: string) => {
    if (!teacherRoleId) return

    setSelectedTeachers((prev) => ({
      ...prev,
      [teacherRoleId]: !prev[teacherRoleId],
    }))
  }

  // Select or deselect all teachers
  const toggleAllTeachers = (selected: boolean) => {
    const newSelection: { [key: string]: boolean } = {}
    teachers.forEach((teacher) => {
      if (teacher && teacher.userRoleId) {
        newSelection[teacher.userRoleId] = selected
      }
    })
    setSelectedTeachers(newSelection)
  }

  // Get the list of selected teachers
  const getSelectedTeachers = (): Teacher[] => {
    return teachers.filter((teacher) => selectedTeachers[teacher.userRoleId])
  }

  // Handle generate button click
  const handleGenerate = () => {
    const selected = getSelectedTeachers()
    if (selected.length > 0) {
      // Log an array of selected teacher userRoleIds
      console.log(
        'Selected teacher IDs:',
        selected.map((teacher) => teacher.userRoleId),
      )
      onGeneratePDF(selected)
      onClose()
    }
  }

  // Find the corresponding userRole for a teacher
  const findUserRoleForTeacher = (teacher: Teacher) => {
    return teacherUserRoles?.find((role: any) => role.id === teacher.userRoleId)
  }
  return (
    <ModalRegular
      isOpen={isOpen}
      onClose={onClose}
      title={t('statistics.teacherAttendancePDF')}
      className={
        '!max-w-4xl pl-4 pt-6 overflow-hidden !bg-[#f6f6f6] max-h-[90vh]'
      }
      classNameTitle="mb-4 w-full text-black !important flex justify-center "
    >
      <div className="p-6 relative flex flex-col h-full">
        <div className="mb-4 flex justify-end items-center">
          {/*<h3 className="text-lg font-medium">
            {t('statistics.teachersToInclude')}
          </h3>*/}
          <div className="space-x-2">
            <Button
              size="xs"
              variant="transparent"
              label={t('multiselect.selectAll')}
              onClick={() => toggleAllTeachers(true)}
            />
            <Button
              size="xs"
              variant="transparent"
              label={t('multiselect.deSelectAll')}
              onClick={() => toggleAllTeachers(false)}
            />
          </div>
        </div>

        <div className="overflow-y-auto max-h-[60vh] pb-20">
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3">
            {teachers.map((teacher) => {
              const userRole = findUserRoleForTeacher(teacher)
              return userRole ? (
                <UserRoleItem
                  key={teacher.userRoleId}
                  userRole={userRole}
                  selected={selectedTeachers[teacher.userRoleId] || false}
                  onClick={() => toggleTeacherSelection(teacher.userRoleId)}
                />
              ) : null
            })}
          </div>
        </div>
        <div
          className={
            'flex justify-center w-full bg-gradient-to-t from-[#f6f6f6] to-transparent fixed bottom-6 left-0 right-0 pb-4 pt-8 h-[100px]'
          }
        >
          <Button
            size="lg"
            variant="primary"
            fontSize="font-bold"
            className="w-[90%] rounded-full shadow-md"
            label={t('blog.generatePDF')}
            onClick={handleGenerate}
            disabled={getSelectedTeachers().length === 0}
          />
        </div>
      </div>
    </ModalRegular>
  )
}

// UserRoleItem component
const UserRoleItem = ({
  userRole,
  onClick,
  selected,
}: {
  userRole: any
  onClick: () => void
  selected?: boolean
}) => {
  return (
    <div
      className={classNames(
        'flex border hover:bg-blue-50 cursor-pointer rounded-xl items-center justify-center',
        selected ? 'border-blueDark bg-blue-50' : 'bg-white',
      )}
      onClick={onClick}
    >
      <div className="flex flex-col justify-center items-center relative p-2 rounded-xl gap-y-2 flex-shrink-0">
        <img
          className="w-8 h-8 rounded-full"
          src={getImageFromRole(userRole)}
          alt=""
        />

        <p className={'text-2xs text-center font-bold w-[70px] line-clamp-2'}>
          {userRole?.user?.attributes?.firstName + ' '}
          <br />
          {userRole?.user?.attributes?.lastName}
        </p>
      </div>
    </div>
  )
}

export default TeacherSelectionModal
