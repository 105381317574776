import React, { forwardRef, useContext, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faCheck } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'moment/locale/sv'
import 'moment/locale/en-gb'
import 'moment/locale/es'
import { t } from 'i18next'
import { UserStoreContext } from '../store/userStore'
import { TERRITORY } from '../Enums/TyraEnums'
import { faLock } from '@fortawesome/pro-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import i18n from '../i18nextConfig'
import classNames from 'classnames'
import PhoneInput from 'react-phone-input-2'

moment.locale('sv')
moment.locale('es')
moment.locale('en')

type Icon = 'Pen' | 'Lock' | 'None'
type InputFormat =
  | 'default'
  | 'YYYYMMDD-XXXX'
  | 'DATE'
  | 'YYYYMMDD-LLXX'
  | 'textarea'
  | 'phone'

interface CustomInputProps {
  label?: string
  value?: string
  onChange: (value: string) => void
  placeholder?: string
  longPlaceholder?: string
  onlyNumbers?: boolean
  onlyNumbersAndDot?: boolean
  format?: InputFormat
  required?: boolean
  className?: string
  onClick?: () => void
  icon?: Icon
  roundedFull?: boolean
  loginTerritory?: number
  disabled?: boolean
}

const CustomInputNew: React.FC<CustomInputProps> = ({
  label,
  value,
  onChange,
  placeholder,
  longPlaceholder,
  onlyNumbers = false,
  onlyNumbersAndDot = false,
  format = 'default',
  required = false,
  className,
  onClick,
  icon,
  roundedFull = false,
  loginTerritory,
  disabled = false,
}) => {
  const borderRadiusClass = roundedFull ? 'rounded-full' : 'rounded-lg'
  const inputValue = value || ''
  const { currentUserRole } = useContext(UserStoreContext)
  const handleFocus = (event: React.MouseEvent<HTMLDivElement>) => {
    if (format === 'DATE') {
      event.stopPropagation()
      handleDatePickerFocus()
    } else {
    }
  }

  const [datePickerOpen, setDatePickerOpen] = useState(false)
  const datePickerRef = useRef<DatePicker | null>(null)
  const handleDatePickerFocus = () => {
    if (!datePickerOpen) {
      setDatePickerOpen(true)
      datePickerRef.current?.setFocus()
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent) => {
    const territory =
      currentUserRole?.school_pointer.attributes.territory ?? loginTerritory

    if (format === 'YYYYMMDD-XXXX' && territory === TERRITORY.SPAIN) {
      if (inputValue.length >= 10) {
        e.preventDefault()
      }
    } else {
      if (onlyNumbers && !/[0-9]/.test(e.key)) {
        e.preventDefault()
      }
      if (onlyNumbersAndDot && !/[0-9.]/.test(e.key)) {
        e.preventDefault()
      }
      if (
        format === 'YYYYMMDD-XXXX' &&
        (!/[0-9-]/.test(e.key) || inputValue.length >= 13)
      ) {
        e.preventDefault()
      }
    }
    if (format === 'YYYYMMDD-LLXX') {
      const validCharRegex =
        inputValue.length < 8
          ? /[0-9]/
          : inputValue.length === 8
          ? /[-]/
          : inputValue.length < 11
          ? /[A-Za-z0-9]/
          : /[A-Za-z0-9]/ //: /[0-9]/

      if (!validCharRegex.test(e.key) || inputValue.length >= 13) {
        e.preventDefault()
      }
    }
  }

  const isValueValid = () => {
    if (inputValue === '') return false

    if (format === 'YYYYMMDD-XXXX') {
      if (territory === TERRITORY.SPAIN) {
        const spanishInputRegex = /^[A-Za-z0-9]{7,10}$/
        return spanishInputRegex.test(inputValue)
      } else {
        const regex = /^\d{8}-\d{4}$/
        const datePart = inputValue.substring(0, 8)
        return (
          regex.test(inputValue) && moment(datePart, 'YYYYMMDD', true).isValid()
        )
      }
    }

    if (format === 'YYYYMMDD-LLXX') {
      if (territory === TERRITORY.SPAIN) {
        const spanishInputRegex = /^[A-Za-z0-9]{7,10}$/
        return spanishInputRegex.test(inputValue)
      } else {
        //const regex = /^\d{8}-[A-Za-z0-9]{2}\d{2}$/
        const regex = /^\d{8}-[A-Za-z0-9]{4}$/
        const datePart = inputValue.substring(0, 8)
        return (
          regex.test(inputValue) && moment(datePart, 'YYYYMMDD', true).isValid()
        )
      }
    }

    if (format === 'DATE') {
      return moment(inputValue, 'YYYY-MM-DD', true).isValid()
    }

    if (onlyNumbers) {
      return /^\d+$/.test(inputValue)
    }
    if (onlyNumbersAndDot) {
      return /^\d+(\.\d*)?$/.test(inputValue)
    }

    return true
  }

  const getDynamicPlaceholder = (currentValue: string) => {
    let formatKey = ''
    switch (
      currentUserRole?.school_pointer.attributes.territory ??
      loginTerritory
    ) {
      case TERRITORY.SWEDEN:
        formatKey = t('admin.placeholderPSNInputSweden')
        break
      case TERRITORY.NORWAY:
        formatKey = t('admin.placeholderPSNInputNorway')
        break
      case TERRITORY.SPAIN:
        formatKey = t('admin.placeholderPSNInputSpain')
        break
      default:
        formatKey = t('admin.placeholderPSNInputDefault')
    }
    const format = t(formatKey)
    let placeholder = ''

    for (let i = 0; i < format.length; i++) {
      if (i < currentValue.length) {
        placeholder += ' ' // byter ut med mellanrum
      } else {
        placeholder += format[i] // behåller kvarståend format
      }
    }

    return placeholder
  }
  const getIcon = (iconType?: Icon): IconProp | null => {
    switch (iconType) {
      case 'Pen':
        return faPen
      case 'Lock':
        return faLock
      case 'None':
        return null
      default:
        return faPen
    }
  }

  const iconToRender = getIcon(icon)
  const territory =
    currentUserRole?.school_pointer.attributes.territory ?? loginTerritory
  const isSpain = territory === TERRITORY.SPAIN && format === 'YYYYMMDD-XXXX'

  return (
    <div
      onClick={disabled ? undefined : onClick}
      className={`flex flex-row ${disabled && 'cursor-not-allowed'}`}
    >
      {' '}
      <div
        className={`flex relative flex-row w-full justify-between bg-white shadow-sm cursor-pointer ${
          disabled && 'cursor-not-allowed border-gray-500 bg-transparent'
        } rounded-2xl p-2 items-center ${className}
        ${
          isValueValid()
            ? /*'border-green-500'*/ ''
            : /*'border-yellow-500'*/ ''
        }`}
        onClick={handleFocus}
      >
        {required && (
          <div className="absolute text-2xs font-bold top-0 right-2 mt-1 text-black items-center">
            🫡 Obligatoriskt
          </div>
        )}
        <div className="w-full">
          <div
            className={classNames(
              'text-sm absolute pl-3 top-0 left-0 w-full h-full flex flex-row  transition duration-75',
              disabled ? 'text-gray-300' : '',
              !value
                ? format === 'phone'
                  ? 'text-xs font-bold'
                  : 'items-center text-gray-500 font-semibold'
                : 'text-xs font-bold',
            )}
          >
            {/*!disabled && required && !inputValue && (
              <div className="text-red-500">*</div>
            )*/}

            <div
              className={classNames(
                'items-center transition duration-75',
                //value ? 'mt-1' : '',
                value ? 'animate-floatPlaceholder' : '',
              )}
            >
              {value ? placeholder : longPlaceholder ?? placeholder}
            </div>
          </div>

          {format === 'DATE' ? (
            <div className="relative">
              <DatePicker
                ref={datePickerRef}
                locale={i18n.language}
                selected={inputValue ? new Date(inputValue) : null}
                onChange={(date: Date) => {
                  const formattedDate = moment(date).format('YYYY-MM-DD')
                  onChange(formattedDate)
                  setDatePickerOpen(false)
                }}
                open={datePickerOpen}
                onCalendarClose={() => setDatePickerOpen(false)}
                onSelect={() => {
                  setDatePickerOpen(false)
                }}
                dateFormat="yyyy-MM-dd"
                className="focus:outline-none cursor-pointer bg-transparent"
                onBlur={() => {
                  setDatePickerOpen(false)
                }}
                shouldCloseOnSelect
                disabled={disabled}
              />
            </div>
          ) : (
            <>
              {format === 'textarea' ? (
                <></>
              ) : (
                <div className="relative w-full bg-transparent customPhoneInput">
                  {format === 'phone' ? (
                    <PhoneInput
                      searchClass=""
                      country={'se'}
                      value={''}
                      autoFormat={false}
                      onChange={(phone, xx, e) => {
                        var s = phone
                        if (s.charAt(2) === '0') {
                          e.preventDefault()
                          var position = 3 // its 1 based
                          s =
                            s.substring(0, position - 1) +
                            s.substring(position, s.length)
                        }
                        onChange('+' + s)
                      }}
                    />
                  ) : (
                    <input
                      disabled={disabled}
                      onChange={(e) => {
                        let newVal = e.target.value
                        const territory =
                          currentUserRole?.school_pointer.attributes
                            .territory ?? loginTerritory
                        // Kontrollera om användaren försöker ta bort bindestrecket
                        if (
                          format === 'YYYYMMDD-XXXX' &&
                          inputValue.length === 9 &&
                          newVal.length === 8
                        ) {
                          onChange(newVal)
                          return
                        }
                        if (
                          format === 'YYYYMMDD-LLXX' &&
                          inputValue.length === 9 &&
                          newVal.length === 8
                        ) {
                          onChange(newVal)
                          return
                        }
                        if (format === 'YYYYMMDD-LLXX') {
                          if (newVal.length > 9) {
                            const firstPart = newVal.substring(0, 9)
                            const secondPart = newVal.substring(9).toUpperCase()
                            newVal = firstPart + secondPart
                          }

                          if (newVal.length === 8 && !newVal.includes('-')) {
                            newVal = newVal + '-'
                          } else if (
                            newVal.length === 10 &&
                            newVal[8] !== '-'
                          ) {
                            newVal = newVal.slice(0, 9) + '-' + newVal.slice(9)
                          }
                          onChange(newVal)
                        }
                        if (format === 'YYYYMMDD-LLXX') {
                          if (newVal.length === 8 && !newVal.includes('-')) {
                            newVal = newVal + '-'
                          } else if (
                            newVal.length === 10 &&
                            newVal[8] !== '-'
                          ) {
                            newVal = newVal.slice(0, 9) + '-' + newVal.slice(9)
                          }
                          onChange(newVal)
                        }
                        if (
                          format === 'YYYYMMDD-XXXX' &&
                          newVal.length === 8 &&
                          !newVal.includes('-')
                        ) {
                          if (territory === TERRITORY.SWEDEN) newVal += '-'
                        }

                        onChange(newVal)
                        if (format === 'YYYYMMDD-XXXX') {
                          if (territory === TERRITORY.SPAIN) {
                            if (newVal.length === 8 && !newVal.includes('-')) {
                              newVal += '-'
                            }
                          } else {
                            if (newVal.length > 9) {
                              newVal = newVal.slice(0, 9)
                            }
                          }
                        }
                        if (format === 'YYYYMMDD-LLXX') {
                          if (territory === TERRITORY.SPAIN) {
                            if (newVal.length === 8 && !newVal.includes('-')) {
                              newVal += '-'
                            }
                          } else {
                            if (newVal.length > 9) {
                              newVal = newVal.slice(0, 9)
                            }
                          }
                        }
                      }}
                      value={inputValue}
                      className={`focus:outline-none cursor-pointer bg-transparent w-full relative h-[45px] pl-1 ${
                        disabled && 'cursor-not-allowed'
                      }`}
                      onKeyPress={handleKeyPress}
                      placeholder={isSpain ? '' : undefined}
                    />
                  )}

                  {!isSpain &&
                    value &&
                    format === 'YYYYMMDD-XXXX' &&
                    inputValue.length < 13 && (
                      <span className="absolute left-1 top-0 flex items-center h-full pointer-events-none text-gray-400 z-0">
                        <span className="bg-transparent">{inputValue}</span>
                        <span>{getDynamicPlaceholder(inputValue)}</span>
                      </span>
                    )}
                  {!isSpain &&
                    value &&
                    format === 'YYYYMMDD-LLXX' &&
                    inputValue.length < 13 && (
                      <span className="absolute left-1 top-0 flex items-center h-full pointer-events-none text-gray-400 z-0">
                        <span className="bg-transparent">{inputValue}</span>
                        <span>{getDynamicPlaceholder(inputValue)}</span>
                      </span>
                    )}
                </div>
              )}
            </>
          )}
        </div>

        {/*isValueValid() ? (
          <FontAwesomeIcon icon={faCheck} className="text-green-500" />
        ) : (
          iconToRender && (
            <FontAwesomeIcon icon={iconToRender} className="text-gray-300" />
          )
          )*/}
      </div>
    </div>
  )
}

export default CustomInputNew
