import React from 'react'
import { BloggDoc } from '../../../Models/BloggDoc'
import moment, { Moment as MomentType } from 'moment'
import Moment from 'react-moment'
import 'moment-timezone'
import 'moment/locale/sv'
import { SORT_VALUES } from '../Statistics'
import { PresenceScheduleDiffContainer } from './PresenceScheduleDiffContainer'
import Singleselect from '../../../components/Singleselect'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const StatisticsSummeryRow = ({
  index,
  ahref,
  image,
  sortBy,
  firstName,
  lastName,
  ssn,
  timeAttendingTotal,
  scheduleTotal,
  timeDifferenceTotal,
  tariffValues,
  tariff,
  onSaveTariff,
  exporting,
  showSsn,
  timeAttending,
  scheduleHoursPerWeek,
  timeDifference,
  type,
  teacherBreakTotal,
  teacherBreakPerWeek,
}: {
  index: number
  ahref: string
  image: string
  sortBy: string
  firstName: string
  lastName: string
  ssn?: string
  timeAttendingTotal: any
  scheduleTotal: any
  timeDifferenceTotal: any
  timeAttending: any
  scheduleHoursPerWeek: any
  timeDifference: any
  tariffValues: {
    value: string | null
    label: string
  }[]
  tariff: any
  onSaveTariff: (e: any) => void
  exporting: boolean
  showSsn: boolean
  type: 'kid' | 'teacher'
  teacherBreakTotal?: any
  teacherBreakPerWeek?: any
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <tr key={index} className="hover:bg-blue-50">
      {/*<a
        href={ahref}
        target="_self"
        className="block p-2 whitespace-nowrap font-bold text-gray-800"
  >*/}
      <td
        className="cursor-pointer"
        onClick={() => {
          navigate(ahref)
        }}
      >
        <div className="flex items-center">
          <div className="flex-shrink-0 mr-2 sm:mr-3">
            <img
              className="rounded-full"
              src={image}
              width="60"
              height="60"
              alt={''}
            />
          </div>
          <div className="text-ellipsis overflow-hidden">
            {sortBy === SORT_VALUES.FIRSTNAMEASC ||
            sortBy === SORT_VALUES.FIRSTNAMEDESC
              ? firstName
              : null}
            {sortBy === SORT_VALUES.LASTNAMEASC ||
            sortBy === SORT_VALUES.LASTNAMEDESC
              ? lastName
              : null}
            <br />{' '}
            <span className="font-normal">
              {sortBy === SORT_VALUES.FIRSTNAMEASC ||
              sortBy === SORT_VALUES.FIRSTNAMEDESC
                ? lastName
                : null}
              {sortBy === SORT_VALUES.LASTNAMEASC ||
              sortBy === SORT_VALUES.LASTNAMEDESC
                ? firstName
                : null}
            </span>
            <br />
            {!exporting ? (
              <span className="text-xs text-gray-700 font-normal">{ssn}</span>
            ) : showSsn ? (
              <span className="text-xs text-gray-700 font-normal">{ssn}</span>
            ) : null}
          </div>
        </div>
      </td>
      {/*</a>*/}
      <td className="p-2 whitespace-nowrap text-left font-medium text-gray-800">
        <PresenceScheduleDiffContainer
          presence={timeAttendingTotal}
          schema={scheduleTotal}
          diff={timeDifferenceTotal}
          compact={false}
          showHeader={false}
          type={type}
          teacherBreak={teacherBreakTotal}
        />
      </td>
      <td className="p-2 whitespace-nowrap text-left font-medium text-gray-800">
        <PresenceScheduleDiffContainer
          presence={timeAttending}
          schema={scheduleHoursPerWeek}
          diff={timeDifference}
          compact={false}
          showHeader={false}
          type={type}
          teacherBreak={teacherBreakPerWeek}
        />
      </td>
      <td className="p-2 whitespace-nowrap text-left font-medium text-gray-800">
        {scheduleHoursPerWeek.totalFree + ' '}
        {scheduleHoursPerWeek?.totalFreeNotBeen > 0 && (
          <>
            <span className="text-orange-400 group relative w-max cursor-help">
              {' '}
              ({scheduleHoursPerWeek.totalFreeNotBeen})
              <span className="pointer-events-none absolute -top-10 right-0 w-max opacity-0 transition-opacity group-hover:opacity-100 border p-1 rounded-xl text-xs bg-gray-100 border-gray-200 text-gray-900">
                {t('statistics.freeNotBeen')}
              </span>
            </span>{' '}
          </>
        )}
        {' ' + t('absence.absenceDays')}{' '}
      </td>
      {tariffValues.length ? (
        <td className="p-2 whitespace-nowrap text-lg text-center">
          <Singleselect
            dropdownClassName="w-[295px] -right-[25px] rounded-full flex flex-col w-full border cursor-pointer"
            removeSelected
            hasBorder
            maxWidth="190px"
            onChange={(e: any) => {
              onSaveTariff(e)
            }}
            items={tariffValues}
            initialSelectedItem={
              tariff
                ? tariffValues.find((v) => Number(v.value) == Number(tariff))
                : {
                    value: null,
                    label: t('statistics.tariffNothingSelected'),
                  }
            }
            itemVariableKey={'label'}
            placeholder={t('statistics.tariff')}
          />
        </td>
      ) : null}
    </tr>
  )
}
