import { useContext, useState } from 'react'
import { UserStoreContext } from '../../../store/userStore'
import { EventListItemData } from '../Events3'
import {
  EVENT3_TYPE,
  Event3StoreContext,
  EventInfoResponse,
} from '../../../store/event3Store'
import {
  faCopy,
  faEyeSlash,
  faPen,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { Button } from '../../../components/Button'
import { useTranslation } from 'react-i18next'
import EditEventPopUp from './EditEventPopUp'
import { ButtonDropdown } from '../../../components/Dropdown'
import { observer } from 'mobx-react'
import ModalRegular from '../../../components/ModalRegular'
import EditBookingEventPopUp from './EditBookinEventPopUp'
import { useNavigate, useParams } from 'react-router-dom'

export const SettingsDetailView: React.FC<{
  openSttings: boolean
  fetchedEventDetail?: EventListItemData
  eventInfo?: EventInfoResponse
  handleExpaneded: (action?: string) => void
  handleToggleMenu?: () => void
}> = observer(
  ({
    openSttings,
    fetchedEventDetail,
    eventInfo,
    handleExpaneded,
    handleToggleMenu,
  }) => {
    const { currentUserRole } = useContext(UserStoreContext)
    const { deleteEvent, setEventsAsUnRead, deleteBookingEvent } =
      useContext(Event3StoreContext)
    const [deleteConfirmation, setDeletConfirmation] = useState<boolean>(false)
    const [deleteRecurring, setDeleteRecurring] = useState<boolean>(false)
    const [editMode, setEditEventMode] = useState<boolean>(false)
    const [editBookingMode, setEditBookingEventMode] = useState<boolean>(false)
    const navigate = useNavigate()
    const { schoolId } = useParams()
    const { t } = useTranslation()

    const toggleEditMode = () => {
      setEditEventMode(!editMode)
    }
    const handleDeletReccuring = () => {
      setDeleteRecurring(!deleteRecurring)
    }

    const handleDeleteEvent = () => {
      setDeletConfirmation(!deleteConfirmation)
    }

    return (
      <>
        {currentUserRole?.role_type && currentUserRole?.role_type > 350 ? (
          <div
            className={`absolute ${
              !eventInfo ? 'right-6 top-4' : 'right-10 top-4'
            }  mx-3 origin-top divide-y !min-w-[200px] ${
              fetchedEventDetail?.times[0].end_time && 'top-9'
            }`}
            style={{
              display: openSttings ? 'block' : 'none',
            }}
          >
            <ButtonDropdown.Menu>
              <ButtonDropdown.Section>
                <>
                  <ButtonDropdown.Button
                    icon={faPen}
                    label={t('blog.edit')}
                    className="text-blue-300"
                    onClick={(e) => {
                      e.stopPropagation()
                      if (eventInfo) {
                        setEditBookingEventMode(!editBookingMode)
                      } else toggleEditMode()
                    }}
                  />
                  {fetchedEventDetail?.type !== EVENT3_TYPE.BOOKING && (
                    <>
                      {eventInfo === undefined && (
                        <ButtonDropdown.Button
                          icon={faCopy}
                          label={t('documents.copy')}
                          className="text-greenForrest"
                          onClick={() => {
                            navigate('/events/create/' + schoolId, {
                              state: { copiedEvent: fetchedEventDetail },
                            })
                          }}
                        />
                      )}

                      {!eventInfo && (
                        <ButtonDropdown.Button
                          icon={faEyeSlash}
                          label={t('documents.setAsUnread')}
                          className="text-purple-300"
                          onClick={(e) => {
                            e.stopPropagation()
                            setEventsAsUnRead(
                              fetchedEventDetail?.eventGroupInfoId,
                            )
                            handleToggleMenu?.()
                            handleExpaneded()
                          }}
                        />
                      )}
                      <ButtonDropdown.Button
                        icon={faTrash}
                        label={t('absence.delete')}
                        className="text-red-300"
                        onClick={(e) => {
                          e.stopPropagation()
                          handleDeleteEvent()
                        }}
                      />
                    </>
                  )}
                </>
              </ButtonDropdown.Section>
            </ButtonDropdown.Menu>
          </div>
        ) : (
          <div
            className="absolute right-9 top-5  z-50 mx-3 origin-top divide-y  w-[200px]"
            style={{
              display: openSttings ? 'block' : 'none',
            }}
          >
            <ButtonDropdown.Menu>
              <ButtonDropdown.Section>
                <ButtonDropdown.Button
                  icon={faEyeSlash}
                  label="Set as unread"
                  className="text-purple-300"
                  onClick={(e) => {
                    e.stopPropagation()
                    setEventsAsUnRead(fetchedEventDetail?.eventGroupInfoId)
                    handleToggleMenu?.()
                    handleExpaneded()
                  }}
                />
              </ButtonDropdown.Section>
            </ButtonDropdown.Menu>
          </div>
        )}
        <div onClick={(e) => e.stopPropagation()}>
          <ModalRegular
            title={t('events.deleteEvent') + '?'}
            isOpen={deleteConfirmation}
            className={'!max-w-lg pt-6 !relative'}
            classNameTitle="mb-4 w-full text-black !important flex justify-center"
            onClose={() => {
              setDeletConfirmation(false)
              handleToggleMenu?.()
            }}
          >
            <div className="flex flex-col">
              {fetchedEventDetail?.isRecurring && (
                <label className="flex items-center space-x-2 justify-center">
                  <span className="font-semibold py-2">
                    {t('events.deleteAllRecurring')}
                  </span>
                  <input
                    type="checkbox"
                    checked={deleteRecurring}
                    onChange={handleDeletReccuring}
                    onClick={(e) => e.stopPropagation()}
                    className="form-checkbox h-4 w-4 text-indigo-600"
                  />
                </label>
              )}

              <div className="absolute bottom-10 w-full flex justify-center  gap-4">
                <Button
                  size="sm"
                  variant="fullWhiteGrayText"
                  fontSize="font-bold"
                  label={t('general.cancel')}
                  onClick={async (e) => {
                    e.stopPropagation()
                    setDeletConfirmation(!deleteConfirmation)
                  }}
                />
                <Button
                  size="sm"
                  variant="fullRed"
                  fontSize="font-bold"
                  label={t('absence.delete') + ' 👍'}
                  onClick={async (e) => {
                    e.stopPropagation()
                    if (!eventInfo) {
                      deleteEvent(
                        fetchedEventDetail?.eventTimeId,
                        fetchedEventDetail?.eventGroupInfoId,
                        deleteRecurring,
                      )
                    } else {
                      deleteBookingEvent(eventInfo.eventGroupInfoId)
                      setDeletConfirmation(!deleteConfirmation)
                      handleToggleMenu?.()
                    }
                    handleExpaneded()
                    console.log('must be closed')
                  }}
                />
              </div>
            </div>
            <p className="flex w-full text-center mt-4 font-semibold justify-center">
              {t('events.deleteEventInfo')}
            </p>
          </ModalRegular>
        </div>
        {editMode && fetchedEventDetail && (
          <EditEventPopUp
            fetchedEventDetail={fetchedEventDetail}
            editMode={editMode}
            setEditEventMode={setEditEventMode}
            closeSettingMenu={handleToggleMenu}
          />
        )}
        {editBookingMode && eventInfo && (
          <EditBookingEventPopUp
            fetchedEventDetail={eventInfo}
            editMode={editBookingMode}
            setEditEventMode={setEditBookingEventMode}
            handleExpaneded={handleExpaneded}
            handleToggleMenu={handleToggleMenu}
          />
        )}
      </>
    )
  },
)
