import { useContext, useEffect, useState } from 'react'
import Page from '../../../../../components/Page'
import { NavigationStoreContext } from '../../../../../store/navigationStore'
import { KidsStoreContext } from '../../../../../store/kidsStore'
import { UserStoreContext } from '../../../../../store/userStore'
import { DepartmentStoreContext } from '../../../../../store/departmentStore'
import {
  getImageFromUser,
  getImageFromUserBall,
  getImageOrPlaceHolder,
} from '../../../../../utils/parseUtils'
import { Kid } from '../../../../../Models/Kid'
import { useParams } from 'react-router-dom'
import { Loading } from '../../../../../components/Loading'
import React from 'react'
import KidListCardHorizontal from '../../../../../components/kid/KidListCardHorizontal'
import { observer } from 'mobx-react'
import { Button } from '../../../../../components/Button'
import { USER_ROLE_STATUS } from '../../../../../Enums/TyraEnums'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { AdminStoreContext } from '../../../../../store/adminStore'
import ModalRegular from '../../../../../components/ModalRegular'
interface Props {
  onChoose: (value: 'existing' | 'new') => void
  onBack: () => void
  kid: Kid
  refetchKids?: () => void
}
export const ChooseOrCreateParent: React.FC<Props> = observer(
  ({ onChoose, onBack, kid, refetchKids }) => {
    const { t } = useTranslation()
    const [showInviteModal, setShowInviteModal] = useState(false)
    const { fetchKids, kids } = useContext(KidsStoreContext)
    const { currentUserRole, fetchUserRolesToInviteToKid, fetchNewUserRoles } =
      useContext(UserStoreContext)
    const { departments, fetchDepartments } = useContext(DepartmentStoreContext)
    const { setTitle } = useContext(NavigationStoreContext)

    const { schoolId } = useParams()
    const [loading, setLoading] = useState(false)
    const { cancelUserRole } = useContext(AdminStoreContext)
    const [deleteConfirmation, setDeletConfirmation] = useState<boolean>(false)

    useEffect(() => {
      setTitle(t('admin.inviteParent'))
    }, [t])

    return (
      <Page>
        {loading && <Loading />}
        <Button
          size="sm"
          className="w-[10%] mx-4 mt-4 text-nowrap"
          variant="callToActionBlue"
          fontSize="font-bold"
          label={t('general.backWithEmoji')}
          onClick={() => {
            onBack()
          }}
        />
        <div className="p-8">
          <KidListCardHorizontal
            kid={kid}
            showDepartmentName
            naked
            makeCenter
            onlyName
          />
          <div className="flex justify-center my-12">
            <div className="grid grid-cols-2 justify-center gap-x-6 max-w-[600px]">
              <div className="grid grid-cols-1 gap-y-4">
                <Button
                  onClick={() => onChoose('existing')}
                  variant="borderBlueSelected"
                  label={t('general.existingUser')}
                  className="max-h-[70px] font-bold"
                />
                <div className="text-sm text-center font-semibold">
                  {t('admin.userAlreadyConnectedToPreSchool')}
                </div>
              </div>
              <div className="grid grid-cols-1 gap-y-4">
                <Button
                  onClick={() => onChoose('new')}
                  variant="callToActionBlue"
                  fontSize="font-bold"
                  label={t('admin.newUser')}
                  className="max-h-[70px] font-bold"
                />
                <div className="text-sm text-center font-semibold">
                  {t('admin.userNotGuardianOrStaff')}
                </div>
              </div>
            </div>
          </div>
          {kid.relatives?.length > 0 &&
            kid.relatives?.some((rel) => rel.status !== undefined) && (
              <div className="flex text-xl font-bold mt-8 mb-4">
                {t('admin.alreadyInvited')}
              </div>
            )}
          <div className="grid grid-cols-3 gap-3">
            {kid.relatives?.map((rel) => {
              console.log('rel', rel)
              if (rel.status === USER_ROLE_STATUS.ACTIVE) {
                return (
                  <div className="w-full min-h-[60px] overflow-hidden rounded-2xl bg-white  flex flex-row justify-between items-center gap-x-1">
                    <div className="flex flex-row justify-start items-center gap-x-2">
                      <img
                        src={getImageFromUser(rel.user!)}
                        className={classNames(
                          'size-10 object-cover rounded-full ml-1',
                        )}
                        alt="relativImage"
                      />
                      <div>
                        <p className="text-sm  truncate w-[170px]">
                          {' '}
                          {rel.user?.firstName + ' ' + rel.user?.lastName}
                        </p>
                        <p className="text-sm">
                          !!Inbjuden på:{' '}
                          {rel.user?.user_type === 20
                            ? 'Email'
                            : rel.user?.user_type === 30
                            ? 'phone'
                            : rel.user?.user_type === 40
                            ? 'bankid'
                            : ''}
                        </p>
                      </div>
                    </div>
                    <p className="flex items-center mr-4 text-sm text-greenForrest font-semibold">
                      {t('general.active')}
                    </p>
                  </div>
                )
              } else if (rel.status === USER_ROLE_STATUS.PENDING) {
                return (
                  <div className="w-full min-h-[60px] overflow-hidden rounded-2xl bg-white  flex flex-row justify-between items-center gap-x-1">
                    <div className="flex flex-row justify-start items-center gap-x-2">
                      {' '}
                      <img
                        src={getImageFromUser(rel.user!)}
                        className={classNames(
                          ' size-10 object-cover rounded-full ml-1 ',
                        )}
                        alt="relativImage"
                      />
                      <div>
                        <p className="text-sm  truncate w-[170px]">
                          {' '}
                          {rel.invite_note}{' '}
                        </p>
                        <p className="text-sm">
                          !!Inbjuden på:{' '}
                          {rel.user?.user_type === 20
                            ? 'Email'
                            : rel.user?.user_type === 30
                            ? 'phone'
                            : rel.user?.user_type === 40
                            ? 'bankid'
                            : ''}
                        </p>
                      </div>
                    </div>
                    <p className="flex items-center mr-4 text-sm">
                      !! Väntar godkännande
                    </p>
                  </div>
                )
              } else if (
                rel.status === USER_ROLE_STATUS.NEW &&
                (rel.user?.firstName || rel.invite_note)
              ) {
                return (
                  <div className="w-full min-h-[60px] overflow-hidden rounded-2xl bg-white  flex flex-row justify-between items-center gap-x-1">
                    <div className="flex flex-row justify-start items-center gap-x-2">
                      <img
                        src={getImageFromUser(rel.user!)}
                        className={classNames(
                          'size-10 object-cover rounded-full ml-1',
                        )}
                        alt="relativImage"
                      />
                      <div>
                        <p className="text-sm truncate w-[170px]">
                          {' '}
                          {rel.user?.firstName
                            ? rel.user.firstName + ' ' + rel.user.lastName
                            : rel.invite_note}{' '}
                        </p>
                        <p className="text-sm">
                          !!Inbjuden på:{' '}
                          {rel.user?.user_type === 20
                            ? 'Email'
                            : rel.user?.user_type === 30
                            ? 'phone'
                            : rel.user?.user_type === 40
                            ? 'bankid'
                            : ''}
                        </p>
                      </div>
                    </div>
                    <Button
                      className=""
                      size="xs"
                      variant="borderRed"
                      fontSize="font-bold"
                      label={t('general.cancel')}
                      onClick={() => {
                        console.log(rel)
                        setDeletConfirmation(true)
                      }}
                    />
                    <div onClick={(e) => e.stopPropagation()}>
                      <ModalRegular
                        title={t('events.Inbjudan') + '?'}
                        isOpen={deleteConfirmation}
                        className={'!max-w-lg pt-6 !relative'}
                        classNameTitle="mb-4 w-full text-black !important flex justify-center"
                        onClose={() => {
                          setDeletConfirmation(false)
                        }}
                      >
                        <div className="flex flex-col">
                          <div className="absolute bottom-10 w-full flex justify-center  gap-4">
                            <Button
                              size="sm"
                              variant="fullWhiteGrayText"
                              fontSize="font-bold"
                              label={t('general.cancel')}
                              onClick={async (e) => {
                                e.stopPropagation()
                                setDeletConfirmation(false)
                              }}
                            />
                            <Button
                              size="sm"
                              variant="fullRed"
                              fontSize="font-bold"
                              label={t('absence.delete') + ' 👍'}
                              onClick={async (e) => {
                                e.stopPropagation()
                                cancelUserRole([rel])
                                refetchKids?.()
                              }}
                            />
                          </div>
                        </div>
                        <p className="flex w-full text-center mt-4 font-semibold justify-center">
                          {t('admin.removeActiveMoreKids').replace(
                            'XYC1337',
                            '"' +
                              rel.user?.firstName +
                              '"' +
                              ' and ' +
                              '"' +
                              rel.kid?.firstName +
                              '"',
                          )}
                        </p>
                      </ModalRegular>
                    </div>
                  </div>
                )
              }
            })}
          </div>
        </div>
      </Page>
    )
  },
)
