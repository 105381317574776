import { useContext, useEffect, useState } from 'react'
import { NavigationStoreContext } from '../../../../../store/navigationStore'
import { KidsStoreContext } from '../../../../../store/kidsStore'
import { UserStoreContext } from '../../../../../store/userStore'
import { DepartmentStoreContext } from '../../../../../store/departmentStore'
import { getImageOrPlaceHolder } from '../../../../../utils/parseUtils'
import { Kid } from '../../../../../Models/Kid'
import { useParams } from 'react-router-dom'
import { Loading } from '../../../../../components/Loading'
import React from 'react'
import KidListCardHorizontal from '../../../../../components/kid/KidListCardHorizontal'
import { observer } from 'mobx-react'
import { Button } from '../../../../../components/Button'
import {
  USER_ROLE_STATUS,
  USER_ROLE_TYPE,
} from '../../../../../Enums/TyraEnums'
import { TabHeaderSlider } from '../../../../documents/DocumentMain'
import {
  ActiveUsers,
  DocumentStoreContext,
} from '../../../../../store/documentStore'
import {
  GuardianItem,
  UserRoleItem,
  findKids,
  findRoles,
} from '../../../../events3/components/SpecificPersonModal'
import { UserRole } from '../../../../../Models/UserRole'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import Fuse from 'fuse.js'
import Page from '../../../../../components/Page'

interface Props {
  onSelectedUserRole: (userRole: UserRole) => void
  parentComponent?: string
}
export const ChooseExistingUser: React.FC<Props> = observer(
  ({ onSelectedUserRole, parentComponent }) => {
    const { t } = useTranslation()
    const {
      fetchUserRolesToInviteToKid,
      userRolesToInviteToKid,
      currentUserRole,
      fetchExistingGuardianToBeInviteAsStaff,
      existingGuardians,
    } = useContext(UserStoreContext)
    const { fetchKids, kids } = useContext(KidsStoreContext)

    const { getSchoolActiveUsers, schoolsActiveUsers } =
      useContext(DocumentStoreContext)
    const { fetchDepartments, departments } = useContext(DepartmentStoreContext)
    const [selectedUserRoleIds, setSelectedUserRoleIds] = useState<any>([])

    const { setTitle } = useContext(NavigationStoreContext)
    const [searchActiveUsers, setSearchActiveUsers] =
      useState<ActiveUsers | null>()

    const [activeTab, setActiveTab] = useState<{
      label: string
      value: string
      color?: string
    }>({
      label: t('general.guardian'),
      value: 'guardians',
    })

    const [loading, setLoading] = useState(false)
    const { schoolId } = useParams()
    const [searchGoal, setSearchGoal] = useState<string>('')

    useEffect(() => {
      if (schoolId) {
        fetchUserRolesToInviteToKid(schoolId)
        fetchExistingGuardianToBeInviteAsStaff(schoolId)
        fetchDepartments(schoolId)
        if (currentUserRole) {
          getSchoolActiveUsers(currentUserRole.id)
          fetchKids(currentUserRole)
        }
      }
    }, [])

    const search = (searchText: string) => {
      const roles = findRoles(schoolsActiveUsers?.userRoles, searchText)
      const kidsSearch = findKids(schoolsActiveUsers?.kids, searchText)
      if (searchText?.length > 0) {
        setSearchActiveUsers({ userRoles: roles, kids: kidsSearch })
      } else {
        setSearchActiveUsers(null)
      }
    }

    useEffect(() => {
      setTitle(t('admin.inviteParent'))
    }, [t])

    /*  const hasMultipleRoles = (
      userRoles: { user?: { id: string }; status: number }[],
    ) => {
      const roleCount = new Map<string, number>()

      userRoles.forEach((role) => {
        if (role.user?.id) {
          roleCount.set(role.user.id, (roleCount.get(role.user.id) || 0) + 1)
          console.log('-----------****>', roleCount, '---- ', role.status)
        }
      })
      console.log()

      return Array.from(roleCount.values()).some((count) => count > 1)
    } */

    const sortGuardiansByFirstLetter = (
      userRoles?: UserRole[],
    ): {
      department: { id: string; name: string }
      userRoles: UserRole[]
    }[] => {
      if (!userRoles || userRoles.length < 1) {
        return []
      }
      // Use a Map to ensure uniqueness based on user ID
      const uniqueUsers = new Map<string, UserRole>()

      for (const userRole of userRoles) {
        if (userRole.user) {
          uniqueUsers.set(userRole.user.id, userRole) // Ensures only one instance per user
        }
      }

      // Convert unique users into an array
      const uniqueUserRoles = Array.from(uniqueUsers.values()) // Fix applied here

      // Group users by the first letter of their firstName
      const userRoleGroups: Record<string, UserRole[]> = {}

      for (const userRole of uniqueUserRoles) {
        const firstLetter =
          userRole?.user?.firstName?.charAt(0).toUpperCase() || '#'

        if (!userRoleGroups[firstLetter]) {
          userRoleGroups[firstLetter] = []
        }

        userRoleGroups[firstLetter].push(userRole)
      }

      // Sort letters in A-Ö order using localeCompare with 'sv' (Swedish locale)
      const sortedLetters = Object.keys(userRoleGroups).sort((a, b) =>
        a.localeCompare(b, 'sv'),
      )

      // Convert grouped users into the desired format
      return sortedLetters.map((letter) => ({
        department: {
          id: `letter-${letter}`,
          name: letter,
        },
        userRoles: userRoleGroups[letter],
      }))
    }

    const sortGuardians = (
      userRoles?: UserRole[],
    ): {
      department: { id: string; name: string }
      userRoles: UserRole[]
    }[] => {
      if (!userRoles || userRoles.length < 1) {
        return []
      }

      // Collect user roles and their department mappings
      const userIds: {
        userId: string
        userRole: UserRole
        kidDepartmentId: string | null
      }[] = []

      for (const userRole of userRoles) {
        if (userRole.user && userRole.kid) {
          const kid = kids?.find((k) => k.id === userRole.attributes.kid.id)
          userIds.push({
            userId: userRole.user.id,
            userRole,
            kidDepartmentId: kid?.attributes.departmentPointer?.id || null,
          })
        }
      }

      // Group by userId to check for multiple departments
      const userRoleGroups: Record<
        string,
        { userId: string; userRole: UserRole; kidDepartmentId: string | null }[]
      > = userIds.reduce((acc, curr) => {
        if (!acc[curr.userId]) {
          acc[curr.userId] = []
        }
        acc[curr.userId].push(curr)
        return acc
      }, {} as Record<string, { userId: string; userRole: UserRole; kidDepartmentId: string | null }[]>)

      const result: {
        department: { id: string; name: string }
        userRoles: UserRole[]
      }[] = []

      for (const department of departments ?? []) {
        const row: {
          department: {
            id: string
            name: string
          }
          userRoles: UserRole[]
        } = {
          department: {
            id: department.id,
            name: department.klassName ?? '',
          },
          userRoles: [],
        }

        // Add userRoles where the user is only associated with this department
        for (const [userId, roles] of Object.entries(userRoleGroups)) {
          const uniqueDepartments = new Set(roles.map((r) => r.kidDepartmentId))

          if (
            uniqueDepartments.size === 1 &&
            uniqueDepartments.has(department.id)
          ) {
            row.userRoles.push(...roles.map((r) => r.userRole))
          }
        }

        if (row.userRoles.length > 0) {
          result.push(row)
        }
      }

      // Handle users with roles across multiple departments
      const multiDepRow: {
        department: {
          id: string
          name: string
        }
        userRoles: UserRole[]
      } = {
        department: {
          id: 'multi-department',
          name: t('events.severalDepartments'),
        },
        userRoles: [],
      }

      // Use a Set to track processed users
      const processedUsers = new Set<string>()

      for (const [userId, roles] of Object.entries(userRoleGroups)) {
        const uniqueDepartments = new Set(roles.map((r) => r.kidDepartmentId))

        if (uniqueDepartments.size > 1 && !processedUsers.has(userId)) {
          // Add the first role of the user (or customize as needed)
          multiDepRow.userRoles.push(roles[0].userRole)
          processedUsers.add(userId) // Mark this user as processed
        }
      }

      if (multiDepRow.userRoles.length > 0) {
        result.push(multiDepRow)
      }

      return result
    }

    const areAllIncluded = (
      smallArray: string[],
      bigArray: string[],
    ): boolean => {
      const bigSet = new Set(bigArray)
      return smallArray.every((id) => bigSet.has(id))
    }

    const items = searchActiveUsers ? searchActiveUsers : schoolsActiveUsers

    const fuse = new Fuse(items?.userRoles ?? [], {
      keys: ['fullName', 'user.firstName', 'user.lastName'],
      threshold: 0.3,
    })

    console.log('fuse obj', fuse)
    console.log('searchGoal   ', searchGoal)

    const filteredResults: UserRole[] = searchGoal
      ? fuse.search(searchGoal).map((r) => r.item)
      : items?.userRoles ?? []

    // Sort the results before rendering
    const sortedResults = sortGuardiansByFirstLetter(
      filteredResults.filter(
        (userRole) => userRole.role_type === USER_ROLE_TYPE.PARENT,
      ),
    )
    console.log('fuse results', sortedResults)

    return (
      <div className="">
        <div className="absolute top-14 left-44 w-[70%] flex justify-center">
          <div className="relative w-[35%]">
            <span className="absolute left-6 top-2 mr-6 text-gray-400">
              <FontAwesomeIcon icon={faSearch} size="sm" />
            </span>
            <input
              className={classNames(
                'px-12 py-2  placeholder-gray-400 border border-gray-300 rounded-full shadow appearance-none focus:outline-none focus:ring-eventsMain focus:border-eventsMain sm:text-sm',
              )}
              type={'text'}
              value={searchGoal}
              placeholder={t('documents.search') + '...'}
              onChange={(e) => {
                setSearchGoal(e.target.value)
              }}
            />
          </div>
        </div>
        {loading && <Loading />}
        {parentComponent !== 'inviteStaff' && (
          <div className=" flex justify-center">
            <div className="font-bold w-[50%]">
              <TabHeaderSlider
                tabs={[
                  {
                    label: t('general.guardian'),
                    value: 'guardians',
                  },
                  {
                    label: t('documents.staff'),
                    value: 'staff',
                  },
                ]}
                onClick={(value) => {
                  setActiveTab(value)
                }}
                activeTab={activeTab}
              />
            </div>
          </div>
        )}
        {activeTab.value === 'guardians' &&
          parentComponent !== 'inviteStaff' && (
            <>
              {sortGuardiansByFirstLetter(
                items?.userRoles.filter(
                  (userRole) => userRole.role_type === USER_ROLE_TYPE.PARENT,
                ),
              ).map((userItems, index) => (
                <React.Fragment key={index}>
                  <>{console.log(userItems)}</>
                  <div className="font-bold my-2 flex justify-between">
                    {userItems.department.name}
                  </div>
                  <div className="grid grid-cols-3 flex-wrap gap-2">
                    {userItems.userRoles.map((userRole) => {
                      let guardianUserRoles = items?.userRoles
                        .filter(
                          (uR) => uR.user?.id === userRole.user?.id && uR.kid,
                        )
                        ?.map((uR) => uR)

                      return (
                        <GuardianItem
                          key={userRole.id} // Use a unique identifier if available, fallback to index
                          kids={items?.kids?.filter(
                            (kid) =>
                              kid.kid.departmentPointer &&
                              kid.userRoles.find(
                                (uR) =>
                                  uR?.user?.id === userRole.user?.id &&
                                  uR.role_type === USER_ROLE_TYPE.PARENT,
                              ),
                          )}
                          userRole={userRole}
                          selectedUserRoleIds={selectedUserRoleIds}
                          guardianUserRoles={guardianUserRoles}
                          guardianClick={() => {}}
                          onClick={() => {
                            onSelectedUserRole(userRole)
                          }}
                        />
                      )
                    })}
                  </div>
                </React.Fragment>
              ))}
            </>
          )}
        {parentComponent === 'inviteStaff' && (
          <>
            {sortedResults.map((userItems, index) => (
              <React.Fragment key={index}>
                <>{console.log(items)}</>
                <div className="font-bold my-2 flex justify-between">
                  {userItems.department.name}
                </div>
                <div className="grid grid-cols-3 flex-wrap gap-2">
                  {userItems.userRoles.map((userRole) => {
                    let guardianUserRoles = items?.userRoles
                      .filter(
                        (uR) => uR.user?.id === userRole.user?.id && uR.kid,
                      )
                      ?.map((uR) => uR)
                    /* hasMultipleRoles(guardianUserRoles!) */

                    return (
                      <GuardianItem
                        key={userRole.id} // Use a unique identifier if available, fallback to index
                        kids={items?.kids?.filter(
                          (kid) =>
                            kid.kid.departmentPointer &&
                            kid.userRoles.find(
                              (uR) =>
                                uR?.user?.id === userRole.user?.id &&
                                uR.role_type === USER_ROLE_TYPE.PARENT,
                            ),
                        )}
                        userRole={userRole}
                        selectedUserRoleIds={selectedUserRoleIds}
                        guardianUserRoles={existingGuardians}
                        guardianClick={() => {}}
                        onClick={() => {
                          onSelectedUserRole(userRole)
                        }}
                        parentComponent="inviteStaff"
                      />
                    )
                  })}
                </div>
              </React.Fragment>
            ))}
          </>
        )}
        {activeTab.value === 'staff' && (
          <div className="w-full ">
            <>
              {sortGuardiansByFirstLetter(
                items?.userRoles.filter(
                  (userRole) => userRole.role_type > USER_ROLE_TYPE.PARENT,
                ),
              ).map((userItems, index) => (
                <React.Fragment key={index}>
                  <div className="font-bold my-2 flex justify-between">
                    {userItems.department.name}
                  </div>
                  <div className="grid grid-cols-5 flex-wrap gap-2">
                    {userItems.userRoles.map((userRole) => {
                      return (
                        <UserRoleItem
                          userRole={userRole}
                          selected={selectedUserRoleIds.find(
                            (sURI: string) => sURI === userRole.id,
                          )}
                          onClick={() => {
                            onSelectedUserRole(userRole)
                          }}
                        />
                      )
                    })}
                  </div>
                </React.Fragment>
              ))}
            </>
          </div>
        )}
      </div>
    )
  },
)
