import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import Page from '../../components/Page'
import { CreateEventParams } from '../../store/event3Store'
import { TypeOfEventSelections } from './components/TypeOfEvent'
import { PostEventToSelections } from './components/PostEventTo'
import { Department } from '../../Models/Department'
import { School } from '../../Models/School'
import { useTranslation } from 'react-i18next'
import { Button } from '../../components/Button'
import classNames from 'classnames'
import { warningSwal } from '../settings/MyConnections'
import { useNavigate, useParams } from 'react-router-dom'
import { initialValues } from './CreateEvent3'

interface Props {
  newEvent: CreateEventParams
  onChange: (e: CreateEventParams) => void
  departments: Department[]
  schools?: School[]
  setEventStep: (e: number) => void
  allowMultiSchools: boolean
}

export const CreateEvent3Start: React.FC<Props> = observer(
  ({
    newEvent,
    onChange,
    departments,
    schools,
    setEventStep,
    allowMultiSchools,
  }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { schoolId } = useParams()
    const [postToMultiSchoolsMode, setPostToMultiSchoolsMode] =
      useState<boolean>(newEvent.multiSchools?.length!! > 1 ? true : false)
    // Check that the user choose at least one department
    // eslint-disable-next-line no-self-compare
    const departmentChosed = newEvent.depIds?.length ?? 0 > 0

    const typeOfEventContainerRef = useRef<HTMLDivElement>(null)
    const postToEventContainerRef = useRef<HTMLDivElement>(null)

    const [height, setHeight] = useState<number | 'auto'>('auto')
    const [heightForPostToEvent, setHeightForPostToEvent] = useState<
      number | 'auto'
    >(400)

    const updateHeight = () => {
      if (typeOfEventContainerRef.current) {
        setHeight(typeOfEventContainerRef.current.scrollHeight)
      }
    }
    const updateHeightForPostToEvent = () => {
      if (postToEventContainerRef.current) {
        setHeightForPostToEvent(postToEventContainerRef.current.scrollHeight)
      }
    }

    // Update the height whenever relevant props change
    useEffect(() => {
      updateHeightForPostToEvent()
      updateHeight()
    }, [postToMultiSchoolsMode, newEvent.eventType])

    return (
      <Page>
        <div className="flex flex-col relative h-full">
          <div
            className={classNames(
              'w-full p-4 text-white bg-eventsMain flex justify-between items-center',
            )}
          >
            <div className="w-1/5">
              <Button
                variant="custom"
                textColor="7eb5f4"
                size="sm"
                fontSize="font-semibold"
                label={t('general.close')}
                onClick={() => {
                  navigate('/events/' + schoolId)
                }}
              />
            </div>
            <div className="flex items-center justify-center text-2xl w-3/5 font-bold">
              {t('events.eventCreate')}
            </div>
            <div className="w-1/5"></div>
          </div>
          <div className="flex  gap-x-2 items-start p-4 animate-fadeIn">
            <div className="flex flex-col p-3 min-h-[100px]  w-[50%] ">
              <h2 className="text-xl font-bold text-left mb-1">
                {t('events.typeOfEvent')}
              </h2>
              <div
                className={`w-full rounded-2xl shadow-sm bg-white   transition-all duration-500 ease-in-out`}
                style={{ minHeight: height }}
              >
                <div ref={typeOfEventContainerRef} className="p-4">
                  <TypeOfEventSelections
                    newEvent={newEvent}
                    onChange={onChange}
                    allowMultiSchools={allowMultiSchools}
                    postToMultiSchoolsMode={postToMultiSchoolsMode}
                  />
                </div>
              </div>
            </div>
            <div
              className="flex flex-col w-[50%]  transition-all duration-500 ease-in-out bg-red-4"
              style={{
                height:
                  typeof heightForPostToEvent === 'number'
                    ? `${heightForPostToEvent}px`
                    : heightForPostToEvent,
              }}
            >
              <div ref={postToEventContainerRef} className="p-3">
                <PostEventToSelections
                  newEvent={newEvent}
                  onChange={onChange}
                  departments={departments}
                  schools={schools}
                  allowMultiSchools={allowMultiSchools}
                  postToMultiSchoolsMode={postToMultiSchoolsMode}
                  setPostToMultiSchoolsMode={setPostToMultiSchoolsMode}
                />
              </div>
            </div>
          </div>

          <div className="flex w-full justify-center h-[15%] items-center absolute bottom-0 left-0 animate-fadeIn">
            <Button
              className="w-[40%]"
              size="lg"
              fontSize="font-bold"
              variant="fullBlue"
              label={t('login.nextTitle')}
              onClick={() => {
                newEvent.specificUserRoleIds &&
                newEvent.specificUserRoleIds.length > 0
                  ? setEventStep(2)
                  : departmentChosed
                  ? setEventStep(2)
                  : warningSwal('departmentMissing')
              }}
            />
          </div>
        </div>
      </Page>
    )
  },
)
