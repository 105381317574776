import {
  faArrowAltCircleDown,
  faArrowAltCircleUp,
  faMagnifyingGlass,
  faSliders,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { t } from 'i18next'
import React, { useRef, useState } from 'react'
import { useOnScreen } from '../hooks/useOnScreen'
import { Button } from './Button'
import ColorCard from './ColorCard'
import { Loading } from './Loading'
import { LoadingBar } from './LoadingBar'
import ModalRegular from './ModalRegular'

interface Props {
  children: React.ReactNode
  description?: string
  title?: string
  loading?: boolean
  topBar?: React.ReactNode
  naked?: boolean
  filter?: React.ReactNode | string
  filterCount?: number
  searchFilter?: React.ReactNode
  clearFilter?: () => void
  className?: string
}

export default function Page(props: Props) {
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const ref: any = useRef<HTMLDivElement>()
  const [showSearchFilter, setShowSearchFilter] = useState(false)

  const isVisible: boolean = useOnScreen<HTMLDivElement>(ref, '-150px')

  const executeScroll = () =>
    // @ts-ignore
    ref.current.scrollIntoView({ behavior: 'smooth' })
  return (
    <>
      {props?.loading && <LoadingBar />}
      <div
        className={classNames(
          'container mx-auto max-w-6xl relative h-full flex flex-wrap justify-center mt:[60px]',
          props.className,
        )}
      >
        {' '}
        {props.topBar && (
          <>
            <div ref={ref}></div>
            <div
              className="relative p-3 sm:fixed h-auto sm:top-[60px] w-full lg:w-[99%] rounded-b-3xl bg-blue-50 z-20 "
              style={{ boxShadow: '0px 12px 6px -15px rgba(0,0,0,0.3)' }}
            >
              <div className="container mx-auto flex justify-center">
                <div className="w-full max-w-[1070px] items-center flex flex-wrap justify-center lg:justify-between gap-x-4 gap-y-4 lg:gap-y-0 min-w-sm">
                  <div className=" flex flex-wrap justify-center lg:justify-start gap-4 min-w-sm">
                    {props.filter && (
                      <>
                        <Button
                          size="md"
                          variant="filter"
                          label={t('general.filter')}
                          icon={faSliders}
                          className="text-eventsMain  cursor-pointer hover:scale-[102%%] relative"
                          onClick={() => {
                            setShowFilter(true)
                          }}
                          countPrompt={props.filterCount}
                        />

                        <ModalRegular
                          title={t('general.filter')}
                          className="pt-6 !relative "
                          classNameTitle="flex w-full justify-center items-center text-black"
                          isOpen={showFilter}
                          onClose={() => {
                            setShowFilter(false)
                          }}
                          hideCloseButton
                        >
                          <div className="w-full px-10 py-10 mb-4">
                            {props.filter}
                          </div>
                          {props.clearFilter && (
                            <div className="w-full flex justify-between flex-col sm:flex-row items-center px-8 sticky bottom-0 left-0 bg-gradient-to-t from-inherit">
                              <Button
                                className="m-4 px-2 !text-white"
                                label={t('general.cleanFilter')}
                                variant="borderRed"
                                onClick={props.clearFilter}
                                size={'lg'}
                                fontSize="font-bold"
                              />
                              <Button
                                className="m-4"
                                variant="roundGreen"
                                label={t('general.done')}
                                onClick={() => {
                                  setShowFilter(false)
                                }}
                                size={'lg'}
                                fontSize="font-bold"
                              />
                            </div>
                          )}
                        </ModalRegular>
                      </>
                    )}
                    {props.searchFilter && (
                      <div className="max-w-[180px] flex flex-row relative">
                        <div className="flex justify-between items-center">
                          <div className="min-w-[200px] flex flex-row justify-center sm:justify-start items-center">
                            <FontAwesomeIcon
                              className="text-eventsMain cursor-pointer mr-4 hover:scale-[110%]"
                              icon={faMagnifyingGlass}
                              onClick={() =>
                                setShowSearchFilter(!showSearchFilter)
                              }
                            />

                            {props.searchFilter && showSearchFilter && (
                              <div
                                className={classNames(
                                  'transition ease-in-out duration-1000',
                                  showSearchFilter ? 'w-fit mr-4' : 'w-0 ',
                                )}
                              >
                                {props.searchFilter}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {props.topBar}
                </div>
              </div>
            </div>
          </>
        )}
        <div
          className={classNames(
            props.topBar
              ? 'container max-w-6xl rounded-2xl  mt-[40px] md:mt-[160px] lg:mt-[120px] 2xl:mt-[80px] mb-10 mx-5'
              : 'container max-w-6xl rounded-2xl  mt-[40px] 2xl:mt-[80px] mb-10 mx-5',
            props.naked
              ? 'lg:mt-[100px]'
              : 'shadow-tyraShadow overflow-x-hidden h-[90%]',
            'bg-[#F7F5F2]',
          )}
        >
          {props.children}
        </div>
      </div>
    </>
  )
}
