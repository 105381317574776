import { useTranslation } from 'react-i18next'
import CustomInputNew from '../../../../../components/CustomInputNew'
import { observer } from 'mobx-react'
import {
  NewUser,
  createPerson,
  newUserInitialValues,
} from '../../inviteParentsNew/InviteParents'
import { useContext, useState } from 'react'
import { UserStoreContext } from '../../../../../store/userStore'
import { Loading } from '../../../../../components/Loading'
import classNames from 'classnames'
import Page from '../../../../../components/Page'
import { TitleAndQualificationStaff } from './TitleAndQualificationStaff'
import { Button } from '../../../../../components/Button'
import ModalRegular from '../../../../../components/ModalRegular'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAt,
  faBriefcase,
  faCheck,
  faLocationDot,
  faPhone,
  faTableCellsLarge,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import { LOGIN_METHOD, USER_ROLE_TYPE } from '../../../../../Enums/TyraEnums'
import { Department } from '../../../../../Models/Department'
import { UserRole } from '../../../../../Models/UserRole'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import Parse from 'parse'

interface Props {
  /*  onlyRelation: boolean
  onChangeRelation: (value: RELATION_AND_AUTORITHY) => void
  relationAndAuthority: RELATION_AND_AUTORITHY
  otherText: string
  editFreeTextValue: (value: string) => void */
  onlyTitleAndPermissions: boolean
  onDone: () => void
  newUserInfo?: NewUser
  setNewUserInfo?: (userInfo: NewUser) => void
  showSummary: boolean
  setShowSummary: (value: boolean) => void
  showInloggningsAlternatives?: boolean
  setShowInloggningsAlternatives?: (value: boolean) => void
  userFound:
    | {
        email?: string
        name?: string
        nin?: string
        userId?: string
        phone?: string
        login_method?: LOGIN_METHOD
      }[]
    | null
  setUserFound: React.Dispatch<
    React.SetStateAction<
      | {
          email?: string
          name?: string
          nin?: string
          userId?: string
          phone?: string
          login_method?: LOGIN_METHOD
        }[]
      | null
    >
  >
  selectedUserRole?: UserRole
}

export const InformationAboutStaff: React.FC<Props> = observer(
  ({
    onlyTitleAndPermissions,
    newUserInfo,
    setNewUserInfo,
    userFound,
    setUserFound,
    showSummary,
    setShowSummary,
    showInloggningsAlternatives,
    setShowInloggningsAlternatives,
    onDone,
    selectedUserRole,
  }) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [permissions, setPermissions] = useState({
      isAdmin: false,
      linkedToSchool: false,
      canEditSchedule: false,
      hasSensitiveAccess: false,
      hasStatisticsAccess: false,
      canUploadEditTyra: false,
    })

    const [inviteAsNewUser, setInviteAsNewUser] = useState(false)
    const [inviteAsExistingUser, setInviteAsExistingUser] = useState<
      null | string
    >(null)
    const [title, setTitle] = useState<string>('')
    const [chosenDepIds, setChosenDepIds] = useState<Department[]>([])
    const { currentUserRole, createUserRole, createPersonInvite } =
      useContext(UserStoreContext)
    const { schoolId } = useParams()
    const [loginMethodWhenInvite, setLoginMethodWhenInvite] =
      useState<number>(0)
    /* const [showInloggningsAlternatives, setShowInloggningsAlternatives] =
      useState<boolean>(false) */
    const [showResponseMsg, setShowResponseMsg] = useState<string>('')

    const toggleSwitch = (key: keyof typeof permissions) => {
      setPermissions((prev) => ({
        ...prev,
        [key]: !prev[key], // Toggle the boolean value
      }))
    }

    const hasAnyPermission = Object.values(permissions).some((value) => value)

    const requiredAdress =
      !!newUserInfo?.city ||
      newUserInfo?.postalCode ||
      newUserInfo?.streetAddress

    const disableContinueButton =
      !onlyTitleAndPermissions &&
      (!newUserInfo?.firstName ||
        !newUserInfo?.lastName ||
        !(newUserInfo?.phone || newUserInfo?.email || newUserInfo?.pn))

    const hasGrandIDUser = userFound?.some(
      (user) => user.login_method === LOGIN_METHOD.GRANDID,
    )
    const hasPhoneUser = userFound?.some(
      (user) => user.login_method === LOGIN_METHOD.PHONE,
    )
    const hasEmailUser = userFound?.some(
      (user) => user.login_method === LOGIN_METHOD.EMAIL,
    )
    return (
      <>
        {loading && <Loading />}
        <div
          className={classNames(
            `grid ${
              onlyTitleAndPermissions
                ? 'm-auto grid max-w-xl -mt-10'
                : 'grid-cols-2'
            } gap-x-4`,
          )}
        >
          {!onlyTitleAndPermissions && (
            <div className="flex flex-col w-full gap-y-2 p-8">
              <div className="flex justify-center text-xl font-bold">
                {t('general.enterPersonalData')}
              </div>
              <div className="flex justify-center text-center text-xs">
                {t('general.dataSavedWithInvitaion')}
              </div>
              <div className="flex flex-col gap-y-4 mt-4">
                <div className="w-full">
                  <CustomInputNew
                    onChange={(value) => {
                      if (setNewUserInfo) {
                        setNewUserInfo({ ...newUserInfo, pn: value })
                      }
                    }}
                    placeholder={t('general.socialNr')}
                    longPlaceholder={t('general.enterPersonalNumber')}
                    label="!!Personnummer"
                    value={newUserInfo?.pn}
                    format="YYYYMMDD-LLXX"
                  />
                </div>
                {currentUserRole?.school_pointer.territory !== 724 && (
                  <div className="w-full text-sm">
                    {t('general.enteringPersonalNumberRecommindation')}
                  </div>
                )}
                <div className="w-full">
                  <CustomInputNew
                    onChange={(value) => {
                      if (setNewUserInfo) {
                        setNewUserInfo({ ...newUserInfo, firstName: value })
                      }
                    }}
                    placeholder={t('admin.firstName')}
                    longPlaceholder={t('general.enterFirstName')}
                    label="!!Förnamn"
                    value={newUserInfo?.firstName}
                    format="default"
                    required
                  />
                </div>
                <div className="w-full">
                  <CustomInputNew
                    onChange={(value) => {
                      if (setNewUserInfo) {
                        setNewUserInfo({ ...newUserInfo, lastName: value })
                      }
                    }}
                    placeholder={t('admin.surName')}
                    longPlaceholder={t('general.enterLastName')}
                    label="!!Förnamn"
                    value={newUserInfo?.lastName}
                    format="default"
                    required
                  />
                </div>
                <div className="w-full">
                  <CustomInputNew
                    onChange={(value) => {
                      if (setNewUserInfo) {
                        setNewUserInfo({ ...newUserInfo, email: value })
                      }
                    }}
                    placeholder={t('login.email')}
                    longPlaceholder={t('general.enterEmail')}
                    label="!!Förnamn"
                    value={newUserInfo?.email}
                    format="default"
                  />
                </div>
                <div className="w-full">
                  <CustomInputNew
                    onChange={(value) => {
                      if (setNewUserInfo) {
                        setNewUserInfo({ ...newUserInfo, phone: value })
                      }
                    }}
                    placeholder={t('contactList.phone')}
                    longPlaceholder={t('general.enterPhoneNumber')}
                    label="!!Förnamn"
                    value={newUserInfo?.phone}
                    format="phone"
                  />
                </div>
                <div className="w-full">
                  <CustomInputNew
                    onChange={(value) => {
                      if (setNewUserInfo) {
                        setNewUserInfo({ ...newUserInfo, streetAddress: value })
                      }
                    }}
                    placeholder={t('contactList.adress')}
                    longPlaceholder={t('general.enterAdress')}
                    label="!!Förnamn"
                    value={newUserInfo?.streetAddress}
                    format="default"
                    required={requiredAdress ? true : false}
                  />
                </div>
                <div className="w-full">
                  <CustomInputNew
                    onChange={(value) => {
                      if (setNewUserInfo) {
                        setNewUserInfo({ ...newUserInfo, postalCode: value })
                      }
                    }}
                    placeholder="!!Postnummer"
                    longPlaceholder={t('general.enterPostalCode')}
                    label="!!Förnamn"
                    value={newUserInfo?.postalCode}
                    format="default"
                    required={requiredAdress ? true : false}
                  />
                </div>
                <div className="w-full">
                  <CustomInputNew
                    onChange={(value) => {
                      if (setNewUserInfo) {
                        setNewUserInfo({ ...newUserInfo, city: value })
                      }
                    }}
                    placeholder="!!Stad"
                    longPlaceholder={t('general.enterCity')}
                    label="!!Förnamn"
                    value={newUserInfo?.city}
                    format="default"
                    required={requiredAdress ? true : false}
                  />
                </div>
              </div>
            </div>
          )}
          <>{console.log('before title and permissions')}</>
          <div
            className={` ${
              onlyTitleAndPermissions && 'flex  flex-col w-[50%]'
            }`}
          >
            <TitleAndQualificationStaff
              permissions={permissions}
              toggleSwitch={toggleSwitch}
              title={title}
              setTitle={setTitle}
              chosenDepIds={chosenDepIds}
              setChosenDepIds={setChosenDepIds}
              onlyTitleAndPermissions={onlyTitleAndPermissions}
            />
            <div className="flex w-full justify-center">
              <Button
                className={`${
                  onlyTitleAndPermissions ? 'mt-6 mb-8' : 'mt-16'
                }  justify-center w-[70%]`}
                label={t('login.continue')}
                variant={
                  disableContinueButton ? 'disableGray' : 'callToActionBlue'
                }
                fontSize="font-bold"
                onClick={async () => {
                  await onDone()
                }}
              />
            </div>
          </div>
        </div>
        {showSummary && onlyTitleAndPermissions && (
          <ModalRegular
            onClose={() => {
              setShowSummary(false)
            }}
            isOpen={true}
            title="!!Sammanfattning"
            className="max-w-lg"
            classNameTitle="font-black flex w-full p-4 pt-8 justify-center text-black"
          >
            <div className="py-4 px-8">
              <div
                className={`absolute -top-5 right-8 size-32 ${
                  showSummary && 'animate-wave'
                }`}
              >
                <img src="/logo.png" alt="logoTyra" />
              </div>

              <div className="flex flex-col justify-center px-6 gap-y-4">
                <div className="flex flex-col p-4 gap-y-2 text-sm font-semibold">
                  {selectedUserRole && (
                    <>
                      <div className="bg-white p-4 rounded-2xl">
                        <FontAwesomeIcon
                          className="mr-2 w-[20px]"
                          icon={faUser}
                          color={'#9B9B9B'}
                        />
                        {selectedUserRole.user?.firstName +
                          ' ' +
                          selectedUserRole.user?.lastName}
                        {selectedUserRole.user?.user_nin?.attributes.nin
                          ? ' (' +
                            selectedUserRole.user?.user_nin.attributes.nin +
                            ')'
                          : null}
                      </div>
                      <div className="bg-white p-4 rounded-2xl">
                        <FontAwesomeIcon
                          className="mr-2 w-[20px]"
                          icon={faPhone}
                          color={'#1398F7'}
                        />
                        {selectedUserRole.user?.user_extra.attributes
                          .primary_phone ?? null}
                      </div>
                      <div className="bg-white p-4 rounded-2xl">
                        <FontAwesomeIcon
                          className="mr-2 w-[20px]"
                          icon={faAt}
                          color={'#C99CF2'}
                        />
                        {selectedUserRole.user?.user_extra.attributes.email ??
                          null}
                      </div>
                      {selectedUserRole.user?.user_extra.attributes
                        .street_address ? (
                        <div className="bg-white p-4 rounded-2xl">
                          <div>
                            <FontAwesomeIcon
                              className="mr-2 w-[20px]"
                              icon={faLocationDot}
                              color={'#6BBD73'}
                            />
                            {
                              selectedUserRole.user?.user_extra.attributes
                                .street_address
                            }
                          </div>
                          <div>
                            <FontAwesomeIcon
                              className="mr-2 w-[20px] opacity-0"
                              icon={faLocationDot}
                              color={'#6BBD73'}
                            />
                            {selectedUserRole.user?.user_extra.attributes
                              .zip_code +
                              ' ' +
                              selectedUserRole.user?.user_extra.attributes.city}
                          </div>
                        </div>
                      ) : null}
                      {title!! && (
                        <>
                          <div className="bg-white p-4 rounded-2xl">
                            <FontAwesomeIcon
                              className="mr-2 w-[20px]"
                              icon={faBriefcase}
                              color={'#F2A785'}
                            />
                            {title}
                          </div>
                        </>
                      )}
                      {chosenDepIds.length > 0 && (
                        <>
                          <div className="bg-white p-4 rounded-2xl">
                            <FontAwesomeIcon
                              className="mr-2 w-[20px]"
                              icon={faTableCellsLarge}
                              color={'#E1A7f7'}
                            />
                            {chosenDepIds[0].klassName}
                          </div>
                        </>
                      )}
                      {hasAnyPermission && (
                        <div className="bg-white p-4 rounded-2xl">
                          {permissions.isAdmin && (
                            <div className="flex w-full items-center gap-2">
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="text-greenForrest"
                              />
                              <>{t('admin.userAdminPermission')}</>
                            </div>
                          )}
                          {permissions.linkedToSchool && (
                            <div className="flex w-full items-center gap-2">
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="text-greenForrest"
                              />
                              <>{t('admin.userSchoolAcc')}</>
                            </div>
                          )}
                          {permissions.canEditSchedule && (
                            <div className="flex w-full items-center gap-2">
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="text-greenForrest"
                              />
                              <>{t('admin.userEditSchedule')}</>
                            </div>
                          )}
                          {permissions.hasSensitiveAccess && (
                            <div className="flex w-full items-center gap-2">
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="text-greenForrest"
                              />
                              <>{t('admin.userSensitiveInfo')}</>
                            </div>
                          )}
                          {permissions.hasStatisticsAccess && (
                            <div className="flex w-full items-center gap-2">
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="text-greenForrest"
                              />
                              <>{t('admin.userStatistic')}</>
                            </div>
                          )}
                          {permissions.canUploadEditTyra && (
                            <div className="flex w-full items-center gap-2">
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="text-greenForrest"
                              />
                              <>{t('admin.userAllowChangeInfo')}</>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>

                <div className="flex justify-center mt-4 z-40">
                  <Button
                    variant="callToActionBlue"
                    label={t('admin.invite')}
                    size="md"
                    className="w-[70%]"
                    fontSize="font-bold"
                    onClick={async () => {
                      console.log('user   ', selectedUserRole?.user?.id)
                      const userRoleId = await createUserRole({
                        userId: selectedUserRole?.user?.id,
                        roleNum: USER_ROLE_TYPE.TEACHER,
                        schoolId: schoolId!,
                        title: title,
                        department:
                          chosenDepIds.length > 0 ? chosenDepIds[0].id : '',
                      }).then((response) => {
                        console.log('response', response)
                        if (response === 'OK') {
                          Swal.fire({
                            text: t('admin.inviteSent'),
                            icon: 'success',
                            showCancelButton: false,
                            showConfirmButton: true,
                            focusConfirm: false,
                            confirmButtonColor: '#7eb5f4',
                            cancelButtonColor: '#d33',
                            cancelButtonText: t('general.cancel'),
                            confirmButtonText: t('general.ok'),
                          })
                        }
                      })
                      setTitle('')
                      setShowSummary(false)

                      /* if (selectedUserRole) {
                        if (schoolId && selectedKid && selectedUserRole.user) {
                          await inviteExistingGuardian(
                            schoolId,
                            selectedKid.id,
                            selectedUserRole.user.id,
                            relationAndAuthority.addDocuments,
                          )
                            .then((response) => {
                              console.log('response', response)
                              if (response === 'OK') {
                                Swal.fire({
                                  text: t('admin.inviteSent'),
                                  icon: 'success',
                                  showCancelButton: false,
                                  showConfirmButton: true,
                                  focusConfirm: false,
                                  confirmButtonColor: '#7eb5f4',
                                  cancelButtonColor: '#d33',
                                  cancelButtonText: t('general.cancel'),
                                  confirmButtonText: t('general.ok'),
                                }).finally(() => {
                                  setSelectedKid(undefined)
                                  setSelectedUserRole(undefined)
                                  setChosenInviteType(undefined)
                                  setRelationAndAuthority({
                                    relation: TYRA_RELATION_NEW.PARENT,
                                    addDocuments: false,
                                    addSchemaTimesToOnlyStaff: false,
                                    addNotesToOnlyStaff: false,
                                  })
                                  setOtherText('')
                                  setShowSummary(false)
                                  setNewUserInfo(newUserInitialValues)
                                  setUserFound(null)
                                  setInviteAsNewUser(false)
                                  setInviteAsExistingUser(null)
                                })
                              }
                              if (response === 'ALREADY_INVITED') {
                                // HANTERA FELMEDDELANDE
                                Swal.fire({
                                  text: t('admin.userAlreadyInvited'),
                                  icon: 'error',
                                  showCancelButton: false,
                                  showConfirmButton: true,
                                  focusConfirm: false,
                                  confirmButtonColor: '#7eb5f4',
                                  cancelButtonColor: '#d33',
                                  cancelButtonText: t('general.cancel'),
                                  confirmButtonText: t('general.ok'),
                                })
                              }
                              if (response === 'ERROR') {
                                // HANTERA FELMEDDELANDE
                                Swal.fire({
                                  title: t('events.eventAlarmFail'),
                                  text: t('statistics.contactSupport'),
                                  icon: 'error',
                                  showCancelButton: false,
                                  showConfirmButton: true,
                                  focusConfirm: false,
                                  confirmButtonColor: '#7eb5f4',
                                  cancelButtonColor: '#d33',
                                  cancelButtonText: t('general.cancel'),
                                  confirmButtonText: t('general.ok'),
                                })
                              }
                              return response
                            })
                            .catch((e) => {
                              console.error(e)
                            })
                            .finally(() => {})
                        }
                      }*/
                    }}
                  />
                </div>
              </div>
            </div>
          </ModalRegular>
        )}
        {showSummary && !onlyTitleAndPermissions && (
          <ModalRegular
            onClose={() => {
              setShowSummary(false)
            }}
            isOpen={true}
            title="!!Sammanfattning"
            className="max-w-lg relative"
            classNameTitle="font-black flex w-full p-4 pt-8 justify-center text-black"
          >
            <div
              className={`absolute -top-5 right-8 size-32 ${
                showSummary && 'animate-wave'
              }`}
            >
              <img src="/logo.png" alt="logoTyra" />
            </div>
            <div className="px-8">
              <div className="flex flex-col justify-center p-8 gap-y-4">
                <div className="flex flex-col rounded-2xl gap-y-2 text-sm font-semibold">
                  <div className="bg-white p-4 rounded-2xl">
                    <FontAwesomeIcon
                      className="mr-2 w-[20px]"
                      icon={faUser}
                      color={'#9B9B9B'}
                    />
                    {newUserInfo?.firstName +
                      ' ' +
                      (newUserInfo?.lastName ? newUserInfo?.lastName : '')}
                    {newUserInfo?.pn ? ' (' + newUserInfo?.pn + ')' : null}
                  </div>
                  {newUserInfo?.phone && (
                    <div className="bg-white p-4 rounded-2xl">
                      <FontAwesomeIcon
                        className="mr-2 w-[20px]"
                        icon={faPhone}
                        color={'#1398F7'}
                      />
                      {newUserInfo?.phone}
                    </div>
                  )}

                  {newUserInfo?.email && (
                    <div className="bg-white p-4 rounded-2xl">
                      <FontAwesomeIcon
                        className="mr-2 w-[20px]"
                        icon={faAt}
                        color={'#C99CF2'}
                      />
                      {newUserInfo?.email}
                    </div>
                  )}
                  {newUserInfo?.streetAddress ? (
                    <>
                      <div className="bg-white p-4 rounded-2xl">
                        <FontAwesomeIcon
                          className="mr-2 w-[20px]"
                          icon={faLocationDot}
                          color={'#6BBD73'}
                        />
                        {newUserInfo.streetAddress},{' '}
                        {newUserInfo.postalCode + ', ' + newUserInfo.city}
                      </div>
                    </>
                  ) : null}
                  {title!! && (
                    <>
                      <div className="bg-white p-4 rounded-2xl">
                        <FontAwesomeIcon
                          className="mr-2 w-[20px]"
                          icon={faBriefcase}
                          color={'#F2A785'}
                        />
                        {title}
                      </div>
                    </>
                  )}
                  {chosenDepIds.length > 0 && (
                    <>
                      <div className="bg-white p-4 rounded-2xl">
                        <FontAwesomeIcon
                          className="mr-2 w-[20px]"
                          icon={faTableCellsLarge}
                          color={'#E1A7f7'}
                        />
                        {chosenDepIds[0].klassName}
                      </div>
                    </>
                  )}
                  {hasAnyPermission && (
                    <div className="bg-white p-4 rounded-2xl">
                      {permissions.isAdmin && (
                        <div className="flex w-full items-center gap-2">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="text-greenForrest"
                          />
                          <>{t('admin.userAdminPermission')}</>
                        </div>
                      )}
                      {permissions.linkedToSchool && (
                        <div className="flex w-full items-center gap-2">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="text-greenForrest"
                          />
                          <>{t('admin.userSchoolAcc')}</>
                        </div>
                      )}
                      {permissions.canEditSchedule && (
                        <div className="flex w-full items-center gap-2">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="text-greenForrest"
                          />
                          <>{t('admin.userEditSchedule')}</>
                        </div>
                      )}
                      {permissions.hasSensitiveAccess && (
                        <div className="flex w-full items-center gap-2">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="text-greenForrest"
                          />
                          <>{t('admin.userSensitiveInfo')}</>
                        </div>
                      )}
                      {permissions.hasStatisticsAccess && (
                        <div className="flex w-full items-center gap-2">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="text-greenForrest"
                          />
                          <>{t('admin.userStatistic')}</>
                        </div>
                      )}
                      {permissions.canUploadEditTyra && (
                        <div className="flex w-full items-center gap-2">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="text-greenForrest"
                          />
                          <>{t('admin.userAllowChangeInfo')}</>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="flex justify-center mt-4 z-40">
                  <Button
                    variant="callToActionBlue"
                    label={t('admin.invite')}
                    size="md"
                    className="w-[70%]"
                    fontSize="font-bold"
                    onClick={async () => {
                      console.log('newUserInfo   ', newUserInfo)

                      const newPerson = createPerson(newUserInfo, schoolId!)
                      console.log('newPerson : ', newPerson)

                      try {
                        const personId = await createPersonInvite({
                          ...newPerson,
                          loginMethod: loginMethodWhenInvite,
                        })

                        const userRoleId = await createUserRole({
                          personUUID: personId,
                          roleNum: USER_ROLE_TYPE.TEACHER,
                          schoolId: schoolId!,
                          title: title,
                          department:
                            chosenDepIds.length > 0 ? chosenDepIds[0].id : '',
                        })
                        if (userRoleId) {
                          setShowResponseMsg('Person was invited successfully')
                        }
                        setShowSummary(false)
                        setNewUserInfo?.(newUserInitialValues)
                        console.log('Person created with ID:', personId)
                      } catch (error) {
                        /*  if (error instanceof Parse.Error) {
                          console.log(error.message)
                          setShowResponseMsg(error.message)
                        } */
                        console.error(error)
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </ModalRegular>
        )}
        {showResponseMsg!! && (
          <ModalRegular
            onClose={() => {
              setShowResponseMsg('')
            }}
            isOpen={true}
            title=""
            className="max-w-lg py-6"
          >
            <div className="flex flex-row justify-center">
              <FontAwesomeIcon
                icon={faCircleXmark}
                size="4x"
                className="text-red-300"
              />
            </div>
            <p className="flex flex-row justify-center font-bold py-16">
              {showResponseMsg}
            </p>
          </ModalRegular>
        )}
        {(userFound || showInloggningsAlternatives) && (
          <ModalRegular
            onClose={() => {
              setUserFound(null)
              setShowInloggningsAlternatives?.(false)
            }}
            isOpen={true}
            className="max-w-lg py-6"
          >
            <div className="py-4 px-8">
              <div className="text-xl font-bold mb-4">
                {!showInloggningsAlternatives
                  ? t('admin.existingUserNotification')
                  : '!Nu ska du bjuda in en ny användare, hur vill ni att de ska logga in ?'}
              </div>
              <div className="flex flex-col gap-y-4">
                {userFound?.map((userInfo) => {
                  return (
                    <div
                      className="flex flex-col bg-white p-4 rounded-2xl text-sm relative cursor-pointer"
                      onClick={() => {
                        setInviteAsNewUser(false)
                        setInviteAsExistingUser(userInfo.userId!)
                      }}
                    >
                      <div>
                        {userInfo.name} {userInfo.nin && <>({userInfo.nin})</>}
                        {userInfo.login_method === LOGIN_METHOD.GRANDID ? (
                          <>({t('login.login_username').toLowerCase()})</>
                        ) : null}
                      </div>
                      <div>
                        {userInfo.phone}{' '}
                        {userInfo.login_method === LOGIN_METHOD.PHONE ? (
                          <>({t('login.login_username').toLowerCase()})</>
                        ) : null}
                      </div>
                      <div>
                        {userInfo.email}{' '}
                        {userInfo.login_method === LOGIN_METHOD.EMAIL ? (
                          <>({t('login.login_username').toLowerCase()})</>
                        ) : null}
                      </div>
                      <div className="absolute right-4 top-0 h-full flex items-center">
                        <div
                          className={`w-6 h-6 rounded-full border flex justify-center items-center  ${
                            inviteAsExistingUser === userInfo.userId
                              ? 'border-transparent bg-blueSelected'
                              : 'border-gray-400'
                          }`}
                        >
                          {inviteAsExistingUser === userInfo.userId && (
                            <FontAwesomeIcon icon={faCheck} color="#ffffff" />
                          )}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              {(!hasGrandIDUser || !hasPhoneUser || !hasEmailUser) && (
                <div className="text-xl font-bold mb-4 mt-4">
                  {t('general.or')}
                </div>
              )}
              <div className="flex flex-col gap-y-3">
                {/* Bjud in with personnumber */}
                {!hasGrandIDUser && (
                  <div
                    className={`flex flex-col bg-white p-4 rounded-2xl text-sm relative  font-semibold ${
                      !newUserInfo?.pn
                        ? 'cursor-default text-gray120'
                        : 'cursor-pointer'
                    }`}
                    onClick={() => {
                      if (
                        (!inviteAsNewUser ||
                          loginMethodWhenInvite !== LOGIN_METHOD.GRANDID) &&
                        newUserInfo?.pn
                      ) {
                        setInviteAsNewUser(true)
                        setLoginMethodWhenInvite(LOGIN_METHOD.GRANDID)
                        setInviteAsExistingUser(null)
                      }
                    }}
                  >
                    <div>!Bjud in som ny användare på personnummer</div>
                    <div className="absolute right-4 top-0 h-full flex items-center">
                      <div
                        className={`w-6 h-6 rounded-full border flex justify-center items-center  ${
                          inviteAsNewUser &&
                          loginMethodWhenInvite === LOGIN_METHOD.GRANDID
                            ? 'border-transparent bg-blueSelected'
                            : 'border-gray-400'
                        }`}
                      >
                        {inviteAsNewUser && (
                          <FontAwesomeIcon icon={faCheck} color="#ffffff" />
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {/* Bjud in with Telefonnummber */}
                {!hasPhoneUser && (
                  <div
                    className={`flex flex-col bg-white p-4 rounded-2xl text-sm relative font-semibold   ${
                      !newUserInfo?.phone
                        ? 'cursor-default text-gray-500'
                        : 'cursor-pointer'
                    }`}
                    onClick={() => {
                      if (
                        (!inviteAsNewUser ||
                          loginMethodWhenInvite !== LOGIN_METHOD.PHONE) &&
                        newUserInfo?.phone
                      ) {
                        setInviteAsNewUser(true)
                        setLoginMethodWhenInvite(LOGIN_METHOD.PHONE)
                        setInviteAsExistingUser(null)
                      }
                    }}
                  >
                    <div>!Bjud in som ny användare med mobilnummer</div>
                    <div className="absolute right-4 top-0 h-full flex items-center">
                      <div
                        className={`w-6 h-6 rounded-full border flex justify-center items-center  ${
                          inviteAsNewUser &&
                          loginMethodWhenInvite === LOGIN_METHOD.PHONE
                            ? 'border-transparent bg-blueSelected'
                            : 'border-gray-400'
                        }`}
                      >
                        {inviteAsNewUser && (
                          <FontAwesomeIcon icon={faCheck} color="#ffffff" />
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {/* Invite with email */}
                {!hasEmailUser && (
                  <div
                    className={`flex flex-col bg-white p-4 rounded-2xl text-sm relative  font-semibold  ${
                      !newUserInfo?.email
                        ? 'cursor-default text-gray-500'
                        : 'cursor-pointer'
                    }`}
                    onClick={() => {
                      if (
                        (!inviteAsNewUser ||
                          loginMethodWhenInvite !== LOGIN_METHOD.EMAIL) &&
                        newUserInfo?.email
                      ) {
                        setInviteAsNewUser(true)
                        setLoginMethodWhenInvite(LOGIN_METHOD.EMAIL)
                        setInviteAsExistingUser(null)
                      }
                    }}
                  >
                    <div>!Bjud in som ny användare med email</div>
                    <div className="absolute right-4 top-0 h-full flex items-center">
                      <div
                        className={`w-6 h-6 rounded-full border flex justify-center items-center  ${
                          inviteAsNewUser &&
                          loginMethodWhenInvite === LOGIN_METHOD.EMAIL
                            ? 'border-transparent bg-blueSelected'
                            : 'border-gray-400'
                        }`}
                      >
                        {inviteAsNewUser && (
                          <FontAwesomeIcon icon={faCheck} color="#ffffff" />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/*  */}
              <div className="flex mt-14 justify-center">
                <Button
                  variant="callToActionBlue"
                  className="w-[60%]"
                  size="md"
                  fontSize="font-bold"
                  label={
                    inviteAsExistingUser
                      ? t('admin.invite')
                      : t('login.continue')
                  }
                  onClick={async () => {
                    if (inviteAsNewUser) {
                      setShowSummary(true)
                      setUserFound(null)
                    } else {
                      const newPerson = createPerson(newUserInfo, schoolId!)
                      const personId = await createPersonInvite({
                        ...newPerson,
                        userId: (userFound && userFound[0].userId) || undefined,
                        loginMethod: loginMethodWhenInvite,
                      })

                      createUserRole({
                        personUUID: personId,
                        roleNum: USER_ROLE_TYPE.TEACHER,
                        schoolId: schoolId!,
                        title: title,
                        department:
                          chosenDepIds.length > 0 ? chosenDepIds[0].id : '',
                      })
                      setShowSummary(false)
                      setUserFound(null)
                    }
                  }}
                />
              </div>
            </div>
          </ModalRegular>
        )}
      </>
    )
  },
)
