import Parse from 'parse'
import { UserRoleConfiguration } from '../App/intefaces/configuration'
import {
  ARCHIVE_STATE,
  USER_ROLE_STATUS,
  USER_ROLE_TYPE,
} from '../Enums/TyraEnums'
import { AlternativeContact } from './AlternativeContact'
import { Department } from './Department'
import { Kid } from './Kid'
import { School } from './School'
import { User } from './User'
import { UserRoleSetting } from './UserRoleSetting'

export class UserRole extends Parse.Object {
  public static readonly Fields = {
    school: 'school',
    user: 'user',
    kid: 'kid',
    archive_state: 'archive_state',
    role_type: 'role_type',
    multi_user: 'multi_user',
    status: 'status',
    user_role_setting: 'user_role_setting',
    alternative_contact: 'alternative_contact',
    staff_primary_dep: 'staff_primary_dep',
    school_pointer: 'school_pointer',
    kid_object_id: 'kid_object_id',
  }
  constructor() {
    super('user_roles')
  }

  get kid_object_id(): string | undefined {
    return this.get('kid_object_id')
  }
  set kid_object_id(value: string | undefined) {
    this.set('kid_object_id', value)
  }
  get user_role_setting(): UserRoleSetting {
    return this.get('user_role_setting')
  }
  set user_role_setting(value: UserRoleSetting) {
    this.set('user_role_setting', value)
  }
  get staff_primary_dep(): Department | undefined {
    return this.get('staff_primary_dep')
  }
  set staff_primary_dep(sp: Department | undefined) {
    this.set('staff_primary_dep', sp)
  }
  get user(): User | undefined {
    return this.get('user')
  }
  set user(u: User | undefined) {
    this.set('user', u)
  }
  get school_pointer(): School {
    return this.get('school_pointer')
  }
  set school_pointer(sp: School) {
    this.set('school_pointer', sp)
  }
  get school(): string {
    return this.get('school')
  }
  set school(s: string) {
    this.set('school', s)
  }
  get alternative_contact(): AlternativeContact {
    return this.get('alternative_contact')
  }
  set alternative_contact(s: AlternativeContact) {
    this.set('alternative_contact', s)
  }
  get multi_user(): User | undefined {
    return this.get('multi_user')
  }
  set multi_user(s: User | undefined) {
    this.set('multi_user', s)
  }
  get status(): USER_ROLE_STATUS {
    return this.get('status')
  }
  set status(s: USER_ROLE_STATUS) {
    this.set('status', s)
  }
  get kid(): Kid | undefined {
    return this.get('kid')
  }
  set kid(value: Kid | undefined) {
    this.set('kid', value)
  }
  get ACL(): Parse.ACL {
    return this.get('ACL')
  }
  set ACL(s: Parse.ACL) {
    this.set('ACL', s)
  }
  get role_type(): USER_ROLE_TYPE {
    const rt: USER_ROLE_TYPE = this.get('role_type')
    return rt !== undefined ? rt : USER_ROLE_TYPE.NONE
  }
  set role_type(r: USER_ROLE_TYPE) {
    this.set('role_type', r)
  }

  set archive_state(value: ARCHIVE_STATE) {
    this.set('archive_state', value)
  }
  get archive_state(): ARCHIVE_STATE {
    const value: ARCHIVE_STATE = this.get('archive_state')
    return value !== undefined ? value : ARCHIVE_STATE.ACTIVE
  }

  set user_is_deleted(value: boolean) {
    this.set('user_is_deleted', value)
  }
  set tyraRelation(value: string) {
    this.set('relation', value)
  }
  get tyraRelation(): string {
    return this.get('relation')
  }
  set last_modified(value: Date) {
    this.set('last_modified', value)
  }
  get invite_note(): string | undefined {
    return this.get('invite_note')
  }
  set invite_note(value: string | undefined) {
    this.set('invite_note', value)
  }
  set staff_schedule_responsable(value: boolean) {
    this.set('staff_schedule_responsable', value)
  }
  get staff_schedule_responsable(): boolean {
    return this.get('staff_schedule_responsable')
  }
  set staff_schedule_invisible(value: boolean | undefined) {
    this.set('staff_schedule_invisible', value)
  }
  get staff_schedule_invisible(): boolean | undefined {
    return this.get('staff_schedule_invisible')
  }
  set stat_access(value: boolean) {
    this.set('stat_access', value)
  }
  get stat_access(): boolean {
    return this.get('stat_access')
  }
  get staff_sensetive_info_bool(): boolean {
    return this.get('staff_sensetive_info_bool')
  }
  set staff_sensetive_info_bool(value: boolean) {
    this.set('staff_sensetive_info_bool', value)
  }

  get configuration(): UserRoleConfiguration | undefined {
    return this.get('configuration')
  }
  set configuration(value: UserRoleConfiguration | undefined) {
    this.set('configuration', value)
  }

  get userFullName(): string {
    if (this.get('archive_state') === 220) {
      return 'Anonym'
    }
    return (
      this.get('user').get('firstName') + ' ' + this.get('user').get('lastName')
    )
  }

  get fullName(): string {
    if (this.get('archive_state') === 220) {
      return 'Anonym'
    }
    return this.get('firstName') + ' ' + this.get('lastName')
  }
  set is_upload_document_parent(value: boolean) {
    this.set('is_upload_document_parent', value)
  }
  get is_upload_document_parent(): boolean {
    return this.get('is_upload_document_parent')
  }

  public isActive(): boolean {
    return this.status === USER_ROLE_STATUS.ACTIVE
  }
}
Parse.Object.registerSubclass('user_roles', UserRole)
