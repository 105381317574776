import { observer } from 'mobx-react'
import CustomInputNew from '../../../../../components/CustomInputNew'
import { useContext, useEffect, useState } from 'react'
import { DepartmentStoreContext } from '../../../../../store/departmentStore'
import { UserStoreContext } from '../../../../../store/userStore'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../../../components/Button'
import ModalRegular from '../../../../../components/ModalRegular'
import { Slider } from '../../../../../components/Slider'
import { Department } from '../../../../../Models/Department'

interface IProps {
  permissions: {
    isAdmin: boolean
    linkedToSchool: boolean
    canEditSchedule: boolean
    hasSensitiveAccess: boolean
    hasStatisticsAccess: boolean
    canUploadEditTyra: boolean
  }
  toggleSwitch: (key: keyof IProps['permissions']) => void
  title: string
  setTitle: React.Dispatch<React.SetStateAction<string>>
  chosenDepIds: Department[]
  setChosenDepIds: React.Dispatch<React.SetStateAction<Department[]>>
  onlyTitleAndPermissions?: boolean
}
export const TitleAndQualificationStaff: React.FC<IProps> = observer(
  ({
    permissions,
    toggleSwitch,
    title,
    setTitle,
    chosenDepIds,
    setChosenDepIds,
    onlyTitleAndPermissions,
  }) => {
    /* const [title, setTitle] = useState<string>()
    const [chosenDepIds, setChosenDepIds] = useState<string[]>([]) */

    const { fetchDepartmentsForMultiSchools, departmentsForMultiSchool } =
      useContext(DepartmentStoreContext)
    const { currentUserRole } = useContext(UserStoreContext)
    const [showDepartmentsModal, setShowDepartmentsModal] =
      useState<boolean>(false)
    const { t } = useTranslation()

    useEffect(() => {
      fetchDepartmentsForMultiSchools([
        currentUserRole?.school_pointer.id ?? '',
      ])
    }, [])
    console.log('------------- DEPS ', departmentsForMultiSchool)

    return (
      <div
        id="titleAndDeps"
        className={`${onlyTitleAndPermissions ? 'p-2' : 'p-8'}`}
      >
        <div className="flex justify-center text-xl font-bold">
          !!Title och behörighet
        </div>
        <p className="text-xs flex justify-center">
          !Detta är arbetstiteln på skolan. Frivillig information.
        </p>
        <div id="title" className="mb-3 mt-2">
          <CustomInputNew
            onChange={(value) => {
              setTitle(value)
            }}
            placeholder="!!Title"
            longPlaceholder={'Personal'}
            label="!!Förnamn"
            value={title}
            format="default"
          />
        </div>
        <div id="ChooseDepartment">
          <div className=" rounded-2xl shadow-sm bg-white py-4 pl-4">
            <h2 className="font-semibold text-left">!!Välj avdelning</h2>

            <div className="flex whitespace-nowrap  gap-2 mb-3 py-1 h-[40px] overflow-auto scrollbar-hide">
              {departmentsForMultiSchool
                ?.map((dep) => dep)
                .slice()
                .sort((a, b) => {
                  const isASelected = chosenDepIds?.some(
                    (dep) => dep.id === a.id,
                  )
                    ? 0
                    : 1
                  const isBSelected = chosenDepIds?.some(
                    (dep) => dep.id === b.id,
                  )
                    ? 0
                    : 1
                  return isASelected - isBSelected
                })
                .map((dep) => {
                  console.log(dep)
                  return (
                    <Button
                      key={dep.id}
                      className="!text-white"
                      fontSize="font-semibold"
                      size="sm"
                      variant={
                        chosenDepIds?.find((depId) => depId.id === dep.id)
                          ? 'custom'
                          : 'fullWhiteGrayText'
                      }
                      bgrondColor={'bg-[#7EB5F4]'}
                      label={dep.klassName}
                      onClick={() => {
                        setChosenDepIds([dep])
                      }}
                    />
                  )
                })}
            </div>
            <div className="flex flex-row items-center justify-end w-full pr-4">
              <div>
                <h4
                  className="text-blueDark select-none cursor-pointer font-semibold rounded-full  text-sm"
                  onClick={() => setShowDepartmentsModal(true)}
                >
                  <>{t('events.chooseOrEdit')}</>
                </h4>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-4 rounded-2xl shadow-sm bg-white mt-3 p-3">
            <div className="flex flex-row justify-between w-full items-center text-center">
              <h1>
                <>{t('admin.userAdminPermission')}</>
              </h1>
              <div>
                <Slider
                  id={0}
                  onSliderClick={() => toggleSwitch('isAdmin')}
                  onBoxClick={() => {}}
                  value={permissions.isAdmin}
                />
              </div>
            </div>
            <div className="flex flex-row justify-between w-full items-center text-start">
              <h1>
                {' '}
                <>{t('admin.userSchoolAcc')}</>
              </h1>
              <div>
                <Slider
                  id={0}
                  onSliderClick={() => toggleSwitch('linkedToSchool')}
                  onBoxClick={() => {}}
                  value={permissions.linkedToSchool}
                />
              </div>
            </div>
            <div className="flex flex-row justify-between w-full items-center text-start">
              <h1>
                {' '}
                <>{t('admin.userEditSchedule')}</>
              </h1>
              <div>
                <Slider
                  id={0}
                  onSliderClick={() => toggleSwitch('canEditSchedule')}
                  onBoxClick={() => {}}
                  value={permissions.canEditSchedule}
                />
              </div>
            </div>

            <div className="flex flex-row justify-between w-full items-center text-start">
              <h1>
                <>{t('admin.userSensitiveInfo')}</>
              </h1>
              <div>
                <Slider
                  id={0}
                  onSliderClick={() => toggleSwitch('hasSensitiveAccess')}
                  onBoxClick={() => {}}
                  value={permissions.hasSensitiveAccess}
                />
              </div>
            </div>
            <div className="flex flex-row justify-between w-full items-center text-start">
              <h1>
                {' '}
                <>{t('admin.userStatistic')}</>
              </h1>
              <div>
                <Slider
                  id={0}
                  onSliderClick={() => toggleSwitch('hasStatisticsAccess')}
                  onBoxClick={() => {}}
                  value={permissions.hasStatisticsAccess}
                />
              </div>
            </div>
            <div className="flex flex-row justify-between w-full items-center text-start">
              <h1>
                <>{t('admin.userAllowChangeInfo')}</>
              </h1>
              <div>
                <Slider
                  id={0}
                  onSliderClick={() => toggleSwitch('canUploadEditTyra')}
                  onBoxClick={() => {}}
                  value={permissions.canUploadEditTyra}
                />
              </div>
            </div>
          </div>
        </div>
        <ModalRegular
          title={t('events.selectDepartmentsToPostTo')}
          isOpen={showDepartmentsModal}
          className={'!max-w-xl px-4 pt-6'}
          classNameTitle="mb-4 w-full text-black !important flex justify-center "
          onClose={() => {
            setShowDepartmentsModal(false)
          }}
        >
          <div className="h-auto max-h-[400px] overflow-auto pt-3">
            {
              <div className="flex flex-col gap-y-1  relative">
                <div className="flex flex-wrap whitespace-nowrap  gap-2 mb-3 py-1 overflow-auto scrollbar-hide pl-2">
                  {departmentsForMultiSchool?.map((dep) => {
                    return (
                      <Button
                        size="sm"
                        variant={
                          chosenDepIds?.find((depId) => depId.id === dep.id)
                            ? 'custom'
                            : 'fullWhiteGrayText'
                        }
                        label={dep.klassName}
                        className="!text-white h-[40px]"
                        bgrondColor={'bg-[#7EB5F4]'}
                        onClick={() => {
                          setChosenDepIds([dep])
                        }}
                      />
                    )
                  })}
                </div>
              </div>
            }
            <div className="sticky flex justify-center items-center w-full pb-2 h-[80px] bg-gradient-to-t from-[#f6f6f6]  bottom-0 left-0">
              <Button
                size="md"
                fontSize="font-bold"
                className="w-[70%]"
                variant="primary"
                label={t('general.done')}
                onClick={() => setShowDepartmentsModal(false)}
              />
            </div>
          </div>
        </ModalRegular>
      </div>
    )
  },
)
