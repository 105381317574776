import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EventListItemData } from './Events3'
import { UserStoreContext } from '../../store/userStore'
import { Event3StoreContext } from '../../store/event3Store'
import { USER_ROLE_TYPE } from '../../Enums/TyraEnums'
import EventParticipatingPopUp from './components/EventParticipatingPopUp'
import { observer } from 'mobx-react'
import { EventOverview } from './components/EventOverview'
import { IKidRelation } from './components/OverviewUsersList'
import { EventReqResOverview } from './EventReqResOverview'
import { EventExpendableCardHeader } from './components/EventDetailCard'
import { KidsStoreContext } from '../../store/kidsStore'
import { Kid } from '../../Models/Kid'

export interface IEventInfoOverviewUser {
  id: string
  userId: string
  name: string
  thumbnail?: string
  departmentIds: (string | undefined)[]
  departmentNames?: (string | undefined)[]
  kids?: IKidRelation[]
  hasRead?: boolean
  readAt?: string
  isUser: boolean
}

export interface IEventActivitiesOverviewUser {
  id: string
  userId: string
  kidId?: string
  name: string
  thumbnail?: string
  departmentIds: Array<string | undefined>
  relatives?: IKidRelation[]
  kids?: IKidRelation[]
  status?: string
  message?: string
  cancelledAt?: string
  extraParticipants?: number
  isUser: boolean
}

export interface IEventOverview {
  eventTimeId: string
  eventGroupInfoId: string
  departmentIds: string[]
  readCount: number
  unreadCount: number
  allCount: number
  readUsers: IEventInfoOverviewUser[]
  unreadUsers: IEventInfoOverviewUser[]
  notRepliedParticipants: IEventInfoOverviewUser[]
  lastReminderSent?: string
}

export interface IEventDetails extends IEventOverview {
  presentKidsCount: number
  acceptedCount: number
  declinedCount: number
  hasMessageCount: number
  notRepliedCount: number
  presentKids: IEventActivitiesOverviewUser[]
  acceptedParticipants: IEventInfoOverviewUser[]
  declinedParticipants: IEventInfoOverviewUser[]
  hasMessageParticipants: IEventInfoOverviewUser[]
}
export const EventDetailPage: React.FC<{
  eventTimeId?: string
  fetchedEventDetail?: EventListItemData
  triggerHasResponded?: () => void
  hasResponded: boolean
  isExpanded?: boolean
  handleExpaneded: () => void
  setFetchedEventDetail: (value: EventListItemData) => void
  openSttings: boolean
  setOpenSettings: (value: boolean) => void
}> = observer(
  ({
    eventTimeId,
    fetchedEventDetail,
    triggerHasResponded,
    hasResponded,
    isExpanded,
    handleExpaneded,
    setFetchedEventDetail,
    openSttings,
    setOpenSettings,
  }) => {
    const { t } = useTranslation()
    const { currentUserRole } = useContext(UserStoreContext)
    const { getEvent3Overview } = useContext(Event3StoreContext)

    const [fetchedEventOverview, setFetchedEventOverview] =
      useState<IEventDetails>()
    const [isPopupVisible, setPopupVisible] = useState(false)
    const [isOverviewVisible, setOverviewVisible] = useState(false)

    const { fetchGuardiansForKids } = useContext(KidsStoreContext)
    /* const [selectedTime, setSelectedTime] =
      useState<EventTimeSmallInterface | null>(null) */

    /* const [choosePresentGuardians, setChoosePresentGuardians] = useState(false)
    const [timeRebookable, setTimeRebookable] = useState(true)
    const [presentGuardians, setPresentGuardians] = useState<UserRole[]>([])
    const [message, setMessage] = useState('') */
    const [loading, setLoading] = useState(false)

    const [showRebook, setShowRebook] = useState(false)
    /* const [showEditAttendees, setShowEditAttendees] = useState(false)
    const [showCancelBooking, setShowCancelBooking] = useState(false) */
    const [openHelpSection, setOpenHelpSection] = useState<boolean>(false)

    const validUserToSeeOverview = currentUserRole?.role_type
      ? currentUserRole?.role_type > USER_ROLE_TYPE.PARENT
      : false

    useEffect(() => {
      let isMounted = true
      if (validUserToSeeOverview) {
        const fetchEventOverview = async () => {
          try {
            const eventOverview = await getEvent3Overview(
              eventTimeId,
              fetchedEventDetail?.eventGroupInfoId,
              fetchedEventDetail?.departmentIds ?? [],
            )
            if (isMounted) {
              setFetchedEventOverview(eventOverview)
            }
          } catch (error) {
            console.error('Error fetching event detail: ', error)
          }
        }
        fetchEventOverview()
      }
      return () => {
        isMounted = false
      }
    }, [fetchedEventDetail, hasResponded])

    useEffect(() => {
      if (showRebook && fetchedEventDetail?.kidId) {
        fetchGuardiansForKids(
          Kid.createWithoutData(fetchedEventDetail.kidId) as Kid,
        )
      }
    }, [showRebook])

    const handleClosePopup = () => {
      setPopupVisible(false)
    }

    return (
      <>
        {!isOverviewVisible ? (
          <div
            className={`flex flex-col max-h-[400px] w-full rounded-3xl  overflow-y-auto justify-between  scrollbar-hide overflow-visible relative ${
              isPopupVisible ? 'filter blur-sm' : ''
            }`}
          >
            {/****************************** HEADER & content  div1 ******************************************* */}
            <div>
              {fetchedEventDetail && (
                <EventExpendableCardHeader
                  event={fetchedEventDetail}
                  setFetchedEventDetail={setFetchedEventDetail}
                  fetchedEventOverview={fetchedEventOverview}
                  setLoading={setLoading}
                  setPopupVisible={setPopupVisible}
                  isOverviewVisible={isOverviewVisible}
                  setOverviewVisible={setOverviewVisible}
                  openSettings={openSttings}
                  onOpenSetting={() => setOpenSettings(!openSttings)}
                  isExpanded={isExpanded}
                  triggerHasResponded={triggerHasResponded}
                  onClick={handleExpaneded}
                />
              )}

              {/****************************** END OF HEADER ******************************************* */}

              {/****************************** Start OF Settings faEllipsisV ******************************************* */}
            </div>
          </div>
        ) : fetchedEventDetail?.statusSummary ? (
          <EventReqResOverview
            eventDetail={fetchedEventDetail}
            setOverviewVisible={setOverviewVisible}
            isOverviewVisible={isOverviewVisible}
            eventOverview={fetchedEventOverview}
            refreshDetail={() => triggerHasResponded?.()}
            hasResponded={hasResponded}
            openHelpSection={openHelpSection}
            setOpenHelpSection={setOpenHelpSection}
          />
        ) : (
          <EventOverview
            eventDetail={fetchedEventDetail}
            setOverviewVisible={setOverviewVisible}
            isOverviewVisible={isOverviewVisible}
            eventOverview={fetchedEventOverview}
            refreshDetail={() => triggerHasResponded?.()}
            openHelpSection={openHelpSection}
            setOpenHelpSection={setOpenHelpSection}
          />
        )}
        <div className="flex items-center justify-center">
          {
            <EventParticipatingPopUp
              eventDetail={fetchedEventDetail}
              onClose={handleClosePopup}
              isOpen={isPopupVisible}
              refreshDetail={() => triggerHasResponded?.()}
            />
          }
        </div>
      </>
    )
  },
)
