import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { ChooseOrCreatePersonal } from './components/ChooseOrCreatePersonal'
import { ChooseExistingUser } from '../inviteParentsNew/components/ChooseExistingUser'
import Page from '../../../../components/Page'
import { UserRole } from '../../../../Models/UserRole'
import { Button } from '../../../../components/Button'
import { InformationAboutParent } from '../inviteParentsNew/components/InformationAboutParent'
import { InformationAboutStaff } from './components/InformationAboutStaff'
import {
  NewUser,
  newUserInitialValues,
} from '../inviteParentsNew/InviteParents'
import { useNavigate, useParams } from 'react-router-dom'
import { AdminStoreContext } from '../../../../store/adminStore'
import { LOGIN_METHOD } from '../../../../Enums/TyraEnums'
import ModalRegular from '../../../../components/ModalRegular'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAt,
  faCheck,
  faLocationDot,
  faPhone,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import { TitleAndQualificationStaff } from './components/TitleAndQualificationStaff'
import { NavigationStoreContext } from '../../../../store/navigationStore'

export const InvitePersonal: React.FC = observer(() => {
  const { t } = useTranslation()
  const [chosenInviteType, setChosenInviteType] = useState<
    'existing' | 'new' | undefined
  >(undefined)
  const [selectedUserRole, setSelectedUserRole] = useState<
    UserRole | undefined
  >(undefined)
  const [newUserInfo, setNewUserInfo] = useState<NewUser>(newUserInitialValues)
  const navigate = useNavigate()
  const { schoolId } = useParams()
  const {
    inviteExistingGuardian,
    inviteGuardian,
    inviteTeacher,
    checkIfUserExist,
  } = useContext(AdminStoreContext)
  const [userFound, setUserFound] = useState<
    | {
        email?: string
        name?: string
        nin?: string
        userId?: string
        phone?: string
        login_method?: LOGIN_METHOD
      }[]
    | null
  >(null)
  const [showSummary, setShowSummary] = useState(false)
  const [showInloggningsAlternatives, setShowInloggningsAlternatives] =
    useState<boolean>(false)
  const { setTitle } = useContext(NavigationStoreContext)

  useEffect(() => {
    setTitle(t('admin.inviteStaff'))
  }, [t])

  if (selectedUserRole) {
    console.log('----------> selectedUser:  ', selectedUserRole)

    return (
      <Page>
        <Button
          size="sm"
          className="w-[10%] mx-4 mt-4 text-nowrap"
          variant="callToActionBlue"
          fontSize="font-bold"
          label={t('general.backWithEmoji')}
          onClick={() => {
            setSelectedUserRole(undefined)
          }}
        />
        <InformationAboutStaff
          onlyTitleAndPermissions={true}
          newUserInfo={newUserInfo}
          setNewUserInfo={(userInfo: NewUser) => setNewUserInfo(userInfo)}
          userFound={userFound}
          setUserFound={setUserFound}
          showSummary={showSummary}
          setShowSummary={setShowSummary}
          onDone={() => {
            console.log('onDone', selectedUserRole)
            setShowSummary(true)
          }}
          selectedUserRole={selectedUserRole}
        />
      </Page>
    )
  }

  return (
    <div className="relative">
      <Page>
        <Button
          size="sm"
          className="w-[10%] mx-4 mt-4 text-nowrap"
          variant="callToActionBlue"
          fontSize="font-bold"
          label={t('general.backWithEmoji')}
          onClick={() => {
            if (chosenInviteType === undefined)
              navigate(`/admin/${schoolId}/invite`)
            else {
              setChosenInviteType(undefined)
              setNewUserInfo(newUserInitialValues)
            }
          }}
        />
        {chosenInviteType === undefined && (
          <ChooseOrCreatePersonal
            onChoose={(value) => {
              setChosenInviteType(value)
            }}
            onBack={() => {}}
          />
        )}
        {chosenInviteType === 'existing' && (
          <div className="p-8">
            <ChooseExistingUser
              onSelectedUserRole={(userRole) => {
                setSelectedUserRole(userRole)
              }}
              parentComponent={'inviteStaff'}
            />
          </div>
        )}

        {chosenInviteType === 'new' && (
          <InformationAboutStaff
            onlyTitleAndPermissions={false}
            newUserInfo={newUserInfo}
            setNewUserInfo={(userInfo: NewUser) => setNewUserInfo(userInfo)}
            userFound={userFound}
            setUserFound={setUserFound}
            showSummary={showSummary}
            setShowSummary={setShowSummary}
            showInloggningsAlternatives={showInloggningsAlternatives}
            setShowInloggningsAlternatives={setShowInloggningsAlternatives}
            onDone={async () => {
              console.log('inside onDone')

              if (
                newUserInfo.firstName &&
                (newUserInfo.pn || newUserInfo.email || newUserInfo.phone)
              ) {
                try {
                  const response = await checkIfUserExist({
                    nin: newUserInfo.pn?.replace('-', ''),
                    firstName: newUserInfo.firstName,
                    lastName: newUserInfo.lastName,
                    phone: newUserInfo.phone,
                    email: newUserInfo.email,
                  })

                  if (response && response.length) {
                    setUserFound(response)
                  } else {
                    /* setShowSummary(true) */
                    setShowInloggningsAlternatives(true)
                    setUserFound(null)
                  }
                } catch (e) {
                  console.error('Error checking user:', e)
                  setUserFound(null)
                }
              } else {
                console.log('Invalid input')
                setUserFound(null)
              }
            }}
          />
        )}
      </Page>
    </div>
  )
})
