import Parse from 'parse'
import { ARCHIVE_STATE, USER_SETTING, USER_TYPE } from '../Enums/TyraEnums'
import { School } from './School'
import { UserExtra } from './UserExtra'
import { UserNin } from './UserNin'
//import { UserExtra } from "./UserExtra"

Parse.User.allowCustomUserClass(true)

export class User extends Parse.User {
  public static readonly Fields = {
    update_user_roles: 'update_user_roles',
    user_setting: 'user_setting',
    user_extra: 'user_extra',
    update_deps: 'update_deps',
    username: 'username',
    email: 'email',
    archive_state: 'archive_state',
    user_nin: 'user_nin',
    update_kids: 'update_kids',
    update_kids_dep: 'update_kids_dep',
  }
  constructor() {
    super(Parse.User)
  }

  get myPic_thumb(): Parse.File | undefined {
    return this.get('myPic_thumb')
  }
  get school_pointer(): School {
    return this.get('school_pointer')
  }
  get user_setting(): USER_SETTING {
    return this.get('user_setting')
  }
  get user_nin(): UserNin {
    return this.get('user_nin')
  }

  get myPic(): Parse.File | undefined {
    return this.get('myPic')
  }
  set myPic(value: Parse.File | undefined) {
    this.set('myPic', value)
  }
  get theSchool(): string {
    return this.get('theSchool')
  }
  set theSchool(value: string) {
    this.set('theSchool', value)
  }
  public get firstName(): string | undefined {
    return this.get('firstName')
  }
  public set firstName(value: string | undefined) {
    this.set('firstName', value)
  }
  get email(): string | undefined {
    return this.get('email')
  }
  set email(value: string | undefined) {
    this.set('email', value)
  }
  get emailVerified(): boolean {
    return this.get('emailVerified')
  }
  set emailVerified(value: boolean) {
    this.set('emailVerified', value)
  }
  get convert_email(): boolean {
    return this.get('convert_email')
  }
  set convert_email(value: boolean) {
    this.set('convert_email', value)
  }
  get user_type(): USER_TYPE {
    return this.get('user_type')
  }
  set user_type(value: USER_TYPE) {
    this.set('user_type', value)
  }
  get user_extra(): UserExtra {
    return this.get('user_extra')
  }
  //set user_extra(value: UserExtra) { this.set("user_extra", value) }
  set username(value: string) {
    this.set('username', value)
  }
  get username(): string {
    return this.get('username')
  }
  set gen_token(value: string) {
    this.set('gen_token', value)
  }
  get gen_token(): string {
    return this.get('gen_token')
  }
  set password(value: string) {
    this.set('password', value)
  }
  set archive_state(value: ARCHIVE_STATE) {
    this.set('archive_state', value)
  }
  get lastName(): string | undefined {
    return this.get('lastName')
  }
}
Parse.User.registerSubclass('_User', User)
