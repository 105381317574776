/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import Parse, { User } from 'parse'
import { makeAutoObservable } from 'mobx'
import { logout } from '../api/api'
import { UserRole } from '../Models/UserRole'
import { Notification } from '../Models/Notification'
import {
  LoginSessionResponse,
  LOGIN_METHOD,
  USER_ROLE_STATUS,
  USER_SETTING,
  USER_ROLE_TYPE,
} from '../Enums/TyraEnums'
import { UserRoleConfigurationBoolean } from '../App/intefaces/configuration'
import { getReadOnly } from '../utils/parseUtils'
import { School } from '../Models/School'
import moment from 'moment'

Parse.serverURL = process.env.REACT_APP_SERVER_URL ?? ''
Parse.initialize(
  process.env.REACT_APP_APP_ID ?? '',
  process.env.REACT_APP_JS_KEY ?? '',
)

interface NoticesJson {
  header: string
  content: string
  createdAt: Date
  updatedAt: Date
  module: number
  action: number
  createdBy: string
  readAt?: Date
  editedAt?: string
  id: string
  //relation ??
}

interface CreateUserRoleParams {
  userId?: string
  personUUID?: string
  roleNum: number
  schoolId: string
  kidId?: string
  title?: string
  directActivation?: boolean
  relation?: string
  department?: string
}

class UserStore {
  currentUser?: Parse.User
  savedUserRoleId?: string
  currentUserRole?: UserRole
  userRoles?: UserRole[]
  allUserRolesWithKid?: UserRole[]
  userRolesToInviteToKid?: UserRole[]
  statusNewUserRoles?: UserRole[]
  userNotActive?: boolean
  readOnly?: UserRoleConfigurationBoolean
  is_upload_document_parent?: boolean
  allUsersForSchool?: Parse.User[]
  manuals?: any[]
  noticeCenterNewPost?: any[] //fix moduel
  //messagesAndPosts?: any[]
  messagesAndPosts?: NoticesJson[]
  allNotices?: NoticesJson[]
  schedulePressence?: any[]
  authorized?: any[]
  myActiveUserRoles?: UserRole[]
  myPendingUserRoles?: UserRole[]
  myNewUserRoles?: UserRole[]
  myPausedUserRoles?: UserRole[]
  schoolsForUserRoleAdmins?: School[]
  teacherUserRoles?: UserRole[]
  expiredSession?: boolean
  teacherUserRole?: UserRole
  alreadyInvitedButNotAcceptedStaff?: UserRole[]
  existingGuardians?: UserRole[]

  constructor(roleId?: string) {
    const u = Parse.User.current()
    this.savedUserRoleId = roleId
    this.userNotActive = false
    this.currentUser = u
    if (u) {
      u.fetch().then((usr) => {
        if (roleId) {
          this.fetchUserRole(roleId)
        }
        this.setCurrentUser(usr)
      })
    }
    makeAutoObservable(this)
  }

  setTeacherUserRole = (userRole: UserRole) => {
    this.teacherUserRole = userRole
  }
  setTeacherUserRoles = (userRoles: UserRole[]) => {
    this.teacherUserRoles = userRoles
  }

  setSchoolsForUserRoleAdmins = (schools: School[]) => {
    this.schoolsForUserRoleAdmins = schools
  }
  setCurrentUser = (currentUser?: Parse.User) => {
    this.currentUser = currentUser
  }

  setNoticeCenterNewPost = (newpost: any) => {
    this.noticeCenterNewPost = newpost
  }

  setMessagesAndPosts = (newmessage: any) => {
    this.messagesAndPosts = newmessage
  }

  setAllNotices = (allNotices: any) => {
    this.allNotices = allNotices
  }

  setSchedulePressence = (schedule: any) => {
    this.schedulePressence = schedule
  }

  setAuthorized = (author: any) => {
    this.authorized = author
  }

  setAllUsersForSchool = (users: Parse.User[]) => {
    this.allUsersForSchool = users
  }

  setManuals = (manuals: any) => {
    this.manuals = manuals
  }

  setMyActiveUserRoles = (userRoles: UserRole[]) => {
    this.myActiveUserRoles = userRoles
  }
  setMyPendingUserRoles = (userRoles: UserRole[]) => {
    this.myPendingUserRoles = userRoles
  }
  setMyNewUserRoles = (userRoles: UserRole[]) => {
    this.myNewUserRoles = userRoles
  }
  setMyPausedUserRoles = (userRoles: UserRole[]) => {
    this.myPausedUserRoles = userRoles
  }

  setCurrentUserRole = (role: UserRole) => {
    this.currentUserRole = role
    this.setSavedUserRoleId(role.id)
    this.readOnly = getReadOnly(role)
  }

  setUserRoles = (roles: UserRole[]) => {
    this.userRoles = roles
  }

  setAllUserRolesWithKid = (roles: UserRole[]) => {
    this.allUserRolesWithKid = roles
  }
  setNewStatusNewUserRoles = (roles: UserRole[]) => {
    this.statusNewUserRoles = roles
  }
  setUserRolesToInviteToKid = (roles: UserRole[]) => {
    this.userRolesToInviteToKid = roles
  }

  setAlreadyInvitedButNotAcceptedStaff = (roles: UserRole[]) => {
    this.alreadyInvitedButNotAcceptedStaff = roles
  }

  setExistingGuardian = (roles: UserRole[]) => {
    this.existingGuardians = roles
  }

  setSavedUserRoleId = (id?: string) => {
    if (id) {
      localStorage.setItem('user_role_id', id)
    } else {
      localStorage.removeItem('user_role_id')
    }

    this.savedUserRoleId = id
  }

  setUserNotActive = (value: boolean) => {
    this.userNotActive = value
  }

  setExpiredSession = (value: boolean) => {
    this.expiredSession = value
  }

  loginUser = async (username: string, password: string) => {
    /* const parseUser = new Parse.User()

    parseUser.set('username', username)
    parseUser.set('password', password)
    const user = await parseUser.logIn()
    if (user) {
      this.setCurrentUser(user)
    }*/

    return await Parse.Cloud.run('loginWithCloud', {
      username,
      password,
    })
  }

  logoutUser = async () => {
    await logout()
    this.setCurrentUser(undefined)
    this.setSavedUserRoleId()
    localStorage.setItem('sessionExpired', 'true')
  }

  fetchKidsForUserOnSchool = async (schoolId: string) => {
    const usersRolesQuery = new Parse.Query(UserRole)
      .equalTo('user', Parse.User.current())
      .equalTo('status', 40)
      .equalTo('school_pointer', School.createWithoutData(schoolId))
      .exists('kid')
      .include('kid')
      .limit(1000)

    const roles = await usersRolesQuery.find()
    const kids = roles.map((role) => role.kid)
    return kids
  }
  //Fetchar anvädare i inloggningsanvändaren
  fetchMyActiveUserRoles = async (schoolId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const currentUserRole = userStore.currentUserRole
    const userRoleQuery = new Parse.Query(UserRole)
      .equalTo('status', USER_ROLE_STATUS.ACTIVE)
      //.equalTo('school', currentUserRole?.school)
      .equalTo('user', userStore.currentUser)
      .include('user')
      .include('kid')
      .include('user.user_extra')
      .include('user.user_nin')
      .include('school_pointer')
      .include('school_pointer.school_setting')

    const userRoles = await userRoleQuery.limit(1000).find()
    this.setMyActiveUserRoles(userRoles)
  }

  fetchMyPendingUserRoles = async () => {
    const currentUserRole = userStore.currentUserRole
    const userRoleQuery = new Parse.Query(UserRole)
      .equalTo('status', USER_ROLE_STATUS.PENDING)
      //.equalTo('school', currentUserRole?.school)
      .equalTo('user', userStore.currentUser)

      .include('user')
      .include('kid')
      .include('user.user_extra')
      .include('user.user_nin')
      .include('school_pointer')

    const userRoles = await userRoleQuery.limit(1000).find()
    this.setMyPendingUserRoles(userRoles)
  }

  fetchMyNewUserRoles = async (schoolId: string) => {
    const currentUserRole = userStore.currentUserRole
    const userRoleQuery = new Parse.Query(UserRole)
      .equalTo('status', USER_ROLE_STATUS.NEW)
      //.equalTo('school', currentUserRole?.school)
      .equalTo('user', userStore.currentUser)

      .include('user')
      .include('kid')
      .include('user.user_extra')
      .include('user.user_nin')
      .include('school_pointer')

    const userRoles = await userRoleQuery.limit(1000).find()
    this.setMyNewUserRoles(userRoles)
  }

  fetchMyPausedUserRoles = async () => {
    const currentUserRole = userStore.currentUserRole
    const userRoleQuery = new Parse.Query(UserRole)
      .equalTo('status', USER_ROLE_STATUS.PAUSED)
      //.equalTo('school', currentUserRole?.school)
      .equalTo('user', userStore.currentUser)
      .include('user')
      .include('kid')
      .include('staff_schedule_invisible')
      .include('school_pointer')
    const userRoles = await userRoleQuery.limit(1000).find()
    this.setMyPausedUserRoles(userRoles)
  }

  fetchNewUserRoles = async (schoolId: string) => {
    const usersRolesQuery = new Parse.Query(UserRole)
      // .equalTo('school_pointer', School.createWithoutData(schoolId))
      .equalTo('status', USER_ROLE_STATUS.NEW)
      .equalTo('user', userStore.currentUser)
      .include('kid')
      .include('user')
      .limit(1000)

    const userRoles = await usersRolesQuery.find()
    this.setNewStatusNewUserRoles(userRoles)
  }

  //Fetchar anvädare i inloggningsanvändaren

  fetchUserRoles = async () => {
    const usersRolesQuery = new Parse.Query(UserRole)
      .equalTo('user', Parse.User.current())
      .equalTo('status', 40)
      .include('user_role_setting')
      .include('kid')
      .include('school_pointer')
      .limit(1000)

    const roles = await usersRolesQuery.find()
    this.setUserRoles(roles)
  }

  fetchTeacherUserRoles = async (schooldIds: string[]) => {
    const usersRolesQuery = new Parse.Query(UserRole)
      .containedIn(
        'school_pointer',
        schooldIds.map((id) => School.createWithoutData(id)),
      )
      .greaterThanOrEqualTo('role_type', USER_ROLE_TYPE.TEACHER)
      .equalTo('status', 40)
      .include('user')
      .limit(1000)

    const roles = await usersRolesQuery.find()

    for (const role of roles) {
      console.log('namn: ', role.user?.firstName + ' ' + role.user?.lastName)
    }

    this.setTeacherUserRoles(roles)
  }

  fetchUserRolesToInviteToKid = async (schoolId: string) => {
    const usersRolesQuery = new Parse.Query(UserRole)
      .equalTo('school_pointer', School.createWithoutData(schoolId))
      .include('kid')
      .include('user.user_nin')
      .include('user.user_extra')
      .greaterThanOrEqualTo('status', USER_ROLE_STATUS.PAUSED)
      .lessThanOrEqualTo('status', USER_ROLE_STATUS.ACTIVE)
      .limit(1000)

    const roles = await usersRolesQuery.find()
    this.setUserRolesToInviteToKid(roles)
  }

  fetchAlreadyInvitedButNotAcceptedStaff = async (schoolId: string) => {
    const usersRolesQuery = new Parse.Query(UserRole)
      .equalTo('school_pointer', School.createWithoutData(schoolId))
      .include('user')
      .greaterThanOrEqualTo('role_type', USER_ROLE_TYPE.TEACHER_LOWEST)
      .exists('user')
      .containedIn('status', [USER_ROLE_STATUS.NEW, USER_ROLE_STATUS.ACTIVE])
      .limit(1000)

    const roles = await usersRolesQuery.find()
    console.log(roles)

    this.setAlreadyInvitedButNotAcceptedStaff(roles)
  }

  fetchExistingGuardianToBeInviteAsStaff = async (schoolId: string) => {
    const usersRolesQuery = new Parse.Query(UserRole)
      .equalTo('school_pointer', School.createWithoutData(schoolId))
      .include([
        'user.user_extra',
        'person',
        'alternative_contact',
        'user.user_nin',
        'kid.departmentPointer',
      ])
      .doesNotExist('archive_state') // Ensure archive_state does not exist
      .limit(100000) // Large limit to fetch all entries

    try {
      const roles = await usersRolesQuery.find()
      console.log('Fetched User Roles:', roles)

      // Set state or process the data
      this.setExistingGuardian(roles)
    } catch (error) {
      console.error('Error fetching user roles:', error)
    }
  }

  fetchAllUserRolesWithKid = async (schoolId: string) => {
    const usersRolesQuery = new Parse.Query(UserRole)
      .equalTo('school_pointer', School.createWithoutData(schoolId))
      .exists('kid')
      .include('kid')
      .limit(1000)

    const roles = await usersRolesQuery.find()
    this.setAllUserRolesWithKid(roles)
  }

  fetchUserRole = async (id: string) => {
    if (id) {
      const userRoleQuery = new Parse.Query(UserRole)
        .equalTo('status', USER_ROLE_STATUS.ACTIVE)
        .equalTo('objectId', id)
        .include('kid')
        .include('school_pointer')
        .include('school_pointer.school_setting')
        .include('school_pointer.idp')
        .limit(1000)
      const role = await userRoleQuery.first()
      if (role) {
        this.setCurrentUserRole(role)
      } else {
        this.setUserNotActive(true)
        //this.selectFirstUserRole()
      }
    }
  }

  fetchTeacherUserRole = async (id: string) => {
    if (id) {
      const userRoleQuery = new Parse.Query(UserRole)
        .equalTo('status', USER_ROLE_STATUS.ACTIVE)
        .equalTo('objectId', id)
        .include('user')
        .include('user.nin')
      const role = await userRoleQuery.first()
      if (role) {
        this.setTeacherUserRole(role)
      }
    }
  }

  selectFirstUserRole = async () => {
    await this.fetchUserRoles()
    if (this.userRoles?.length) {
      this.setCurrentUserRole(this.userRoles[0])
      this.setUserNotActive(false)
      return this.userRoles[0].id
    }
  }

  checkUser = async (username: string) => {
    return await Parse.Cloud.run('checkUser', {
      username,
    })
  }

  loginWithNin = async (nin: string, thisDevice: boolean) => {
    return await Parse.Cloud.run('loginWithNIN', {
      nin: nin,
      thisDevice: thisDevice,
    })
  }
  pollLoginWithNIN = async (sessionId: string) => {
    return await Parse.Cloud.run('pollLoginWithNIN', {
      sessionId: sessionId,
    })
  }

  pollIdpLogin = async (idpSessionId: string, username: string) => {
    return await Parse.Cloud.run('pollIdpLogin', {
      idpSessionId: idpSessionId,
      username: username,
    })
  }

  createLoginRequest = async (username: string) => {
    // Kolla om username har IDP login.

    return await Parse.Cloud.run('createLoginRequest', {
      entityId: 'https://sso.hervar.se/nidp/saml2/metadata',
      username: username,
    })
  }

  /* Start the new login */
  loginNew = async (
    username: string,
    loginMethod: LOGIN_METHOD,
    chosenUserId?: string | undefined,
    chosenId?: string | undefined,
  ): Promise<LoginSessionResponse> => {
    const clientType = 'web'
    return await Parse.Cloud.run('initiateLoginSession', {
      username,
      loginMethod,
      clientType,
      chosenUserId,
      chosenId,
    })
  }

  pollLoginSession = async (
    loginSessionId: string,
    clientAction: string,
    password?: string,
    verificationCode?: string,
    thisDevice?: boolean,
    cancelGrandIdSession?: boolean,
  ): Promise<LoginSessionResponse> => {
    return await Parse.Cloud.run('pollLoginSession', {
      loginSessionId,
      password,
      thisDevice,
      verificationCode,
      clientAction,
      cancelGrandIdSession,
    })
  }
  getCurrentUserInfo = async () => {
    const userInfo = await Parse.User.current()?.fetchWithInclude([
      'user_extra',
      'user_nin',
    ])
  }
  changeUserSetting = async (user: User, value: USER_SETTING) => {
    user.set('user_setting', value)
    //await user.save()
  }
  saveUserChanges = async (user: User) => {
    await user.save()
  }
  deleteUser = async (userId: string | undefined, value: number) => {
    console.log('userId', userId)
    console.log('value', value)
    await Parse.Cloud.run('deleteUserAcc', {
      userId: userId,
      value: value,
    })
  }
  fetchManualDoc = async (territory: number) => {
    //måste filtrera på territory
    //Behöver göra en OR query på territory eller undefined
    const manualsDocQueryUndefined = new Parse.Query('manuals')
    manualsDocQueryUndefined.doesNotExist('territory')
    const manualsDocQuery = new Parse.Query('manuals')
    manualsDocQuery.containedIn('territory', [territory])
    const bothQuerys = Parse.Query.or(manualsDocQueryUndefined, manualsDocQuery)
    const manuals = await bothQuerys.find()
    this.setManuals(manuals)
  }
  /* End the new login */

  createNoticeContent(notice: Notification) {
    if (notice.module === 1337) {
      return 'Något'
    }

    return 'En liten content'
  }

  createNoticeHeader(notice: Notification) {
    if (notice.module === 1337) {
      return 'Något'
    }

    return 'En liten header'
  }

  cleanData = async (notices: Notification[]) => {
    const noticesJson: NoticesJson[] = []

    for (const notice of notices) {
      const row: NoticesJson = {
        header: this.createNoticeHeader(notice),
        content: this.createNoticeContent(notice),
        module: notice.module,
        action: notice.action,
        createdAt: notice.createdAt,
        updatedAt: notice.updatedAt,
        createdBy:
          notice?.attributes?.notice_creator?.firstName +
          ' ' +
          notice?.attributes?.notice_creator?.lastName,
        readAt: notice.readAt,
        editedAt: notice.editedAt
          ? moment(notice.editedAt).format('YYYY-MM-DD')
          : undefined,
        id: notice.id,
      }
      noticesJson.push(row)
    }

    return noticesJson
  }

  fetchNotice = async () => {
    const userRole = userStore.currentUserRole
    const newPostsNumbers = [17, 9]
    const messagesCommentsNumbers = [54, 53, 10, 1, 2, 3, 4, 10, 12]
    const schedulePresenceNumbers = [7, 8, 14, 5, 6, 11, 15, 16, 13, 14, 31, 32]
    const authorizedNumber = [98, 99, 18]
    const noticeForUser = new Parse.Query(Notification)

    noticeForUser.equalTo('user', userRole?.user)
    noticeForUser.include('origin')
    noticeForUser.include('notice_creator')
    noticeForUser.include('user')
    noticeForUser.limit(200)

    const results = await noticeForUser.find()
    const newPosts = await this.cleanData(
      results.filter((res: Notification) =>
        newPostsNumbers.includes(res.module),
      ),
    )
    this.setNoticeCenterNewPost(newPosts)

    /*const messageComments = results.filter((res) =>
      messagesCommentsNumbers.includes(res.attributes.module),
    )*/

    const messageComments = await this.cleanData(
      results.filter((res: Notification) =>
        messagesCommentsNumbers.includes(res.module),
      ),
    )

    this.setMessagesAndPosts(messageComments)
    console.log('messageComments123', messageComments)

    const allNotices = await this.cleanData(
      results.filter((res: Notification) => results),
    )
    this.setAllNotices(allNotices)

    const schedulePresence = await this.cleanData(
      results.filter((res: Notification) =>
        schedulePresenceNumbers.includes(res.module),
      ),
    )
    this.setSchedulePressence(schedulePresence)

    const authorized = await this.cleanData(
      results.filter((res: Notification) =>
        authorizedNumber.includes(res.module),
      ),
    )
    this.setAuthorized(authorized)
    return results
  }
  absenceCreateBool = async (userRole: UserRole, value: boolean) => {
    userRole.user_role_setting.set('absence_push', value)
    await userRole.save()
  }
  checkInfoKidBool = async (userRole: UserRole, value: boolean) => {
    userRole.user_role_setting.set('check_info_push', value)
    await userRole.save()
  }
  newEventBool = async (userRole: UserRole, value: boolean) => {
    userRole.user_role_setting.set('events_push', value)
    await userRole.save()
  }
  newBlogPostBool = async (userRole: UserRole, value: boolean) => {
    userRole.user_role_setting.set('blog_push', value)
    await userRole.save()
  }
  newBlogCommentBool = async (userRole: UserRole, value: boolean) => {
    userRole.user_role_setting.set('blog_push_comment', value)
    await userRole.save()
  }
  newPortPostBool = async (userRole: UserRole, value: boolean) => {
    userRole.user_role_setting.set('portfolio_push', value)
    await userRole.save()
  }
  newPortCommentBool = async (userRole: UserRole, value: boolean) => {
    userRole.user_role_setting.set('portfolio_comments_push', value)
    await userRole.save()
  }
  scheduleEditKidBool = async (userRole: UserRole, value: boolean) => {
    userRole.user_role_setting.set('kids_schedule_push', value)
    await userRole.save()
  }
  staffScheduleEditBool = async (userRole: UserRole, value: boolean) => {
    userRole.user_role_setting.set('staff_schedule_push', value)
    await userRole.save()
  }
  createNoteKidBool = async (userRole: UserRole, value: boolean) => {
    userRole.user_role_setting.set('notes_push', value)
    await userRole.save()
  }
  documentCreatedBool = async (userRole: UserRole, value: boolean) => {
    userRole.user_role_setting.set('document_push', value)
    await userRole.save()
  }
  staffCheckBool = async (userRole: UserRole, value: boolean) => {
    userRole.user_role_setting.set('staff_check_push', value)
    await userRole.save()
  }
  adminEditCheckBool = async (userRole: UserRole, value: boolean) => {
    userRole.user_role_setting.set('staff_check_edit_push', value)
    await userRole.save()
  }
  //Vårdnadshavare
  kidCheckInBool = async (userRole: UserRole, value: boolean) => {
    userRole.user_role_setting.set('check_in_push', value)
    await userRole.save()
  }
  kidCheckOutBool = async (userRole: UserRole, value: boolean) => {
    userRole.user_role_setting.set('check_out_push', value)
    await userRole.save()
  }
  boxEmpty = async (userRole: UserRole, value: boolean) => {
    userRole.user_role_setting.set('box_none_push', value)
    await userRole.save()
  }
  boxAlmostEmpty = async (userRole: UserRole, value: boolean) => {
    userRole.user_role_setting.set('box_some_push', value)
    await userRole.save()
  }
  markAllTeacherBool = async (userRole: UserRole, value: boolean) => {
    userRole.user_role_setting.set('absence_push', value)
    userRole.user_role_setting.set('check_info_push', value)
    userRole.user_role_setting.set('events_push', value)
    userRole.user_role_setting.set('blog_push', value)
    userRole.user_role_setting.set('blog_push_comment', value)
    userRole.user_role_setting.set('portfolio_comments_push', value)
    userRole.user_role_setting.set('kids_schedule_push', value)
    userRole.user_role_setting.set('staff_schedule_push', value)
    userRole.user_role_setting.set('notes_push', value)
    userRole.user_role_setting.set('document_push', value)
    userRole.user_role_setting.set('staff_check_push', value)
    userRole.user_role_setting.set('staff_check_edit_push', value)
    await userRole.save()
  }
  markAllParentBool = async (userRole: UserRole, value: boolean) => {
    userRole.user_role_setting.set('check_in_push', value)
    userRole.user_role_setting.set('check_out_push', value)
    userRole.user_role_setting.set('check_info_push', value)
    userRole.user_role_setting.set('events_push', value)
    userRole.user_role_setting.set('blog_push', value)
    userRole.user_role_setting.set('blog_push_comment', value)
    userRole.user_role_setting.set('portfolio_push', value)
    userRole.user_role_setting.set('portfolio_comments_push', value)
    userRole.user_role_setting.set('kids_schedule_push', value)
    userRole.user_role_setting.set('document_push', value)
    userRole.user_role_setting.set('staff_check_edit_push', value)
    userRole.user_role_setting.set('box_none_push', value)
    userRole.user_role_setting.set('box_some_push', value)
    await userRole.save()
  }
  selectNoticeDeparments = async (userRole: UserRole, departmentId: string) => {
    // Kolla om active_departments finns eller är undefined
    if (userRole.user_role_setting.attributes.active_departments) {
      // Kolla om departmentId finns i active_departments
      if (
        userRole.user_role_setting.attributes.active_departments.includes(
          departmentId,
        )
      ) {
        // Ta bort från array
        const newArray =
          userRole.user_role_setting.attributes.active_departments.filter(
            (depId: string) => depId !== departmentId,
          )
        userRole.user_role_setting.set('active_departments', newArray)
        console.log('hej3')
      } else {
        // Lägg till i array
        const newArray = [
          ...userRole.user_role_setting.attributes.active_departments,
          departmentId,
        ]
        userRole.user_role_setting.set('active_departments', newArray)
      }
    } else {
      // Skapa array med departmentId i sig
      const newArray = [departmentId]
      userRole.user_role_setting.set('active_departments', newArray)
    }
    await userRole.save()
  }

  async getSchoolsForUser(userRole: UserRole) {
    if (!userStore.currentUser) {
      return
    }
    const usersRolesQuery = new Parse.Query(UserRole)
    usersRolesQuery.equalTo('user', userRole.user)
    const userRoles = await usersRolesQuery.find()

    const schoolQuery = new Parse.Query(School)
    schoolQuery.containedIn(
      'objectId',
      userRoles.map((uR) => uR.school_pointer.id),
    )
  }

  getSchoolsForUserRoleAdmins = async () => {
    if (!userStore.currentUser) {
      return
    }
    const usersRolesQuery = new Parse.Query(UserRole)
    usersRolesQuery.equalTo('user', userStore.currentUser)
    usersRolesQuery.greaterThanOrEqualTo('role_type', USER_ROLE_TYPE.ADMIN)
    usersRolesQuery.doesNotExist('archive_state')
    usersRolesQuery.equalTo('status', USER_ROLE_STATUS.ACTIVE)
    const userRoles = await usersRolesQuery.find()

    const schoolQuery = new Parse.Query(School)
    schoolQuery.containedIn(
      'objectId',
      userRoles.map((uR) => uR.school_pointer.id),
    )
    const schools = await schoolQuery.find()
    this.setSchoolsForUserRoleAdmins(schools)
  }

  createPersonInvite = async (data: {
    givenName: string
    familyName: string
    civicNo?: string
    email?: string
    emailType?: string
    phone?: string
    phoneType?: string
    streetAddress?: string
    locality?: string
    postalCode?: string
    country?: string
    schoolId: string
    userId?: string
    loginMethod: number
  }) => {
    const perosnData = {
      givenName: data.givenName,
      familyName: data.familyName,
      civicNo: data.civicNo,
      email: data.email,
      phone: data.phone,
      streetAddress: data.streetAddress,
      locality: data.locality,
      postalCode: data.postalCode,
      country: data.country,
      schoolId: data.schoolId,
      userId: data.userId,
      loginMethod: data.loginMethod,
    }
    console.log('perosnData from store ', perosnData)

    const person = await Parse.Cloud.run('createPerson', perosnData)
    console.log(person)

    return person
  }

  // add createRole function that should be used after creating a person
  createUserRole = async (params: CreateUserRoleParams) => {
    try {
      const result = await Parse.Cloud.run('createRole', params)
      console.log('UserRole Created:', result)
      return result
    } catch (error) {
      console.error('Error creating user role:', error)
      throw error
    }
  }
}

export const userStore = (() => {
  const roleId = localStorage.getItem('user_role_id') ?? undefined
  return new UserStore(roleId)
})()
export const UserStoreContext: React.Context<UserStore> =
  React.createContext(userStore)
